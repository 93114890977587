import React from 'react'
import { useTheme } from 'styled-components'
import { useViewport } from '../../hooks/useViewport'

import CloseWhite from '../../assets/icons/close-white.svg'
import CloseBlue from '../../assets/icons/close-blue.svg'
import MobileModalCloseWhite from '../../assets/icons/modal-mobile-close-white.svg'
import MobileModalCloseBlack from '../../assets/icons/modal-mobile-close-black.svg'

const ModalCloseButton = ({ handleClose }) => {
    const { isDark } = useTheme()
    const { isMobile } = useViewport()

    return (
        <div>
            {isMobile ? (
                <>
                    {isDark ? (
                        <img onClick={handleClose} className='close-icon' src={MobileModalCloseWhite} alt="" />
                    ) : (
                        <img onClick={handleClose} className='close-icon' src={MobileModalCloseBlack} alt="" />
                    )}
                </>
            ) : (
                <>
                    {isDark ? (
                        <img onClick={handleClose} className='close-icon' src={CloseWhite} alt="" />
                    ) : (
                        <img onClick={handleClose} className='close-icon' src={CloseBlue} alt="" />
                    )}
                </>
            )}
        </div>
    )
}

export default ModalCloseButton