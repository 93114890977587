import React from 'react'

import { useEditorContext } from '../../context/EditorContext'
import { imageColorToFilter } from '../../utils/functions'
import { useUpdateLogoMutation } from '../../api/api'

const EditorNavbarSymbol = () => {
    const {
        companyName, slogan, editableElement,
        horizontalFlip, imageWidth, verticalFlip,
        sloganColor, setLogo, imageHorizontalPosition,
        imageVerticalPosition, companyNameHorizontalPosition,
        companyNameVerticalPosition, sloganHorizontalPosition,
        sloganVerticalPosition, layout, sloganSpacing,
        companyNameSpacing, sloganTransform, companyNameTransform,
        backgroundColor, companyNameFontFamily, sloganFontFamily,
        fetchedIcons, currentTemplateData, setCurrentHistoryStep,
        setHistoryChanges, currentHistoryStep, logo, imageColor,
        companyNameColor,
    } = useEditorContext()

    const [updateLogo] = useUpdateLogoMutation()

    const handleSelectSymbol = (symbol) => {
        setLogo(symbol)
        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = {
                logo: {
                    prev: logo,
                    next: symbol,
                }
            }

            return cuttedToCurrent
        })

        updateLogo({ id: currentTemplateData.id, icon: symbol }).then((res) => {
            if ('error' in res) {

            } else {
                setLogo(res.data.icon)
            }
        })
    }

    return (
        <div className='editor-navbar-item-content'>
            <div className='editor-suggested-templates-list'>
                {fetchedIcons.map((symbol) => (
                    <div
                        key={symbol.id}
                        onClick={() => handleSelectSymbol(symbol.svg)}
                        style={{
                            width: '100%',
                            minHeight: '180px',
                            backgroundColor: `${backgroundColor}`,
                            flexDirection: `${layout === 'top' ? 'column' : layout === 'bottom' ? 'column-reverse' : layout === 'left' ? 'row' : 'row-reverse'}`
                        }}
                        className='preview-image-block'
                    >
                        {symbol.svg && (
                            <div
                                style={{ transform: `translateX(${imageHorizontalPosition}px) translateY(${imageVerticalPosition}px)` }}
                                className={`generated-logo-icon ${editableElement === 'image' ? 'editable' : ''}`}
                            >
                                <img
                                    draggable={false}
                                    style={{
                                        transform: `rotateX(${horizontalFlip ? '180deg' : '0deg'}) rotateY(${verticalFlip ? '180deg' : '0deg'})`,
                                        filter: `${imageColorToFilter(imageColor)}`,
                                        width: `${imageWidth < 60 ? imageWidth : 60}px`,
                                    }}
                                    src={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(symbol.svg)}`}
                                    alt=""
                                />
                            </div>
                        )}
                        <div className='generated-logo-text'>
                            <span
                                style={{
                                    color: `${companyNameColor}`,
                                    fontFamily: `${companyNameFontFamily}`,
                                    fontSize: `16px`,
                                    transform: `translateX(${companyNameHorizontalPosition}px) translateY(${companyNameVerticalPosition}px)`,
                                    letterSpacing: `${companyNameSpacing}px`,
                                    textTransform: `${companyNameTransform}`,
                                    textAlign: `${layout === 'top' ? 'center' : layout === 'bottom' ? 'center' : layout === 'left' ? 'start' : 'end'}`,
                                    wordBreak: 'break-all',
                                }}
                                className={`generated-logo-company-name ${editableElement === 'company' ? 'editable' : ''}`}
                            >
                                {companyName}
                            </span>
                            {slogan && (
                                <span
                                    style={{
                                        color: `${sloganColor}`,
                                        fontFamily: `${sloganFontFamily}`,
                                        fontSize: `12px`,
                                        transform: `translateX(${sloganHorizontalPosition}px) translateY(${sloganVerticalPosition}px)`,
                                        letterSpacing: `${sloganSpacing}px`,
                                        textTransform: `${sloganTransform}`,
                                        textAlign: `${layout === 'top' ? 'center' : layout === 'bottom' ? 'center' : layout === 'left' ? 'start' : 'end'}`,
                                        wordBreak: 'break-all',
                                    }}
                                    className={`generated-logo-slogan ${editableElement === 'slogan' ? 'editable' : ''}`}
                                >
                                    {slogan}
                                </span>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default EditorNavbarSymbol