import { useState } from 'react'

import { BACKGROUND_COLOR_SCHEMAS } from '../../utils/constants'
import { useEditorContext } from '../../context/EditorContext'
import { imageColorToFilter } from '../../utils/functions'
import { useUpdateLogoMutation } from '../../api/api'

const EditorNavbarBackground = () => {
    const [selectedSchema, setSelectedSchema] = useState('black')

    const {
        companyName, slogan,
        editableElement, companyNameColor,
        horizontalFlip, verticalFlip, imageColor, imageWidth,
        sloganColor, sloganFontFamily, logo,
        companyNameFontFamily, imageHorizontalPosition,
        companyNameHorizontalPosition, companyNameVerticalPosition,
        sloganHorizontalPosition, sloganVerticalPosition,
        layout, sloganSpacing, companyNameSpacing,
        sloganTransform, companyNameTransform,
        onSelectBackgroundColor, imageVerticalPosition,
        currentTemplateData, setCurrentHistoryStep, backgroundColor,
        setHistoryChanges, currentHistoryStep, setBackgroundColor,
    } = useEditorContext()

    const [updateLogo] = useUpdateLogoMutation()

    const handleSelectBackgroundColor = (color) => {
        onSelectBackgroundColor(color)
        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = {
                backgroundColor: {
                    prev: backgroundColor,
                    next: color,
                },
            }

            return cuttedToCurrent
        })

        updateLogo({ id: currentTemplateData.id, background_color: color }).then((res) => {
            if ('error' in res) {

            } else {
                setBackgroundColor(res.data.background_color)
            }
        })
    }

    return (
        <div className='editor-navbar-item-content'>
            <div className='editor-navbar-background-colors-list'>
                <div onClick={() => setSelectedSchema('black')} style={{ backgroundColor: '#000000' }} className='editor-background-color-item'></div>
                <div onClick={() => setSelectedSchema('red')} style={{ backgroundColor: '#EB5757' }} className='editor-background-color-item'></div>
                <div onClick={() => setSelectedSchema('orange')} style={{ backgroundColor: '#F2994A' }} className='editor-background-color-item'></div>
                <div onClick={() => setSelectedSchema('yellow')} style={{ backgroundColor: '#F2C94C' }} className='editor-background-color-item'></div>
                <div onClick={() => setSelectedSchema('green')} style={{ backgroundColor: '#219653' }} className='editor-background-color-item'></div>
                <div onClick={() => setSelectedSchema('ocean')} style={{ backgroundColor: '#56CCF2' }} className='editor-background-color-item'></div>
                <div onClick={() => setSelectedSchema('blue')} style={{ backgroundColor: '#2F80ED' }} className='editor-background-color-item'></div>
                <div onClick={() => setSelectedSchema('purple')} style={{ backgroundColor: '#9B51E0' }} className='editor-background-color-item'></div>
                <div onClick={() => setSelectedSchema('pink')} style={{ backgroundColor: '#F485E9' }} className='editor-background-color-item'></div>
            </div>
            <div className='editor-suggested-templates-list'>
                {BACKGROUND_COLOR_SCHEMAS[selectedSchema].length > 0 && BACKGROUND_COLOR_SCHEMAS[selectedSchema].map((schemaColor, index) => {
                    if (schemaColor !== sloganColor && schemaColor !== companyNameColor) {
                        return (
                            <div
                                key={index}
                                onClick={() => handleSelectBackgroundColor(schemaColor)}
                                style={{
                                    width: '100%',
                                    minHeight: '180px',
                                    backgroundColor: `${schemaColor}`,
                                    flexDirection: `${layout === 'top' ? 'column' : layout === 'bottom' ? 'column-reverse' : layout === 'left' ? 'row' : 'row-reverse'}`
                                }}
                                className='preview-image-block'
                            >
                                {logo && (
                                    <div
                                        style={{ transform: `translateX(${imageHorizontalPosition}px) translateY(${imageVerticalPosition}px)` }}
                                        className={`generated-logo-icon ${editableElement === 'image' ? 'editable' : ''}`}
                                    >
                                        <img
                                            draggable={false}
                                            style={{
                                                transform: `rotateX(${horizontalFlip ? '180deg' : '0deg'}) rotateY(${verticalFlip ? '180deg' : '0deg'})`,
                                                filter: `${imageColorToFilter(imageColor)}`,
                                                width: `${imageWidth < 60 ? imageWidth : 60}px`,
                                            }}
                                            src={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(logo)}`}
                                            alt=""
                                        />
                                    </div>
                                )}
                                <div className='generated-logo-text'>
                                    <span
                                        style={{
                                            color: `${companyNameColor}`,
                                            fontFamily: `${companyNameFontFamily}`,
                                            fontSize: `16px`,
                                            transform: `translateX(${companyNameHorizontalPosition}px) translateY(${companyNameVerticalPosition}px)`,
                                            letterSpacing: `${companyNameSpacing}px`,
                                            textTransform: `${companyNameTransform}`,
                                            textAlign: `${layout === 'top' ? 'center' : layout === 'bottom' ? 'center' : layout === 'left' ? 'start' : 'end'}`,
                                            wordBreak: 'break-all',
                                        }}
                                        className={`generated-logo-company-name ${editableElement === 'company' ? 'editable' : ''}`}
                                    >
                                        {companyName}
                                    </span>
                                    {slogan && (
                                        <span
                                            style={{
                                                color: `${sloganColor}`,
                                                fontFamily: `${sloganFontFamily}`,
                                                fontSize: `12px`,
                                                transform: `translateX(${sloganHorizontalPosition}px) translateY(${sloganVerticalPosition}px)`,
                                                letterSpacing: `${sloganSpacing}px`,
                                                textTransform: `${sloganTransform}`,
                                                textAlign: `${layout === 'top' ? 'center' : layout === 'bottom' ? 'center' : layout === 'left' ? 'start' : 'end'}`,
                                                wordBreak: 'break-all',
                                            }}
                                            className={`generated-logo-slogan ${editableElement === 'slogan' ? 'editable' : ''}`}
                                        >
                                            {slogan}
                                        </span>
                                    )}
                                </div>
                            </div>
                        )
                    } else {
                        return null
                    }
                })}
            </div>
        </div>
    )
}

export default EditorNavbarBackground