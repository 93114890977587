import React, { useEffect, useState } from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'

import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import GetStartedBottom from '../components/GetStartedBottom/GetStartedBottom'

import { HomeGuideBlock, PageWrapper } from '../styles/styles'

import FirstImage from '../assets/how-it-works/first.png'
import FirstMobileImage from '../assets/how-it-works/first-mobile.png'
import SecondImage from '../assets/how-it-works/second.png'
import ThirdImage from '../assets/how-it-works/third.png'
import FourthImage from '../assets/how-it-works/fourth.png'
import FourthWhiteImage from '../assets/how-it-works/FourthWhite.png'
import Star from '../assets/mainPage/Star.svg';
import First from '../assets/mainPage/01.png';
import FirstSub from '../assets/mainPage/01sub.svg';
import FirstSub2 from '../assets/mainPage/01sub2.svg';
import BottomArrow from '../assets/mainPage/bottomArrow.svg';
import BottomArrowBlack from '../assets/icons/bottom-arrow-how-works.svg';

const HowItWorks = () => {
    const [companyName, setCompanyName] = useState('');

    const navigate = useNavigate()

    const { isDark } = useTheme()

    const handleCompanyName = (e) => {
        setCompanyName(e.target.value);
    }

    const navigateToLogoMaker = () => {
        navigate('/logo-maker')
    }

    return (
        <PageWrapper>
            <Header />
            <HomeGuideBlock>
                <div className="mobile-wrapper max-w-[1440px] p-[120px] m-auto lg:px-0 lg:py-[40px] flex flex-col gap-[80px] lg:gap-0">
                    <div className="flex flex-col items-center justify-center gap-[16px]">
                        <div className="theme-text-color text-[64px] relative m-auto text-center font-normal font-[Poppins] leading-[94.2%] tracking-[-2px] w-[840px] lg:text-[36px] lg:text-center lg:w-[auto] lg:max-w-[80%] lg:m-auto">
                            <img className="absolute w-[36px] top-[-15px] left-[220px] z-[0] lg:left-[-42px] lg:top-[-16px] lg:w-[42px]" src={Star} alt="" />
                            How it <span className="text-[60px] leading-[94.2%] font-[Gilda Display] tracking-[-2px] theme-text-color lg:text-[38px] lg:text-center font-light">works </span>
                            {isDark ? (
                                <img className="absolute -rotate-[35deg] w-[300px] top-[0px] right-[-170px] z-[2] lg:right-[-60px] lg:top-[90px] lg:w-[150px]" src={BottomArrow} alt="" />
                            ) : (
                                <img className="absolute w-[300px] top-[0px] right-[-170px] z-[2] lg:right-[-60px] lg:top-[90px] lg:w-[150px]" src={BottomArrowBlack} alt="" />
                            )}
                        </div>
                        <p className="theme-text-color text-[22px] font-medium text-center lg:max-w-[80%] lg:m-auto lg:text-[16px] font-[Inter]">Use SwiftLogo’s AI-powered platform to design a logo and build a brand you love.</p>
                    </div>

                    <div className="flex flex-col gap-[80px] lg:w-[90%] lg:m-auto lg:gap-[40px]">
                        <div className="flex jystify-between items-end lg:flex-col lg:items-center lg:max-w-[600px] m-auto lg:pt-[20px]">
                            <div className="w-[600px] rounded-[8px] flex-none flex justify-center items-center relative lg:w-[100%] max-w-[600px]">
                                <div className="w-[100%] h-[100%] overflow-hidden rounded-[8px] relative z-[0]">
                                    <img src={FirstImage} className="w-[100%] first-desktop" alt="" />
                                    <img src={FirstMobileImage} className="w-[100%] first-mobile" alt="" />
                                </div>
                            </div>
                            <div className="px-[32px] pt-[16px] lg:px-[0] lg:pt-[0]">
                                <div className="block-guide-title flex flex-col lg:items-center lg:justify-start lg:flex-row gap-[8px] lg:mb-3">
                                    <h4 className="text-[64px] font-[Neuton] text-yellow font-extralight">01</h4>
                                    <h6 className="font-[Poppins] theme-text-color text-[36px] mb-6 lg:leading-[28px] font-normal lg:text-[28px]">Start with design inspiration</h6>
                                </div>
                                <p className="font-[Inter] theme-text-color text-[22px] font-medium lg:text-[18px]">
                                    Enter your company name and select the logo styles, colors, and symbols you like. SwiftLogo’s AI-powered logo maker will use these as inspiration when generating your logo designs.
                                </p>
                                <button onClick={navigateToLogoMaker} className='get-started-button'>Get started</button>
                            </div>
                        </div>
                        <div className="flex jystify-between items-end home-guide-row-reverse lg:flex-col lg:items-center lg:max-w-[600px] m-auto lg:pt-[20px]">
                            <div className="w-[600px] rounded-[8px] flex-none flex justify-center items-center relative lg:w-[100%] max-w-[600px]">
                                <div className="w-[100%] h-[100%] overflow-hidden rounded-[8px] relative z-[0]">
                                    <img src={SecondImage} className="w-[100%]" alt="" />
                                </div>
                            </div>
                            <div className="px-[32px] pt-[16px] lg:px-[0] lg:pt-[0]">
                                <div className="block-guide-title flex flex-col lg:items-center lg:justify-start lg:flex-row gap-[8px] lg:mb-3">
                                    <h4 className="text-[64px] font-[Neuton] text-pink font-extralight">02</h4>
                                    <h6 className="font-[Poppins] theme-text-color mb-6 text-[36px] lg:leading-[28px] font-normal lg:text-[28px]">Browse and favorite logos</h6>
                                </div>
                                <p className="font-[Inter] theme-text-color text-[22px] font-medium lg:text-[18px]">
                                    Within minutes, you’ll be browsing 100% custom logos tailored to your business. As you scroll, SwiftLogo will generate more designs based on your preferences.
                                </p>
                                <button onClick={navigateToLogoMaker} className='get-started-button'>Explore Custom Logos</button>
                            </div>
                        </div>
                        <div className="flex jystify-between items-end lg:flex-col lg:items-center lg:max-w-[600px] m-auto lg:pt-[20px]">
                            <div className="w-[600px] rounded-[8px] flex-none flex justify-center items-center relative lg:w-[100%] max-w-[600px]">
                                <AnimationOnScroll animateIn="animate__fadeInRightBig" className="max-w-[50%] w-[100%] absolute bottom-[16%] left-[-7%] z-[1]">
                                    <img src={FirstSub} className="max-w-[307px] w-[100%]" alt="" />
                                </AnimationOnScroll>
                                <AnimationOnScroll animateIn="animate__fadeInLeftBig" className="max-w-[17%] w-[100%] absolute top-[-9%] left-[18%] z-[1]">
                                    <img src={FirstSub2} className="max-w-[106px] w-[100%]" alt="" />
                                </AnimationOnScroll>
                                <div className="w-[100%] h-[100%] overflow-hidden rounded-[8px] relative z-[0]">
                                    <img src={First} className="w-[100%]" alt="" />
                                </div>
                            </div>
                            <div className="px-[32px] pt-[16px] lg:px-[0] lg:pt-[0]">
                                <div className="block-guide-title flex flex-col lg:items-center lg:justify-start lg:flex-row gap-[8px] lg:mb-3">
                                    <h4 className="text-[64px] font-[Neuton] text-blue font-extralight">03</h4>
                                    <h6 className="font-[Poppins] theme-text-color mb-6 text-[36px] lg:leading-[28px] font-normal lg:text-[28px]">Perfect your design</h6>
                                </div>
                                <p className="font-[Inter] theme-text-color text-[22px] font-medium lg:text-[18px]">
                                    Click any logo design to explore variations and change colors, text fonts, layouts, and symbols. You can also change spacing and sizing.
                                </p>
                                <button onClick={navigateToLogoMaker} className='get-started-button'>Start Customizing</button>
                            </div>
                        </div>
                        <div className="flex jystify-between items-end home-guide-row-reverse lg:flex-col lg:items-center lg:max-w-[600px] m-auto lg:pt-[20px]">
                            <div className="w-[600px] rounded-[8px] flex-none flex justify-center items-center relative overflow-x-hidden lg:w-[100%] max-w-[600px]">
                                <img src={ThirdImage} className="w-[100%] relative z-[0]" alt="" />
                            </div>
                            <div className="px-[32px] pt-[16px] lg:px-[0] lg:pt-[0]">
                                <div className="block-guide-title flex flex-col lg:items-center lg:justify-start lg:flex-row gap-[8px] lg:mb-3">
                                    <h4 className="text-[64px] font-[Neuton] text-yellow font-extralight">04</h4>
                                    <h6 className="font-[Poppins] theme-text-color mb-6 text-[36px] lg:leading-[28px] font-normal lg:text-[28px]">Preview logo mockups</h6>
                                </div>
                                <p className="font-[Inter] theme-text-color text-[22px] font-medium lg:text-[18px]">
                                    Favorite a logo to preview what it looks like on business cards, T-shirts, and more. As you make changes to logos in the editor, previews update in real-time.
                                </p>
                                <button onClick={navigateToLogoMaker} className='get-started-button'>See it in Action</button>
                            </div>
                        </div>
                        <div className="flex jystify-between items-end lg:flex-col lg:items-center lg:max-w-[600px] m-auto lg:pt-[20px] overflow-hidden">
                            <div className="w-[600px] rounded-[8px] flex-none flex justify-center items-center relative lg:w-[100%] max-w-[600px]">
                                {isDark ? (
                                    <img src={FourthImage} className="w-[100%] relative z-[0]" alt="" />
                                ) : (
                                    <img src={FourthWhiteImage} className="w-[100%] relative z-[0]" alt="" />
                                )}
                            </div>
                            <div className="px-[32px] pt-[16px] lg:px-[0] lg:pt-[0]">
                                <div className="block-guide-title flex flex-col lg:items-center lg:justify-start lg:flex-row gap-[8px] lg:mb-3">
                                    <h4 className="text-[64px] font-[Neuton] text-pink font-extralight">05</h4>
                                    <h6 className="font-[Poppins] theme-text-color mb-6 text-[36px] lg:leading-[28px] font-normal lg:text-[28px]">Download your files</h6>
                                </div>
                                <p className="font-[Inter] theme-text-color text-[22px] font-medium lg:text-[18px]">
                                    After you choose and purchase a logo, we’ll send you all of the files a designer would, including high-res PNGs and vector logo files to use online and in print.
                                </p>
                                <button onClick={navigateToLogoMaker} className='get-started-button'>Get started</button>
                            </div>
                        </div>
                    </div>
                </div>
            </HomeGuideBlock>
            <GetStartedBottom companyName={companyName} handleCompanyName={handleCompanyName} />
            <Footer />
        </PageWrapper >
    )
}

export default HowItWorks