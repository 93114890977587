import React from 'react'
import { useNavigate } from 'react-router-dom'

import { WhatWillDesignTodayBlock } from '../../styles/styles'

const WhatWillDesignToday = () => {
    const navigate = useNavigate()

    return (
        <WhatWillDesignTodayBlock>
            <h5 className='what-will-design-heading'>What will you design today?</h5>
            <button className='create-logo-button' onClick={() => navigate('/logo-maker')}>Create new logo</button>
        </WhatWillDesignTodayBlock>
    )
}

export default WhatWillDesignToday