import React from 'react'
import { useTheme } from 'styled-components'

import { PurchaseHistoryBlock } from '../../styles/styles'

import NoLogosBlock from '../NoLogosBlock/NoLogosBlock'

import DownloadWhite from '../../assets/icons/download-white.svg'
import DownloadBlack from '../../assets/icons/download-black.svg'
import HelpIcon from '../../assets/icons/help-icon.svg'

const PurchaseHistory = () => {
    const { isDark } = useTheme()

    return (
        <PurchaseHistoryBlock>
            <div className='recent-header-block'>
                <h5 className='recent-header-title'>Purchase History</h5>
                <div className='recent-header-filters'>
                    <span className='recent-filter-text'>
                        {isDark ? (
                            <img src={DownloadWhite} alt="" />
                        ) : (
                            <img src={DownloadBlack} alt="" />
                        )}
                        Download All
                    </span>
                    <span className='recent-support-text'>
                        <img src={HelpIcon} alt="" />
                        Support
                    </span>
                </div>
            </div>
            <NoLogosBlock heading={"You have no purchased logos!"} />
        </PurchaseHistoryBlock>
    )
}

export default PurchaseHistory