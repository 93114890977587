import { useState, useEffect, useRef, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useEditorContext } from "../context/EditorContext";
import { LogoMakerContentBlock, LogoMakerWrapper } from "../styles/styles";

import LogoMakerHeader from "../components/LogoMakerHeader/LogoMakerHeader";
import LogoMakerStepOne from "../components/LogoMakerFlow/LogoMakerStepOne";
import LogoMakerStepsNav from "../components/LogoMakerFlow/LogoMakerStepsNav";
import LogoMakerStepTwo from "../components/LogoMakerFlow/LogoMakerStepTwo";
import LogoMakerStepThree from "../components/LogoMakerFlow/LogoMakerStepThree";
import LogoMakerStepFour from "../components/LogoMakerFlow/LogoMakerStepFour";
import LogoMakerStepFive from "../components/LogoMakerFlow/LogoMakerStepFive";
import LogoMakerStepSix from "../components/LogoMakerFlow/LogoMakerStepSix";
import AuthModal from "../modals/AuthModal";

import AuthModalImage from '../assets/auth-modal-image.svg'

const LogoMaker = () => {
    const [step, setStep] = useState(1)
    const [industry, setIndustry] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [slogan, setSlogan] = useState('')
    const [selectedStyles, setSelectedStyles] = useState([])
    const [selectedColors, setSelectedColors] = useState([])
    const [selectedIconsType, setSelectedIconsType] = useState([])
    const [selectedIcons, setSelectedIcons] = useState([])
    const [isDisabled, setIsDisabled] = useState(false)
    const [isSkip, setIsSkip] = useState(false)
    const [showAuthModal, setShowAuthModal] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [stepProcess, setStepProcess] = useState({
        first: false,
        second: false,
        third: false,
        fourth: false,
        fifth: false,
    })

    const user = useSelector((state) => state.app.user)

    const navigationRef = useRef(null)

    const { setGeneratedLogos } = useEditorContext()

    const handleNextStep = () => {
        if (step !== 6) {
            setStep((prev) => {
                if (prev === 3) {
                    navigationRef.current.scrollLeft += 160
                } else if (prev === 5) {
                    navigationRef.current.scrollLeft += 80
                    setSearchParams({ step: 'exploring' })
                    handleSaveSettings()
                }

                return prev + 1
            })
        } else {
            if (!user) {
                setShowAuthModal(true)
            }
        }
    }

    const handleGetSettings = () => {
        const settings = localStorage.getItem('maker_state')

        if (settings) {
            const parsedValue = JSON.parse(settings)

            setIndustry(parsedValue.industry)
            setCompanyName(parsedValue.companyName)
            setSlogan(parsedValue.slogan)
            setSelectedStyles(parsedValue.selectedStyles)
            setSelectedColors(parsedValue.selectedColors)
            setSelectedIconsType(parsedValue.iconsType)
            setSelectedIcons(parsedValue.selectedIcons)
        }
    }

    const handleSaveSettings = useCallback(() => {
        const settings = {
            industry: industry,
            companyName: companyName,
            slogan: slogan,
            selectedStyles: selectedStyles,
            selectedColors: selectedColors,
            iconsType: selectedIconsType,
            selectedIcons: selectedIcons,
        }

        localStorage.setItem('maker_state', JSON.stringify(settings))
        setGeneratedLogos([])
    }, [companyName, industry, selectedColors, selectedIcons, selectedIconsType, selectedStyles, slogan, setGeneratedLogos])

    useEffect(() => {
        const name = localStorage.getItem('company_name');

        setCompanyName(name)
    }, [])

    useEffect(() => {
        const step = searchParams.get('step')

        if (step === 'exploring' && step !== 6) {
            handleGetSettings()
            setStep(6)
        }
    }, [searchParams, setSearchParams])

    useEffect(() => {
        const company = searchParams.get('company')
        const stepParam = searchParams.get('step')

        if (step === 4 && company) {
            setCompanyName(company)

            if (stepParam) {
                setSearchParams({ step: 'exploring' })
            } else {
                setSearchParams({})
            }
        }
    }, [step, searchParams, setSearchParams])

    useEffect(() => {
        if (stepProcess.first && stepProcess.fourth && stepProcess.fifth) {
            handleSaveSettings()
        }
    }, [stepProcess, handleSaveSettings])

    useEffect(() => {
        let resultSteps = {
            first: industry ? true : false,
            second: selectedStyles.length ? true : false,
            third: selectedColors.length ? true : false,
            fourth: companyName ? true : false,
            fifth: selectedIconsType.length || selectedIcons.length ? true : false,
        }

        setStepProcess(resultSteps)

        switch (step) {
            case 1: {
                if (!industry) {
                    setIsDisabled(true)
                    setIsSkip(false)
                } else {
                    setIsDisabled(false)
                    setIsSkip(false)
                }
                break;
            }
            case 2: {
                if (selectedStyles.length) {
                    setIsSkip(false)
                    setIsDisabled(false)
                } else {
                    setIsSkip(true)
                }
                break;
            }
            case 3: {
                if (selectedColors.length) {
                    setIsSkip(false)
                    setIsDisabled(false)
                } else {
                    setIsSkip(true)
                }
                break;
            }
            case 4: {
                if (companyName) {
                    setIsDisabled(false)
                } else {
                    setIsDisabled(true)
                }
                setIsSkip(false)
                break;
            }
            case 5: {
                if (selectedIconsType.length || selectedIcons.length) {
                    setIsSkip(false)
                    setIsDisabled(false)
                } else {
                    setIsSkip(false)
                    setIsDisabled(true)
                }
                break;
            }
            default: {
                setIsDisabled(false)
                break;
            }
        }
    }, [step, industry, selectedStyles, selectedColors, companyName, selectedIconsType, selectedIcons])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'instant' })
    }, [step])

    useEffect(() => {
        const newImage = new Image();
        newImage.src = AuthModalImage;
        window[AuthModalImage] = newImage;
    }, [])

    return (
        <LogoMakerWrapper>
            <LogoMakerHeader />
            <LogoMakerContentBlock>
                {step === 1 ? (
                    <LogoMakerStepOne
                        industry={industry}
                        setIndustry={setIndustry}
                    />
                ) : step === 2 ? (
                    <LogoMakerStepTwo
                        selectedStyles={selectedStyles}
                        setSelectedStyles={setSelectedStyles}
                    />
                ) : step === 3 ? (
                    <LogoMakerStepThree
                        selectedColors={selectedColors}
                        setSelectedColors={setSelectedColors}
                    />
                ) : step === 4 ? (
                    <LogoMakerStepFour
                        slogan={slogan}
                        companyName={companyName}
                        setSlogan={setSlogan}
                        setCompanyName={setCompanyName}
                    />
                ) : step === 5 ? (
                    <LogoMakerStepFive
                        selectedIconsType={selectedIconsType}
                        setSelectedIconsType={setSelectedIconsType}
                        selectedIcons={selectedIcons}
                        setSelectedIcons={setSelectedIcons}
                        industry={industry}
                    />
                ) : (
                    <LogoMakerStepSix />
                )}
                <LogoMakerStepsNav
                    step={step}
                    setStep={setStep}
                    isDisabled={isDisabled}
                    isSkip={isSkip}
                    navigationRef={navigationRef}
                    stepProcess={stepProcess}
                />
                <div className="mobile-continue-block">
                    <button
                        disabled={step === 1}
                        onClick={() => setStep(step - 1)}
                        className='logo-maker-back-btn'
                    >
                        Back
                    </button>
                    {isSkip ? (
                        <button
                            disabled={isDisabled}
                            onClick={handleNextStep}
                            className='logo-maker-skip-btn'
                        >
                            Skip
                        </button>
                    ) : (
                        <button
                            disabled={isDisabled || (step === 6 && user)}
                            onClick={handleNextStep}
                            className='logo-maker-continue-btn'
                        >
                            Continue
                        </button>
                    )}
                </div>
                {showAuthModal && (
                    <AuthModal
                        show={showAuthModal}
                        handleClose={() => setShowAuthModal(false)}
                        signUp={true}
                        image={AuthModalImage}
                    />
                )}
            </LogoMakerContentBlock>
        </LogoMakerWrapper>
    );
}

export default LogoMaker;
