import React from 'react'

import { LogoMakerStepTwoBlock } from '../../styles/styles'
import { LOGO_STYLES } from '../../utils/constants'

const LogoMakerStepTwo = ({ selectedStyles, setSelectedStyles }) => {

    const handleBlockClick = (id) => {
        if (selectedStyles.includes(id)) {
            const filteredList = selectedStyles.filter((el) => el !== id)

            setSelectedStyles(filteredList)
        } else {
            setSelectedStyles([...selectedStyles, id])
        }
    }

    return (
        <LogoMakerStepTwoBlock>
            <div className="flex justify-between items-center">
                <div>
                    <h4 className="logo-maker-step-two-heading">Pick some logos you like</h4>
                    <p className="logo-maker-step-two-description">We’ll use these as inspiration. These were all made by SwiftLogo.</p>
                </div>
            </div>

            <div className="logo-maker-step-two-styles-list">
                {LOGO_STYLES.map((item) => (
                    <div
                        key={item.id}
                        className={`logo-maker-step-two-styles-item-block ${selectedStyles.includes(item.id) ? 'selected' : ''}`}
                        onClick={() => handleBlockClick(item.id)}
                    >
                        <img src={item.img} alt="" className="logo-maker-step-two-styles-icon" />
                    </div>
                ))}
            </div>
        </LogoMakerStepTwoBlock>
    )
}

export default LogoMakerStepTwo