import * as yup from "yup";

const FIELD_REQUIRED = "Please enter required fields."
const INVALID_EMAIL = "Email not valid. Please try again."

export const loginSchema = yup.object({
    email: yup.string().required(FIELD_REQUIRED).email(INVALID_EMAIL),
    password: yup.string().required(FIELD_REQUIRED),
    passwordConfirmation: yup.string(),
}).required();

export const signUpSchema = yup.object({
    email: yup.string().required(FIELD_REQUIRED).email(INVALID_EMAIL),
    password: yup.string().required(FIELD_REQUIRED),
}).required();