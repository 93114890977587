import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { useCancelSubscriptionMutation } from '../api/api'
import { CancelSubscriptionModalBlock } from '../styles/styles'
import { handleValidatePassword } from '../firebase/crud/updateUserEmail'

import BillingsCheckbox from '../assets/icons/billings-checkbox.svg'
import ModalCloseButton from '../components/ModalCloseButton/ModalCloseButton'

const CancelSubscriptionModal = ({ show, handleClose }) => {
    const [step, setStep] = useState(1)
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)

    const navigate = useNavigate()

    const [cancelSubscription] = useCancelSubscriptionMutation()

    const handleContinueToCancel = () => {
        setStep(2)
    }

    const handleBackToDashboard = () => {
        navigate('/dashboard')
    }

    const handleBackToHome = () => {
        navigate('/')
    }

    const handleCancelSubscription = async () => {
        const res = await handleValidatePassword(password)

        if (res) {
            cancelSubscription().then((res) => {
                if ('error' in res) {
                    setError(true)
                } else {
                    setStep(3)
                    setError(false)
                }
            })
        } else {
            setError(true)
        }
    }

    const handleChangePlan = () => {
        navigate('/plans')
    }

    return (
        <CancelSubscriptionModalBlock centered show={show} onHide={handleClose}>
            <Modal.Body>
                {step === 1 ? (
                    <>
                        <h5 className='send-invoice-title'>When you cancel you lose access to</h5>
                        <div className='billings-current-plan-options'>
                            <div className='billing-current-plan-options-block'>
                                <p className='billing-current-plan-option-item'>
                                    <img src={BillingsCheckbox} alt="" />
                                    Multiple high-res file types
                                </p>
                                <p className='billing-current-plan-option-item'>
                                    <img src={BillingsCheckbox} alt="" />
                                    Full ownership
                                </p>
                                <p className='billing-current-plan-option-item'>
                                    <img src={BillingsCheckbox} alt="" />
                                    Unlimited changes
                                </p>
                                <p className='billing-current-plan-option-item'>
                                    <img src={BillingsCheckbox} alt="" />
                                    Over $3,000 in Exclusive Offers
                                </p>
                                <p className='billing-current-plan-option-item'>
                                    <img src={BillingsCheckbox} alt="" />
                                    Lifetime technical support
                                </p>
                                <p className='billing-current-plan-option-item'>
                                    <img src={BillingsCheckbox} alt="" />
                                    Business Cards
                                </p>
                            </div>
                        </div>
                        <span className='send-invoice-description'>
                            You can change your plan without canceling.
                            We can help you find the plan that works best for you.
                        </span>
                        <div className='send-invoice-actions'>
                            <button onClick={handleChangePlan} className='send-invoice-cancel'>Change your plan</button>
                            <button onClick={handleContinueToCancel} className='send-invoice-delete'>Continue to Cancel</button>
                        </div>
                    </>
                ) : step === 2 ? (
                    <>
                        <h5 className='send-invoice-title'>Cancel subscription</h5>
                        <span className='send-invoice-description'>
                            Please enter your password to cancel your subscription.
                        </span>
                        <input placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} className='reenter-password-input' type="password" />
                        {error && (
                            <span className='cancel-subscription-error'>Password incorrect. Please try again.</span>
                        )}
                        <div className='send-invoice-actions'>
                            <button onClick={handleClose} className='send-invoice-cancel'>Nevermind</button>
                            <button disabled={!password} onClick={handleCancelSubscription} className='send-invoice-delete'>Cancel subscription</button>
                        </div>
                    </>
                ) : (
                    <>
                        <h5 className='send-invoice-title'>Subscription successfully cancelled</h5>
                        <span className='send-invoice-description'>
                            Your subscription has been successfully cancelled. An early cancellation fee was charged to the VISA ending in 0000.
                        </span>
                        <div className='send-invoice-actions'>
                            <button onClick={handleBackToHome} className='send-invoice-cancel'>Back to Home</button>
                            <button onClick={handleBackToDashboard} className='send-invoice-delete'>Back to Dashboard</button>
                        </div>
                    </>
                )}
                <ModalCloseButton handleClose={handleClose} />
            </Modal.Body>
        </CancelSubscriptionModalBlock >
    )
}

export default CancelSubscriptionModal