import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { DashboardNavigationBlock } from '../../styles/styles'
import { userSignOut } from '../../firebase/auth/googleAuth'
import { useViewport } from '../../hooks/useViewport'
import { logoutUser } from '../../redux/appSlice'
import { api } from '../../api/api'

import AuthModal from '../../modals/AuthModal'

import NavigationDashboardIcons from '../../assets/icons/navigation-dashboard-icon.svg'
import EditorMenuGenerated from '../../assets/icons/editor-menu-generated.svg'
import EditorMenuSettings from '../../assets/icons/editor-menu-settings.svg'
import EditorMenuBilling from '../../assets/icons/editor-menu-billing.svg'
import EditorMenuSignUp from '../../assets/icons/editor-menu-signup.svg'
import EditorMenuLogout from '../../assets/icons/editor-menu-logout.svg'
import EditorMenuSaved from '../../assets/icons/editor-menu-saved.svg'
import UserIconGuest from '../../assets/icons/user-icon-guest.svg'
import CurrentPlanIcon from '../../assets/icons/current-plan-icon.svg'
import AuthModalImage from '../../assets/auth-modal-image.svg'
import DefaultAvatar from '../../assets/default-avatar.jpg'

const DashboardNavigation = () => {
    const [showAuthModal, setShowAuthModal] = useState(false)
    const [userPlan, setUserPlan] = useState('')
    const user = useSelector((state) => state.app.user)
    const profile = useSelector((state) => state.app.profile)

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { isMobile } = useViewport()

    const handleSignUp = () => {
        setShowAuthModal(true)
    }

    const handleLogout = async () => {
        await userSignOut()
        dispatch(api.util.resetApiState());
        dispatch(logoutUser())
    }

    const handleNavigateTo = (link) => {
        navigate(link)
    }

    useEffect(() => {
        const newImage = new Image();
        newImage.src = AuthModalImage;
        window[AuthModalImage] = newImage;
    }, [])

    useEffect(() => {
        if (profile && profile.plan) {
            if (profile.plan.includes('Basic')) {
                setUserPlan('Basic')
            } else if (profile.plan.includes('Premium')) {
                setUserPlan('Premium')
            } else {
                setUserPlan('Free')
            }
        }
    }, [profile])

    return (
        <DashboardNavigationBlock>
            {!isMobile && (
                <div className='user-avatar-block'>
                    {user ? (
                        <>
                            <img className='avatar-navigation' src={DefaultAvatar} alt="" />
                            <div className='navigation-plan-block'>
                                <span className='plan-block-name'>
                                    {user.displayName ? user.displayName : 'User'}
                                </span>
                                <span className='plan-block-plan-name'>
                                    {userPlan !== 'Free' && (
                                        <img width={12} src={CurrentPlanIcon} alt="" />
                                    )}
                                    {userPlan}
                                </span>
                            </div>
                        </>
                    ) : (
                        <>
                            <img src={UserIconGuest} alt="" />
                            Guest
                        </>
                    )}
                </div>
            )}
            <div className='navigation-group-block'>
                <div onClick={() => handleNavigateTo('/dashboard')} className={`navigation-link ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                    <img width={26} src={NavigationDashboardIcons} alt="" />
                    Dashboard
                </div>
            </div>
            <div className='navigation-group-block'>
                <div onClick={() => handleNavigateTo('/dashboard/saved-logos')} className={`navigation-link ${location.pathname === '/dashboard/saved-logos' ? 'active' : ''}`}>
                    <img width={26} src={EditorMenuSaved} alt="" />
                    Saved Logos
                </div>
                <div onClick={() => handleNavigateTo('/logo-maker')} className={`navigation-link ${location.pathname === '/dashboard/logo-maker' ? 'active' : ''}`}>
                    <img width={26} src={EditorMenuGenerated} alt="" />
                    Logo Generator
                </div>
            </div>
            <div className='navigation-group-block'>
                <div onClick={() => handleNavigateTo('/dashboard/account')} className={`navigation-link ${location.pathname === '/dashboard/account' ? 'active' : ''}`}>
                    <img width={26} src={EditorMenuSettings} alt="" />
                    Your Account
                </div>
                <div onClick={() => handleNavigateTo('/dashboard/billing-plans')} className={`navigation-link ${location.pathname === '/dashboard/billing-plans' ? 'active' : ''}`}>
                    <img width={26} src={EditorMenuBilling} alt="" />
                    Billing & Plans
                </div>
            </div>
            <div className='navigation-group-block'>
                {user ? (
                    <div onClick={handleLogout} className='navigation-link'>
                        <img width={26} src={EditorMenuLogout} alt="" />
                        Logout
                    </div>
                ) : (
                    <div onClick={handleSignUp} className='navigation-link'>
                        <img width={26} src={EditorMenuSignUp} alt="" />
                        Sign Up
                    </div>
                )}
            </div>
            <div className='navigation-terms-navigation'>
                <div onClick={() => handleNavigateTo('/terms-and-conditions')} className='navigation-term-item'>Terms of Service</div>
                <div onClick={() => handleNavigateTo('/privacy-policy')} className='navigation-term-item'>Privacy</div>
            </div>
            <AuthModal
                show={showAuthModal}
                handleClose={() => setShowAuthModal(false)}
                signUp={true}
                image={AuthModalImage}
            />
        </DashboardNavigationBlock>
    )
}

export default DashboardNavigation