import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./plunkConfig";

export const invalidateOn = ({ success, error }) => {
    return (result) => result ? success : error
}

export const plunkApi = createApi({
    reducerPath: 'plunkApi',
    baseQuery: baseQuery,
    tagTypes: [],
    endpoints: builder => ({
        sendEmailOnSignUp: builder.mutation({
            query: ({ email, domain }) => {
                return {
                    url: ``,
                    method: 'POST',
                    body: {
                        event: 'user-signup',
                        email: email,
                        data: { domain },
                    },
                }
            }
        }),
        sendEmailOnPurchase: builder.mutation({
            query: ({ email, domain }) => {
                return {
                    url: ``,
                    method: 'POST',
                    body: {
                        event: 'purchase',
                        email: email,
                        data: { domain },
                    },
                }
            },
        }),
        sendEmailOnDownload: builder.mutation({
            query: ({ email, domain }) => {
                return {
                    url: ``,
                    method: 'POST',
                    body: {
                        event: 'download-logo',
                        email: email,
                        data: { domain },
                    },
                }
            },
        }),
        sendEmailOnNoDownload: builder.mutation({
            query: ({ email, domain }) => {
                return {
                    url: ``,
                    method: 'POST',
                    body: {
                        event: 'no-dowload-or-purchase-made',
                        email: email,
                        data: { domain },
                    },
                }
            },
        }),
    })
})

export const {
    useSendEmailOnSignUpMutation,
    useSendEmailOnPurchaseMutation,
    useSendEmailOnDownloadMutation,
    useSendEmailOnNoDownloadMutation,
} = plunkApi;