import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import { ShareModalBlock } from '../styles/styles'

import ModalCloseButton from '../components/ModalCloseButton/ModalCloseButton'

import CopyIcon from '../assets/icons/copy-icon.svg'

const ShareModal = ({ show, handleClose }) => {
    const [copied, setCopied] = useState(false)

    const handleCopy = () => {
        navigator.clipboard.writeText(window.location.href)
        setCopied(true)
    }

    return (
        <ShareModalBlock centered show={show} onHide={handleClose}>
            <Modal.Body>
                <h5 className='share-modal-title'>Share this logo with others</h5>
                <span className='share-modal-description'>Spread the word and amplify your brand.</span>
                <div className='share-modal-input-block'>
                    <input className={`share-modal-input ${copied ? 'copied' : ''}`} value={copied ? 'Link copied!' : window.location.href} readOnly type="text" />
                    <img onClick={handleCopy} className='cursor-pointer' src={CopyIcon} alt="" />
                </div>
                <ModalCloseButton handleClose={handleClose} />
            </Modal.Body>
        </ShareModalBlock>
    )
}

export default ShareModal