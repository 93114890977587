import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { useGetInvoiceByPaymentIntentIdQuery } from '../api/api'
import { useSendEmailOnPurchaseMutation } from '../api/plunkApi'
import { UpgradeSuccessBLock } from '../styles/styles'
import { PLANS } from '../utils/constants'

import Header from '../components/Header/Header'

import CheckoutBackArrowWhite from '../assets/icons/checkout-back-arrow-white.svg'
import CheckoutBackArrowBlack from '../assets/icons/checkout-back-arrow-black.svg'
import ReceiptDownload from '../assets/icons/receipt-download.svg'
import CheckoutLock from '../assets/icons/checkout-lock.svg'

const UpgradeSuccess = () => {
    const [planPrice, setPlanPrice] = useState(0)
    const [paymentIntentId, setPaymentIntentId] = useState('')
    const [paymentType, setPaymentType] = useState('')
    const [searchParams] = useSearchParams()
    const user = useSelector((state) => state.app.user)

    const navigate = useNavigate()

    const { plan } = useParams()
    const { isDark } = useTheme()

    const [sendPurchaseEmail] = useSendEmailOnPurchaseMutation()
    const { data } = useGetInvoiceByPaymentIntentIdQuery({ paymentIntentId }, { skip: !paymentIntentId || !user || paymentType === 'paypal' })

    const handleBackToDashboard = () => {
        navigate('/dashboard')
    }

    const handleDownloadReceipt = () => {
        window.open(data?.invoice_pdf, '_blank')
    }

    useEffect(() => {
        if (planPrice) {
            window.uetq = window.uetq || [];
            window.uetq.push(
                'event',
                'subscribe',
                {
                    "revenue_value": planPrice ? planPrice : 19.95,
                    "currency": "USD"
                }
            );
        }
    }, [planPrice])

    useEffect(() => {
        const paymentId = searchParams.get('payment_intent')
        const paymentType = searchParams.get('payment_type')
        const paymentIntentClientSecret = searchParams.get('payment_intent_client_secret')

        setPaymentIntentId(paymentId)
        setPaymentType(paymentType)

        if (paymentIntentClientSecret && user?.email) {
            sendPurchaseEmail({ email: user?.email, domain: window.location.host })
        }
    }, [searchParams, user, sendPurchaseEmail])

    useEffect(() => {
        (async () => {
            const selectedPlan = PLANS.find((el) => el.name === plan)

            if (selectedPlan) {
                setPlanPrice(selectedPlan.price)
            }
        })()
    }, [plan])

    return (
        <UpgradeSuccessBLock>
            <Header withBorder={true} />
            <div className="checkout-page pb-4">
                <div onClick={handleBackToDashboard} className="checkout-body-header">
                    {isDark ? (
                        <img src={CheckoutBackArrowWhite} alt="" />
                    ) : (
                        <img src={CheckoutBackArrowBlack} alt="" />
                    )}
                    Purchase Confirmed
                </div>
                <div className="checkout-body">
                    <div className="checkout-field-block">
                        <h5 className='success-upgrade-title'>
                            Thank you for your purchase!
                            <br />
                            Order Number: {data?.id ? data?.id : paymentIntentId}
                        </h5>
                        <p className='success-upgrade-description'>
                            Your order has been successfully placed and is now being processed.
                            <br />
                            You will receive an email confirmation shortly with all the details of your purchase.
                        </p>
                        {paymentType !== 'paypal' && (
                            <div className='success-upgrade-download-actions'>
                                <button onClick={handleDownloadReceipt} className='success-upgrade-action-button'>
                                    <img src={ReceiptDownload} alt="" />
                                    Download Receipt
                                </button>
                            </div>
                        )}
                        <div className='success-upgrade-info-block'>
                            <h6 className='success-upgrade-infor-title'>Confirmation email</h6>
                            <p className='success-upgrade-infor-text'>
                                A confirmation email has been sent to your provided email address. Please check your inbox for this email, and be sure to also check your spam folder if you don't see it in your main inbox.
                            </p>
                        </div>
                        <div className='success-upgrade-info-block'>
                            <h6 className='success-upgrade-infor-title'>Need Assistance?</h6>
                            <p className='success-upgrade-infor-text'>
                                If you have any questions or need assistance with your order, please don't hesitate to contact our customer support team at [Customer Support Email] or [Customer Support Phone Number].
                            </p>
                            <p className='success-upgrade-infor-text'>
                                Thank you for choosing [Your Company Name]!
                                <br />
                                We appreciate your business and hope you enjoy your purchase.
                            </p>
                        </div>
                    </div>
                    <div className="checkout-body-right">
                        <div className="checkout-plan-info">
                            <div className='checkout-plan-info-header'>
                                <span className='checkout-plan-info-header-subscription'>Subscriptions</span>
                                <span className='checkout-plan-info-header-prices'>All prices in USD</span>
                            </div>
                            <div className="w-100 flex justify-between items-center checkout-plan-price w-full">
                                <p className="checkout-plan-info-plan-name">{plan}</p>
                                <p className="checkout-plan-info-plan-name">${planPrice}</p>
                            </div>
                            <span className="checkout-line"></span>
                            <div className="w-100 flex justify-between items-center checkout-plan-price w-full">
                                <p className="checkout-plan-info-plan-name">Total</p>
                                <p className="checkout-plan-info-plan-name">${planPrice}</p>
                            </div>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <p className="checkout-bottom-text">
                                <img src={CheckoutLock} alt="" />
                                Secured by 256-bit SSL encryption
                            </p>
                            <p className="checkout-bottom-text">
                                Issues? Text us at:
                            </p>
                            <p className="checkout-bottom-text">
                                support@qrcodeveloper.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </UpgradeSuccessBLock>
    )
}

export default UpgradeSuccess