import React from 'react'
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { GetStartedTopBlock, GetStartedTopTextWrapper } from '../../styles/styles';
import { useViewport } from '../../hooks/useViewport';

import Hand from '../../assets/mainPage/Hand.png';
import HandWhiteTheme from '../../assets/mainPage/HandWhiteTheme.svg';
import Caps from '../../assets/mainPage/Caps.png';
import Outline from '../../assets/mainPage/Outline.svg';
import Highlight from '../../assets/mainPage/Highlight.svg';
import HighlightWhiteTheme from '../../assets/mainPage/HighlightWhiteTheme.svg';


const GetStartedTop = ({ companyName, handleCompanyName }) => {
    const { isDark } = useTheme()
    const { isMobile } = useViewport()

    return (
        <GetStartedTopBlock>
            <div className="text-wrapper">
                {isDark ? (
                    <img className="corner-image" src={Highlight} alt="" />
                ) : (
                    <img className="corner-image" src={HighlightWhiteTheme} alt="" />
                )}
                <GetStartedTopTextWrapper>
                    <span className="different-text me-3">
                        AI-powered
                    </span>
                    {isMobile && (
                        <br />
                    )}
                    platform to design a {!isMobile && 'logo'}
                    <span className='desktop-text'> and build a brand you</span>
                    {isMobile && (
                        <br />
                    )}
                    <span className="different-text">
                        <div className='mobile-normal-text-get-started'>
                            logo
                            <span style={{ width: 'fit-content' }} className='different-text mobile-text mt-[-10px]'>you love.</span>
                        </div>
                        <span className="different-text desktop-text relative ml-3">
                            <span className='different-text'>love.</span>
                            <img className="absolute scale-[1.3] top-[15px] left-[0] z-[-1] lg:hidden" src={Outline} alt="" />
                        </span>
                    </span>
                </GetStartedTopTextWrapper>
                <div className='flex justify-center items-center w-[100%] mt-12 gap-[8px] lg:flex-col lg:px-4 lg:mx-auto'>
                    <input value={companyName} onChange={handleCompanyName} className='w-[inherit] bg-[#fff] rounded-[8px] px-4 py-[15px]  leading-[22px] text-[18px]' type="text" placeholder="Enter your company name" />
                    <Link
                        to={`/logo-maker?company=${companyName}`}
                        className='bg-yellow px-4 py-[15px] text-[18px] font-medium text-black leading-[22px] rounded-[8px] lg:w-[100%] lg:text-center w-[160px]'
                        style={{ 'textWrap': 'nowrap', whiteSpace: 'nowrap' }}
                    >
                        Get started
                    </Link>
                </div>
            </div>
            <div className="absolute top-[-50px] right-[0] z-[1] translate-x-[200px] lg:relative lg:translate-x-[0px] lg:top-[10px] lg:w-[90%]">
                <img src={Caps} className="w-[900px] rotate-[18.339deg]" alt="" />
            </div>
            <div style={{ bottom: `${isDark ? '-150' : '0'}px` }} className={`absolute right-[10%] lg:bottom-0 z-[1] lg:relative lg:w-[20%] lg:right-[0%]`}>
                {isDark ? (
                    <img src={Hand} className="w-[231px]" alt="" />
                ) : (
                    <img src={HandWhiteTheme} className="w-[231px]" alt="" />
                )}
            </div>
        </GetStartedTopBlock>
    )
}

export default GetStartedTop