import React from 'react'
import { Modal } from 'react-bootstrap'
import { useTheme } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { api, useDeleteUserAccountMutation } from '../api/api'
import { DeleteAccountModalBlock } from '../styles/styles'
import { userSignOut } from '../firebase/auth/googleAuth'
import { setUser } from '../redux/appSlice'

import ModalCloseButton from '../components/ModalCloseButton/ModalCloseButton'

const DeleteAccountModal = ({ show, handleClose }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { isDark } = useTheme()

    const [deleteAccount, { isSuccess }] = useDeleteUserAccountMutation()

    const handleBackToHome = async () => {
        navigate('/')
        await userSignOut()
        dispatch(api.util.resetApiState());
        dispatch(
            setUser({
                user: null
            })
        )
    }

    const handleDelete = async () => {
        await deleteAccount()
    }

    return (
        <DeleteAccountModalBlock centered show={show} onHide={handleClose}>
            <Modal.Body>
                <h5 className='delete-account-title'>Delete Account</h5>
                <span className='delete-account-description'>
                    {
                        isSuccess
                            ? 'Your account has been successfully been deleted. We’re sorry to see you go! '
                            : 'Are you sure you want to delete your account? This is a permanent action and cannot be undone.'
                    }
                </span>
                <div className='delete-account-actions'>
                    {isSuccess ? (
                        <button onClick={handleBackToHome} className='delete-account-cancel'>Back to Home</button>
                    ) : (
                        <>
                            <button onClick={handleClose} className='delete-account-cancel'>Cancel</button>
                            <button onClick={handleDelete} className='delete-account-delete'>Confirm</button>
                        </>
                    )}
                </div>
                <ModalCloseButton handleClose={handleClose} />
            </Modal.Body>
        </DeleteAccountModalBlock >
    )
}

export default DeleteAccountModal