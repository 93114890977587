import { createSlice } from '@reduxjs/toolkit'

import { api } from '../api/api'

const initialState = {
    user: null,
    profile: null,
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setUser: (state, { payload }) => {
            state.user = payload.user
        },
        logoutUser: (state) => {
            state.user = null
            state.profile = null
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            api.endpoints.getUserProfile.matchFulfilled,
            (state, { payload }) => {
                state.profile = payload
            }
        )
    }
})

export const { setUser, logoutUser } = appSlice.actions

export default appSlice.reducer