import React, { useEffect, useRef, useState } from 'react'
import RangeSlider from 'react-range-slider-input';
import { useTheme } from 'styled-components'
import { ChromePicker } from 'react-color'

import { useEditorContext } from '../../context/EditorContext';
import { EditorToolbarBlock } from '../../styles/styles'
import { useUpdateLogoMutation } from '../../api/api';

import HorizontalFlipWhite from '../../assets/icons/horizontal-flip-white.svg'
import HorizontalFlipBlack from '../../assets/icons/horizontal-flip-black.svg'
import LetterSpacingBlack from '../../assets/icons/letter-spacing-black.svg'
import LetterSpacingWhite from '../../assets/icons/letter-spacing-white.svg'
import EditorRemoveWhite from '../../assets/icons/editor-remove-white.svg'
import VerticalFlipWhite from '../../assets/icons/vertical-flip-white.svg'
import VerticalFlipBlack from '../../assets/icons/vertical-flip-black.svg'
import EditorRemoveBlack from '../../assets/icons/editor-remove-black.svg'
import LayoutBottomBlack from '../../assets/icons/layout-bottom-black.svg'
import LayoutBottomWhite from '../../assets/icons/layout-bottom-white.svg'
import RevertValueBlack from '../../assets/icons/revert-value-black.svg'
import RevertValueWhite from '../../assets/icons/revert-value-white.svg'
import LayoutRightWhite from '../../assets/icons/layout-right-white.svg'
import LayoutRightBlack from '../../assets/icons/layout-right-black.svg'
import LayoutLeftWhite from '../../assets/icons/layout-left-white.svg'
import LayoutLeftBlack from '../../assets/icons/layout-left-black.svg'
import LayoutTopWhite from '../../assets/icons/layout-top-white.svg'
import LayoutTopBlack from '../../assets/icons/layout-top-black.svg'

const EditorToolbar = () => {
    const [currentColor, setCurrentColor] = useState('#ffffff')
    const [currentScaleValue, setCurrentScaleValue] = useState(1)
    const [currentSpacingValue, setCurrentSpacingValue] = useState(1)
    const [currentTransformValue, setCurrentTransformValue] = useState('unset')
    const [currentHorizontalPosition, setCurrentHorizontalPosition] = useState(0)
    const [currentVerticalPosition, setCurrentVerticalPosition] = useState(0)
    const [showColorPicker, setShowColorPicker] = useState(false)
    const [showScale, setShowScale] = useState(false)
    const [showPosition, setShowPosition] = useState(false)
    const [showSpacing, setShowSpacing] = useState(false)
    const [tempCompanyName, setTempCompanyName] = useState('')
    const [tempSlogan, setTempSlogan] = useState('')

    const {
        companyNameColor, imageHorizontalPosition,
        setImageHorizontalPosition, imageVerticalPosition,
        setImageVerticalPosition, editableElement,
        setCompanyNameColor, deleteElement,
        horizontalFlip, verticalFlip, setCurrentTemplateData,
        setHorizontalFlip, setVerticalFlip,
        imageColor, setImageColor, setCompanyNameSize,
        logoScale, setLogoScale, setSloganSize,
        previewBlockRef, contentPreviewBlockRef,
        setEditableElement, sloganColor,
        setSloganColor, companyNameScale,
        setCompanyNameScale, sloganScale,
        setSloganScale, resetSizeElement,
        resetHorizontalPostitionElement, resetVerticalPostitionElement,
        companyNameHorizontalPosition, setCompanyNameHorizontalPosition,
        companyNameVerticalPosition, setCompanyNameVerticalPosition,
        sloganHorizontalPosition, setSloganHorizontalPosition,
        sloganVerticalPosition, setSloganVerticalPosition,
        layout, handleChangeLayout, companyName,
        setCompanyName, companyNameSpacing, setCompanyNameSpacing,
        sloganSpacing, setSloganSpacing, resetLetterSpacing,
        companyNameTransform, setCompanyNameTransform,
        sloganTransform, setSloganTransform, slogan, setSlogan,
        currentTemplateData, setCurrentHistoryStep, setHistoryChanges,
        currentHistoryStep, sloganSize, companyNameSize,
    } = useEditorContext()

    const dropdownColorRef = useRef(null)
    const scaleBlockRef = useRef(null)
    const positionBlockRef = useRef(null)
    const spacingBlockRef = useRef(null)

    const { isDark } = useTheme()

    const [updateLogo] = useUpdateLogoMutation()

    const handleDeleteElement = () => {
        deleteElement(editableElement)
    }

    const handleResetSizeValue = () => {
        resetSizeElement(editableElement)
    }

    const handleResetHorizontalPositionValue = () => {
        resetHorizontalPostitionElement(editableElement)
    }

    const handleResetVerticalPositionValue = () => {
        resetVerticalPostitionElement(editableElement)
    }

    const handleLayoutChangeClick = (newLayout) => {
        if (newLayout !== layout) {
            handleChangeLayout(newLayout)
        }
    }

    const handleResetSpacingValue = () => {
        resetLetterSpacing(editableElement)
    }

    const handleChangeCompanyName = (value) => {
        setCompanyName(value)
        setTempCompanyName(value)
    }

    const handleChangeSlogan = (value) => {
        setSlogan(value)
        setTempSlogan(value)
    }

    const handleImageVerticalFlip = (value) => {
        setVerticalFlip(value)
        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = {
                verticalFlip: {
                    prev: verticalFlip,
                    next: value,
                }
            }

            return cuttedToCurrent
        })

        updateLogo({ id: currentTemplateData.id, vertical_flip: value }).then((res) => {
            if ('error' in res) {

            } else {
                setVerticalFlip(res.data.vertical_flip)
            }
        })
    }

    const handleChangeSpacingEnd = () => {
        let historyObject = {}

        switch (editableElement) {
            case 'company': {
                historyObject = {
                    companyNameSpacing: {
                        prev: currentTemplateData.company_name_spacing,
                        next: companyNameSpacing
                    }
                }
                break;
            }
            case 'slogan': {
                historyObject = {
                    sloganSpacing: {
                        prev: currentTemplateData.slogan_spacing,
                        next: sloganSpacing
                    }
                }
                break;
            }
            default: {
                break;
            }
        }

        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = historyObject

            return cuttedToCurrent
        })

        updateLogo({ id: currentTemplateData.id, company_name_spacing: companyNameSpacing, slogan_spacing: sloganSpacing }).then((res) => {
            if ('error' in res) {

            } else {
                setCompanyNameSpacing(res.data.company_name_spacing)
                setSloganSpacing(res.data.slogan_spacing)
            }
        })
    }

    const handleImageHorizontalFlip = (value) => {
        setHorizontalFlip(value)
        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = {
                horizontalFlip: {
                    prev: horizontalFlip,
                    next: value,
                }
            }

            return cuttedToCurrent
        })

        updateLogo({ id: currentTemplateData.id, horizontal_flip: value }).then((res) => {
            if ('error' in res) {

            } else {
                setHorizontalFlip(res.data.horizontal_flip)
            }
        })
    }

    const handleTextTransformChange = (value) => {
        let historyObject = {}

        switch (editableElement) {
            case 'company': {
                setCompanyNameTransform(value)

                historyObject = {
                    companyNameTransform: {
                        prev: currentTemplateData.company_name_transform,
                        next: value
                    }
                }

                updateLogo({ id: currentTemplateData.id, company_name_transform: value }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setCompanyNameTransform(res.data.company_name_transform)
                    }
                })
                break;
            }
            case 'slogan': {
                setSloganTransform(value)

                historyObject = {
                    sloganTransform: {
                        prev: currentTemplateData.slogan_transform,
                        next: value
                    }
                }

                updateLogo({ id: currentTemplateData.id, slogan_transform: value }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setSloganTransform(res.data.slogan_transform)
                    }
                })
                break;
            }
            default: {
                break;
            }
        }

        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = historyObject

            return cuttedToCurrent
        })
    }

    const handleSpacingChange = (values) => {
        setCurrentSpacingValue(values[1])

        switch (editableElement) {
            case 'company': {
                setCompanyNameSpacing(values[1])
                break;
            }
            case 'slogan': {
                setSloganSpacing(values[1])
                break;
            }
            default: {
                break;
            }
        }
    }

    const handleChangeScaleEnd = () => {
        let historyObject = {}

        switch (editableElement) {
            case 'image': {
                updateLogo({ id: currentTemplateData.id, logo_scale: logoScale }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setLogoScale(res.data.logo_scale)
                    }
                })

                historyObject = {
                    logoScale: {
                        prev: currentTemplateData.logo_scale,
                        next: logoScale,
                    }
                }
                break;
            }
            case 'company': {
                updateLogo({ id: currentTemplateData.id, company_name_scale: companyNameScale }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setCompanyNameScale(res.data.company_name_scale)
                    }
                })

                historyObject = {
                    companyNameScale: {
                        prev: currentTemplateData.company_name_scale,
                        next: companyNameScale,
                    }
                }
                break;
            }
            case 'slogan': {
                updateLogo({ id: currentTemplateData.id, slogan_scale: sloganScale }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setSloganScale(res.data.slogan_scale)
                    }
                })

                historyObject = {
                    sloganScale: {
                        prev: currentTemplateData.slogan_scale,
                        next: sloganScale,
                    }
                }
                break;
            }
            default: {
                break;
            }
        }

        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = historyObject

            return cuttedToCurrent
        })
    }

    const handleChangeHorizontalPositionEnd = () => {
        let historyObject = {}

        switch (editableElement) {
            case 'image': {
                updateLogo({ id: currentTemplateData.id, logo_horizontal_position: imageHorizontalPosition }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setImageHorizontalPosition(res.data.logo_horizontal_position)
                    }
                })

                historyObject = {
                    imageHorizontalPosition: {
                        prev: currentTemplateData?.logo_horizontal_position,
                        next: imageHorizontalPosition
                    }
                }
                break;
            }
            case 'company': {
                updateLogo({ id: currentTemplateData.id, company_name_horizontal_position: companyNameHorizontalPosition }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setCompanyNameHorizontalPosition(res.data.company_name_horizontal_position)
                    }
                })

                historyObject = {
                    companyNameHorizontalPosition: {
                        prev: currentTemplateData?.company_name_horizontal_position,
                        next: companyNameHorizontalPosition
                    }
                }
                break;
            }
            case 'slogan': {
                updateLogo({ id: currentTemplateData.id, slogan_horizontal_position: sloganHorizontalPosition }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setSloganHorizontalPosition(res.data.slogan_horizontal_position)
                    }
                })

                historyObject = {
                    sloganHorizontalPosition: {
                        prev: currentTemplateData?.slogan_horizontal_position,
                        next: sloganHorizontalPosition
                    }
                }
                break;
            }
            default: {
                break;
            }
        }

        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = historyObject

            return cuttedToCurrent
        })
    }

    const handleChangeVerticalPositionEnd = () => {
        let historyObject = {}

        switch (editableElement) {
            case 'image': {
                updateLogo({ id: currentTemplateData.id, logo_vertical_position: imageVerticalPosition }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setImageVerticalPosition(res.data.logo_vertical_position)
                    }
                })

                historyObject = {
                    imageVerticalPosition: {
                        prev: currentTemplateData?.logo_vertical_position,
                        next: imageVerticalPosition
                    }
                }
                break;
            }
            case 'company': {
                updateLogo({ id: currentTemplateData.id, company_name_vertical_position: companyNameVerticalPosition }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setCompanyNameVerticalPosition(res.data.company_name_vertical_position)
                    }
                })

                historyObject = {
                    companyNameVerticalPosition: {
                        prev: currentTemplateData?.company_name_vertical_position,
                        next: companyNameVerticalPosition
                    }
                }
                break;
            }
            case 'slogan': {
                updateLogo({ id: currentTemplateData.id, slogan_vertical_position: sloganVerticalPosition }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setSloganVerticalPosition(res.data.slogan_vertical_position)
                    }
                })

                historyObject = {
                    sloganVerticalPosition: {
                        prev: currentTemplateData?.slogan_vertical_position,
                        next: sloganVerticalPosition
                    }
                }
                break;
            }
            default: {
                break;
            }
        }

        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = historyObject

            return cuttedToCurrent
        })
    }

    const handleHorizontalPositionChange = (values) => {
        setCurrentHorizontalPosition(values[1])

        switch (editableElement) {
            case 'image': {
                setImageHorizontalPosition(values[1])
                break;
            }
            case 'company': {
                setCompanyNameHorizontalPosition(values[1])
                break;
            }
            case 'slogan': {
                setSloganHorizontalPosition(values[1])
                break;
            }
            default: {
                break;
            }
        }
    }

    const handleVerticalPositionChange = (values) => {
        setCurrentVerticalPosition(values[1])

        switch (editableElement) {
            case 'image': {
                setImageVerticalPosition(values[1])
                break;
            }
            case 'company': {
                setCompanyNameVerticalPosition(values[1])
                break;
            }
            case 'slogan': {
                setSloganVerticalPosition(values[1])
                break;
            }
            default: {
                break;
            }
        }
    }

    const handleScaleChange = (values) => {
        setCurrentScaleValue(values[1])

        switch (editableElement) {
            case 'image': {
                setLogoScale(values[1])
                break;
            }
            case 'company': {
                setCompanyNameScale(values[1])
                break;
            }
            case 'slogan': {
                setSloganScale(values[1])
                break;
            }
            default: {
                break;
            }
        }
    }

    const handleChangeColor = (color) => {
        let historyObject = {}
        setCurrentColor(color.hex)

        switch (editableElement) {
            case 'image': {
                setImageColor(color.hex)

                historyObject = {
                    imageColor: {
                        prev: imageColor,
                        next: color.hex
                    }
                }

                updateLogo({ id: currentTemplateData.id, image_color: color.hex }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setImageColor(res.data.image_color)
                    }
                })

                break;
            }
            case 'company': {
                setCompanyNameColor(color.hex)

                historyObject = {
                    companyNameColor: {
                        prev: companyNameColor,
                        next: color.hex
                    }
                }

                updateLogo({ id: currentTemplateData.id, company_name_color: color.hex }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setCompanyNameColor(res.data.company_name_color)
                    }
                })

                break;
            }
            case 'slogan': {
                setSloganColor(color.hex)

                historyObject = {
                    sloganColor: {
                        prev: sloganColor,
                        next: color.hex
                    }
                }

                updateLogo({ id: currentTemplateData.id, slogan_color: color.hex }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setSloganColor(res.data.slogan_color)
                    }
                })

                break;
            }
            default: {
                break;
            }
        }

        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = historyObject

            return cuttedToCurrent
        })
    }

    useEffect(() => {
        const changeTimer = setTimeout(() => {
            if ((slogan && slogan !== currentTemplateData?.slogan) || (sloganSize && sloganSize !== currentTemplateData?.slogan_size)) {
                setCurrentHistoryStep((prev) => {
                    return prev + 1
                })
                setHistoryChanges((prev) => {
                    const cuttedToCurrent = prev.slice(0, currentHistoryStep)

                    cuttedToCurrent[currentHistoryStep] = {
                        slogan: {
                            prev: currentTemplateData?.slogan,
                            next: slogan,
                        }
                    }

                    return cuttedToCurrent
                })

                updateLogo({ id: currentTemplateData?.id, slogan: slogan, slogan_size: sloganSize }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setSlogan(res.data.slogan)
                        setSloganSize(res.data.slogan_size)
                        setCurrentTemplateData((prev) => {
                            return {
                                ...prev,
                                slogan: res.data.slogan,
                                slogan_size: res.data.slogan_size,
                            }
                        })
                    }
                })
            }
        }, 2000)

        return () => {
            clearTimeout(changeTimer)
        }
    }, [tempSlogan, sloganSize])

    useEffect(() => {
        const changeTimer = setTimeout(() => {
            if ((companyName && companyName !== currentTemplateData?.company_name) || (companyNameSize && companyNameSize !== currentTemplateData?.company_name_size)) {
                setCurrentHistoryStep((prev) => {
                    return prev + 1
                })
                setHistoryChanges((prev) => {
                    const cuttedToCurrent = prev.slice(0, currentHistoryStep)

                    cuttedToCurrent[currentHistoryStep] = {
                        companyName: {
                            prev: currentTemplateData?.company_name,
                            next: companyName,
                        }
                    }

                    return cuttedToCurrent
                })

                updateLogo({ id: currentTemplateData?.id, company_name: companyName, company_name_size: companyNameSize }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setCompanyName(res.data.company_name)
                        setCompanyNameSize(res.data.company_name_size)
                        setCurrentTemplateData((prev) => {
                            return { ...prev, company_name: res.data.company_name, company_name_size: res.data.company_name_size }
                        })
                    }
                })
            }
        }, 2000)

        return () => {
            clearTimeout(changeTimer)
        }
    }, [tempCompanyName, companyNameSize])

    useEffect(() => {
        switch (editableElement) {
            case 'image': {
                setCurrentColor(imageColor)
                setCurrentScaleValue(logoScale)
                setCurrentHorizontalPosition(imageHorizontalPosition)
                setCurrentVerticalPosition(imageVerticalPosition)
                break;
            }
            case 'company': {
                setCurrentColor(companyNameColor)
                setCurrentScaleValue(companyNameScale)
                setCurrentHorizontalPosition(companyNameHorizontalPosition)
                setCurrentVerticalPosition(companyNameVerticalPosition)
                setCurrentSpacingValue(companyNameSpacing)
                setCurrentTransformValue(companyNameTransform)
                break;
            }
            case 'slogan': {
                setCurrentColor(sloganColor)
                setCurrentScaleValue(sloganScale)
                setCurrentHorizontalPosition(sloganHorizontalPosition)
                setCurrentVerticalPosition(sloganVerticalPosition)
                setCurrentSpacingValue(sloganSpacing)
                setCurrentTransformValue(sloganTransform)
                break;
            }
            default: {
                break;
            }
        }
    }, [
        editableElement, companyNameColor, companyNameScale,
        imageColor, imageHorizontalPosition, imageVerticalPosition,
        logoScale, sloganColor, sloganScale,
        sloganHorizontalPosition, sloganVerticalPosition,
        companyNameHorizontalPosition, companyNameVerticalPosition,
        companyNameSpacing, sloganSpacing, companyNameTransform,
        sloganTransform,
    ])

    useEffect(() => {
        const handleClickOutsideToolbar = (event) => {
            if (previewBlockRef.current && contentPreviewBlockRef.current && !previewBlockRef.current.contains(event.target) && contentPreviewBlockRef.current.contains(event.target)) {
                setEditableElement('')
            }

            if (dropdownColorRef.current && !dropdownColorRef.current.contains(event.target)) {
                setShowColorPicker(false);
            }

            if (scaleBlockRef.current && !scaleBlockRef.current.contains(event.target)) {
                setShowScale(false)
            }

            if (positionBlockRef.current && !positionBlockRef.current.contains(event.target)) {
                setShowPosition(false)
            }

            if (spacingBlockRef.current && !spacingBlockRef.current.contains(event.target)) {
                setShowSpacing(false)
            }
        };
        document.addEventListener('mousedown', handleClickOutsideToolbar);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideToolbar);
        };
    }, [dropdownColorRef, scaleBlockRef, positionBlockRef, spacingBlockRef]);

    return (
        <EditorToolbarBlock>
            {editableElement ? (
                <>
                    <div ref={dropdownColorRef} className='color-picker-wrapper'>
                        <div onClick={() => setShowColorPicker(!showColorPicker)} style={{ backgroundColor: `${currentColor}` }} className='color-picker-block'></div>
                        {showColorPicker && (
                            <div className='dropdown-color-picker'>
                                <ChromePicker color={currentColor} onChangeComplete={handleChangeColor} />
                            </div>
                        )}
                    </div>
                    <div ref={scaleBlockRef} className='toolbar-size-wrapper'>
                        <span onClick={() => setShowScale(!showScale)} className={`toolbar-size-button ${showScale ? 'active' : ''}`}>Size</span>
                        {showScale && (
                            <div className='toolbar-size-range-block'>
                                <RangeSlider
                                    className="single-thumb navigation-logo-scale-input"
                                    defaultValue={[0, 1]}
                                    min={0}
                                    max={2}
                                    step={0.01}
                                    value={[0, currentScaleValue]}
                                    thumbsDisabled={[true, false]}
                                    rangeSlideDisabled={true}
                                    onInput={handleScaleChange}
                                    onThumbDragEnd={handleChangeScaleEnd}
                                />
                                <span className='toolbar-size-range-text-value'>{currentScaleValue}</span>
                                <div onClick={handleResetSizeValue} className='toolbar-revert-size-range-value'>
                                    {isDark ? (
                                        <img src={RevertValueWhite} alt="" />
                                    ) : (
                                        <img src={RevertValueBlack} alt="" />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div ref={positionBlockRef} className='toolbar-position-wrapper'>
                        <span onClick={() => setShowPosition(!showPosition)} className={`toolbar-position-button ${showPosition ? 'active' : ''}`}>Position</span>
                        {showPosition && (
                            <div className='toolbar-position-block'>
                                <div className='toolbar-position-option-block'>
                                    <span className='toolbar-position-option-title'>Horizontal Position</span>
                                    <div className='toolbar-position-option-range-block'>
                                        <RangeSlider
                                            className="single-thumb navigation-logo-scale-input"
                                            defaultValue={[-60, 0]}
                                            min={-60}
                                            max={60}
                                            step={1}
                                            value={[-60, currentHorizontalPosition]}
                                            thumbsDisabled={[true, false]}
                                            rangeSlideDisabled={true}
                                            onInput={handleHorizontalPositionChange}
                                            onThumbDragEnd={handleChangeHorizontalPositionEnd}
                                        />
                                        <span className='toolbar-size-range-text-value'>{currentHorizontalPosition}</span>
                                        <div onClick={handleResetHorizontalPositionValue} className='toolbar-revert-size-range-value'>
                                            {isDark ? (
                                                <img src={RevertValueWhite} alt="" />
                                            ) : (
                                                <img src={RevertValueBlack} alt="" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='toolbar-position-option-block'>
                                    <span className='toolbar-position-option-title'>Vertical Position</span>
                                    <div className='toolbar-position-option-range-block'>
                                        <RangeSlider
                                            className="single-thumb navigation-logo-scale-input"
                                            defaultValue={[-60, 0]}
                                            min={-60}
                                            max={60}
                                            step={1}
                                            value={[-60, currentVerticalPosition]}
                                            thumbsDisabled={[true, false]}
                                            rangeSlideDisabled={true}
                                            onInput={handleVerticalPositionChange}
                                            onThumbDragEnd={handleChangeVerticalPositionEnd}
                                        />
                                        <span className='toolbar-size-range-text-value'>{currentVerticalPosition}</span>
                                        <div onClick={handleResetVerticalPositionValue} className='toolbar-revert-size-range-value'>
                                            {isDark ? (
                                                <img src={RevertValueWhite} alt="" />
                                            ) : (
                                                <img src={RevertValueBlack} alt="" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {editableElement === 'image' ? (
                        <>
                            <div className='toolbar-flip-block'>
                                <div onClick={() => handleImageVerticalFlip(!verticalFlip)} className={`toolbar-flip-option ${verticalFlip ? 'flip-option-active' : ''}`}>
                                    {isDark ? (
                                        <img src={HorizontalFlipWhite} alt="" />
                                    ) : (
                                        <img src={HorizontalFlipBlack} alt="" />
                                    )}
                                </div>
                                <div onClick={() => handleImageHorizontalFlip(!horizontalFlip)} className={`toolbar-flip-option ${horizontalFlip ? 'flip-option-active' : ''}`}>
                                    {isDark ? (
                                        <img src={VerticalFlipWhite} alt="" />
                                    ) : (
                                        <img src={VerticalFlipBlack} alt="" />
                                    )}
                                </div>
                            </div>
                            <div className='toolbar-layout-block'>
                                <div onClick={() => handleLayoutChangeClick('left')} className={`toolbar-layout-option ${layout === 'left' ? 'layout-option-active' : ''}`}>
                                    {isDark ? (
                                        <img src={LayoutLeftWhite} alt="" />
                                    ) : (
                                        <img src={LayoutLeftBlack} alt="" />
                                    )}
                                </div>
                                <div onClick={() => handleLayoutChangeClick('right')} className={`toolbar-layout-option ${layout === 'right' ? 'layout-option-active' : ''}`}>
                                    {isDark ? (
                                        <img src={LayoutRightWhite} alt="" />
                                    ) : (
                                        <img src={LayoutRightBlack} alt="" />
                                    )}
                                </div>
                                <div onClick={() => handleLayoutChangeClick('top')} className={`toolbar-layout-option ${layout === 'top' ? 'layout-option-active' : ''}`}>
                                    {isDark ? (
                                        <img src={LayoutTopWhite} alt="" />
                                    ) : (
                                        <img src={LayoutTopBlack} alt="" />
                                    )}
                                </div>
                                <div onClick={() => handleLayoutChangeClick('bottom')} className={`toolbar-layout-option ${layout === 'bottom' ? 'layout-option-active' : ''}`}>
                                    {isDark ? (
                                        <img src={LayoutBottomWhite} alt="" />
                                    ) : (
                                        <img src={LayoutBottomBlack} alt="" />
                                    )}
                                </div>
                            </div>
                        </>
                    ) : editableElement === 'company' ? (
                        <>
                            <div className='toolbar-company-name-block'>
                                <input className='toolbar-company-name-input' type="text" value={companyName} onChange={(e) => handleChangeCompanyName(e.target.value)} />
                            </div>
                        </>
                    ) : editableElement === 'slogan' ? (
                        <>
                            <div className='toolbar-company-name-block'>
                                <input className='toolbar-company-name-input' type="text" value={slogan} onChange={(e) => handleChangeSlogan(e.target.value)} />
                            </div>
                        </>
                    ) : null}
                    {editableElement === 'company' || editableElement === 'slogan' ? (
                        <>
                            <div ref={spacingBlockRef} className={`toolbar-letter-spacing-block ${showSpacing ? 'active' : ''}`}>
                                <span onClick={() => setShowSpacing(!showSpacing)} className='flex items-center justify-center w-full h-full'>
                                    {isDark ? (
                                        <img src={LetterSpacingWhite} alt="" />
                                    ) : (
                                        <img src={LetterSpacingBlack} alt="" />
                                    )}
                                </span>
                                {showSpacing && (
                                    <div className='toolbar-spacing-range-block'>
                                        <RangeSlider
                                            className="single-thumb navigation-logo-scale-input"
                                            defaultValue={[-5, 0]}
                                            min={-5}
                                            max={10}
                                            step={0.1}
                                            value={[-5, currentSpacingValue]}
                                            thumbsDisabled={[true, false]}
                                            rangeSlideDisabled={true}
                                            onInput={handleSpacingChange}
                                            onThumbDragEnd={handleChangeSpacingEnd}
                                        />
                                        <span className='toolbar-size-range-text-value'>{currentSpacingValue}</span>
                                        <div onClick={handleResetSpacingValue} className='toolbar-revert-size-range-value'>
                                            {isDark ? (
                                                <img src={RevertValueWhite} alt="" />
                                            ) : (
                                                <img src={RevertValueBlack} alt="" />
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='toolbar-text-transform-block'>
                                <div onClick={() => handleTextTransformChange('uppercase')} className={`toolbar-text-transform-option ${currentTransformValue === 'uppercase' ? 'active' : ''}`}>
                                    ABC
                                </div>
                                <div onClick={() => handleTextTransformChange('unset')} className={`toolbar-text-transform-option ${currentTransformValue === 'unset' ? 'active' : ''}`}>
                                    abc
                                </div>
                            </div>
                        </>
                    ) : null}
                    <div onClick={handleDeleteElement} className={`toolbar-remove-wrapper ${editableElement === 'company' ? 'remove-disabled' : ''}`}>
                        {isDark ? (
                            <img src={EditorRemoveWhite} alt="" />
                        ) : (
                            <img src={EditorRemoveBlack} alt="" />
                        )}
                    </div>
                </>
            ) : null}
        </EditorToolbarBlock>
    )
}

export default EditorToolbar