import React from 'react'

import { LoaderBlock } from '../../styles/styles'

const Loader = () => {
    return (
        <LoaderBlock></LoaderBlock>
    )
}

export default Loader