import React from 'react'
import { useNavigate } from 'react-router-dom'

import { NoLogosBlockWrapper } from '../../styles/styles'

const NoLogosBlock = ({ heading }) => {
    const navigate = useNavigate()

    return (
        <NoLogosBlockWrapper>
            <div className='no-logos-heading-block'>
                <h5 className='heading-text'>{heading}</h5>
                <span className='heading-text-label'>Start designing a brand you love today.</span>
            </div>
            <button className='create-logo-button' onClick={() => navigate('/logo-maker')}>Create new logo</button>
        </NoLogosBlockWrapper>
    )
}

export default NoLogosBlock