import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { IoCloseOutline } from 'react-icons/io5';
import { IoIosMenu } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux';

import { HeaderBlock, HeaderLink, HeaderLoginButton, HeaderLogoBlock, HeaderMenuBlock } from '../../styles/styles';
import { userSignOut } from '../../firebase/auth/googleAuth';
import { logoutUser } from '../../redux/appSlice';
import { api } from '../../api/api';

import AuthModal from '../../modals/AuthModal';

import AuthModalImage from '../../assets/auth-modal-image.svg'
import LogoVector from '../../assets/logos/logo.png';

const Header = ({ withBorder = false }) => {
    const [isSignUp, setIsSignUp] = useState(true)
    const [showAuthModal, setShowAuthModal] = useState(false)
    const user = useSelector((state) => state.app.user)
    const profile = useSelector((state) => state.app.profile)

    const location = useLocation()
    const dispatch = useDispatch()

    const handleLogin = () => {
        setIsSignUp(false)
        setShowAuthModal(true)
    }

    const handleLogout = async () => {
        await userSignOut()
        dispatch(api.util.resetApiState());
        dispatch(logoutUser())
    }

    const handleSignUp = () => {
        setIsSignUp(true)
        setShowAuthModal(true)
    }

    useEffect(() => {
        const newImage = new Image();
        newImage.src = AuthModalImage;
        window[AuthModalImage] = newImage;
    }, [])

    return (
        <HeaderBlock className={withBorder ? 'header-with-border' : ''}>
            <div className="flex gap-[8px]">
                <HeaderLogoBlock to="/">
                    <img width={35} src={LogoVector} alt="" />
                    <span>SwiftLogo.ai</span>
                </HeaderLogoBlock>
            </div>

            <div className="flex justify-between items-center flex-auto lg:hidden">
                <div className="flex gap-[32px] items-center">
                    <HeaderLink to='/logo-maker'>Logo Maker</HeaderLink>
                    <HeaderLink to='/how-it-works'>How It Works</HeaderLink>
                    {(profile?.plan === 'Free' || !profile?.plan) && (
                        <HeaderLink to='/plans'>Plans & Pricing</HeaderLink>
                    )}
                </div>

                {!user && (
                    <div className="flex gap-[8px] items-center">
                        <HeaderLoginButton onClick={handleLogin}>Log in</HeaderLoginButton>
                        <button onClick={handleSignUp} className="font-[Inter] bg-yellow px-4 py-[15px] text-[18px] font-medium text-black leading-[22px] rounded-[8px]">Sign up</button>
                    </div>
                )}
            </div>

            <div className={`${user ? '' : 'hidden flex lg:block'} relative justify-center items-center h-[28px] w-[28px] block`}>
                <HeaderMenuBlock right customBurgerIcon={<IoIosMenu className="menu-icon" />} customCrossIcon={<IoCloseOutline className='menu-close' />} >
                    {user && (
                        <div className="flex flex-col">
                            <Link className={location.pathname === '/dashboard' ? 'header-menu-link active-menu-link' : 'header-menu-link'} to='/dashboard'>Dashboard</Link>
                        </div>
                    )}
                    <div className='menu-wrapper'>
                        <Link className={location.pathname === '/logo-maker' ? 'header-menu-link active-menu-link' : 'header-menu-link'} to='/logo-maker'>Create Logo</Link>
                        <Link className={location.pathname === '/how-it-works' ? 'header-menu-link active-menu-link' : 'header-menu-link'} to='/how-it-works'>How It Works</Link>
                        <Link className={location.pathname === '/plans' ? 'header-menu-link active-menu-link' : 'header-menu-link'} to='/plans'>Plans & Pricing</Link>
                    </div>

                    {user ? (
                        <div className="flex flex-col">
                            <div className='header-menu-link' onClick={handleLogout}>Logout</div>
                        </div>
                    ) : (
                        <div className="flex flex-col">
                            <div className='header-menu-link' onClick={handleLogin}>Log in</div>
                            <div className='header-menu-link' onClick={handleSignUp}>Sign up</div>
                        </div>
                    )}
                </HeaderMenuBlock >
            </div >
            {showAuthModal && (
                <AuthModal
                    show={showAuthModal}
                    handleClose={() => setShowAuthModal(false)}
                    signUp={isSignUp}
                    image={AuthModalImage}
                />
            )}
        </HeaderBlock >
    )
}

export default Header