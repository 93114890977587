import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';

import { useThemeContext } from './context/ThemeContext';
import { darkTheme, whiteTheme } from './styles/styles';

import TermsAndConditions from './pages/TermsAndConditions';
import UpgradeSuccess from './pages/UpgradeSuccess';
import PrivacyPolicy from './pages/PrivacyPolicy';
import HowItWorks from './pages/HowItWorks';
import LogoEditor from './pages/LogoEditor';
import Dashboard from './pages/Dashboard';
import LogoMaker from './pages/LogoMaker';
import Checkout from './pages/Checkout';
import MainPage from './pages/MainPage';
import Plans from './pages/Plans';
import Layout from './Layout';

import './App.css';

const App = () => {
  const user = useSelector((state) => state.app.user)

  const { isDarkTheme } = useThemeContext()

  return (
    <ThemeProvider theme={isDarkTheme ? darkTheme : whiteTheme}>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<MainPage />} />
          <Route path='how-it-works' element={<HowItWorks />} />
          <Route path='plans' element={<Plans />} />
          <Route path='editor/:id' element={<LogoEditor />} />
          <Route path='logo-maker' element={<LogoMaker />} />
          <Route path='checkout/:secret/:plan' element={<Checkout />} />
          <Route path='upgrade-success/:plan' element={<UpgradeSuccess />} />
          <Route path='terms-and-conditions' element={<TermsAndConditions />} />
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path='*' element={<MainPage />} />
          {user && (
            <Route path='dashboard/*' element={<Dashboard />} />
          )}
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

export default App;
