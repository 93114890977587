import { getAuth, updateEmail, reauthenticateWithCredential, EmailAuthProvider, updatePassword, verifyBeforeUpdateEmail } from "firebase/auth";

const auth = getAuth();

export const handleValidatePassword = async (password) => {
    try {
        const credential = EmailAuthProvider.credential(
            auth.currentUser.email,
            password
        )

        const { user } = await reauthenticateWithCredential(
            auth.currentUser,
            credential
        )

        if (user) {
            return true
        }
    } catch (error) {
        return false
    }
}

export const handleUpdatePassword = async (newPassword) => {
    try {
        let result;

        updatePassword(auth.currentUser, newPassword).then(() => {
            result = true
        }).catch((error) => {
            result = false
        });

        return result;
    } catch (error) {
        return false
    }
}

export const handleUpdateUserEmail = async (email) => {
    try {
        const result = await updateEmail(auth.currentUser, email)

        if (result) {
            return true
        } else {
            return false
        }
    } catch (error) {
        return false
    }
}

export const handleSendVerificationEmail = async (email) => {
    try {
        await verifyBeforeUpdateEmail(auth.currentUser, email)

        setTimeout(() => {
            handleCheckIfEmailIsVerified()
        }, 8000)

        return true
    } catch (error) {
        return false
    }
}

export const handleCheckIfEmailIsVerified = async () => {
    try {
        await auth.currentUser.reload()

        if (auth.currentUser) {
            setTimeout(() => {
                handleCheckIfEmailIsVerified()
            }, 8000)
        }
    } catch (error) {
        return error
    }
}