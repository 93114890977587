import React from 'react'

import { useEditorContext } from '../../context/EditorContext'
import { FONT_FAMILY_TYPES } from '../../utils/constants'
import { imageColorToFilter } from '../../utils/functions'
import { useUpdateLogoMutation } from '../../api/api'

const EditorNavbarSlogan = () => {
    const {
        companyName, slogan, editableElement,
        horizontalFlip, verticalFlip, imageColor,
        sloganColor, logo, imageWidth, companyNameColor,
        imageHorizontalPosition, imageVerticalPosition,
        companyNameHorizontalPosition, companyNameVerticalPosition,
        sloganHorizontalPosition, sloganVerticalPosition,
        layout, sloganSpacing, companyNameSpacing,
        sloganTransform, companyNameTransform, backgroundColor,
        companyNameFontFamily, setSloganFontFamily, currentTemplateData,
        setCurrentHistoryStep, setHistoryChanges, currentHistoryStep,
        sloganFontFamily,
    } = useEditorContext()

    const [updateLogo] = useUpdateLogoMutation()

    const handleSelectSloganFontFamily = (fontFamily) => {
        setSloganFontFamily(fontFamily)
        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = {
                sloganFontFamily: {
                    prev: sloganFontFamily,
                    next: fontFamily,
                }
            }

            return cuttedToCurrent
        })

        updateLogo({ id: currentTemplateData.id, slogan_font_family: fontFamily }).then((res) => {
            if ('error' in res) {

            } else {
                setSloganFontFamily(res.data.slogan_font_family)
            }
        })
    }

    return (
        <div className='editor-navbar-item-content'>
            <div className='editor-suggested-templates-list'>
                {FONT_FAMILY_TYPES.map((fontFamily, index) => (
                    <div
                        key={index}
                        onClick={() => handleSelectSloganFontFamily(fontFamily)}
                        style={{
                            width: '100%',
                            minHeight: '180px',
                            backgroundColor: `${backgroundColor}`,
                            flexDirection: `${layout === 'top' ? 'column' : layout === 'bottom' ? 'column-reverse' : layout === 'left' ? 'row' : 'row-reverse'}`
                        }}
                        className='preview-image-block'
                    >
                        {logo && (
                            <div
                                style={{ transform: `translateX(${imageHorizontalPosition}px) translateY(${imageVerticalPosition}px)` }}
                                className={`generated-logo-icon ${editableElement === 'image' ? 'editable' : ''}`}
                            >
                                <img
                                    draggable={false}
                                    style={{
                                        transform: `rotateX(${horizontalFlip ? '180deg' : '0deg'}) rotateY(${verticalFlip ? '180deg' : '0deg'})`,
                                        filter: `${imageColorToFilter(imageColor)}`,
                                        width: `${imageWidth < 60 ? imageWidth : 60}px`,
                                    }}
                                    src={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(logo)}`}
                                    alt=""
                                />
                            </div>
                        )}
                        <div className='generated-logo-text'>
                            <span
                                style={{
                                    color: `${companyNameColor}`,
                                    fontFamily: `${companyNameFontFamily}`,
                                    fontSize: `16px`,
                                    transform: `translateX(${companyNameHorizontalPosition}px) translateY(${companyNameVerticalPosition}px)`,
                                    letterSpacing: `${companyNameSpacing}px`,
                                    textTransform: `${companyNameTransform}`,
                                    textAlign: `${layout === 'top' ? 'center' : layout === 'bottom' ? 'center' : layout === 'left' ? 'start' : 'end'}`,
                                    wordBreak: 'break-all',
                                }}
                                className={`generated-logo-company-name ${editableElement === 'company' ? 'editable' : ''}`}
                            >
                                {companyName}
                            </span>
                            <span
                                style={{
                                    color: `${sloganColor}`,
                                    fontFamily: `${fontFamily}`,
                                    fontSize: `12px`,
                                    transform: `translateX(${sloganHorizontalPosition}px) translateY(${sloganVerticalPosition}px)`,
                                    letterSpacing: `${sloganSpacing}px`,
                                    textTransform: `${sloganTransform}`,
                                    textAlign: `${layout === 'top' ? 'center' : layout === 'bottom' ? 'center' : layout === 'left' ? 'start' : 'end'}`,
                                    wordBreak: 'break-all',
                                }}
                                className={`generated-logo-slogan ${editableElement === 'slogan' ? 'editable' : ''}`}
                            >
                                {slogan ? slogan : 'Slogan'}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default EditorNavbarSlogan