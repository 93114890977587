import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector } from 'react-redux';

import { useSendEmailOnPurchaseMutation } from '../../api/plunkApi';

const PayPalButton = ({ height, planName, selectedPlan, setError }) => {
    const user = useSelector((state) => state.app.user)

    const navigate = useNavigate();

    const [sendPurchaseEmail] = useSendEmailOnPurchaseMutation()

    const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID ? process.env.REACT_APP_PAYPAL_CLIENT_ID : '';

    const createSubscription = (data, actions) => {
        return actions.subscription.create({
            'plan_id': selectedPlan.paypal_plan_id,
            'quantity': 1,
            'custom_id': JSON.stringify({
                'customer_id': user.uid,
                'plan_id': selectedPlan.paypal_plan_id,
                'plan_name': selectedPlan.name,
            }),
        })
    };

    const createOrder = (data, actions) => {
        return actions.order.create({
            'purchase_units': [{
                'amount': {
                    'currency_code': 'USD',
                    'value': selectedPlan.price,
                },
                'custom_id': JSON.stringify({
                    'customer_id': user.uid,
                    'plan_id': selectedPlan.paypal_plan_id,
                    'plan_name': selectedPlan.name,
                }),
            }],
        })
    }

    const onApprove = (data, actions) => {
        actions.subscription.get().then(function (details) {
            sendPurchaseEmail({ email: user.email, domain: window.location.host })
            navigate(`/upgrade-success/${planName}?payment_intent=${details.id}&payment_type=paypal`)
        });
    };

    const onApproveOrder = (data, actions) => {
        actions.order.get().then(function (details) {
            sendPurchaseEmail({ email: user.email, domain: window.location.host })
            navigate(`/upgrade-success/${planName}?payment_intent=${details.id}&payment_type=paypal`)
        });
    }

    const onError = (error) => {
        setError(true)
    };

    return (
        <PayPalScriptProvider options={{ "client-id": CLIENT_ID, "disable-funding": "card,credit", "vault": "true" }}>
            {selectedPlan.paypal_plan_id ? (
                <PayPalButtons
                    className="w-100"
                    onApprove={onApprove}
                    onError={onError}
                    createSubscription={createSubscription}
                    style={{
                        disableMaxWidth: true,
                        height: height ? 50 : 52,
                    }}
                />
            ) : (
                <PayPalButtons
                    className="w-100"
                    onApprove={onApproveOrder}
                    onError={onError}
                    createOrder={createOrder}
                    style={{
                        disableMaxWidth: true,
                        height: height ? 50 : 52,
                    }}
                />
            )}
        </PayPalScriptProvider>
    )
}

export default PayPalButton