import React, { useMemo, useState } from 'react'
import { useTheme } from 'styled-components';
import { IconContext } from 'react-icons'
import * as IconNameAi from "react-icons/ai";
import * as IconNameBi from "react-icons/bi";
import * as IconNameBs from "react-icons/bs";
import * as IconNameCg from "react-icons/cg";
import * as IconNameCi from "react-icons/ci";
import * as IconNameDi from "react-icons/di";
import * as IconNameFa from "react-icons/fa";
import * as IconNameFi from "react-icons/fi";
import * as IconNameGi from "react-icons/gi";
import * as IconNameGo from "react-icons/go";
import * as IconNameMd from "react-icons/md";

import { useViewport } from '../../hooks/useViewport'
import { SEARCH_SYMBOL_SUGGESTIONS, SYMBOL_TYPES_BY_INDUSTRY } from '../../utils/constants'

import { LogoMakerStepFiveBlock } from '../../styles/styles'

const LogoMakerStepFive = ({ selectedIconsType, setSelectedIconsType, selectedIcons, setSelectedIcons, industry }) => {
    const [search, setSearch] = useState('')
    const [searchFilter, setSearchFilter] = useState('')
    const [pickOwnSymbol, setPickOwnSymbol] = useState(false)
    const [showSearchSymbols, setShowSearchSymbols] = useState(false)

    const { isDark } = useTheme()
    const { isMobile } = useViewport()

    const allIcons = useMemo(() => {
        return { ...IconNameAi, ...IconNameBi, ...IconNameBs, ...IconNameCg, ...IconNameCi, ...IconNameDi, ...IconNameFa, ...IconNameFi, ...IconNameGi, ...IconNameGo, ...IconNameMd }
    }, [])

    const iconsArray = useMemo(() => {
        return Object.keys(allIcons);
    }, [allIcons])

    const filteredIcons = useMemo(() => {
        const searchLowerCase = searchFilter.toLowerCase()

        return iconsArray.filter((iconKey) =>
            iconKey.toLowerCase().includes(searchLowerCase)
        );
    }, [iconsArray, searchFilter])

    const handlePickMyOwnSymbol = () => {
        setSelectedIconsType([])
        setPickOwnSymbol(true)
    }

    const handleSearchIcons = () => {
        setSearchFilter(search)
        setShowSearchSymbols(true)
    }

    const handlePickSymbolForMe = () => {
        setSelectedIcons([])
        setPickOwnSymbol(false)
    }

    const handleSelectIcon = (key) => {
        if (selectedIcons.includes(key)) {
            const filteredList = selectedIcons.filter((el) => el !== key)

            setSelectedIcons(filteredList)
        } else if (selectedIcons.length < 5) {
            setSelectedIcons([...selectedIcons, key])
        }
    }

    const handleChangeSearch = (e) => {
        const value = e.target.value

        setSearch(value)

        if (!value) {
            setShowSearchSymbols(false)
        }
    }

    const handleSelectSearchCategory = (name) => {
        setSearch(name)
        setSearchFilter(name)
        setShowSearchSymbols(true)
    }

    const handleBlockSymbolClick = (name) => {
        if (selectedIconsType.includes(name)) {
            const filteredList = selectedIconsType.filter((el) => el !== name)

            setSelectedIconsType(filteredList)
        } else {
            setSelectedIconsType([...selectedIconsType, name])
        }
    }

    return (
        <LogoMakerStepFiveBlock>
            <div className="flex justify-between items-center">
                <div>
                    <h4 className="logo-maker-step-five-heading">Pick some symbol types</h4>
                    {pickOwnSymbol ? (
                        <div onClick={handlePickSymbolForMe} className="logo-maker-step-five-pick-symbol">
                            Pick symbols for me
                        </div>
                    ) : (
                        <p className="logo-maker-step-five-description">We’ve hand-curated symbols for these types.</p>
                    )}
                    {isMobile && !pickOwnSymbol && (
                        <div onClick={handlePickMyOwnSymbol} className="logo-maker-step-five-want-to-pick mt-0 mb-4">
                            I want to pick my own symbols
                        </div>
                    )}
                </div>
            </div>

            <div className="logo-maker-step-five-icons-wrapper">
                {!pickOwnSymbol ? (
                    <>
                        <div className="symbols-categories-list">
                            {SYMBOL_TYPES_BY_INDUSTRY[industry] ? (
                                <>
                                    {SYMBOL_TYPES_BY_INDUSTRY[industry].map((item, index) => (
                                        <div
                                            key={index}
                                            className={`symbol-categories-item ${selectedIconsType.includes(item) ? 'selected' : ''}`}
                                            onClick={() => handleBlockSymbolClick(item)}
                                        >
                                            {item}
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <>
                                    {SYMBOL_TYPES_BY_INDUSTRY['Other'].map((item, index) => (
                                        <div
                                            key={index}
                                            className={`symbol-categories-item ${selectedIconsType.includes(item) ? 'selected' : ''}`}
                                            onClick={() => handleBlockSymbolClick(item)}
                                        >
                                            {item}
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                        {!isMobile && (
                            <div onClick={handlePickMyOwnSymbol} className="logo-maker-step-five-want-to-pick">
                                I want to pick my own symbols
                            </div>
                        )}
                    </>
                ) : (
                    <div className="step-five-symbols-search-block-wrapper">
                        <div className="search-block-input-wrapper">
                            <div className="search-block-input-container">
                                <input
                                    type="text"
                                    placeholder="Search icons"
                                    value={search}
                                    onChange={handleChangeSearch}
                                    className="search-block-input"
                                />
                                {search.length > 0 &&
                                    <button
                                        type='button'
                                        onClick={handleSearchIcons}
                                        className={`symbols-pick-search-button`}
                                    >
                                        Search
                                    </button>
                                }
                            </div>
                            <IconContext.Provider value={{ size: '35px', color: `${isDark ? '#FFF' : '#1E1E1E'}` }}>
                                <div className='symbols-pick-selected-list'>
                                    {selectedIcons.length > 0 && [0, 1, 2, 3, 4].map((index) => {
                                        const Icon = allIcons[selectedIcons[index]];

                                        if (Icon) {
                                            return (
                                                <div key={index} className='symbols-pick-selected-icon symbols-pick-selected-icon-item'>
                                                    <Icon />
                                                    <div onClick={() => handleSelectIcon(selectedIcons[index])} className='symbols-pick-selected-icon-item-delete'>
                                                        <IconNameAi.AiOutlineClose fill='white' />
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div key={index} className='symbols-pick-selected-icon'></div>
                                            )
                                        }
                                    })}
                                </div>
                            </IconContext.Provider>
                        </div>
                        {showSearchSymbols ? (
                            <>
                                <div className='search-active-categories-list'>
                                    {SEARCH_SYMBOL_SUGGESTIONS.map((item) => (
                                        <div
                                            key={item.id}
                                            className={`symbol-categories-item ${searchFilter === item.name ? 'search-active-categories-list-active-item' : ''}`}
                                            onClick={() => handleSelectSearchCategory(item.name)}
                                        >
                                            {item.name}
                                        </div>
                                    ))}
                                </div>
                                <IconContext.Provider value={{ size: `${isMobile ? '85px' : '106px'}`, color: `${isDark ? '#FFF' : '#1E1E1E'}` }}>
                                    <div className="logo-maker-step-five-icons-list">
                                        {filteredIcons.map((iconKey, index) => {
                                            const Icon = allIcons[iconKey];

                                            if (Icon) {
                                                return (
                                                    <div onClick={() => handleSelectIcon(iconKey)} key={index} className={`logo-maker-step-five-icon ${selectedIcons.includes(iconKey) ? 'selected' : ''}`}>
                                                        <Icon />
                                                    </div>
                                                );
                                            } else {
                                                return null
                                            }
                                        })}
                                    </div>
                                </IconContext.Provider>
                            </>
                        ) : (
                            <>
                                <div className="step-five-select-one-of-this-text">...Or select one of these options: </div>
                                <div className="w-[100%] flex flex-wrap justify-start items-start gap-[24px] pt-[48px] lg:pt-[20px] lg:gap-[8px]">
                                    {SEARCH_SYMBOL_SUGGESTIONS.map((item) => (
                                        <div
                                            key={item.id}
                                            className={`symbol-categories-item`}
                                            onClick={() => handleSelectSearchCategory(item.name)}
                                        >
                                            {item.name}
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </LogoMakerStepFiveBlock>
    )
}

export default LogoMakerStepFive