import React from 'react'
import { Modal } from 'react-bootstrap'

import { VerificationEmailSentModalBlock } from '../styles/styles'

import ModalCloseButton from '../components/ModalCloseButton/ModalCloseButton'

const VerificationEmailSentModal = ({ show, handleClose }) => {
    return (
        <VerificationEmailSentModalBlock centered show={show} onHide={handleClose}>
            <Modal.Body>
                <h5 className='delete-account-title'>Verification email sent</h5>
                <span className='delete-account-description'>
                    Your email will be updated after verification. Please check your email for a verification link.
                </span>
                <div className='delete-account-actions'>
                    <button onClick={handleClose} className='delete-account-cancel'>Close</button>
                    <button onClick={handleClose} className='delete-account-delete'>Got it</button>
                </div>
                <ModalCloseButton handleClose={handleClose} />
            </Modal.Body>
        </VerificationEmailSentModalBlock >
    )
}

export default VerificationEmailSentModal