import React from 'react'

import { DashboardMainBlock } from '../../styles/styles'

import LogosAvailableForPurchase from './LogosAvailableForPurchase'
import WhatWillDesignToday from './WhatWillDesignToday'
import RecentDesigns from './RecentDesigns'

const DashboardMain = () => {
    return (
        <DashboardMainBlock>
            <WhatWillDesignToday />
            <RecentDesigns />
            {/* <LogosAvailableForPurchase /> */}
        </DashboardMainBlock>
    )
}

export default DashboardMain