import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { useTheme } from 'styled-components';

import { HomeGuideBlock } from '../../styles/styles';

import Star from '../../assets/mainPage/Star.svg';
import FirstSub from '../../assets/mainPage/01sub.svg';
import FirstSub2 from '../../assets/mainPage/01sub2.svg';
import BottomArrow from '../../assets/mainPage/bottomArrow.svg';
import BottomArrowDark from '../../assets/mainPage/bottomArrowDark.svg';
import NewFirst from '../../assets/mainPage/new01.png';
import NewSecond from '../../assets/mainPage/new02.png';
import NewSubSecond from '../../assets/mainPage/newsub02.png';
import NewThird from '../../assets/mainPage/new03.png';
import NewSubThird from '../../assets/mainPage/newsub03.png';

const HomeGuide = () => {
    const { isDark } = useTheme()

    return (
        <HomeGuideBlock home={"true"}>
            <div className="max-w-[1440px] p-[120px] m-auto lg:px-0 lg:py-[40px] flex flex-col gap-[80px] lg:gap-[40px]">
                <div className="flex flex-col items-center justify-center gap-[16px]">
                    <div className="theme-text-color text-[64px] relative m-auto text-center font-normal font-[Poppins] leading-[94.2%] tracking-[-2px] w-[840px] lg:text-[36px] lg:text-center lg:w-[auto] lg:max-w-[80%] lg:m-auto">
                        <img className="absolute w-[36px] top-[-15px] left-[120px] z-[0] lg:left-[72px] lg:top-[-36px] lg:w-[42px]" src={Star} alt="" />
                        The <span className="text-[60px] leading-[94.2%] font-[Gilda Display] tracking-[-2px] theme-text-color lg:text-[35px] lg:text-center font-light">easiest way </span>
                        to design and launch your new business
                        {isDark ? (
                            <img className="absolute w-[300px] top-[70px] right-[-170px] z-[0] lg:right-[-60px] lg:top-[50px] lg:w-[150px]" src={BottomArrow} alt="" />
                        ) : (
                            <img className="absolute w-[300px] top-[70px] right-[-170px] z-[0] lg:right-[-60px] lg:top-[50px] lg:w-[150px]" src={BottomArrowDark} alt="" />
                        )}
                    </div>
                    <p className="theme-text-color text-[22px] font-medium text-center lg:max-w-[80%] lg:m-auto lg:text-[16px] font-[Inter]">Use SwiftLogo’s AI-powered platform to design a logo and build a brand you love.</p>
                </div>

                <div className="flex flex-col gap-[80px] lg:w-[90%] lg:m-auto lg:gap-[40px]">
                    <div className="flex jystify-between items-end lg:flex-col lg:items-center lg:max-w-[600px] m-auto lg:pt-[20px]">
                        <div className="w-[600px] rounded-[8px] flex-none flex justify-center items-center relative lg:w-[100%] max-w-[600px]">
                            <AnimationOnScroll animateIn="animate__fadeInRightBig" className="max-w-[50%] w-[100%] absolute bottom-[16%] left-[-7%] z-[1]">
                                <img src={FirstSub} className="max-w-[307px] w-[100%]" alt="" />
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__fadeInLeftBig" className="max-w-[17%] w-[100%] absolute top-[-9%] left-[18%] z-[1]">
                                <img src={FirstSub2} className="max-w-[106px] w-[100%]" alt="" />
                            </AnimationOnScroll>
                            <div className="w-[100%] h-[100%] overflow-hidden rounded-[8px] relative z-[0]">
                                <img src={NewFirst} className="w-[100%]" alt="" />
                            </div>
                        </div>
                        <div className="px-[32px] pt-[16px] lg:px-[0] lg:pt-[0]">
                            <div className="block-guide-title flex flex-col lg:items-center lg:justify-start lg:flex-row gap-[8px] lg:mb-3">
                                <h4 className="text-[64px] font-[Neuton] text-yellow font-extralight">01</h4>
                                <h6 className="font-[Poppins] theme-text-color text-[36px] lg:leading-[28px] font-normal lg:text-[28px]">Make a logo in minutes</h6>
                            </div>
                            <p className="font-[Inter] theme-text-color text-[22px] font-medium lg:text-[18px]">
                                Forget looking generic! SwiftLogo’s software is powered by AI to create a logo that matches your vision — no design skills required.
                                Generate endless options, and tweak designs to get exactly what you want.
                            </p>
                        </div>
                    </div>
                    <div className="flex jystify-between items-end home-guide-row-reverse lg:flex-col lg:items-center lg:max-w-[600px] m-auto lg:pt-[20px]">
                        <div className="w-[600px] rounded-[8px] flex-none flex justify-center items-center relative overflow-x-hidden lg:w-[100%] max-w-[600px]">
                            <AnimationOnScroll animateIn="animate__fadeInRightBig" className="max-w-[500px] w-[100%] absolute bottom-[5%] right-[0] z-[1]">
                                <img src={NewSubSecond} className="max-w-[500px] w-[100%]" alt="" />
                            </AnimationOnScroll>
                            <img style={{ border: '1px solid #EAEAEA', borderRadius: '8px' }} src={NewSecond} className="w-[100%] relative z-[0]" alt="" />
                        </div>
                        <div className="px-[32px] pt-[16px] lg:px-[0] lg:pt-[0]">
                            <div className="block-guide-title flex flex-col lg:items-center lg:justify-start lg:flex-row gap-[8px] lg:mb-3">
                                <h4 className="text-[64px] font-[Neuton] text-pink font-extralight">02</h4>
                                <h6 className="font-[Poppins] theme-text-color text-[36px] lg:leading-[28px] font-normal lg:text-[28px]">Bring your brand to life</h6>
                            </div>
                            <p className="font-[Inter] theme-text-color text-[22px] font-medium lg:text-[18px]">
                                With your logo design finalized, it’s time to start building your brand identity. SwiftLogo’s Brand Kit uses your logo, colors, and fonts to instantly create hundreds of branded marketing materials — all in one spot.
                            </p>
                        </div>
                    </div>
                    <div className="flex jystify-between items-end lg:flex-col lg:items-center lg:max-w-[600px] m-auto lg:pt-[20px] overflow-hidden">
                        <div className="w-[600px] rounded-[8px] flex-none flex justify-center items-center relative lg:w-[100%] max-w-[600px]">
                            <AnimationOnScroll animateIn="animate__fadeInLeftBig" className="absolute max-w-[284px] w-[100%] z-[1] bottom-[5%] left-[1px] lg:max-w-[51%]">
                                <img src={NewSubThird} className="w-[100%] relative" alt="" />
                            </AnimationOnScroll>
                            <img style={{ borderRadius: '8px', border: '1px solid #EAEAEA' }} src={NewThird} className="w-[100%] relative z-[0]" alt="" />
                        </div>
                        <div className="px-[32px] pt-[16px] lg:px-[0] lg:pt-[0]">
                            <div className="block-guide-title flex flex-col lg:items-center lg:justify-start lg:flex-row gap-[8px] lg:mb-3">
                                <h4 className="text-[64px] font-[Neuton] text-blue font-extralight">03</h4>
                                <h6 className="font-[Poppins] theme-text-color text-[36px] lg:leading-[28px] font-normal lg:text-[28px]">Market your business</h6>
                            </div>
                            <p className="font-[Inter] theme-text-color text-[22px] font-medium lg:text-[18px]">
                                Choose from 300+ templates tailored to your brand and industry. Easily customize business cards, social profiles, email signatures, and more using the Brand Kit’s easy-to-use editor.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </HomeGuideBlock >
    )
}

export default HomeGuide