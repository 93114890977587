import React, { useEffect, useState } from 'react'
import { IoIosMenu } from 'react-icons/io';
import { IoCloseOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { api } from '../../api/api';

import { HeaderLogoBlock, HeaderMenuBlock, LogoMakerHeaderBlock } from '../../styles/styles';
import { userSignOut } from '../../firebase/auth/googleAuth';
import { logoutUser } from '../../redux/appSlice';

import AuthModal from '../../modals/AuthModal';

import AuthModalImage from '../../assets/auth-modal-image.svg'
import LogoVector from '../../assets/logos/logo.png';

const LogoMakerHeader = () => {
    const [isSignUp, setIsSignUp] = useState(true)
    const [showAuthModal, setShowAuthModal] = useState(false)
    const user = useSelector((state) => state.app.user)

    const dispatch = useDispatch()
    const location = useLocation()

    const handleLogin = () => {
        setIsSignUp(false)
        setShowAuthModal(true)
    }

    const handleLogout = async () => {
        await userSignOut()
        dispatch(api.util.resetApiState());
        dispatch(logoutUser())
    }

    const handleSignUp = () => {
        setIsSignUp(true)
        setShowAuthModal(true)
    }

    useEffect(() => {
        const newImage = new Image();
        newImage.src = AuthModalImage;
        window[AuthModalImage] = newImage;
    }, [])

    return (
        <LogoMakerHeaderBlock>
            <div className="flex gap-[8px]">
                <HeaderLogoBlock to="/">
                    <img width={35} src={LogoVector} alt="" />
                    <span>SwiftLogo.ai</span>
                </HeaderLogoBlock>
            </div>

            {!user && (
                <div className="flex justify-end items-center flex-auto lg:hidden">
                    <div className="flex gap-[8px] items-center">
                        <button onClick={handleLogin} className="font-[Inter] px-[16px] py-[15px] text-[18px] font-medium leading-[22px] rounded-[8px]">Log in</button>
                        <button onClick={handleSignUp} className="font-[Inter] bg-[#FFCB03] px-4 py-[15px] text-[18px] font-medium text-black leading-[22px] rounded-[8px]">Sign up</button>
                    </div>
                </div>
            )}

            <div className={`${user ? '' : 'hidden flex'} relative justify-center items-center h-[28px] w-[28px] block`}>
                <HeaderMenuBlock right customBurgerIcon={<IoIosMenu className="menu-icon" />} customCrossIcon={<IoCloseOutline className='menu-close' />} >
                    {user && (
                        <div className="flex flex-col">
                            <Link className={location.pathname === '/dashboard' ? 'header-menu-link active-menu-link' : 'header-menu-link'} to='/dashboard'>Dashboard</Link>
                        </div>
                    )}
                    <div className='menu-wrapper'>
                        <Link className={location.pathname === '/logo-maker' ? 'header-menu-link active-menu-link' : 'header-menu-link'} to='/logo-maker'>Create Logo</Link>
                        <Link className={location.pathname === '/how-it-works' ? 'header-menu-link active-menu-link' : 'header-menu-link'} to='/how-it-works'>How It Works</Link>
                        <Link className={location.pathname === '/plans' ? 'header-menu-link active-menu-link' : 'header-menu-link'} to='/plans'>Plans & Pricing</Link>
                    </div>

                    {user ? (
                        <div className="flex flex-col">
                            <div className='header-menu-link' onClick={handleLogout}>Logout</div>
                        </div>
                    ) : (
                        <div className="flex flex-col">
                            <div className='header-menu-link' onClick={handleLogin}>Log in</div>
                            <div className='header-menu-link' onClick={handleSignUp}>Sign up</div>
                        </div>
                    )}
                </HeaderMenuBlock>
            </div>
            {showAuthModal && (
                <AuthModal
                    show={showAuthModal}
                    handleClose={() => setShowAuthModal(false)}
                    signUp={isSignUp}
                    image={AuthModalImage}
                />
            )}
        </LogoMakerHeaderBlock>
    )
}

export default LogoMakerHeader