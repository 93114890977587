import html2canvas from 'html2canvas'
import { hexToCSSFilter } from "hex-to-css-filter";

export const selectRandomArrayElements = (array, elementsNeed) => {
    const selectedElements = [];

    while (selectedElements.length < elementsNeed) {
        const randomIndex = Math.floor(Math.random() * array.length);

        if (!selectedElements.includes(randomIndex)) {
            selectedElements.push(randomIndex);
        }
    }

    const randomSelectedElements = selectedElements.map(index => array[index]);

    return randomSelectedElements;
}

export const imageColorToFilter = (color) => {
    const colorFilter = hexToCSSFilter(color);

    return colorFilter.filter.replace(/;/g, '')
}

export const convertHtmlToCanvas = async (htmlElement, format, premium) => {
    if (!htmlElement) return

    html2canvas(htmlElement, {
        scale: 5,
        backgroundColor: null,
        logging: false,
        onclone: (clonedNode) => {
            const block = clonedNode.getElementById('preview-block')

            if (premium) {
                block.style.backgroundColor = 'transparent'
            }

            block.style.border = 'none'

            block.children[0].style.margin = '0 0 -20px 0'

            return block
        }
    }).then(canvas => {
        const screenshotUrl = canvas.toDataURL('image/png');

        if (format === 'jpg' || format === 'png') {
            const downloadLink = document.createElement('a');
            downloadLink.href = screenshotUrl;
            downloadLink.download = `logo.${format === 'svg' ? 'png' : format}`;
            downloadLink.click();
        } else {
            const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            svgElement.setAttribute("xmlns", "http://www.w3.org/2000/svg");
            svgElement.setAttribute("width", "100%");
            svgElement.setAttribute("height", "100%");
            svgElement.setAttribute("viewBox", `0 0 ${canvas.width} ${canvas.height}`);
            svgElement.setAttribute("preserveAspectRatio", "none");
            const imageElement = document.createElementNS("http://www.w3.org/2000/svg", "image");
            imageElement.setAttributeNS("http://www.w3.org/1999/xlink", "href", screenshotUrl);
            imageElement.setAttribute("width", "100%");
            imageElement.setAttribute("height", "100%");
            svgElement.appendChild(imageElement);
            const downloadLink = document.createElement("a");
            downloadLink.href = 'data:image/svg+xml,' + encodeURIComponent(new XMLSerializer().serializeToString(svgElement));
            downloadLink.download = `logo.svg`;
            downloadLink.click();
        }

        return screenshotUrl
    })
}

export const downloadListImages = (ids_list, prefix, premium) => {
    if (!ids_list || !ids_list.length) return

    for (let id of ids_list) {
        const img = document.getElementById(`${prefix}${id}`)

        html2canvas(img, {
            scale: 5,
            backgroundColor: null,
            logging: false,
            onclone: (clonedNode) => {
                const block = clonedNode.getElementById(`${prefix}${id}`)

                if (premium) {
                    block.style.backgroundColor = 'transparent'
                }

                block.style.border = 'none'

                block.children[0].style.margin = '0 0 -20px 0'

                return block
            }
        }).then(canvas => {
            const screenshotUrl = canvas.toDataURL('image/png');

            const downloadLink = document.createElement('a');
            downloadLink.href = screenshotUrl;
            downloadLink.download = `logo.png`;
            downloadLink.click();

            return screenshotUrl
        })
    }
}

export const downloadLogoPreviewFrames = async (htmlElements) => {
    if (!htmlElements || !htmlElements.length) return

    for (let id of htmlElements) {
        html2canvas(document.getElementById(`preview-image-inserted-logo${id}`), {
            scale: 3,
            backgroundColor: null,
            logging: false,
            onclone: (clonedNode) => {
                const block = clonedNode.getElementById(`preview-image-inserted-logo${id}`)

                const imgBlock = block.children[1].children[0].children[0]

                imgBlock.style.margin = '0 0 -5px 0'

                block.style.border = 'none'

                return block
            }
        }).then(canvas => {
            const screenshotUrl = canvas.toDataURL('image/png');

            const downloadLink = document.createElement('a');
            downloadLink.href = screenshotUrl;
            downloadLink.download = `preview.png`;
            downloadLink.click();

            return screenshotUrl
        })
    }
}