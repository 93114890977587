import React from 'react'
import { useTheme } from 'styled-components';

import { LogoMakerStepThreeBlock } from '../../styles/styles'
import { COLOR_SCHEMAS } from '../../utils/constants'

import Line from '../../assets/Planning/line.svg';
import WhiteLine from '../../assets/Planning/line-white.svg';

const LogoMakerStepThree = ({ selectedColors, setSelectedColors }) => {
    const { isDark } = useTheme()

    const handleColorBlockClick = (id) => {
        if (selectedColors.includes(id)) {
            const filteredList = selectedColors.filter((el) => el !== id)

            setSelectedColors(filteredList)
        } else {
            setSelectedColors([...selectedColors, id])
        }
    }

    return (
        <LogoMakerStepThreeBlock>
            <div className="flex justify-between items-center">
                <div>
                    <h4 className="logo-maker-step-two-heading">Pick some colors you like</h4>
                    <p className="logo-maker-step-two-description">Colors help convey emotions in your logo.</p>
                </div>
            </div>

            <div className="logo-maker-step-two-colors-list">
                {COLOR_SCHEMAS.map((item) => (
                    <div
                        key={item.id}
                        className="color-hover logo-maker-step-two-color-item"
                        style={{ background: item.color }}
                        onClick={() => handleColorBlockClick(item.id)}
                    >
                        <div className={`relative flex flex-col ${selectedColors.includes(item.id) && 'color-hover-text'}`}>
                            <span className="text-white text-[24px] font-bold relative w-fit font-['Almarai']">
                                {item.name}
                                {
                                    selectedColors.includes(item.id) && (
                                        <>
                                            {isDark ? (
                                                <img src={Line} alt="" className="absolute top-[-13px] left-[-23px] w-[130px] max-w-[130px] z-[1]" />
                                            ) : (
                                                <img src={WhiteLine} alt="" className="absolute top-[-13px] left-[-23px] w-[130px] max-w-[130px] z-[1]" />
                                            )}
                                        </>
                                    )
                                }
                            </span>
                            <p className="text-white logo-maker-step-two-color-hover-text">
                                {item.description}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </LogoMakerStepThreeBlock >
    )
}

export default LogoMakerStepThree