import React, { useState } from 'react'
import { useTheme } from 'styled-components'

import { FAQItemBlock } from '../../styles/styles'

import FaqExpandWhite from '../../assets/icons/faq-expand-white.svg'
import FaqExpandBlack from '../../assets/icons/faq-expand-black.svg'

const FAQItem = ({ faq }) => {
    const [expandItem, setExpandItem] = useState(null)

    const handleSelectExpandId = (id) => {
        if (expandItem === id) {
            setExpandItem(null)
        } else {
            setExpandItem(id)
        }
    }

    const { isDark } = useTheme()

    return (
        <FAQItemBlock>
            <div key={faq.id} className='support-window-question-item'>
                <div onClick={() => handleSelectExpandId(faq.id)} className={`support-window-question-item-heading w-full ${expandItem === faq.id ? 'active' : ''}`}>
                    {faq.question}
                    {isDark ? (
                        <img src={FaqExpandWhite} alt="" />
                    ) : (
                        <img src={FaqExpandBlack} alt="" />
                    )}
                </div>
                {expandItem === faq.id && (
                    <span className='support-window-question-answers'>
                        {faq.answer}
                    </span>
                )}
            </div>
        </FAQItemBlock>
    )
}

export default FAQItem