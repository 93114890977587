import React from 'react'

import { SUGGESTED_TEMPLATES_TYPES } from '../../utils/constants'
import { imageColorToFilter } from '../../utils/functions'
import { useEditorContext } from '../../context/EditorContext'

const EditorNavbarSuggested = ({
    suggestedDropdownRef,
    suggestedType,
    showSuggestedTypes,
    setShowSuggestedTypes,
    handleSelectSuggestionType,
    defaultCompanyName,
    defaultSlogan
}) => {
    const { onSelectSuggestedTemplate, generatedLogos, currentTemplateId } = useEditorContext()

    const handleSelectTemplate = (template) => {
        onSelectSuggestedTemplate(template)
    }

    return (
        <div className='editor-navbar-item-content'>
            <div ref={suggestedDropdownRef} className='editor-suggested-type-dropdown-wrapper'>
                <div className='editor-suggested-type-dropdown-button'>
                    <span className='editor-suggested-type-dropdown-value'>{suggestedType}</span>
                    <span onClick={() => setShowSuggestedTypes(!showSuggestedTypes)} className='editor-suggested-type-dropdown-change'>Change</span>
                </div>
                {showSuggestedTypes && (
                    <div className='editor-suggested-types-dropdown-menu'>
                        {SUGGESTED_TEMPLATES_TYPES.map((suggestion) => (
                            <div onClick={() => handleSelectSuggestionType(suggestion.name)} key={suggestion.id} className='editor-suggested-type-item'>{suggestion.name}</div>
                        ))}
                    </div>
                )}
            </div>
            <div className='editor-suggested-templates-list'>
                {generatedLogos.length > 0 && generatedLogos.map((template) => {
                    if (template.id !== currentTemplateId) {
                        return (
                            <div
                                key={template.id}
                                onClick={() => handleSelectTemplate(template)}
                                style={{
                                    width: '100%',
                                    minHeight: '180px',
                                    backgroundColor: `${template.background_color}`,
                                    flexDirection: `${template.layout === 'top' ? 'column' : template.layout === 'bottom' ? 'column-reverse' : template.layout === 'left' ? 'row' : 'row-reverse'}`
                                }}
                                className='preview-image-block'
                            >
                                {template.icon && (
                                    <div
                                        className={`generated-logo-icon`}
                                    >
                                        <img
                                            draggable={false}
                                            style={{
                                                filter: `${imageColorToFilter(template.image_color)}`,
                                                width: `50px`,
                                            }}
                                            src={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(template.icon)}`}
                                            alt=""
                                        />
                                    </div>
                                )}
                                <div className='generated-logo-text'>
                                    <span
                                        style={{
                                            color: `${template.company_name_color}`,
                                            fontFamily: `${template.company_name_font_family}`,
                                            fontSize: `16px`,
                                            letterSpacing: `${template.company_name_spacing}px`,
                                            textTransform: `${template.company_name_transform}`,
                                            textAlign: `${template.layout === 'top' ? 'center' : template.layout === 'bottom' ? 'center' : template.layout === 'left' ? 'start' : 'end'}`,
                                            wordBreak: 'break-all',
                                        }}
                                        className={`generated-logo-company-name`}
                                    >
                                        {defaultCompanyName}
                                    </span>
                                    <span
                                        style={{
                                            color: `${template.slogan_color}`,
                                            fontFamily: `${template.slogan_font_family}`,
                                            fontSize: `12px`,
                                            letterSpacing: `${template.slogan_spacing}px`,
                                            textTransform: `${template.slogan_transform}`,
                                            textAlign: `${template.layout === 'top' ? 'center' : template.layout === 'bottom' ? 'center' : template.layout === 'left' ? 'start' : 'end'}`,
                                            wordBreak: 'break-all',
                                        }}
                                        className={`generated-logo-slogan`}
                                    >
                                        {defaultSlogan ? defaultSlogan : 'Slogan'}
                                    </span>
                                </div>
                            </div>
                        )
                    } else {
                        return null
                    }
                })}
            </div>
        </div>
    )
}

export default EditorNavbarSuggested