import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'

import { useAddPaymentMethodMutation } from '../../api/api'

const AddCreditCard = ({ setShowAddPayment }) => {
    const [error, setError] = useState(null)

    const elements = useElements()
    const stripe = useStripe()

    const { isDark } = useTheme()

    const [addCard] = useAddPaymentMethodMutation()

    const inputStyle = {
        color: "#A0A0A0",
        fontSize: "18px",
        fontWeight: "500",
        backgroundColor: isDark ? "transparent" : '#E5E5E5',
    }

    const handleCancelCreditCard = () => {
        setShowAddPayment(false)
    }

    const handleAddCreditCard = async () => {
        if (!stripe || !elements) {
            return;
        }

        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement),
        });

        if (payload.error) {
            setError(payload.error.message)
        } else {
            addCard({ payment_method_id: payload.paymentMethod.id }).then((res) => {
                if ('error' in res) {

                } else {
                    setShowAddPayment(false)
                }
            })
        }
    }

    return (
        <div className='add-payment-form-element'>
            <div className='add-payment-block'>
                <span className='add-payment-input-label'>Card Number</span>
                <div className='add-payment-input-wrapper'>
                    <CardNumberElement
                        options={{
                            style: {
                                base: inputStyle,
                            },
                        }}
                    />
                </div>
            </div>
            <div className='add-payment-cvc-expiry-block'>
                <div className='add-payment-block'>
                    <span className='add-payment-input-label'>Expiry</span>
                    <div className='add-payment-input-wrapper'>
                        <CardExpiryElement
                            options={{
                                style: {
                                    base: inputStyle,
                                },
                            }}
                        />
                    </div>
                </div>
                <div className='add-payment-block'>
                    <span className='add-payment-input-label'>CVC</span>
                    <div className='add-payment-input-wrapper'>
                        <CardCvcElement
                            options={{
                                style: {
                                    base: inputStyle,
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
            <button onClick={handleAddCreditCard} className='add-payment-save-btn'>Save</button>
            <button onClick={handleCancelCreditCard} className='add-payment-cancel-btn'>Cancel</button>
            {error && (
                <span className='add-credit-card-error'>{error}</span>
            )}
        </div>
    )
}

export default AddCreditCard