import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { useSelector } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';

import { BillingPlansBlock } from '../../styles/styles'
import { PLAN_TO_PRICE, PRICE_TO_PLAN } from '../../utils/constants'
import { useGetInvoicesQuery, useGetPaymentMethodsQuery, useSetDefaultPaymentMethodMutation } from '../../api/api'

import DeletePaymentMethodModal from '../../modals/DeletePaymentMethodModal';
import CancelSubscriptionModal from '../../modals/CancelSubscriptionModal';
import SupportWindow from '../SupportWindow/SupportWindow';
import AddCreditCard from '../AddCreditCard/AddCreditCard'

import AccountEditIconBlack from '../../assets/icons/account-edit-icon-black.svg'
import BillingDownload from '../../assets/icons/billing-download-black.svg'
import AccountEditIconWhite from '../../assets/icons/account-edit-icon.svg'
import BillingsCheckbox from '../../assets/icons/billings-checkbox.svg'
import PremiumPlanIcon from '../../assets/icons/premium-plan-icon.svg'
import AddPaymentPlus from '../../assets/icons/add-payment-plus.svg'
import BasicPlanIcon from '../../assets/icons/basic-plan-icon.svg'
import BillingEye from '../../assets/icons/billing-eye-black.svg'
import VisaIcon from '../../assets/icons/visa-icon.svg'
import HelpIcon from '../../assets/icons/help-icon.svg'

const stripePromise = loadStripe("pk_test_51N64VIGtupj8Z7jUf0GWLkkxBnaTDtgHsSKAictepHloA69omH7eGMf36ebT03HrUpBqcvLV8QWyfhkFi5krDTl200dn45npZY");

const BillingPlans = () => {
    const [userPlan, setUserPlan] = useState('')
    const [showAddPayment, setShowAddPayment] = useState(false)
    const [editCardElement, setEditCardElement] = useState('')
    const [showDeletePaymentMethodModal, setShowDeletePaymentMethodModal] = useState(false)
    const [showCancelSubscription, setShowCancelSubscription] = useState(false)
    const [showSupport, setShowSupport] = useState(false)
    const profile = useSelector(state => state.app.profile)

    const navigate = useNavigate()

    const { isDark } = useTheme()

    const { data } = useGetInvoicesQuery(undefined, { skip: !profile })
    const { data: paymentMethods } = useGetPaymentMethodsQuery(undefined, { skip: !profile })
    const [setDefaultPaymentMethod] = useSetDefaultPaymentMethodMutation()

    const handleInvoiceOpen = (url) => {
        window.open(url, '_blank')
    }

    const handleDownloadInvoice = (url) => {
        window.open(url, '_blank')
    }

    const handleEditPaymentMethod = (card) => {
        setEditCardElement(card)
    }

    const handleSetDefaultPaymentMethod = (id) => {
        setDefaultPaymentMethod(id).then((res) => {
            if ('error' in res) {

            } else {
                setEditCardElement('')
            }
        })
    }

    const handleDeletePaymentMethod = (id) => {
        setShowDeletePaymentMethodModal(true)
    }

    const handleChangePlans = () => {
        navigate('/plans')
    }

    useEffect(() => {
        if (profile && profile.plan) {
            if (profile.plan.includes('Basic')) {
                setUserPlan('Basic')
            } else if (profile.plan.includes('Premium')) {
                setUserPlan('Premium')
            } else {
                setUserPlan('Free')
            }
        }
    }, [profile])

    return (
        <BillingPlansBlock>
            <div className='recent-header-block'>
                <h5 className='recent-header-title'>Billing & Plans</h5>
                <div className='recent-header-filters'>
                    <span onClick={() => setShowSupport(!showSupport)} className='recent-support-text'>
                        <img src={HelpIcon} alt="" />
                        Support
                    </span>
                </div>
            </div>
            {userPlan !== 'Free' && (
                <div className='billings-info-block'>
                    <h5 className='billings-info-block-title'>Your Plan</h5>
                    <div className='billings-info-current-plan-block'>
                        <div className='billings-info-current-plan'>
                            {userPlan === 'Basic' ? (
                                <img height={18} width={23} src={BasicPlanIcon} alt="" />
                            ) : userPlan === 'Premium' ? (
                                <img height={18} width={43} src={PremiumPlanIcon} alt="" />
                            ) : null}
                            <h5 className='current-plan-name'>{userPlan}</h5>
                            <span className='current-plan-payment-type'>{userPlan === 'Premium' ? 'Billed Annually' : 'One Time Payment'}</span>
                            {userPlan === 'Premium' && (
                                <div className='current-plan-info-block'>
                                    <div className='current-plan-info-side-block'>
                                        Next payment due on
                                        <br />
                                        {new Date(profile?.current_period_end).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}
                                    </div>
                                    <div className='current-plan-info-side-block'>
                                        ${PLAN_TO_PRICE[userPlan]}/year
                                        <span className='current-plan-exclude-tax'>
                                            (excl. tax)
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <span className='billings-vertical-line'></span>
                        <div className='billings-current-plan-options'>
                            <p className='billing-current-plan-options-title'>
                                What’s included:
                            </p>
                            {userPlan === 'Basic' ? (
                                <div className='billing-current-plan-options-block'>
                                    <p className='billing-current-plan-option-item'>
                                        <img src={BillingsCheckbox} alt="" />
                                        JPG File Type
                                    </p>
                                </div>
                            ) : (
                                <div className='billing-current-plan-options-block'>
                                    <p className='billing-current-plan-option-item'>
                                        <img src={BillingsCheckbox} alt="" />
                                        JPG File Type
                                    </p>
                                    <p className='billing-current-plan-option-item'>
                                        <img src={BillingsCheckbox} alt="" />
                                        PNG File Type
                                    </p>
                                    <p className='billing-current-plan-option-item'>
                                        <img src={BillingsCheckbox} alt="" />
                                        SVG File Type
                                    </p>
                                    <p className='billing-current-plan-option-item'>
                                        <img src={BillingsCheckbox} alt="" />
                                        Full Ownership
                                    </p>
                                    <p className='billing-current-plan-option-item'>
                                        <img src={BillingsCheckbox} alt="" />
                                        Unlimited Technical Support
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <div className='billings-info-block'>
                <h5 className='billings-info-block-title'>Manage</h5>
                <div className='billings-manage-content-block'>
                    {userPlan === 'Free' && (
                        <div className='manage-block-wrapper'>
                            <p className='manage-block-title'>Change your plan</p>
                            <span className='manage-block-description'>Not enough in your plan? Annual payment too high? The switch is quick and easy.</span>
                            <button onClick={handleChangePlans} className='manage-block-button'>Change plans</button>
                        </div>
                    )}
                    {userPlan !== 'Free' && (
                        <div className='manage-block-wrapper'>
                            <p className='manage-block-title'>End your service</p>
                            <span className='manage-block-description'>Sometimes you just need to call it quits. We get it.</span>
                            <button onClick={() => setShowCancelSubscription(true)} className='manage-block-button'>End service</button>
                        </div>
                    )}
                </div>
            </div>
            <div className='billings-info-block'>
                <h5 className='billings-info-block-title'>Payment</h5>
                <div className={`account-payment-methods-list ${showAddPayment || editCardElement ? 'expanded-payment-items-list' : ''}`}>
                    {paymentMethods && paymentMethods.length > 0 && paymentMethods.map((item, index) => (
                        <div key={index} className='account-info-existing-payment-method'>
                            {item.id === editCardElement.id ? (
                                <div className='edit-payment-method-block'>
                                    <div className='add-payment-form-element'>
                                        <div className='add-payment-block'>
                                            <span className='add-payment-input-label'>Card Number</span>
                                            <div className='add-payment-input-wrapper'>
                                                <input readOnly className='edit-payment-element-input' placeholder={`**** **** **** ${editCardElement.card.last4}`} type="text" />
                                            </div>
                                        </div>
                                        <div className='add-payment-cvc-expiry-block'>
                                            <div className='add-payment-block'>
                                                <span className='add-payment-input-label'>Expiry</span>
                                                <div className='add-payment-input-wrapper'>
                                                    <input readOnly className='edit-payment-element-input' placeholder={`${editCardElement.card.exp_month}/${editCardElement.card.exp_year}`} type="text" />
                                                </div>
                                            </div>
                                            <div className='add-payment-block'>
                                                <span className='add-payment-input-label'>CVC</span>
                                                <div className='add-payment-input-wrapper'>
                                                    <input readOnly className='edit-payment-element-input' placeholder='***' type="text" />
                                                </div>
                                            </div>
                                        </div>
                                        {editCardElement.id !== profile.default_payment_method && (
                                            <button onClick={() => handleSetDefaultPaymentMethod(editCardElement.id)} className='add-payment-save-btn'>Set as Default</button>
                                        )}
                                        <button onClick={() => setEditCardElement('')} className='add-payment-cancel-btn'>Cancel</button>
                                        <button onClick={() => handleDeletePaymentMethod(editCardElement.id)} className='edit-payment-delete-payment-method'>Delete Payment Method</button>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {item.card.brand === 'visa' ? (
                                        <img src={VisaIcon} alt="" />
                                    ) : null}
                                    <div className='existing-card-info-block'>
                                        <span className='existing-card-info-ending'>
                                            Card ending in {item.card.last4}
                                        </span>
                                        <span className='exisiting-card-info-expires'>
                                            Expires {item.card.exp_month}/{item.card.exp_year}
                                        </span>
                                    </div>
                                    <div onClick={() => handleEditPaymentMethod(item)} className='existing-card-edit'>
                                        {
                                            isDark ? (
                                                <img src={AccountEditIconWhite} alt="" />
                                            ) : (
                                                <img src={AccountEditIconBlack} alt="" />
                                            )
                                        }
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                    {showAddPayment && (
                        <div className='add-payment-method-wrapper'>
                            <div className='add-payment-method-block'>
                                <Elements stripe={stripePromise}>
                                    <AddCreditCard setShowAddPayment={setShowAddPayment} />
                                </Elements>
                            </div>
                        </div>
                    )}
                    <div onClick={() => setShowAddPayment(true)} className='account-info-add-payment-method'>
                        Add new payment method
                        <img src={AddPaymentPlus} alt="" />
                    </div>
                </div>
            </div>
            <div className='billings-info-block'>
                <h5 className='billings-info-block-title'>Billing History</h5>
                <div className='billings-history-wrapper'>
                    {data && data.length > 0 ? (
                        <table>
                            <thead>
                                <tr className='table-head-row'>
                                    <th className='table-head-cell'>Date</th>
                                    <th className='table-head-cell'>Invoice No.</th>
                                    <th className='table-head-cell'>Name</th>
                                    <th className='table-head-cell'>Amount</th>
                                    <th style={{ width: '120px' }} className='table-head-cell'>Downlaod</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item) => (
                                    <tr key={item.id} className='table-body-row'>
                                        <td className='table-body-cell'>
                                            {new Date(item.created).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}
                                        </td>
                                        <td className='table-body-cell'>{item.id}</td>
                                        <td className='table-body-cell'>{PRICE_TO_PLAN[item.amount_paid]}</td>
                                        <td className='table-body-cell'>US${item.amount_paid}</td>
                                        <td className='table-body-cell'>
                                            <div className='billing-table-actions'>
                                                <div onClick={() => handleInvoiceOpen(item.hosted_invoice_url)} className='billing-table-action-block'>
                                                    <img src={BillingEye} alt="" />
                                                </div>
                                                <div onClick={() => handleDownloadInvoice(item.invoice_pdf)} className='billing-table-action-block'>
                                                    <img src={BillingDownload} alt="" />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className='billing-history-empty'>
                            Billing history is empty
                        </div>
                    )}
                </div>
            </div>
            <DeletePaymentMethodModal show={showDeletePaymentMethodModal} handleClose={() => setShowDeletePaymentMethodModal(false)} id={editCardElement?.id} setEditCardElement={setEditCardElement} />
            {showCancelSubscription && (
                <CancelSubscriptionModal show={showCancelSubscription} handleClose={() => setShowCancelSubscription(false)} />
            )}
            {showSupport && (
                <SupportWindow position='bottom' handleClose={() => setShowSupport(false)} />
            )}
        </BillingPlansBlock>
    )
}

export default BillingPlans