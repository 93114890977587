import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';

import { LogoEditorContentWrapper, LogoEditorWrapperBlock } from '../styles/styles';
import { useCreateLogosQuery, useGetIconsQuery, useGetLogoByIdQuery, useUpdateLogoMutation } from '../api/api';
import { useEditorContext } from '../context/EditorContext';

import LogoEditorNavigation from '../components/LogoEditorNavigation/LogoEditorNavigation';
import LogoEditorHeader from '../components/LogoEditorHeader/LogoEditorHeader';
import LogoPreview from '../components/LogoPreview/LogoPreview';
import EditorToolbar from '../components/Editor/EditorToolbar';
import EditorNavbar from '../components/Editor/EditorNavbar';

function LogoEditor() {
    const [defaultCompanyName, setDefaultCompanyName] = useState('')
    const [defaultSlogan, setDefaultSlogan] = useState('')
    const [iconsKeywords, setIconsKeywords] = useState([])
    const [templatesLoaded, setTemplatesLoaded] = useState(false)
    const user = useSelector((state) => state.app.user)

    const { id } = useParams();

    const navigate = useNavigate()

    const {
        imageScale, setImageScale, previewMode,
        setPreviewMode, setLogo, setSlogan, setCompanyName,
        setBackgroundColor, setImageWidth, setEditableElement,
        setCompanyNameColor, contentPreviewBlockRef,
        setCompanyNameFontFamily, setSloganFontFamily, setCompanyNameSize,
        setSloganSize, setCompanyNameSpacing, setSloganSpacing,
        setCompanyNameTransform, setSloganTransform, setImageColor,
        setSloganColor, setCurrentTemplateId, setImageHorizontalPosition,
        setImageVerticalPosition, setCompanyNameHorizontalPosition,
        setCompanyNameVerticalPosition, setSloganHorizontalPosition,
        setSloganVerticalPosition, setCurrentTemplateData, setLayout,
        setVerticalFlip, setHorizontalFlip, setFetchedIcons,
        setGeneratedLogos, setCompanyNameScale, setSloganScale, setLogoScale,
    } = useEditorContext()

    const [updateLogo] = useUpdateLogoMutation()
    const { data: template, isError } = useGetLogoByIdQuery(id, { skip: !id || !user })
    const { data: icons } = useGetIconsQuery({ keyword: iconsKeywords.join(','), limit: 6 }, { skip: !iconsKeywords.length || !user })
    const { data: logos } = useCreateLogosQuery({
        limit: 6,
        query: iconsKeywords.length > 0 ? iconsKeywords.join(',') : 'arrow',
        company_name: defaultCompanyName,
        slogan: defaultSlogan,
    },
        { skip: !defaultCompanyName || !iconsKeywords.length || templatesLoaded || !user }
    )

    useEffect(() => {
        if (logos && logos.length > 0) {
            setTemplatesLoaded(true)
            setGeneratedLogos((prev) => {
                return [...prev, ...logos]
            })
        }
    }, [logos, setGeneratedLogos])

    useEffect(() => {
        if (previewMode) {
            setEditableElement('')
        }
    }, [previewMode, setEditableElement])

    useEffect(() => {
        if (icons && icons.length > 0) {
            setFetchedIcons(icons)
        }
    }, [icons, setFetchedIcons])

    useEffect(() => {
        if (isError) {
            navigate('/logo-maker')
        }
    }, [isError, navigate])

    useEffect(() => {
        const makerState = localStorage.getItem('maker_state')

        if (makerState) {
            const parsedIconKeywords = JSON.parse(makerState).iconsType

            if (parsedIconKeywords && parsedIconKeywords.length > 0) {
                setIconsKeywords(parsedIconKeywords)
            } else {
                const parsedSelectedIcons = JSON.parse(makerState).selectedIcons

                if (parsedSelectedIcons && parsedSelectedIcons.length > 0) {
                    const keywords = []

                    parsedSelectedIcons.forEach((icon) => {
                        keywords.push(icon.split(/(?=[A-Z])/)[2])
                    })

                    setIconsKeywords(keywords)
                }
            }
        }
    }, [])

    useEffect(() => {
        if (template) {
            setLogo(template.icon)
            setBackgroundColor(template.background_color)
            setImageWidth(template.image_size)
            setCompanyNameColor(template.company_name_color)
            setSloganColor(template.slogan_color)
            setCompanyNameFontFamily(template.company_name_font_family)
            setSloganFontFamily(template.slogan_font_family)
            setCompanyNameSize(template.company_name_size)
            setSloganSize(template.slogan_size)
            setCompanyNameSpacing(template.company_name_spacing)
            setSloganSpacing(template.slogan_spacing)
            setCompanyNameTransform(template.company_name_transform)
            setSloganTransform(template.slogan_transform)
            setCompanyName(template.company_name)
            setDefaultCompanyName(template.company_name)
            setSlogan(template.slogan)
            setDefaultSlogan(template.slogan)
            setImageColor(template.image_color)
            setCurrentTemplateId(template.id)
            setImageHorizontalPosition(template.logo_horizontal_position)
            setImageVerticalPosition(template.logo_vertical_position)
            setCompanyNameHorizontalPosition(template.company_name_horizontal_position)
            setCompanyNameVerticalPosition(template.company_name_vertical_position)
            setSloganHorizontalPosition(template.slogan_horizontal_position)
            setSloganVerticalPosition(template.slogan_vertical_position)
            setLayout(template.layout)
            setVerticalFlip(template.vertical_flip)
            setHorizontalFlip(template.horizontal_flip)
            setLogoScale(template.logo_scale)
            setCompanyNameScale(template.company_name_scale)
            setSloganScale(template.slogan_scale)
            setCurrentTemplateData(template)

            if (!template.saved) {
                updateLogo({
                    id: template.id,
                    saved: true,
                })
            }
        }
    }, [template])

    return (
        <LogoEditorWrapperBlock preview={previewMode ? "true" : ""}>
            <LogoEditorHeader />
            <LogoEditorContentWrapper>
                {!previewMode && (
                    <EditorToolbar />
                )}
                <div ref={contentPreviewBlockRef} className='w-full h-full flex items-center justify-center'>
                    <LogoPreview />
                </div>
            </LogoEditorContentWrapper>
            {!previewMode && (
                <EditorNavbar
                    defaultCompanyName={defaultCompanyName}
                    defaultSlogan={defaultSlogan}
                />
            )}
            <LogoEditorNavigation
                imageScale={imageScale}
                setImageScale={setImageScale}
                previewMode={previewMode}
                setPreviewMode={setPreviewMode}
            />
        </LogoEditorWrapperBlock >
    );

}

export default LogoEditor;