import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { handleUpdatePassword, handleValidatePassword } from '../firebase/crud/updateUserEmail'
import { ChangePasswordModalBlock } from '../styles/styles'

import ModalCloseButton from '../components/ModalCloseButton/ModalCloseButton'

const ChangePasswordModal = ({ show, handleClose, newPassword, setEditElement }) => {
    const [step, setStep] = useState(1)
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)

    const navigate = useNavigate()

    const handleBackToDashboard = () => {
        navigate('/dashboard')
    }

    const handleSavePassword = async () => {
        const validated = await handleValidatePassword(password)

        if (validated) {
            await handleUpdatePassword(newPassword)

            setEditElement('')
            setStep(2)
        } else {
            setError(true)
        }
    }

    return (
        <ChangePasswordModalBlock centered show={show} onHide={handleClose}>
            <Modal.Body>
                {step === 1 ? (
                    <>
                        <h5 className='send-invoice-title'>Password Change</h5>
                        <span className='send-invoice-description'>
                            Please enter your old password to update.
                        </span>
                        <input placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} className='reenter-password-input' type="password" />
                        {error && (
                            <span className='cancel-subscription-error'>Password incorrect. Please try again.</span>
                        )}
                        <div className='send-invoice-actions'>
                            <button onClick={handleClose} className='send-invoice-cancel'>Cancel</button>
                            <button onClick={handleSavePassword} disabled={!password} className='send-invoice-delete'>Confirm</button>
                        </div>
                    </>
                ) : (
                    <>
                        <h5 className='send-invoice-title'>Password Successfully Changed</h5>
                        <span className='send-invoice-description'>
                            Your password has been successfully updated.
                        </span>
                        <div className='send-invoice-actions'>
                            <button onClick={handleBackToDashboard} className='send-invoice-cancel'>Back to Dashboard</button>
                            <button onClick={handleClose} disabled={!password} className='send-invoice-delete'>Back to Settings</button>
                        </div>
                    </>
                )}
                <ModalCloseButton handleClose={handleClose} />
            </Modal.Body>
        </ChangePasswordModalBlock>
    )
}

export default ChangePasswordModal