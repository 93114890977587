import React from 'react'

import { PageWrapper } from '../styles/styles'

import PlansBlock from '../components/PlansBlock/PlansBlock'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import FAQ from '../components/FAQ/FAQ'

const Plans = () => {
    return (
        <PageWrapper>
            <Header withBorder={true} />
            <PlansBlock />
            <FAQ />
            <Footer />
        </PageWrapper>
    )
}

export default Plans