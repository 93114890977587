import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { useUpdateSubscriptionMutation } from '../../api/api'
import { PlanCardBlock } from '../../styles/styles'

import AuthModal from '../../modals/AuthModal'

import PlanOptionYellowCheck from '../../assets/icons/plan-option-yellow-check.svg'
import PlanOptionBlackCheck from '../../assets/icons/plan-option-black-check.svg'
import PlanOptionBlackUncheck from '../../assets/icons/plan-option-black-uncheck.svg'
import AuthModalImage from '../../assets/auth-modal-image.svg'

const PlanCard = ({ plan, fromDownload }) => {
    const [showAuthModal, setShowAuthModal] = useState(false)
    const [userPlan, setUserPlan] = useState('Free')
    const user = useSelector((state) => state.app.user)
    const profile = useSelector((state) => state.app.profile)

    const navigate = useNavigate()

    const [updateSubscription] = useUpdateSubscriptionMutation()

    const handleSelectPlan = () => {
        if (user) {
            if (userPlan !== 'Free') {
                updateSubscription({ price_id: plan.plan_price_id }).then((res) => {
                    if ('error' in res) {
                        navigate(`/checkout/${plan.plan_price_id}/${plan.name}${fromDownload ? `?download=true` : ''}`)
                    }
                })
            } else {
                navigate(`/checkout/${plan.plan_price_id}/${plan.name}${fromDownload ? `?download=true` : ''}`)
            }
        } else {
            setShowAuthModal(true)
        }
    }

    useEffect(() => {
        const newImage = new Image();
        newImage.src = AuthModalImage;
        window[AuthModalImage] = newImage;
    }, [])

    useEffect(() => {
        if (profile && profile.plan) {
            if (profile.plan.includes('Basic')) {
                setUserPlan('Basic')
            } else if (profile.plan.includes('Premium')) {
                setUserPlan('Premium')
            } else {
                setUserPlan('Free')
            }
        }
    }, [profile])

    return (
        <PlanCardBlock popular={plan.most_popular ? 'true' : null}>
            <h3 className='plan-card-name'>{plan.name} {plan.type}</h3>
            <h5 className='plan-card-price'>
                ${plan.price_for_card}
                <span className='plan-card-price-per'>{plan.price_per}</span>
            </h5>
            <p className='plan-card-payment-type'>{plan.payment_type}</p>
            <div className='plan-card-underline'></div>
            <div className='plan-card-options-list'>
                {plan.options.map((option, index) => (
                    <div key={index} className='plan-card-option'>
                        {option.available && !plan.most_popular ? (
                            <img width={24} src={PlanOptionYellowCheck} alt="" />
                        ) : option.available && plan.most_popular ? (
                            <img width={24} src={PlanOptionBlackCheck} alt="" />
                        ) : !option.available && !plan.most_popular && (
                            <img width={24} src={PlanOptionBlackUncheck} alt="" />
                        )}
                        {option.text}
                    </div>
                ))}
            </div>
            <div className='plan-card-description'>
                {plan.description}
            </div>
            <button disabled={userPlan === plan.name ? true : false} onClick={handleSelectPlan} className='plan-card-select-button'>{userPlan === plan.name ? 'Current Package' : 'Select Package'}</button>
            {plan.most_popular && (
                <div className='plan-card-most-popular-block'>
                    Most Popular
                </div>
            )}
            {showAuthModal && (
                <AuthModal
                    show={showAuthModal}
                    handleClose={() => setShowAuthModal(false)}
                    signUp={true}
                    image={AuthModalImage}
                />
            )}
        </PlanCardBlock>
    )
}

export default PlanCard