import React from 'react'
import { Link } from 'react-router-dom'

import StarBlack from '../../assets/mainPage/StarBlack.svg';
import Arrow from '../../assets/mainPage/Arrow.svg';

const GetStartedBottom = ({ companyName, handleCompanyName }) => {
    return (
        <div className="w-[100%] bg-yellow">
            <div className="max-w-[1200px] p-[120px] m-auto lg:px-0 lg:py-[40px] flex flex-col gap-[48px] lg:gap-[40px]">
                <div className="flex flex-col items-center justify-center gap-[16px] lg:max-w-[80%] lg:m-auto">
                    <div className="text-[64px] relative m-auto text-center font-normal font-[Poppins] leading-[94.2%] tracking-[-2px] text-black w-[960px] lg:text-[36px] lg:text-center lg:w-[auto] lg:max-w-[80%] lg:m-auto">
                        Get started for <span> </span>
                        <span className="text-[86px] leading-[94.2%] font-[Neuton] tracking-[-2px] text-black lg:text-[48px] lg:text-center font-extralight relative">
                            free
                            <img className="absolute w-[36px] top-[20px] left-[95px] scale-[0.9] z-[0] lg:left-[48px] lg:top-[5px] lg:scale-[0.5]" src={StarBlack} alt="" />
                            <img className="absolute top-[40px] left-[120px] w-[100%] z-[0] lg:left-[61px] lg:top-[27px] lg:rotate-[43deg]" src={Arrow} alt="" />
                        </span>
                    </div>
                </div>

                <div className='flex justify-center items-center w-[100%] gap-[8px] lg:flex-col lg:px-4 lg:mx-auto'>
                    <input value={companyName} onChange={handleCompanyName} className='w-[inherit] bg-[#fff] rounded-[8px] px-4 py-[15px]  leading-[22px] text-[18px]' type="text" placeholder="Enter your company name" />
                    <Link
                        to={`/logo-maker?company=${companyName}`}
                        className='bg-pink px-4 py-[15px] text-[18px] font-medium text-black leading-[22px] rounded-[8px] lg:w-[100%] lg:text-center w-[160px]'
                        style={{ 'textWrap': 'nowrap' }}
                    >
                        Get started
                    </Link>
                </div>

            </div>
        </div>
    )
}

export default GetStartedBottom