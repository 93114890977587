import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { DashboardContentBlock } from '../../styles/styles'

import PurchaseHistory from './PurchaseHistory'
import DashboardMain from './DashboardMain'
import BillingPlans from './BillingPlans'
import SavedLogos from './SavedLogos'
import Account from './Account'
import Recent from './Recent'

const DashboardContent = () => {
    return (
        <DashboardContentBlock>
            <Routes>
                <Route path='/' element={<DashboardMain />} />
                <Route path='/saved-logos' element={<SavedLogos />} />
                <Route path='/recent' element={<Recent />} />
                <Route path='/purchase-history' element={<PurchaseHistory />} />
                <Route path='/account' element={<Account />} />
                <Route path='/billing-plans' element={<BillingPlans />} />
            </Routes>
        </DashboardContentBlock>
    )
}

export default DashboardContent