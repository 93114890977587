import React from 'react'

import { LogoMakerStepFourBlock } from '../../styles/styles'

const LogoMakerStepFour = ({ slogan, companyName, setSlogan, setCompanyName }) => {
    return (
        <LogoMakerStepFourBlock>
            <div className="flex justify-between items-center">
                <div>
                    <h4 className="logo-maker-step-two-heading">Enter your company name</h4>
                    <p className="logo-maker-step-two-description">You can always change these later.</p>
                </div>
            </div>

            <div className="logo-maker-step-four-block">
                <input
                    placeholder='Company name'
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    type="text"
                    className="logo-maker-step-one-input"
                />
                <input
                    placeholder='Slogan (optional)'
                    value={slogan}
                    onChange={(e) => setSlogan(e.target.value)}
                    type="text"
                    className="logo-maker-step-one-input"
                />
            </div>
        </LogoMakerStepFourBlock>
    )
}

export default LogoMakerStepFour