import React, { useState } from 'react'
import RangeSlider from 'react-range-slider-input';
import { useTheme } from 'styled-components'

import { useEditorContext } from '../../context/EditorContext';
import { LogoEditorNavigationBlock } from '../../styles/styles'
import { useViewport } from '../../hooks/useViewport';

import SupportWindow from '../SupportWindow/SupportWindow';

import EditNavigationQuestionWhite from '../../assets/icons/edit-navigation-question-white.svg'
import EditNavigationQuestionBlack from '../../assets/icons/edit-navigation-question-black.svg'
import EditNavigationExpandWhite from '../../assets/icons/edit-navigation-expand-white.svg'
import EditNavigationExpandBlack from '../../assets/icons/edit-navigation-expand-black.svg'
import EditExitPreview from '../../assets/icons/editor-exit-preview.svg'
import EditPreview from '../../assets/icons/edit-preview.svg'
import UndoWhite from '../../assets/icons/undo-white.svg'
import RedoWhite from '../../assets/icons/redo-white.svg'
import UndoBlack from '../../assets/icons/undo-black.svg'
import RedoBlack from '../../assets/icons/redo-black.svg'

const LogoEditorNavigation = ({ imageScale, setImageScale, previewMode, setPreviewMode }) => {
    const [showHelp, setShowHelp] = useState(false)

    const { isDark } = useTheme()
    const { isMobile } = useViewport()

    const { handleUndoChange, handleRedoChange, currentHistoryStep, historyChanges } = useEditorContext()

    const handleInputChange = (values) => {
        setImageScale(values[1])
    }

    const handleChangePreviewMode = () => {
        setPreviewMode(!previewMode)
    }

    return (
        <LogoEditorNavigationBlock>
            <div className='logo-editor-navigation-left'>
                {previewMode ? (
                    <button onClick={handleChangePreviewMode} className='navigation-preview-button'>
                        <img src={EditExitPreview} alt="" />
                        Exit Preview
                    </button>
                ) : (
                    <button onClick={handleChangePreviewMode} className='navigation-preview-button'>
                        <img src={EditPreview} alt="" />
                        Preview
                    </button>
                )}
                <button disabled={currentHistoryStep === 0 ? true : false} onClick={handleUndoChange} className='navigation-undo-button'>
                    {isDark ? (<img src={UndoWhite} alt="" />) : (<img src={UndoBlack} alt="" />)}
                    {isMobile ? '' : 'Undo'}
                </button>
                <button disabled={currentHistoryStep === historyChanges.length ? true : false} onClick={handleRedoChange} className='navigation-undo-button'>
                    {isMobile ? '' : 'Redo'}
                    {isDark ? (<img src={RedoWhite} alt="" />) : (<img src={RedoBlack} alt="" />)}
                </button>
            </div>
            <div className='logo-editor-navigation-rigth'>
                <RangeSlider
                    className="single-thumb navigation-logo-scale-input"
                    defaultValue={[25, 150]}
                    min={25}
                    max={200}
                    value={[25, imageScale]}
                    thumbsDisabled={[true, false]}
                    rangeSlideDisabled={true}
                    onInput={handleInputChange}
                />
                <span className='logo-scale-value'>{imageScale}%</span>
                {isDark ? (
                    <>
                        {!isMobile && (
                            <img onClick={() => setPreviewMode(!previewMode)} className='expand-icon-button' src={EditNavigationExpandWhite} alt="" />
                        )}
                        <div className='help-section-wrapper'>
                            <img onClick={() => setShowHelp(!showHelp)} className='expand-icon-button' src={EditNavigationQuestionWhite} alt="" />
                            {showHelp && (
                                <SupportWindow position='top' handleClose={() => setShowHelp(false)} />
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        {!isMobile && (
                            <img onClick={() => setPreviewMode(!previewMode)} className='expand-icon-button' src={EditNavigationExpandBlack} alt="" />
                        )}
                        <div className='help-section-wrapper'>
                            <img onClick={() => setShowHelp(!showHelp)} className='expand-icon-button' src={EditNavigationQuestionBlack} alt="" />
                            {showHelp && (
                                <SupportWindow position='top' handleClose={() => setShowHelp(false)} />
                            )}
                        </div>
                    </>
                )}
            </div>
        </LogoEditorNavigationBlock>
    )
}

export default LogoEditorNavigation