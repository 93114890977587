import React, { useEffect, useState } from 'react'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { PLANS } from '../../utils/constants'

import { useSendEmailOnNoDownloadMutation } from '../../api/plunkApi'
import { useUpdateSubscriptionMutation } from '../../api/api'
import { PlansBlockWrapper } from '../../styles/styles'
import { useViewport } from '../../hooks/useViewport'

import AuthModal from '../../modals/AuthModal'
import PlanCard from './PlanCard'

import PlanOptionBlackUncheck from '../../assets/icons/plan-option-black-uncheck.svg'
import PlanOptionYellowCheck from '../../assets/icons/plan-option-yellow-check.svg'
import PlansCircleOutline from '../../assets/icons/plans-circle-outline.svg'
import AuthModalImage from '../../assets/auth-modal-image.svg'

const PlansBlock = () => {
    const [userPlan, setUserPlan] = useState('Free')
    const [mobilePlanSelected, setMobilePlanSelected] = useState('Premium')
    const [selectedPlanOptions, setSelectedPlanOptions] = useState({})
    const [showAuthModal, setShowAuthModal] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [fromDownload, setFromDownload] = useState(false)
    const user = useSelector((state) => state.app.user)
    const profile = useSelector((state) => state.app.profile)

    const navigate = useNavigate()

    const { isMobile } = useViewport()

    const [updateSubscription] = useUpdateSubscriptionMutation()
    const [sendEmailOnNoDownload] = useSendEmailOnNoDownloadMutation()

    const handleChoosePlanMobile = () => {
        if (user) {
            if (userPlan !== 'Free') {
                updateSubscription({ price_id: selectedPlanOptions.plan_price_id }).then((res) => {
                    if ('error' in res) {
                        navigate(`/checkout/${selectedPlanOptions.plan_price_id}/${selectedPlanOptions.name}${fromDownload ? `?download=true` : ''}`)
                    }
                })
            } else {
                navigate(`/checkout/${selectedPlanOptions.plan_price_id}/${selectedPlanOptions.name}${fromDownload ? `?download=true` : ''}`)
            }
        } else {
            setShowAuthModal(true)
        }
    }

    useEffect(() => {
        const newImage = new Image();
        newImage.src = AuthModalImage;
        window[AuthModalImage] = newImage;

        window.scrollTo({ top: 0, behavior: 'instant' })
    }, [])

    useEffect(() => {
        const planName = searchParams.get('planName')
        const download = searchParams.get('download')

        if (planName) {
            setMobilePlanSelected(planName)
            setSearchParams({
                download: 'true'
            })
        }

        if (download) {
            setFromDownload(true)
        }
    }, [searchParams, setSearchParams])

    useEffect(() => {
        if (profile && profile.plan) {
            if (profile.plan.includes('Basic')) {
                setUserPlan('Basic')
            } else if (profile.plan.includes('Premium')) {
                setUserPlan('Premium')
            } else {
                setUserPlan('Free')
            }
        }
    }, [profile])

    useEffect(() => {
        const plan = PLANS.find((plan) => plan.name === mobilePlanSelected)

        if (plan) {
            setSelectedPlanOptions(plan)
        }
    }, [mobilePlanSelected])

    useEffect(() => {
        return () => {
            if (!window.location.pathname.includes('checkout') && user?.email && fromDownload) {
                sendEmailOnNoDownload({ email: user?.email, domain: window.location.host })
            }
        }
    }, [fromDownload, sendEmailOnNoDownload, setSearchParams, user])

    return (
        <PlansBlockWrapper className='p-[120px] m-auto lg:px-0 lg:py-[40px]'>
            <h1 className='plans-heading'>
                Choose a package that suits
                <span className='heading-different-text'>
                    you.
                    <img className='heading-circle-image' src={PlansCircleOutline} alt="" />
                </span>
            </h1>
            <p className='plans-description'>
                You will always own the rights to your logo, even after your subscription ends.
            </p>
            {isMobile ? (
                <div className='upgrade-plan-new-mobile-block'>
                    <div className='upgrade-plan-new-plans-list'>
                        {PLANS.map((plan, index) => {
                            if (plan.name === mobilePlanSelected) {
                                return (
                                    <div onClick={() => setMobilePlanSelected(plan.name)} key={index} className='upgrade-plan-new-mobile-item-active'>
                                        <div className='upgrade-plan-new-mobile-item-text-block'>
                                            <div className='upgrade-plan-new-mobile-item-price-block'>
                                                <span className='upgrade-plan-new-mobile-item-price'>${plan.price_for_card}{plan.price_per}</span>
                                                {plan.most_popular && (
                                                    <span className='upgrade-plan-new-mobile-item-best'>Most Popular</span>
                                                )}
                                            </div>
                                            <span className='upgrade-plan-new-mobile-item-name'>{plan.name} {plan.type}</span>
                                            <span className='upgrade-plan-mobile-payment-type'>{plan.payment_type}</span>
                                        </div>
                                        <BsFillCheckCircleFill size={20} fill='#FFCB03' />
                                    </div>
                                )
                            } else {
                                return (
                                    <div onClick={() => setMobilePlanSelected(plan.name)} key={index} className='upgrade-plan-new-mobile-item'>
                                        <div className='upgrade-plan-new-mobile-item-text-block'>
                                            <div className='upgrade-plan-new-mobile-item-price-block'>
                                                <span className='upgrade-plan-new-mobile-item-price'>${plan.price_for_card}{plan.price_per}</span>
                                                {plan.most_popular && (
                                                    <span className='upgrade-plan-new-mobile-item-best'>Most Popular</span>
                                                )}
                                            </div>
                                            <span className='upgrade-plan-new-mobile-item-name'>{plan.name} {plan.type}</span>
                                            <span className='upgrade-plan-mobile-payment-type'>{plan.payment_type}</span>
                                        </div>
                                        <div className='upgrade-plan-new-mobile-select-icon-inactive'></div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                    <ul style={{ padding: '16px 12px', margin: '0' }} className="upgrade-plan-options-list w-full">
                        {selectedPlanOptions.options.map((option, index) => {
                            return (
                                <li key={index} className="upgrade-plan-active-option">
                                    {
                                        option.available ? (
                                            <img width={18} src={PlanOptionYellowCheck} alt="" />
                                        ) : (
                                            <img width={18} src={PlanOptionBlackUncheck} alt="" />
                                        )
                                    }
                                    <span className="upgrade-plan-active-option-text">
                                        {option.text}
                                    </span>
                                </li>
                            )
                        })}
                    </ul>
                    <div className='select-package-mobile-button-block'>
                        <button disabled={userPlan === mobilePlanSelected ? true : false} onClick={handleChoosePlanMobile} className='upgrade-plan-new-mobile-choose-button'>
                            {userPlan === mobilePlanSelected ? 'Current Package' : 'Select Package'}
                        </button>
                    </div>
                    <span className='upgrade-plan-new-note'>
                        {selectedPlanOptions.description}
                    </span>
                </div>
            ) : (
                <div className='plans-list'>
                    {PLANS.map((plan) => (
                        <PlanCard key={plan.id} plan={plan} fromDownload={fromDownload} />
                    ))}
                </div>
            )}
            {showAuthModal && (
                <AuthModal
                    show={showAuthModal}
                    handleClose={() => setShowAuthModal(false)}
                    signUp={true}
                    image={AuthModalImage}
                />
            )}
        </PlansBlockWrapper>
    )
}

export default PlansBlock