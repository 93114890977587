import React, { useState } from 'react'
import { useTheme } from 'styled-components'

import { SupportWindowBlock, SupportWindowWrapper } from '../../styles/styles'
import { FAQ_QUESTIONS } from '../../utils/constants'

import EditNavigationQuestionWhite from '../../assets/icons/edit-navigation-question-white.svg'
import EditNavigationQuestionBlack from '../../assets/icons/edit-navigation-question-black.svg'
import SupportExpandBlack from '../../assets/icons/support-expand-dark.svg'
import SupportExpandWhite from '../../assets/icons/support-expand-white.svg'
import SupportCloseWhite from '../../assets/icons/support-close-white.svg'
import SupportCloseBlack from '../../assets/icons/support-close-black.svg'
import SupportSearch from '../../assets/icons/support-search.svg'

const SupportWindow = ({ position = 'top', handleClose }) => {
    const [inputValue, setInputValue] = useState('')
    const [filteredValue, setFilteredValue] = useState(FAQ_QUESTIONS)
    const [expandItem, setExpandItem] = useState(null)

    const { isDark } = useTheme()

    const handleSelectExpandId = (id) => {
        if (expandItem === id) {
            setExpandItem(null)
        } else {
            setExpandItem(id)
        }
    }

    const handleSearch = () => {
        if (inputValue.length > 0) {
            const filtered = FAQ_QUESTIONS.filter((question) => {
                return question.question.toLowerCase().includes(inputValue.toLowerCase())
            })
            setFilteredValue(filtered)
        } else {
            setFilteredValue(FAQ_QUESTIONS)
        }
    }

    return (
        <SupportWindowWrapper>
            <SupportWindowBlock position={position}>
                <div className='support-window-header'>
                    <div className='support-window-header-title'>
                        {isDark ? (
                            <img src={EditNavigationQuestionWhite} alt="" />
                        ) : (
                            <img src={EditNavigationQuestionBlack} alt="" />
                        )}
                        Support
                    </div>
                    {isDark ? (
                        <img className='cursor-pointer' onClick={handleClose} src={SupportCloseWhite} alt="" />
                    ) : (
                        <img className='cursor-pointer' onClick={handleClose} src={SupportCloseBlack} alt="" />
                    )}
                </div>
                <div className='support-window-search-block'>
                    <input className='support-search-input' value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder='Search for help' type="text" />
                    <img onClick={handleSearch} className='cursor-pointer' src={SupportSearch} alt="" />
                </div>
                <div className='support-window-underline'></div>
                <div className='support-block-questions-title-block'>
                    <span className='support-block-question-title'>Popular Questions</span>
                    <span className='support-block-question-description'>Getting Started with SwiftLogo</span>
                </div>
                <div className='support-window-questions-list'>
                    {filteredValue.map((question) => (
                        <div key={question.id} className='support-window-question-item'>
                            <div onClick={() => handleSelectExpandId(question.id)} className={`support-window-question-item-heading ${expandItem === question.id ? 'active' : ''}`}>
                                {question.question}
                                {isDark ? (
                                    <img src={SupportExpandWhite} alt="" />
                                ) : (
                                    <img src={SupportExpandBlack} alt="" />
                                )}
                            </div>
                            {expandItem === question.id && (
                                <span className='support-window-question-answers'>
                                    {question.answer}
                                </span>
                            )}
                        </div>
                    ))}
                </div>
            </SupportWindowBlock>
        </SupportWindowWrapper>
    )
}

export default SupportWindow