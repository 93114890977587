import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useDownloadDoneMutation, useGetSavedLogosQuery, useUnsaveLogoMutation } from '../../api/api'
import { useSendEmailOnDownloadMutation } from '../../api/plunkApi'
import { downloadListImages } from '../../utils/functions'
import { SavedLogosBlock } from '../../styles/styles'

import SupportWindow from '../SupportWindow/SupportWindow'
import SavedLogoItem from '../LogoBlockItem/SavedLogoItem'
import DeleteLogoModal from '../../modals/DeleteLogoModal'
import NoLogosBlock from '../NoLogosBlock/NoLogosBlock'

import DownloadWhite from '../../assets/icons/download-white.svg'
import DownloadBlack from '../../assets/icons/download-black.svg'
import HelpIcon from '../../assets/icons/help-icon.svg'

const SavedLogos = () => {
    const [showDelete, setShowDelete] = useState(false)
    const [deleteLogoId, setDeleteLogoId] = useState(null)
    const [showSupport, setShowSupport] = useState(false)
    const user = useSelector(state => state.app.user)
    const profile = useSelector(state => state.app.profile)

    const navigate = useNavigate()

    const { isDark } = useTheme()

    const { data } = useGetSavedLogosQuery(undefined, { skip: !user })
    const [unsaveLogo] = useUnsaveLogoMutation()
    const [sendEmailOnDownload] = useSendEmailOnDownloadMutation()
    const [downloadedLogo] = useDownloadDoneMutation()

    const handleUnsaveLogo = (id) => {
        unsaveLogo(id)
    }

    const handleOpenDeleteModal = (id) => {
        setDeleteLogoId(id)
        setShowDelete(true)
    }

    const handleEditLogo = (id) => {
        navigate(`/editor/${id}`)
    }

    const handleDownloadAll = () => {
        if (profile?.plan === 'Free') {
            navigate('/plans')
        } else {
            const listOfIds = data.map(logo => logo.id)

            downloadListImages(listOfIds, 'saved', profile?.plan !== 'Free' ? true : false)

            if (profile?.downloads_done === 0) {
                sendEmailOnDownload({ email: profile?.email, domain: window.location.host })
            }

            data.forEach((logo) => {
                downloadedLogo({ logo_id: logo.id })
            })
        }
    }

    return (
        <SavedLogosBlock>
            <div className='recent-header-block'>
                <h5 className='recent-header-title'>Saved Logos</h5>
                <div className='recent-header-filters'>
                    {data && data.length > 0 && profile?.plan.includes('Premium') && (
                        <span onClick={handleDownloadAll} className='recent-filter-text'>
                            {isDark ? (
                                <img src={DownloadWhite} alt="" />
                            ) : (
                                <img src={DownloadBlack} alt="" />
                            )}
                            Download All
                        </span>
                    )}
                    <span onClick={() => setShowSupport(!showSupport)} className='recent-support-text'>
                        <img src={HelpIcon} alt="" />
                        Support
                    </span>
                </div>
            </div>
            {data && data.length > 0 ? (
                <div className='dashboard-logos-list'>
                    {data.map((logo) => (
                        <SavedLogoItem key={logo.id} logo={logo} handleEditLogo={handleEditLogo} handleUnsaveLogo={handleUnsaveLogo} handleOpenDeleteModal={handleOpenDeleteModal} />
                    ))}
                </div>
            ) : (
                <NoLogosBlock heading={"You have no saved logos!"} />
            )}
            <DeleteLogoModal show={showDelete} handleClose={() => setShowDelete(false)} id={deleteLogoId} />
            {showSupport && (
                <SupportWindow position='bottom' handleClose={() => setShowSupport(false)} />
            )}
        </SavedLogosBlock>
    )
}

export default SavedLogos