import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import { ThemeProvider } from './context/ThemeContext';
import { EditorProvider } from './context/EditorContext';
import { AuthProvider } from './context/AuthContext';
import { store } from './redux/store';

import App from './App';

import 'react-range-slider-input/dist/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <AuthProvider>
          <ThemeProvider>
            <EditorProvider>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </EditorProvider>
          </ThemeProvider>
        </AuthProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);