import React, { useEffect, useState } from 'react'

import EditIcon from '../../assets/icons/dashboard-edit.svg'

const RecentLogoItem = ({ logo, handleEditLogo, downloadPrefix }) => {
    const [logoElement, setLogoElement] = useState('')

    useEffect(() => {
        if (logo) {
            let newStr = ''
            let splittedLogo = logo.icon.split('<path')

            if (splittedLogo.length > 0) {
                splittedLogo.forEach((string, index) => {
                    if (index === 0) {
                        newStr += string
                    } else {
                        newStr += `<path fill='${logo.image_color}' ${string}`
                    }
                })
            }

            setLogoElement(`${newStr}`.replaceAll('  ', ' '))
        }
    }, [logo])

    return (
        <div
            id={`${downloadPrefix}${logo.id}`}
            style={{
                backgroundColor: `${logo.background_color}`,
                flexDirection: `${logo.layout === 'top' ? 'column' : logo.layout === 'bottom' ? 'column-reverse' : logo.layout === 'left' ? 'row' : 'row-reverse'}`,
                gap: '4px',
            }}
            key={logo.id}
            className='dashboard-logo-item'
        >
            {logo && (
                <div
                    style={{ transform: `translateX(${logo.image_horizontal_position}px) translateY(${logo.image_vertical_position}px)` }}
                    className={`generated-logo-icon`}
                >
                    <img
                        draggable={false}
                        style={{
                            transform: `rotateX(${logo.horizontal_flip ? '180deg' : '0deg'}) rotateY(${logo.vertical_flip ? '180deg' : '0deg'})`,
                            width: `${logo.image_size * logo.logo_scale / 1.5}px`,
                        }}
                        src={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(logoElement)}`}
                        alt=""
                    />
                </div>
            )}
            <div
                style={{
                    alignItems: `${logo.layout === 'top' ? 'center' : logo.layout === 'bottom' ? 'center' : logo.layout === 'left' ? 'start' : 'end'}`,
                }}
                className='generated-logo-text'
            >
                <span
                    style={{
                        color: `${logo.company_name_color}`,
                        fontFamily: `${logo.company_name_font_family}`,
                        fontSize: `${logo.company_name_size * logo.company_name_scale / 1.5}px`,
                        transform: `translateX(${logo.company_name_horizontal_position}px) translateY(${logo.company_name_vertical_posititon}px)`,
                        letterSpacing: `${logo.company_name_spacing}px`,
                        textTransform: `${logo.company_name_transform}`,
                        textAlign: `${logo.layout === 'top' ? 'center' : logo.layout === 'bottom' ? 'center' : logo.layout === 'left' ? 'start' : 'end'}`,
                    }}
                    className={`generated-logo-company-name w-fit`}
                >
                    {logo.company_name}
                </span>
                {logo.slogan && (
                    <span
                        style={{
                            color: `${logo.slogan_color}`,
                            fontFamily: `${logo.slogan_font_family}`,
                            fontSize: `${logo.slogan_font_size * logo.slogan_scale / 1.5}px`,
                            transform: `translateX(${logo.slogan_horizontal_position}px) translateY(${logo.slogan_vertical_position}px)`,
                            letterSpacing: `${logo.slogan_spacing}px`,
                            textTransform: `${logo.slogan_transform}`,
                            textAlign: `${logo.layout === 'top' ? 'center' : logo.layout === 'bottom' ? 'center' : logo.layout === 'left' ? 'start' : 'end'}`,
                        }}
                        className={`generated-logo-slogan w-fit`}
                    >
                        {logo.slogan}
                    </span>
                )}
                <div className='dashboard-logo-item-actions-block'>
                    <button onClick={() => handleEditLogo(logo.id)} className='dashboard-logo-item-action-button'>
                        <img src={EditIcon} alt="" />
                        Edit
                    </button>
                </div>
            </div>
        </div>
    )
}

export default RecentLogoItem