import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import { handleSendVerificationEmail, handleUpdateUserEmail, handleValidatePassword } from '../firebase/crud/updateUserEmail'
import { EnterPasswordModalBlock } from '../styles/styles'

import ModalCloseButton from '../components/ModalCloseButton/ModalCloseButton'

const EnterPasswordModal = ({ show, handleClose, email, showVerificationModal }) => {
    const [step, setStep] = useState(1)
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)

    const handleReauthenticate = async () => {
        const validated = await handleValidatePassword(password)

        if (validated) {
            const updated = await handleUpdateUserEmail(email)

            if (updated) {
                setStep(2)
            } else {
                const send = await handleSendVerificationEmail(email)

                if (send) {
                    showVerificationModal()
                }
            }
        } else {
            setError(true)
        }
    }

    return (
        <EnterPasswordModalBlock centered show={show} onHide={handleClose}>
            <Modal.Body>
                {step === 1 ? (
                    <>
                        <h5 className='send-invoice-title'>Email Change</h5>
                        <span className='send-invoice-description'>
                            Please enter your password to update your email.
                        </span>
                        <input placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} className='reenter-password-input' type="password" />
                        {error && (
                            <span className='cancel-subscription-error'>Password incorrect. Please try again.</span>
                        )}
                        <div className='send-invoice-actions'>
                            <button onClick={handleClose} className='send-invoice-cancel'>Cancel</button>
                            <button disabled={!password} onClick={handleReauthenticate} className='send-invoice-delete'>Confirm</button>
                        </div>
                    </>
                ) : null}
                <ModalCloseButton handleClose={handleClose} />
            </Modal.Body>
        </EnterPasswordModalBlock>
    )
}

export default EnterPasswordModal