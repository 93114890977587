import { useRef, useEffect, useState } from 'react'

import { useEditorContext } from '../../context/EditorContext';
import { LogoPreviewBlock } from '../../styles/styles'

const LogoPreview = () => {
    const [logoElement, setLogoElement] = useState('')

    const textBlockRef = useRef(null)

    const {
        imageScale, logo, companyName, slogan,
        backgroundColor, imageWidth, editableElement,
        setEditableElement, previewMode, companyNameColor,
        previewBlockRef, horizontalFlip, verticalFlip,
        imageColor, logoScale, setPreviewMode, sloganColor,
        companyNameScale, sloganScale, sloganSize, sloganFontFamily,
        companyNameFontFamily, companyNameSize, imageHorizontalPosition,
        imageVerticalPosition, companyNameHorizontalPosition,
        companyNameVerticalPosition, sloganHorizontalPosition,
        sloganVerticalPosition, layout, sloganSpacing, companyNameSpacing,
        sloganTransform, companyNameTransform, setCompanyNameSize, setSloganSize,
        setNavbarTab,
    } = useEditorContext()

    const handleEditableElement = (element) => {
        if (!previewMode) {
            setEditableElement(element)

            if (element === 'company') {
                setNavbarTab('Name')
            } else if (element === 'slogan') {
                setNavbarTab('Slogan')
            } else if (element === 'image') {
                setNavbarTab('Symbol')
            }
        } else {
            setPreviewMode(false)
            setEditableElement(element)

            if (element === 'company') {
                setNavbarTab('Name')
            } else if (element === 'slogan') {
                setNavbarTab('Slogan')
            } else if (element === 'image') {
                setNavbarTab('Symbol')
            }
        }
    }

    useEffect(() => {
        const adjustFontSize = () => {
            if (textBlockRef.current) {
                const companyNameWidth = textBlockRef?.current?.children[0]?.scrollWidth;
                const parentWidth = textBlockRef?.current?.parentElement?.offsetWidth
                const companyNamePersentWidth = companyNameWidth / (parentWidth / 100)

                if (companyNamePersentWidth > 80) {
                    setCompanyNameSize(companyNameSize - 2);
                } else if (companyNamePersentWidth <= 70 && companyNameSize < 28) {
                    const newSize = companyNameSize + 1

                    if (newSize > 28) {
                        setCompanyNameSize(28)
                    } else if (newSize > 0) {
                        setCompanyNameSize(newSize)
                    }
                }
            }
        };

        adjustFontSize();
    }, [companyName, companyNameScale, companyNameSize, previewMode, companyNameFontFamily]);

    useEffect(() => {
        const adjustFontSize = () => {
            if (textBlockRef.current) {
                const sloganWidth = textBlockRef?.current?.children[1]?.scrollWidth;
                const parentWidth = textBlockRef?.current?.parentElement?.offsetWidth
                const sloganPersentWidth = sloganWidth / (parentWidth / 100)

                if (sloganPersentWidth > 80) {
                    setSloganSize(sloganSize - 2);
                } else if (sloganPersentWidth <= 70 && sloganSize < 18) {
                    const newSize = sloganSize + 1

                    if (newSize > 18) {
                        setSloganSize(18)
                    } else if (newSize > 0) {
                        setSloganSize(newSize)
                    }
                }
            }
        };

        adjustFontSize();
    }, [slogan, sloganScale, sloganSize, previewMode, sloganFontFamily]);

    useEffect(() => {
        if (logo) {
            let newStr = ''
            let splittedLogo = logo.split('<path')

            if (splittedLogo.length > 0) {
                splittedLogo.forEach((string, index) => {
                    if (index === 0) {
                        newStr += string
                    } else {
                        newStr += `<path fill='${imageColor}' ${string}`
                    }
                })
            }

            setLogoElement(`${newStr}`.replaceAll('  ', ' '))
        }
    }, [logo, imageColor])

    return (
        <LogoPreviewBlock>
            <div
                id='preview-block'
                ref={previewBlockRef}
                style={{
                    width: `${35 * imageScale / 100}%`,
                    maxWidth: '95%',
                    maxHeight: '95%',
                    height: `${50 * imageScale / 100}%`,
                    backgroundColor: `${backgroundColor}`,
                    flexDirection: `${layout === 'top' ? 'column' : layout === 'bottom' ? 'column-reverse' : layout === 'left' ? 'row' : 'row-reverse'}`
                }}
                className='preview-image-block'
            >
                {logo && (
                    <div
                        style={{ transform: `translateX(${imageHorizontalPosition}px) translateY(${imageVerticalPosition}px)` }}
                        className={`generated-logo-icon ${editableElement === 'image' ? 'editable' : ''}`}
                    >
                        <img
                            draggable={false}
                            onClick={() => handleEditableElement('image')}
                            style={{
                                transform: `rotateX(${horizontalFlip ? '180deg' : '0deg'}) rotateY(${verticalFlip ? '180deg' : '0deg'})`,
                                width: `${imageWidth * logoScale * imageScale / 100}px`,
                            }}
                            src={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(logoElement)}`}
                            alt=""
                        />
                    </div>
                )}
                <div
                    style={{
                        alignItems: `${layout === 'top' ? 'center' : layout === 'bottom' ? 'center' : layout === 'left' ? 'start' : 'end'}`,
                    }}
                    ref={textBlockRef}
                    className='generated-logo-text'
                >
                    <span
                        onClick={() => handleEditableElement('company')}
                        style={{
                            color: `${companyNameColor}`,
                            fontFamily: `${companyNameFontFamily}`,
                            fontSize: `${companyNameSize * companyNameScale * imageScale / 100}px`,
                            transform: `translateX(${companyNameHorizontalPosition}px) translateY(${companyNameVerticalPosition}px)`,
                            letterSpacing: `${companyNameSpacing}px`,
                            textTransform: `${companyNameTransform}`,
                            textAlign: `${layout === 'top' ? 'center' : layout === 'bottom' ? 'center' : layout === 'left' ? 'start' : 'end'}`,
                        }}
                        className={`generated-logo-company-name w-fit ${editableElement === 'company' ? 'editable' : ''}`}
                    >
                        {companyName}
                    </span>
                    {slogan && (
                        <span
                            onClick={() => handleEditableElement('slogan')}
                            style={{
                                color: `${sloganColor}`,
                                fontFamily: `${sloganFontFamily}`,
                                fontSize: `${sloganSize * sloganScale * imageScale / 100}px`,
                                transform: `translateX(${sloganHorizontalPosition}px) translateY(${sloganVerticalPosition}px)`,
                                letterSpacing: `${sloganSpacing}px`,
                                textTransform: `${sloganTransform}`,
                                textAlign: `${layout === 'top' ? 'center' : layout === 'bottom' ? 'center' : layout === 'left' ? 'start' : 'end'}`,
                            }}
                            className={`generated-logo-slogan w-fit ${editableElement === 'slogan' ? 'editable' : ''}`}
                        >
                            {slogan}
                        </span>
                    )}
                </div>
            </div>
        </LogoPreviewBlock >
    )
}

export default LogoPreview