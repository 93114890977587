import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { LogoMakerStepsNavigationBlock } from '../../styles/styles'

import AuthModal from '../../modals/AuthModal'

import AuthModalImage from '../../assets/auth-modal-image.svg'

const LogoMakerStepsNav = ({ step, setStep, isDisabled, isSkip, navigationRef, stepProcess }) => {
    const [showAuthModal, setShowAuthModal] = useState(false)
    const user = useSelector((state) => state.app.user)
    const [, setSearchParams] = useSearchParams()

    const handleNextStep = () => {
        if (step === 5) {
            setSearchParams({ step: 'exploring' })
        }

        if (step === 6) {
            if (!user) {
                setShowAuthModal(true)
            }
        } else {
            setStep(step + 1)
        }
    }

    const handleChangeStep = (selectedStep) => {
        switch (selectedStep) {
            case 1: {
                setStep(1)
                break;
            }
            case 2: {
                if (stepProcess.first) {
                    setStep(2)
                }
                break;
            }
            case 3: {
                if (stepProcess.first) {
                    setStep(3)
                }
                break;
            }
            case 4: {
                if (stepProcess.fourth && stepProcess.first) {
                    setStep(4)
                }
                break;
            }
            case 5: {
                if (stepProcess.fourth && stepProcess.first) {
                    setStep(5)
                }
                break;
            }
            case 6: {
                if (stepProcess.first && stepProcess.fourth && stepProcess.fifth) {
                    setStep(6)
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    useEffect(() => {
        const newImage = new Image();
        newImage.src = AuthModalImage;
        window[AuthModalImage] = newImage;
    }, [])

    return (
        <LogoMakerStepsNavigationBlock className="overflow-auto scroll-hidden">
            <div ref={navigationRef} className='logo-maker-step-points-wrapper'>
                <div onClick={() => handleChangeStep(1)} className={`logo-make-step-point ${step > 1 ? 'passed' : step === 1 || stepProcess.first ? 'active' : ''}`}>
                    <span className={`logo-maker-step-point-circle`}></span>
                    <span className={`logo-maker-step-point-name`}>Industry</span>
                </div>
                <div onClick={() => handleChangeStep(2)} className={`logo-make-step-point ${step > 2 ? 'passed' : step === 2 || stepProcess.second ? 'active' : ''}`}>
                    <span className={`logo-maker-step-point-circle`}></span>
                    <span className={`logo-maker-step-point-name`}>Samples</span>
                </div>
                <div onClick={() => handleChangeStep(3)} className={`logo-make-step-point ${step > 3 ? 'passed' : step === 3 || stepProcess.third ? 'active' : ''}`}>
                    <span className={`logo-maker-step-point-circle`}></span>
                    <span className={`logo-maker-step-point-name`}>Colors</span>
                </div>
                <div onClick={() => handleChangeStep(4)} className={`logo-make-step-point ${step > 4 ? 'passed' : step === 4 || stepProcess.fourth ? 'active' : ''}`}>
                    <span className={`logo-maker-step-point-circle`}></span>
                    <span className={`logo-maker-step-point-name`}>Name & Slogan</span>
                </div>
                <div onClick={() => handleChangeStep(5)} className={`logo-make-step-point ${step > 5 ? 'passed' : step === 5 || stepProcess.fifth ? 'active' : ''}`}>
                    <span className={`logo-maker-step-point-circle`}></span>
                    <span className={`logo-maker-step-point-name`}>Symbols</span>
                </div>
                <div onClick={() => handleChangeStep(6)} className={`logo-make-step-point ${step > 6 ? 'passed' : step === 6 ? 'active' : ''}`}>
                    <span className={`logo-maker-step-point-circle`}></span>
                    <span className={`logo-maker-step-point-name`}>Customize</span>
                </div>
            </div>
            <button
                disabled={step === 1}
                onClick={() => setStep(step - 1)}
                className='logo-maker-back-btn'
            >
                Back
            </button>
            {isSkip ? (
                <button
                    disabled={isDisabled}
                    onClick={handleNextStep}
                    className='logo-maker-skip-btn'
                >
                    Skip
                </button>
            ) : (
                <button
                    disabled={isDisabled || (step === 6 && user)}
                    onClick={handleNextStep}
                    className='logo-maker-continue-btn'
                >
                    Continue
                </button>
            )}
            {showAuthModal && (
                <AuthModal
                    show={showAuthModal}
                    handleClose={() => setShowAuthModal(false)}
                    signUp={true}
                    image={AuthModalImage}
                />
            )}
        </LogoMakerStepsNavigationBlock >
    )
}

export default LogoMakerStepsNav