import React, { useEffect, useState } from 'react'
import { IoClose } from 'react-icons/io5';

import { INDUSTRY_OPTIONS } from '../../utils/constants';
import { LogoMakerStepOneBlock } from '../../styles/styles';

const LogoMakerStepOne = ({ industry, setIndustry }) => {
    const [filteredItems, setFilteredItems] = useState([])
    const [isFocused, setIsFocused] = useState(false);

    const handleInput = (e) => {
        const searchText = e.target.value;
        setIndustry(searchText);
    };

    const handleItemClick = (itemName) => {
        setIndustry(itemName);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsFocused(false);
        }, 200);
    };

    useEffect(() => {
        if (!industry) {
            setFilteredItems(INDUSTRY_OPTIONS)
        } else {
            const industryLowerCase = industry.toLowerCase()
            const filteredItems = INDUSTRY_OPTIONS.filter((el) => el.name.toLowerCase().includes(industryLowerCase))

            setFilteredItems(filteredItems)
        }
    }, [industry])

    return (
        <LogoMakerStepOneBlock>
            <div className="flex justify-between items-center">
                <div>
                    <h4 className="logo-maker-step-one-heading">Tell us your industry</h4>
                    <p className="logo-maker-step-one-description">Knowing your industry will help us pick symbols, colors, and more.</p>
                </div>
            </div>

            <div className="logo-maker-step-one-input-wrapper relative">
                {industry && (
                    <IoClose onClick={() => handleInput({ target: { value: '' } })} className='close-icon-for-reset' color="#A0A0A0" size={26} />
                )}
                <input
                    placeholder='Restaurant, Consulting, Beauty, Fitness etc.'
                    value={industry}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleInput}
                    type="text"
                    className="logo-maker-step-one-input"
                />

                {isFocused && filteredItems.length > 0 && (
                    <div className="logo-maker-step-one-suggestions-block">
                        {filteredItems.map((item) => (
                            <div key={item.id}>
                                <div
                                    onClick={() => handleItemClick(item.name)}
                                    className="logo-maker-step-one-suggestions-item"
                                >
                                    {item.name}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </LogoMakerStepOneBlock>
    )
}

export default LogoMakerStepOne