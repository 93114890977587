import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { useSelector } from 'react-redux'

import { useSendEmailOnDownloadMutation } from '../../api/plunkApi'
import { downloadListImages } from '../../utils/functions'
import { RecentDesignsBlock } from '../../styles/styles'
import { useDownloadDoneMutation, useGetRecentDesignsQuery } from '../../api/api'

import RecentLogoItem from '../LogoBlockItem/RecentLogoItem'
import SupportWindow from '../SupportWindow/SupportWindow'
import NoLogosBlock from '../NoLogosBlock/NoLogosBlock'

import DownloadWhite from '../../assets/icons/download-white.svg'
import DownloadBlack from '../../assets/icons/download-black.svg'
import HelpIcon from '../../assets/icons/help-icon.svg'

const RecentDesigns = () => {
    const [limit, setLimit] = useState(9)
    const [more, setMore] = useState(false)
    const [showSupport, setShowSupport] = useState(false)
    const profile = useSelector((state) => state.app.profile)

    const navigate = useNavigate()

    const { isDark } = useTheme()

    const { data } = useGetRecentDesignsQuery(limit)
    const [sendEmailOnDownload] = useSendEmailOnDownloadMutation()
    const [downloadedLogo] = useDownloadDoneMutation()

    const handleEditLogo = (id) => {
        navigate(`/editor/${id}`)
    }

    const handleViewMore = () => {
        setLimit(limit + 9)
    }

    const handleDownloadAll = () => {
        if (profile?.plan === 'Free') {
            navigate('/plans')
        } else {
            const listOfIds = data.map(logo => logo.id)

            downloadListImages(listOfIds, 'recent_designs', profile?.plan !== 'Free' ? true : false)

            if (profile?.downloads_done === 0) {
                sendEmailOnDownload({ email: profile?.email, domain: window.location.host })
            }

            data.forEach((logo) => {
                downloadedLogo({ logo_id: logo.id })
            })
        }
    }

    useEffect(() => {
        if (data && data.length > 0) {
            if (data.length < limit) {
                setMore(false)
            } else {
                setMore(true)
            }
        }
    }, [data])

    return (
        <RecentDesignsBlock>
            <div className='recent-header-block'>
                <h5 className='recent-header-title'>Recent Designs</h5>
                <div className='recent-header-filters'>
                    {data && data.length > 0 && profile?.plan.includes('Premium') && (
                        <span onClick={handleDownloadAll} className='recent-filter-text'>
                            {isDark ? (
                                <img src={DownloadWhite} alt="" />
                            ) : (
                                <img src={DownloadBlack} alt="" />
                            )}
                            Download All
                        </span>
                    )}
                    <span onClick={() => setShowSupport(!showSupport)} className='recent-support-text'>
                        <img src={HelpIcon} alt="" />
                        Support
                    </span>
                </div>
            </div>
            {data && data.length > 0 ? (
                <div className='flex flex-col w-full gap-6'>
                    <div className='dashboard-logos-list'>
                        {data.map((logo) => (
                            <RecentLogoItem key={logo.id} logo={logo} handleEditLogo={handleEditLogo} downloadPrefix="recent_designs" />
                        ))}
                    </div>
                    {more && (
                        <div onClick={handleViewMore} className='load-more-block'>
                            View more
                        </div>
                    )}
                </div>
            ) : (
                <NoLogosBlock heading={"You have no recent designs!"} />
            )}
            {showSupport && (
                <SupportWindow position='bottom' handleClose={() => setShowSupport(false)} />
            )}
        </RecentDesignsBlock>
    )
}

export default RecentDesigns