import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useGetPaymentMethodsQuery, useSetDefaultPaymentMethodMutation } from '../../api/api'
import DeletePaymentMethodModal from '../../modals/DeletePaymentMethodModal';
import { useThemeContext } from '../../context/ThemeContext';
import { PLAN_TO_PRICE } from '../../utils/constants';
import { AccountBlock } from '../../styles/styles'

import VerificationEmailSentModal from '../../modals/VerificationEmailSentModal'
import ChangePasswordModal from '../../modals/ChangePasswordModal';
import EnterPasswordModal from '../../modals/EnterPasswordModal'
import DeleteAccountModal from '../../modals/DeleteAccountModal'
import AddCreditCard from '../AddCreditCard/AddCreditCard';
import SupportWindow from '../SupportWindow/SupportWindow';

import AccountEditIconBlack from '../../assets/icons/account-edit-icon-black.svg'
import AccountEditIconWhite from '../../assets/icons/account-edit-icon.svg'
import PremiumPlanIcon from '../../assets/icons/premium-plan-icon.svg'
import AddPaymentPlus from '../../assets/icons/add-payment-plus.svg'
import BasicPlanIcon from '../../assets/icons/basic-plan-icon.svg'
import VisaIcon from '../../assets/icons/visa-icon.svg'
import HelpIcon from '../../assets/icons/help-icon.svg'
import ArrowBottomGrey from '../../assets/icons/arrow-bottom-grey.svg'
import ArrowBottomWhite from '../../assets/icons/arrow-bottom-white.svg'

const stripePromise = loadStripe("pk_test_51N64VIGtupj8Z7jUf0GWLkkxBnaTDtgHsSKAictepHloA69omH7eGMf36ebT03HrUpBqcvLV8QWyfhkFi5krDTl200dn45npZY");

const Account = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [editElement, setEditElement] = useState('')
    const [userPlan, setUserPlan] = useState('')
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)
    const [showPasswordModal, setShowPasswordModal] = useState(false)
    const [showVerificationEmailSentModal, setShowVerificationEmailSentModal] = useState(false)
    const [showAddPayment, setShowAddPayment] = useState(false)
    const [editCardElement, setEditCardElement] = useState('')
    const [showDeletePaymentMethodModal, setShowDeletePaymentMethodModal] = useState(false)
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
    const [showThemeDropdown, setShowThemeDropdown] = useState(false)
    const [showSupport, setShowSupport] = useState(false)
    const user = useSelector(state => state.app.user)
    const profile = useSelector(state => state.app.profile)

    const themeDropdownRef = useRef(null)

    const { isDark } = useTheme()
    const { selectedMode, setIsDarkTheme, setSelectedMode } = useThemeContext()

    const { data } = useGetPaymentMethodsQuery(undefined, { skip: !profile })
    const [setDefaultPaymentMethod] = useSetDefaultPaymentMethodMutation()

    const handleEditField = (field) => {
        setEditElement(field)
    }

    const handleSaveChangesEmail = async () => {
        setShowPasswordModal(true)
    }

    const handleOpenDeleteModal = () => {
        setShowDeleteAccountModal(true)
    }

    const handleShowVerificationModal = () => {
        setShowVerificationEmailSentModal(true)
        setShowPasswordModal(false)
    }

    const handleEditPaymentMethod = (card) => {
        setEditCardElement(card)
    }

    const handleSetDefaultPaymentMethod = (id) => {
        setDefaultPaymentMethod(id).then((res) => {
            if ('error' in res) {

            } else {
                setEditCardElement('')
            }
        })
    }

    const handleDeletePaymentMethod = (id) => {
        setShowDeletePaymentMethodModal(true)
    }

    const handleOpenChangePassword = () => {
        setShowChangePasswordModal(true)
    }

    const handleSelectTheme = (theme) => {
        setShowThemeDropdown(false)

        if (theme === 'Light mode') {
            localStorage.setItem('theme', 'white')
            setIsDarkTheme(false)
            setSelectedMode('Light mode')
        } else if (theme === 'Dark mode') {
            localStorage.setItem('theme', 'dark')
            setIsDarkTheme(true)
            setSelectedMode('Dark mode')
        } else {
            const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches

            if (systemTheme) {
                setIsDarkTheme(true)
                setSelectedMode('Use system settings')
            } else {
                setIsDarkTheme(false)
                setSelectedMode('Use system settings')
            }

            localStorage.setItem('theme', 'system')
        }
    }

    useEffect(() => {
        if (user) {
            setEmail(user.email)
        }
    }, [user])

    useEffect(() => {
        const handleClickOutsideToolbar = (event) => {
            if (themeDropdownRef.current && !themeDropdownRef.current.contains(event.target)) {
                setShowThemeDropdown(false)
            }
        };
        document.addEventListener('mousedown', handleClickOutsideToolbar);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideToolbar);
        };
    }, []);

    useEffect(() => {
        if (profile && profile.plan) {
            if (profile.plan.includes('Basic')) {
                setUserPlan('Basic')
            } else if (profile.plan.includes('Premium')) {
                setUserPlan('Premium')
            } else {
                setUserPlan('Free')
            }
        }
    }, [profile])

    return (
        <AccountBlock>
            <div className='recent-header-block'>
                <h5 className='recent-header-title'>Your Account</h5>
                <div className='recent-header-filters'>
                    <span onClick={() => setShowSupport(!showSupport)} className='recent-support-text'>
                        <img src={HelpIcon} alt="" />
                        Support
                    </span>
                </div>
            </div>
            <div className='account-info-block'>
                <h5 className='account-info-block-title'>General</h5>
                <div className='account-info-block-content'>
                    <div className='account-info-input-block'>
                        <label className='account-info-input-label'>Email</label>
                        <div className='account-info-input-wrapper'>
                            <div onClick={() => handleEditField('email')} className='account-info-edit-icon'>
                                {editElement === 'email' ? (
                                    <button onClick={handleSaveChangesEmail} className='account-info-edit-save-btn'>Save Changes</button>
                                ) : (
                                    <>
                                        {
                                            isDark ? (
                                                <img src={AccountEditIconWhite} alt="" />
                                            ) : (
                                                <img src={AccountEditIconBlack} alt="" />
                                            )}
                                    </>
                                )}
                            </div>
                            <input
                                readOnly={editElement === 'email' ? false : true}
                                className='account-info-input'
                                placeholder='user@example.com'
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='account-info-input-block'>
                        <label className='account-info-input-label'>Password</label>
                        <div className='account-info-input-wrapper'>
                            <div onClick={() => handleEditField('password')} className='account-info-edit-icon'>
                                {editElement === 'password' ? (
                                    <button onClick={handleOpenChangePassword} disabled={!password} className='account-info-edit-save-btn'>Save Changes</button>
                                ) : (
                                    <>
                                        {
                                            isDark ? (
                                                <img src={AccountEditIconWhite} alt="" />
                                            ) : (
                                                <img src={AccountEditIconBlack} alt="" />
                                            )}
                                    </>
                                )}
                            </div>
                            <input
                                readOnly={editElement === 'password' ? false : true}
                                className='account-info-input'
                                placeholder='**************'
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='account-info-block'>
                <h5 className='account-info-block-title'>Appearance</h5>
                <div ref={themeDropdownRef} className='account-info-change-theme-block'>
                    <div onClick={() => setShowThemeDropdown(!showThemeDropdown)} className={`account-info-change-theme-button ${showThemeDropdown ? 'active' : ''}`}>
                        {selectedMode}
                        {isDark ? (
                            <img src={ArrowBottomWhite} alt="" />
                        ) : (
                            <img src={ArrowBottomGrey} alt="" />
                        )}
                    </div>
                    {showThemeDropdown && (
                        <div className='account-info-change-theme-dropdown'>
                            <div onClick={() => handleSelectTheme('Use system settings')} className={`account-info-change-theme-dropdown-item ${selectedMode === 'Use system settings' ? 'selected' : ''}`}>Use system settings</div>
                            <div onClick={() => handleSelectTheme('Light mode')} className={`account-info-change-theme-dropdown-item ${selectedMode === 'Light mode' ? 'selected' : ''}`}>Light mode</div>
                            <div onClick={() => handleSelectTheme('Dark mode')} className={`account-info-change-theme-dropdown-item ${selectedMode === 'Dark mode' ? 'selected' : ''}`}>Dark mode</div>
                        </div>
                    )}
                </div>
            </div>
            <div className='account-info-block'>
                <h5 className='account-info-block-title'>Billing & Payment</h5>
                <div className={`account-payment-methods-list ${showAddPayment || editCardElement ? 'expanded-payment-items-list' : ''}`}>
                    {data && data.length > 0 && data.map((item, index) => (
                        <div key={index} className='account-info-existing-payment-method'>
                            {item.id === editCardElement.id ? (
                                <div className='edit-payment-method-block'>
                                    <div className='add-payment-form-element'>
                                        <div className='add-payment-block'>
                                            <span className='add-payment-input-label'>Card Number</span>
                                            <div className='add-payment-input-wrapper'>
                                                <input readOnly className='edit-payment-element-input' placeholder={`**** **** **** ${editCardElement.card.last4}`} type="text" />
                                            </div>
                                        </div>
                                        <div className='add-payment-cvc-expiry-block'>
                                            <div className='add-payment-block'>
                                                <span className='add-payment-input-label'>Expiry</span>
                                                <div className='add-payment-input-wrapper'>
                                                    <input readOnly className='edit-payment-element-input' placeholder={`${editCardElement.card.exp_month}/${editCardElement.card.exp_year}`} type="text" />
                                                </div>
                                            </div>
                                            <div className='add-payment-block'>
                                                <span className='add-payment-input-label'>CVC</span>
                                                <div className='add-payment-input-wrapper'>
                                                    <input readOnly className='edit-payment-element-input' placeholder='***' type="text" />
                                                </div>
                                            </div>
                                        </div>
                                        {editCardElement.id !== profile.default_payment_method && (
                                            <button onClick={() => handleSetDefaultPaymentMethod(editCardElement.id)} className='add-payment-save-btn'>Set as Default</button>
                                        )}
                                        <button onClick={() => setEditCardElement('')} className='add-payment-cancel-btn'>Cancel</button>
                                        <button onClick={() => handleDeletePaymentMethod(editCardElement.id)} className='edit-payment-delete-payment-method'>Delete Payment Method</button>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {item.card.brand === 'visa' ? (
                                        <img src={VisaIcon} alt="" />
                                    ) : null}
                                    <div className='existing-card-info-block'>
                                        <span className='existing-card-info-ending'>
                                            Card ending in {item.card.last4}
                                        </span>
                                        <span className='exisiting-card-info-expires'>
                                            Expires {item.card.exp_month}/{item.card.exp_year}
                                        </span>
                                    </div>
                                    <div onClick={() => handleEditPaymentMethod(item)} className='existing-card-edit'>
                                        {
                                            isDark ? (
                                                <img src={AccountEditIconWhite} alt="" />
                                            ) : (
                                                <img src={AccountEditIconBlack} alt="" />
                                            )
                                        }
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                    {showAddPayment && (
                        <div className='add-payment-method-wrapper'>
                            <div className='add-payment-method-block'>
                                <Elements stripe={stripePromise}>
                                    <AddCreditCard setShowAddPayment={setShowAddPayment} />
                                </Elements>
                            </div>
                        </div>
                    )}
                    <div onClick={() => setShowAddPayment(true)} className='account-info-add-payment-method'>
                        Add new payment method
                        <img src={AddPaymentPlus} alt="" />
                    </div>
                </div>
            </div>
            {
                userPlan !== 'Free' && (
                    <div className='account-info-block'>
                        <h5 className='account-info-block-title'>Your Plan</h5>
                        <div className='account-info-current-plan-block'>
                            {userPlan === 'Basic' ? (
                                <img height={18} width={23} src={BasicPlanIcon} alt="" />
                            ) : userPlan === 'Premium' ? (
                                <img height={18} width={43} src={PremiumPlanIcon} alt="" />
                            ) : null}
                            <h5 className='current-plan-name'>{userPlan}</h5>
                            <span className='current-plan-payment-type'>{userPlan === 'Premium' ? 'Billed Annually' : 'One Time Payment'}</span>
                            {userPlan === 'Premium' && (
                                <>
                                    <span className='current-plan-underline'></span>
                                    <div className='current-plan-info-block'>
                                        <div className='current-plan-info-side-block'>
                                            Next payment due on
                                            <br />
                                            {new Date(profile?.current_period_end).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}
                                        </div>
                                        <div className='current-plan-info-side-block'>
                                            ${PLAN_TO_PRICE[userPlan]}/year
                                            <span className='current-plan-exclude-tax'>
                                                (excl. tax)
                                            </span>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )
            }
            <div className='flex w-full items-start lg:justify-center'>
                <button onClick={handleOpenDeleteModal} className='delete-account-btn'>Delete Account</button>
            </div>
            <DeleteAccountModal
                show={showDeleteAccountModal}
                handleClose={() => setShowDeleteAccountModal(false)}
            />
            <EnterPasswordModal
                show={showPasswordModal}
                handleClose={() => setShowPasswordModal(false)}
                email={email}
                showVerificationModal={handleShowVerificationModal}
            />
            <VerificationEmailSentModal
                show={showVerificationEmailSentModal}
                handleClose={() => setShowVerificationEmailSentModal(false)}
            />
            <DeletePaymentMethodModal
                show={showDeletePaymentMethodModal}
                handleClose={() => setShowDeletePaymentMethodModal(false)}
                id={editCardElement?.id}
                setEditCardElement={setEditCardElement}
            />
            <ChangePasswordModal
                show={showChangePasswordModal}
                handleClose={() => setShowChangePasswordModal(false)}
                newPassword={password}
                setEditElement={setEditElement}
            />
            {showSupport && (
                <SupportWindow position='bottom' handleClose={() => setShowSupport(false)} />
            )}
        </AccountBlock >
    )
}

export default Account