import React, { useEffect, useRef, useState } from "react";

import { useUpdateLogoMutation } from "../api/api";
import { useViewport } from "../hooks/useViewport";

const EditorContext = React.createContext();

export const useEditorContext = () => React.useContext(EditorContext);

export const EditorProvider = ({ children }) => {
    const [imageScale, setImageScale] = useState(150)
    const [previewMode, setPreviewMode] = useState(true)
    const [logo, setLogo] = useState(null)
    const [slogan, setSlogan] = useState(null)
    const [companyName, setCompanyName] = useState('')
    const [backgroundColor, setBackgroundColor] = useState('')
    const [imageWidth, setImageWidth] = useState('')
    const [editableElement, setEditableElement] = useState('company')
    const [companyNameColor, setCompanyNameColor] = useState('')
    const [horizontalFlip, setHorizontalFlip] = useState(false)
    const [verticalFlip, setVerticalFlip] = useState(false)
    const [imageColor, setImageColor] = useState('#000000')
    const [logoScale, setLogoScale] = useState(1.00)
    const [sloganColor, setSloganColor] = useState('#000000')
    const [companyNameScale, setCompanyNameScale] = useState(1.00)
    const [sloganScale, setSloganScale] = useState(1.00)
    const [companyNameFontFamily, setCompanyNameFontFamily] = useState("Inter")
    const [sloganFontFamily, setSloganFontFamily] = useState("Inter")
    const [companyNameSize, setCompanyNameSize] = useState(24)
    const [sloganSize, setSloganSize] = useState(24)
    const [imageHorizontalPosition, setImageHorizontalPosition] = useState(0)
    const [imageVerticalPosition, setImageVerticalPosition] = useState(0)
    const [companyNameHorizontalPosition, setCompanyNameHorizontalPosition] = useState(0)
    const [companyNameVerticalPosition, setCompanyNameVerticalPosition] = useState(0)
    const [sloganHorizontalPosition, setSloganHorizontalPosition] = useState(0)
    const [sloganVerticalPosition, setSloganVerticalPosition] = useState(0)
    const [layout, setLayout] = useState('top')
    const [companyNameSpacing, setCompanyNameSpacing] = useState(0)
    const [sloganSpacing, setSloganSpacing] = useState(0)
    const [companyNameTransform, setCompanyNameTransform] = useState('unset')
    const [sloganTransform, setSloganTransform] = useState('unset')
    const [navbarTab, setNavbarTab] = useState('')
    const [suggestedType, setSuggestedType] = useState('Modern')
    const [generatedLogos, setGeneratedLogos] = useState([])
    const [currentTemplateId, setCurrentTemplateId] = useState(null)
    const [currentTemplateData, setCurrentTemplateData] = useState(null)
    const [fetchedIcons, setFetchedIcons] = useState([])
    const [currentHistoryStep, setCurrentHistoryStep] = useState(0)
    const [historyChanges, setHistoryChanges] = useState([])
    const [expandMobileNavbar, setExpandMobileNavbar] = useState(false)

    const contentPreviewBlockRef = useRef(null)
    const previewBlockRef = useRef(null)

    const { isMobile } = useViewport()

    const [updateLogo] = useUpdateLogoMutation()

    const handleUndoChange = () => {
        let templateObject = { ...currentTemplateData }
        const backToStep = currentHistoryStep - 1

        const changesToUndo = historyChanges[backToStep]

        Object.keys(changesToUndo).forEach((changeKey) => {
            switch (changeKey) {
                case 'backgroundColor': {
                    setBackgroundColor(changesToUndo[changeKey].prev)
                    templateObject.background_color = changesToUndo[changeKey].prev
                    break;
                }
                case 'companyNameFontFamily': {
                    setCompanyNameFontFamily(changesToUndo[changeKey].prev)
                    templateObject.company_name_font_family = changesToUndo[changeKey].prev
                    break;
                }
                case 'sloganFontFamily': {
                    setSloganFontFamily(changesToUndo[changeKey].prev)
                    templateObject.slogan_font_family = changesToUndo[changeKey].prev
                    break;
                }
                case 'logo': {
                    setLogo(changesToUndo[changeKey].prev)
                    templateObject.icon = changesToUndo[changeKey].prev
                    break;
                }
                case 'template': {
                    templateObject = changesToUndo[changeKey].prev
                    setLogo(templateObject.icon)
                    setBackgroundColor(templateObject.background_color)
                    setImageWidth(templateObject.image_size)
                    setCompanyNameColor(templateObject.company_name_color)
                    setSloganColor(templateObject.slogan_color)
                    setCompanyNameFontFamily(templateObject.company_name_font_family)
                    setSloganFontFamily(templateObject.slogan_font_family)
                    setCompanyNameSize(templateObject.company_name_size)
                    setSloganSize(templateObject.slogan_size)
                    setCompanyNameSpacing(templateObject.company_name_spacing)
                    setSloganSpacing(templateObject.slogan_spacing)
                    setCompanyNameTransform(templateObject.company_name_transform)
                    setSloganTransform(templateObject.slogan_transform)
                    setCompanyName(templateObject.company_name)
                    setSlogan(templateObject.slogan)
                    setImageColor(templateObject.image_color)
                    setImageHorizontalPosition(templateObject.logo_horizontal_position)
                    setImageVerticalPosition(templateObject.logo_vertical_position)
                    setCompanyNameHorizontalPosition(templateObject.company_name_horizontal_position)
                    setCompanyNameVerticalPosition(templateObject.company_name_vertical_position)
                    setSloganHorizontalPosition(templateObject.slogan_horizontal_position)
                    setSloganVerticalPosition(templateObject.slogan_vertical_position)
                    setLayout(templateObject.layout)
                    setVerticalFlip(templateObject.vertical_flip)
                    setHorizontalFlip(templateObject.horizontal_flip)
                    setLogoScale(templateObject.logo_scale)
                    setCompanyNameScale(templateObject.company_name_scale)
                    setSloganScale(templateObject.slogan_scale)
                    setCurrentTemplateData(templateObject)
                    break;
                }
                case 'slogan': {
                    setSlogan(changesToUndo[changeKey].prev)
                    templateObject.slogan = changesToUndo[changeKey].prev
                    break;
                }
                case 'layout': {
                    setLayout(changesToUndo[changeKey].prev)
                    templateObject.layout = changesToUndo[changeKey].prev
                    break;
                }
                case 'verticalFlip': {
                    setVerticalFlip(changesToUndo[changeKey].prev)
                    templateObject.vertical_flip = changesToUndo[changeKey].prev
                    break;
                }
                case 'horizontalFlip': {
                    setHorizontalFlip(changesToUndo[changeKey].prev)
                    templateObject.horizontal_flip = changesToUndo[changeKey].prev
                    break;
                }
                case 'imageVerticalPosition': {
                    setImageVerticalPosition(changesToUndo[changeKey].prev)
                    templateObject.logo_vertical_position = changesToUndo[changeKey].prev
                    break;
                }
                case 'companyNameVerticalPosition': {
                    setCompanyNameVerticalPosition(changesToUndo[changeKey].prev)
                    templateObject.company_name_vertical_position = changesToUndo[changeKey].prev
                    break;
                }
                case 'sloganVerticalPosition': {
                    setSloganVerticalPosition(changesToUndo[changeKey].prev)
                    templateObject.slogan_vertical_position = changesToUndo[changeKey].prev
                    break;
                }
                case 'imageHorizontalPosition': {
                    setImageHorizontalPosition(changesToUndo[changeKey].prev)
                    templateObject.logo_horizontal_position = changesToUndo[changeKey].prev
                    break;
                }
                case 'companyNameHorizontalPosition': {
                    setCompanyNameHorizontalPosition(changesToUndo[changeKey].prev)
                    templateObject.company_name_horizontal_position = changesToUndo[changeKey].prev
                    break;
                }
                case 'sloganHorizontalPosition': {
                    setSloganHorizontalPosition(changesToUndo[changeKey].prev)
                    templateObject.slogan_horizontal_position = changesToUndo[changeKey].prev
                    break;
                }
                case 'logoScale': {
                    setLogoScale(changesToUndo[changeKey].prev)
                    templateObject.logo_scale = changesToUndo[changeKey].prev
                    break;
                }
                case 'companyNameScale': {
                    setCompanyNameScale(changesToUndo[changeKey].prev)
                    templateObject.company_name_scale = changesToUndo[changeKey].prev
                    break;
                }
                case 'sloganScale': {
                    setSloganScale(changesToUndo[changeKey].prev)
                    templateObject.slogan_scale = changesToUndo[changeKey].prev
                    break;
                }
                case 'companyName': {
                    setCompanyName(changesToUndo[changeKey].prev)
                    templateObject.company_name = changesToUndo[changeKey].prev
                    break;
                }
                case 'imageColor': {
                    setImageColor(changesToUndo[changeKey].prev)
                    templateObject.image_color = changesToUndo[changeKey].prev
                    break;
                }
                case 'companyNameColor': {
                    setCompanyNameColor(changesToUndo[changeKey].prev)
                    templateObject.company_name_color = changesToUndo[changeKey].prev
                    break;
                }
                case 'sloganColor': {
                    setSloganColor(changesToUndo[changeKey].prev)
                    templateObject.slogan_color = changesToUndo[changeKey].prev
                    break;
                }
                case 'companyNameSpacing': {
                    setCompanyNameSpacing(changesToUndo[changeKey].prev)
                    templateObject.company_name_spacing = changesToUndo[changeKey].prev
                    break;
                }
                case 'sloganSpacing': {
                    setSloganSpacing(changesToUndo[changeKey].prev)
                    templateObject.slogan_spacing = changesToUndo[changeKey].prev
                    break;
                }
                case 'companyNameTransform': {
                    setCompanyNameTransform(changesToUndo[changeKey].prev)
                    templateObject.company_name_transform = changesToUndo[changeKey].prev
                    break;
                }
                case 'sloganTransform': {
                    setSloganTransform(changesToUndo[changeKey].prev)
                    templateObject.slogan_transform = changesToUndo[changeKey].prev
                    break;
                }
                default: {
                    break;
                }
            }
        })

        setCurrentHistoryStep(backToStep)
        updateLogo(templateObject)
    }

    const handleRedoChange = () => {
        let templateObject = { ...currentTemplateData }
        const nextToStep = currentHistoryStep

        const changesToRedo = historyChanges[nextToStep]

        Object.keys(changesToRedo).forEach((changeKey) => {
            switch (changeKey) {
                case 'backgroundColor': {
                    setBackgroundColor(changesToRedo[changeKey].next)
                    templateObject.background_color = changesToRedo[changeKey].next
                    break;
                }
                case 'companyNameFontFamily': {
                    setCompanyNameFontFamily(changesToRedo[changeKey].next)
                    templateObject.company_name_font_family = changesToRedo[changeKey].next
                    break;
                }
                case 'sloganFontFamily': {
                    setSloganFontFamily(changesToRedo[changeKey].next)
                    templateObject.slogan_font_family = changesToRedo[changeKey].next
                    break;
                }
                case 'logo': {
                    setLogo(changesToRedo[changeKey].next)
                    templateObject.icon = changesToRedo[changeKey].next
                    break;
                }
                case 'template': {
                    templateObject = changesToRedo[changeKey].next
                    setLogo(templateObject.icon)
                    setBackgroundColor(templateObject.background_color)
                    setImageWidth(templateObject.image_size)
                    setCompanyNameColor(templateObject.company_name_color)
                    setSloganColor(templateObject.slogan_color)
                    setCompanyNameFontFamily(templateObject.company_name_font_family)
                    setSloganFontFamily(templateObject.slogan_font_family)
                    setCompanyNameSize(templateObject.company_name_size)
                    setSloganSize(templateObject.slogan_size)
                    setCompanyNameSpacing(templateObject.company_name_spacing)
                    setSloganSpacing(templateObject.slogan_spacing)
                    setCompanyNameTransform(templateObject.company_name_transform)
                    setSloganTransform(templateObject.slogan_transform)
                    setCompanyName(templateObject.company_name)
                    setSlogan(templateObject.slogan)
                    setImageColor(templateObject.image_color)
                    setImageHorizontalPosition(templateObject.logo_horizontal_position)
                    setImageVerticalPosition(templateObject.logo_vertical_position)
                    setCompanyNameHorizontalPosition(templateObject.company_name_horizontal_position)
                    setCompanyNameVerticalPosition(templateObject.company_name_vertical_position)
                    setSloganHorizontalPosition(templateObject.slogan_horizontal_position)
                    setSloganVerticalPosition(templateObject.slogan_vertical_position)
                    setLayout(templateObject.layout)
                    setVerticalFlip(templateObject.vertical_flip)
                    setHorizontalFlip(templateObject.horizontal_flip)
                    setLogoScale(templateObject.logo_scale)
                    setCompanyNameScale(templateObject.company_name_scale)
                    setSloganScale(templateObject.slogan_scale)
                    setCurrentTemplateData(templateObject)
                    break;
                }
                case 'slogan': {
                    setSlogan(changesToRedo[changeKey].next)
                    templateObject.slogan = changesToRedo[changeKey].next
                    break;
                }
                case 'layout': {
                    setLayout(changesToRedo[changeKey].next)
                    templateObject.layout = changesToRedo[changeKey].next
                    break;
                }
                case 'verticalFlip': {
                    setVerticalFlip(changesToRedo[changeKey].next)
                    templateObject.vertical_flip = changesToRedo[changeKey].next
                    break;
                }
                case 'horizontalFlip': {
                    setHorizontalFlip(changesToRedo[changeKey].next)
                    templateObject.horizontal_flip = changesToRedo[changeKey].next
                    break;
                }
                case 'imageVerticalPosition': {
                    setImageVerticalPosition(changesToRedo[changeKey].next)
                    templateObject.logo_vertical_position = changesToRedo[changeKey].next
                    break;
                }
                case 'companyNameVerticalPosition': {
                    setCompanyNameVerticalPosition(changesToRedo[changeKey].next)
                    templateObject.company_name_vertical_position = changesToRedo[changeKey].next
                    break;
                }
                case 'sloganVerticalPosition': {
                    setSloganVerticalPosition(changesToRedo[changeKey].next)
                    templateObject.slogan_vertical_position = changesToRedo[changeKey].next
                    break;
                }
                case 'imageHorizontalPosition': {
                    setImageHorizontalPosition(changesToRedo[changeKey].next)
                    templateObject.logo_horizontal_position = changesToRedo[changeKey].next
                    break;
                }
                case 'companyNameHorizontalPosition': {
                    setCompanyNameHorizontalPosition(changesToRedo[changeKey].next)
                    templateObject.company_name_horizontal_position = changesToRedo[changeKey].next
                    break;
                }
                case 'sloganHorizontalPosition': {
                    setSloganHorizontalPosition(changesToRedo[changeKey].next)
                    templateObject.slogan_horizontal_position = changesToRedo[changeKey].next
                    break;
                }
                case 'logoScale': {
                    setLogoScale(changesToRedo[changeKey].next)
                    templateObject.logo_scale = changesToRedo[changeKey].next
                    break;
                }
                case 'companyNameScale': {
                    setCompanyNameScale(changesToRedo[changeKey].next)
                    templateObject.company_name_scale = changesToRedo[changeKey].next
                    break;
                }
                case 'sloganScale': {
                    setSloganScale(changesToRedo[changeKey].next)
                    templateObject.slogan_scale = changesToRedo[changeKey].next
                    break;
                }
                case 'companyName': {
                    setCompanyName(changesToRedo[changeKey].next)
                    templateObject.company_name = changesToRedo[changeKey].next
                    break;
                }
                case 'imageColor': {
                    setImageColor(changesToRedo[changeKey].next)
                    templateObject.image_color = changesToRedo[changeKey].next
                    break;
                }
                case 'companyNameColor': {
                    setCompanyNameColor(changesToRedo[changeKey].next)
                    templateObject.company_name_color = changesToRedo[changeKey].next
                    break;
                }
                case 'sloganColor': {
                    setSloganColor(changesToRedo[changeKey].next)
                    templateObject.slogan_color = changesToRedo[changeKey].next
                    break;
                }
                case 'companyNameSpacing': {
                    setCompanyNameSpacing(changesToRedo[changeKey].next)
                    templateObject.company_name_spacing = changesToRedo[changeKey].next
                    break;
                }
                case 'sloganSpacing': {
                    setSloganSpacing(changesToRedo[changeKey].next)
                    templateObject.slogan_spacing = changesToRedo[changeKey].next
                    break;
                }
                case 'companyNameTransform': {
                    setCompanyNameTransform(changesToRedo[changeKey].next)
                    templateObject.company_name_transform = changesToRedo[changeKey].next
                    break;
                }
                case 'sloganTransform': {
                    setSloganTransform(changesToRedo[changeKey].next)
                    templateObject.slogan_transform = changesToRedo[changeKey].next
                    break;
                }
                default: {
                    break;
                }
            }
        })

        setCurrentHistoryStep(nextToStep + 1)
        updateLogo(templateObject)
    }

    const handleChangeLayout = (newLayout) => {
        setLayout(newLayout)
        setImageHorizontalPosition(0)
        setImageVerticalPosition(0)
        setCompanyNameHorizontalPosition(0)
        setCompanyNameVerticalPosition(0)
        setSloganHorizontalPosition(0)
        setSloganVerticalPosition(0)
        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = {
                layout: {
                    prev: layout,
                    next: newLayout,
                }
            }

            return cuttedToCurrent
        })

        updateLogo({
            id: currentTemplateData.id,
            layout: newLayout,
            logo_horizontal_position: 0,
            logo_vertical_position: 0,
            company_name_horizontal_position: 0,
            company_name_vertical_position: 0,
            slogan_horizontal_position: 0,
            slogan_vertical_position: 0,
        }).then((res) => {
            if ('error' in res) {

            } else {
                setLayout(res.data.layout)
                setImageHorizontalPosition(0)
                setImageVerticalPosition(0)
                setCompanyNameHorizontalPosition(0)
                setCompanyNameVerticalPosition(0)
                setSloganHorizontalPosition(0)
                setSloganVerticalPosition(0)
            }
        })
    }

    const onSelectBackgroundColor = (newColor) => {
        setBackgroundColor(newColor)
    }

    const onSelectSuggestedTemplate = (template) => {
        setLogo(template.icon)
        setBackgroundColor(template.background_color)
        setImageWidth(template.image_size)
        setCompanyNameColor(template.company_name_color)
        setSloganColor(template.slogan_color)
        setCompanyNameFontFamily(template.company_name_font_family)
        setSloganFontFamily(template.slogan_font_family)
        setCompanyNameSize(template.company_name_size)
        setSloganSize(template.slogan_size)
        setCompanyNameSpacing(template.company_name_spacing)
        setSloganSpacing(template.slogan_spacing)
        setCompanyNameTransform(template.company_name_transform)
        setSloganTransform(template.slogan_transform)
        setCompanyName(template.company_name)
        setSlogan(template.slogan)
        setImageColor(template.image_color)
        setCurrentTemplateId(template.id)
        setImageHorizontalPosition(template.logo_horizontal_position)
        setImageVerticalPosition(template.logo_vertical_position)
        setCompanyNameHorizontalPosition(template.company_name_horizontal_position)
        setCompanyNameVerticalPosition(template.company_name_vertical_position)
        setSloganHorizontalPosition(template.slogan_horizontal_position)
        setSloganVerticalPosition(template.slogan_vertical_position)
        setVerticalFlip(template.vertical_flip)
        setHorizontalFlip(template.horizontal_flip)
        setLogoScale(template.logo_scale)
        setCompanyNameScale(template.company_name_scale)
        setSloganScale(template.slogan_scale)
        setCurrentTemplateData(template)
        setLayout(template.layout)

        if (!slogan && template.default_slogan) {
            setSlogan(template.default_slogan)
        }

        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = {
                template: {
                    prev: currentTemplateData,
                    next: { ...template, id: currentTemplateData.id },
                }
            }

            return cuttedToCurrent
        })

        updateLogo({ ...template, id: currentTemplateData.id })
    }

    const deleteElement = (name) => {
        let historyObject = {}

        switch (name) {
            case 'image': {
                setLogo(null)
                setEditableElement('')

                updateLogo({ id: currentTemplateData.id, icon: '' }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setLogo('')
                    }
                })

                historyObject = {
                    logo: {
                        prev: logo,
                        next: '',
                    }
                }
                break;
            }
            case 'slogan': {
                setSlogan('')
                setEditableElement('')

                updateLogo({ id: currentTemplateData.id, slogan: '' }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setSlogan('')
                    }
                })

                historyObject = {
                    slogan: {
                        prev: slogan,
                        next: '',
                    }
                }
                break;
            }
            default: {
                break
            }
        }

        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = historyObject

            return cuttedToCurrent
        })
    }

    const resetLetterSpacing = (name) => {
        let historyObject = {}

        switch (name) {
            case 'company': {
                setCompanyNameSpacing(0)

                updateLogo({ id: currentTemplateData.id, company_name_spacing: 0 }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setCompanyNameSpacing(0)
                    }
                })

                historyObject = {
                    companyNameSpacing: {
                        prev: currentTemplateData.company_name_spacing,
                        next: 0
                    }
                }
                break;
            }
            case 'slogan': {
                setSloganSpacing(0)

                updateLogo({ id: currentTemplateData.id, slogan_spacing: 0 }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setSloganSpacing(0)
                    }
                })

                historyObject = {
                    sloganSpacing: {
                        prev: currentTemplateData.slogan_spacing,
                        next: 0
                    }
                }
                break;
            }
            default: {
                break
            }
        }

        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = historyObject

            return cuttedToCurrent
        })
    }

    const resetHorizontalPostitionElement = (name) => {
        let historyObject = {}

        switch (name) {
            case 'image': {
                setImageHorizontalPosition(0)

                updateLogo({ id: currentTemplateData.id, logo_horizontal_position: 0 }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setImageHorizontalPosition(0)
                    }
                })

                historyObject = {
                    imageHorizontalPosition: {
                        prev: currentTemplateData.logo_horizontal_position,
                        next: 0,
                    }
                }
                break;
            }
            case 'company': {
                setCompanyNameHorizontalPosition(0)

                updateLogo({ id: currentTemplateData.id, company_name_horizontal_position: 0 }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setCompanyNameHorizontalPosition(0)
                    }
                })

                historyObject = {
                    companyNameHorizontalPosition: {
                        prev: currentTemplateData.company_name_horizontal_position,
                        next: 0,
                    }
                }
                break;
            }
            case 'slogan': {
                setSloganHorizontalPosition(0)

                updateLogo({ id: currentTemplateData.id, slogan_horizontal_position: 0 }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setSloganHorizontalPosition(0)
                    }
                })

                historyObject = {
                    sloganHorizontalPosition: {
                        prev: currentTemplateData.slogan_horizontal_position,
                        next: 0,
                    }
                }
                break;
            }
            default: {
                break
            }
        }

        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = historyObject

            return cuttedToCurrent
        })
    }

    const resetVerticalPostitionElement = (name) => {
        let historyObject = {}

        switch (name) {
            case 'image': {
                setImageVerticalPosition(0)

                updateLogo({ id: currentTemplateData.id, logo_vertical_position: 0 }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setImageVerticalPosition(0)
                    }
                })

                historyObject = {
                    imageVerticalPosition: {
                        prev: currentTemplateData.logo_vertical_position,
                        next: 0,
                    }
                }
                break;
            }
            case 'company': {
                setCompanyNameVerticalPosition(0)

                updateLogo({ id: currentTemplateData.id, company_name_vertical_position: 0 }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setCompanyNameVerticalPosition(0)
                    }
                })

                historyObject = {
                    companyNameVerticalPosition: {
                        prev: currentTemplateData.company_name_vertical_position,
                        next: 0,
                    }
                }
                break;
            }
            case 'slogan': {
                setSloganVerticalPosition(0)

                updateLogo({ id: currentTemplateData.id, slogan_vertical_position: 0 }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setSloganVerticalPosition(0)
                    }
                })

                historyObject = {
                    sloganVerticalPosition: {
                        prev: currentTemplateData.slogan_vertical_position,
                        next: 0,
                    }
                }
                break;
            }
            default: {
                break
            }
        }

        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = historyObject

            return cuttedToCurrent
        })
    }

    const resetSizeElement = (name) => {
        let historyObject = {}

        switch (name) {
            case 'image': {
                setLogoScale(1)

                updateLogo({ id: currentTemplateData.id, logo_scale: 1 }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setLogoScale(1)
                    }
                })

                historyObject = {
                    logoScale: {
                        prev: currentTemplateData.logo_scale,
                        next: 1,
                    }
                }
                break;
            }
            case 'company': {
                setCompanyNameScale(1)

                updateLogo({ id: currentTemplateData.id, company_name_scale: 1 }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setCompanyNameScale(1)
                    }
                })

                historyObject = {
                    companyNameScale: {
                        prev: currentTemplateData.company_name_scale,
                        next: 1,
                    }
                }
                break;
            }
            case 'slogan': {
                setSloganScale(1)

                updateLogo({ id: currentTemplateData.id, slogan_scale: 1 }).then((res) => {
                    if ('error' in res) {

                    } else {
                        setSloganScale(1)
                    }
                })

                historyObject = {
                    sloganScale: {
                        prev: currentTemplateData.slogan_scale,
                        next: 1,
                    }
                }
                break;
            }
            default: {
                break
            }
        }

        setCurrentHistoryStep((prev) => {
            return prev + 1
        })
        setHistoryChanges((prev) => {
            const cuttedToCurrent = prev.slice(0, currentHistoryStep)

            cuttedToCurrent[currentHistoryStep] = historyObject

            return cuttedToCurrent
        })
    }

    useEffect(() => {
        if (isMobile) {
            setImageScale(90)
            setPreviewMode(false)
        }
    }, [isMobile])

    const value = {
        imageScale, setImageScale,
        previewMode, setPreviewMode,
        logo, setLogo,
        slogan, setSlogan,
        layout, setLayout,
        companyName, setCompanyName,
        backgroundColor, setBackgroundColor,
        imageWidth, setImageWidth,
        editableElement, setEditableElement,
        companyNameColor, setCompanyNameColor,
        horizontalFlip, setHorizontalFlip,
        verticalFlip, setVerticalFlip,
        imageColor, setImageColor,
        logoScale, setLogoScale,
        sloganColor, setSloganColor,
        companyNameScale, setCompanyNameScale,
        sloganScale, setSloganScale,
        companyNameSize, setCompanyNameSize,
        sloganSize, setSloganSize,
        imageHorizontalPosition, setImageHorizontalPosition,
        imageVerticalPosition, setImageVerticalPosition,
        deleteElement, contentPreviewBlockRef,
        previewBlockRef, resetSizeElement,
        resetHorizontalPostitionElement, resetVerticalPostitionElement,
        companyNameHorizontalPosition, setCompanyNameHorizontalPosition,
        companyNameVerticalPosition, setCompanyNameVerticalPosition,
        sloganHorizontalPosition, setSloganHorizontalPosition,
        sloganVerticalPosition, setSloganVerticalPosition,
        handleChangeLayout, companyNameSpacing, setCompanyNameSpacing,
        sloganSpacing, setSloganSpacing, resetLetterSpacing,
        companyNameTransform, setCompanyNameTransform,
        sloganTransform, setSloganTransform, navbarTab, setNavbarTab,
        suggestedType, setSuggestedType, onSelectSuggestedTemplate,
        companyNameFontFamily, setCompanyNameFontFamily,
        sloganFontFamily, setSloganFontFamily, onSelectBackgroundColor,
        generatedLogos, setGeneratedLogos, currentTemplateId,
        setCurrentTemplateId, currentTemplateData, setExpandMobileNavbar,
        setCurrentTemplateData, fetchedIcons, setFetchedIcons, handleUndoChange,
        handleRedoChange, currentHistoryStep, setCurrentHistoryStep,
        historyChanges, setHistoryChanges, expandMobileNavbar,
    }

    return (
        <EditorContext.Provider value={value} >
            {children}
        </EditorContext.Provider >
    );
};
