import React from 'react'
import { Modal } from 'react-bootstrap'

import { DeleteLogoModalBlock } from '../styles/styles'
import { useUnsaveLogoMutation } from '../api/api'

import ModalCloseButton from '../components/ModalCloseButton/ModalCloseButton'

const DeleteLogoModal = ({ show, handleClose, id }) => {
    const [unsaveLogo] = useUnsaveLogoMutation()

    const handleDelete = () => {
        unsaveLogo(id)
        handleClose()
    }

    return (
        <DeleteLogoModalBlock centered show={show} onHide={handleClose}>
            <Modal.Body>
                <h5 className='delete-logo-title'>Delete logo</h5>
                <span className='delete-logo-description'>
                    Are you sure you want to delete this logo? This is a permanent action and cannot be undone.
                </span>
                <div className='delete-logo-actions'>
                    <button onClick={handleClose} className='delete-logo-cancel'>Cancel</button>
                    <button onClick={handleDelete} className='delete-logo-delete'>Delete</button>
                </div>
                <ModalCloseButton handleClose={handleClose} />
            </Modal.Body>
        </DeleteLogoModalBlock>
    )
}

export default DeleteLogoModal