import Template1 from '../assets/template-samples/template1.png'
import Template2 from '../assets/template-samples/template2.png'
import Template3 from '../assets/template-samples/template3.png'
import Template4 from '../assets/template-samples/template4.png'
import Template5 from '../assets/template-samples/template5.png'
import Template6 from '../assets/template-samples/template6.png'
import Template7 from '../assets/template-samples/template7.png'
import Template8 from '../assets/template-samples/template8.png'
import Template9 from '../assets/template-samples/template9.png'

export const FIREBASE_ERRORS_TO_TEXT = {
    'auth/invalid-login-credentials': 'The password or email you entered is incorrect. Please double-check and try again.',
    'auth/weak-password': 'Password Strength Too Low. Your password does not meet the required security criteria. Make sure it has a minimum of 8 characters, including letters, numbers, and symbols.',
    'auth/email-already-in-use': 'Email Address is already in use. This email is already associated with an existing account.',
}

export const PRICE_TO_PLAN = {
    '19.95': 'Basic',
    '47.40': 'Premium',
}

export const PLAN_TO_PRICE = {
    'Basic': '19.95',
    'Premium': '47.40',
}

export const PLANS = [
    {
        id: 1,
        name: 'Basic',
        type: 'Logo Package',
        description: '',
        price: '19.95',
        price_per: null,
        price_for_card: '19.95',
        payment_type: 'One-Time Purchase',
        most_popular: false,
        plan_price_id: 'price_1OObg2Gtupj8Z7jU9GSerIdI',
        paypal_plan_id: null,
        options: [
            {
                id: 1,
                text: 'JPG File Type',
                available: true,
            },
            {
                id: 2,
                text: 'PNG File Type',
                available: false,
            },
            {
                id: 3,
                text: 'SVG File Type',
                available: false,
            },
            {
                id: 4,
                text: 'Full Ownership',
                available: false,
            },
            {
                id: 5,
                text: 'Unlimited Technical Support',
                available: false,
            },
        ]
    },
    {
        id: 2,
        name: 'Premium',
        type: 'Logo Package',
        description: 'Auto-renewal at $47.40 each year. You can cancel this anytime.',
        price: '47.40',
        price_per: '/month',
        price_for_card: '3.95',
        payment_type: 'Billed Annually',
        most_popular: true,
        plan_price_id: 'price_1OObiWGtupj8Z7jUN2oxTvwL',
        paypal_plan_id: 'P-75L840398P5604739MWADKHI',
        options: [
            {
                id: 1,
                text: 'JPG File Type',
                available: true,
            },
            {
                id: 2,
                text: 'PNG File Type',
                available: true,
            },
            {
                id: 3,
                text: 'SVG File Type',
                available: true,
            },
            {
                id: 4,
                text: 'Full Ownership',
                available: true,
            },
            {
                id: 5,
                text: 'Unlimited Technical Support',
                available: true,
            },
        ]
    },
]

export const FAQ_QUESTIONS = [
    {
        id: 1,
        question: 'How do I make a logo with SwiftLogo?',
        answer: `To make a logo with SwiftLogo, start by logging into your SwiftLogo account or signing up if you're a new user. Whether you choose from our selection of templates or start from scratch, SwiftLogo allows you to customize your design with preferred colors, fonts, and icons. Add your business name and other necessary details, then preview your logo to ensure it aligns with your vision. Once satisfied, simply click the 'Download' or 'Purchase' button for high-resolution files.`
    },
    {
        id: 2,
        question: 'How do I save logos?',
        answer: `Saving your logos with SwiftLogo is simple. First, log in to your SwiftLogo account. Navigate to your logo dashboard or the specific project containing the logo you wish to save. Click on the desired logo, and locate the 'Save' or 'Download' option. Choose this option to store your logo on your device or securely within your SwiftLogo account for convenient access.`
    },
    {
        id: 3,
        question: 'Once I receive my purchase, how do I use my logo?',
        answer: `After you purchase a logo through SwiftLogo, you will receive the logo files via email or in your account. To use your logo, simply download the logo files to your device. Then, import the files into your design software and save them for use on your website, business cards, or other marketing materials.`
    },
    {
        id: 4,
        question: 'How do I download a logo?',
        answer: `To download a logo created with SwiftLogo, log in to your account, navigate to your logo dashboard or project, select the desired logo, and click 'Download' to save it to your computer or device.`
    },
    {
        id: 5,
        question: 'How do I make changes to my logo after purchasing?',
        answer: `To make changes to your purchased logo, download the logo files from your SwiftLogo account or email, then open them in your preferred design software (e.g., Adobe Illustrator, Canva, or Photoshop). Once open, edit the logo as needed and save the updated design.`
    },
    {
        id: 6,
        question: 'What is the Brand Kit?',
        answer: `The Brand Kit is a feature provided by SwiftLogo that allows you to store and manage all your branding assets in one place. It typically includes your logo, color palette, fonts, and other design elements. With the Brand Kit, you can maintain a consistent brand identity and easily access your branding materials for various marketing and design projects.`
    },
    {
        id: 7,
        question: 'Do I own the full copyright to my purchased logo?',
        answer: `When you purchase a logo through SwiftLogo, you typically receive a license to use the logo for your business and branding purposes. However, the copyright ownership may vary depending on the terms and conditions of your purchase. It's essential to review the licensing agreement provided with your logo to understand the rights and restrictions associated with it.`
    },
    {
        id: 8,
        question: 'Can I talk to someone?',
        answer: `Yes, you can get in touch with our customer support team for assistance and inquiries. To contact us, please visit our "Contact Us" page on our website or reach out to our support email or phone number. We're here to help and answer any questions you may have.`,
    },
    {
        id: 9,
        question: 'Why won’t my logos load?',
        answer: `If you're encountering difficulties with logos not loading, several factors could be at play, such as internet connectivity issues, browser-related issues, or temporary glitches. To address this, consider the following steps: check and stabilize your internet connection, clear your browser cache and cookies, and ensure your browser is up to date. If the problem persists, please reach out to our customer support team for further assistance.`
    },
]

export const INDUSTRY_OPTIONS = [
    { id: 1, name: 'Academic' },
    { id: 2, name: 'Accessories' },
    { id: 3, name: 'Hair Accessories' },
    { id: 4, name: '3D Printing' },
    { id: 5, name: 'Decor' },
    { id: 6, name: 'Delivery' },
    { id: 7, name: 'Design' },
    { id: 8, name: 'Development' },
    { id: 9, name: 'Alcohol' },
    { id: 10, name: 'Airline' },
    { id: 11, name: 'Animals' },
    { id: 12, name: 'Bakery' },
    { id: 13, name: 'Band' },
    { id: 14, name: 'Basketball' },
    { id: 15, name: 'Banking' },
    { id: 16, name: 'Cafe' },
    { id: 17, name: 'Car Sales' },
    { id: 18, name: 'Care' },
    { id: 19, name: 'Dance' },
    { id: 20, name: 'Videos' },
    { id: 21, name: 'Video Games' },
    { id: 22, name: 'eCommerce' },
    { id: 23, name: 'Accomodation' },
    { id: 24, name: 'Accounting' },
    { id: 25, name: 'Administration' },
    { id: 26, name: 'Adventure' },
    { id: 27, name: 'Advertising' },
    { id: 28, name: 'Analytics' },
    { id: 29, name: 'App' },
    { id: 30, name: 'Architecture' },
    { id: 31, name: 'Artificial Intelligence' },
    { id: 32, name: 'Audio' },
    { id: 33, name: 'Auto' },
    { id: 34, name: 'Bags' },
    { id: 35, name: 'Barber' },
    { id: 36, name: 'Beauty' },
    { id: 37, name: 'Bitcoin' },
    { id: 38, name: 'Building' },
    { id: 39, name: 'Business' },
    { id: 40, name: 'Campaign' },
    { id: 41, name: 'Decoration' },
    { id: 42, name: 'Detailing' },
    { id: 43, name: 'Distribution' },
    { id: 44, name: 'Dog Training' },
    { id: 45, name: 'Engineering' },
    { id: 46, name: 'Instagram' },
    { id: 47, name: 'Social' },
    { id: 48, name: 'Social Media' },
    { id: 49, name: 'Event Management' },
    { id: 50, name: 'Events' },
    { id: 51, name: 'Outdoor' },
    { id: 52, name: 'Healthcare' },
    { id: 53, name: 'Gadgets' },
    { id: 54, name: 'Philosophy' },
    { id: 55, name: 'Videography' },
    { id: 56, name: 'Logistics' },
    { id: 57, name: 'Taxi' },
    { id: 58, name: 'Other' },
]

export const SYMBOL_TYPES_BY_INDUSTRY = {
    'Academic': ['Education', 'Teacher', 'Student', 'Learning', 'School', 'Collage', 'Science', 'Books', 'Creativity', 'Excellence', 'Innovation', 'Abstract', 'Pencil', 'Pen', 'Writing', 'Study'],
    'Accessories': ['Creativity', 'Bag', 'Gifts', 'Perfume', 'Shoes', 'Abstract', 'Style', 'Crown', 'Accessories', 'Diamond', 'Fashion', 'Hat', 'Glasses', 'Tranquility', 'Luxury', 'Strength'],
    'Hair Accessories': ['Hair Salon', 'Creativity', 'Innovation', 'Beauty Salon', 'Heart', 'Hat', 'Beauty', 'Hair', 'Star', 'Female', 'Woman', 'Flower', 'Face', 'Accessories', 'Butterfly', 'Excellence'],
    '3D Printing': ['Print', 'Gear', 'Creativity', 'Tranquility', 'Excellence', 'Letter', 'Computer', '3D', 'Paint', 'Model', 'Shape', 'Square', 'Paper', 'Figure', 'Abstract', 'Innovation'],
    'Decor': ['Creativity', 'Books', 'Candles', 'Excellence', 'Tranquility', 'Abstract', 'Interior Design', 'Plant', 'Vintage', 'Photo', 'Lighting', 'Light', 'Home Decor', 'Flowers', 'Furniture', 'Strength'],
    'Delivery': ['Delivery', 'Creativity', 'Excellence', 'Innovation', 'Boat', 'Square', 'Logistics', 'Airplane', 'Arrow', 'World', 'Transparation', 'Truck', 'Box', 'Road', 'Shipping', 'Product', 'Truck'],
    'Design': ['Creativity', 'Art', 'Graphic', 'Web', 'UI/UX', 'Print', 'Digital', 'Illustration', 'Drawing', 'Color', 'Typography'],
    'Development': ['Software', 'Web', 'Mobile', 'Front-end', 'Back-end', 'Full-stack', 'Coding', 'Programming', 'Debugging', 'Frameworks', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Alcohol': ['Wine', 'Beer', 'Spirits', 'Cocktails', 'Brewery', 'Distillery', 'Tasting', 'Liquor', 'Bar', 'Mixology', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Airline': ['Travel', 'Flight', 'Aircraft', 'Passenger', 'Airport', 'Pilot', 'Cabin Crew', 'Aviation', 'Booking', 'Baggage', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Animals': ['Zoo', 'Wildlife', 'Conservation', 'Pet Care', 'Nature', 'Biodiversity', 'Animal Rescue', 'Veterinary', 'Endangered Species', 'Habitat', 'Animal Welfare', 'Wild Animals', 'Domestic Animals', 'Animal Rights', 'Animal Behavior'],
    'Bakery': ['Bread', 'Pastry', 'Cakes', 'Desserts', 'Oven', 'Flour', 'Baker', 'Sweet', 'Confectionery', 'Cookies', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Band': ['Music', 'Performance', 'Concert', 'Musicians', 'Album', 'Live', 'Rock', 'Pop', 'Genre', 'Songwriting', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Basketball': ['Sports', 'NBA', 'Court', 'Dribbling', 'Scoring', 'Team', 'Basket', 'Player', 'Crossover', 'Rebounding', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Banking': ['Finance', 'Savings', 'Loans', 'Investment', 'Credit', 'Bank Account', 'ATM', 'Transaction', 'Money', 'Financial Services', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Cafe': ['Coffee', 'Tea', 'Cakes', 'Pastries', 'Barista', 'Cafeteria', 'Espresso', 'Latte', 'Menu', 'Ambiance', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Car Sales': ['Automobile', 'Vehicle', 'Dealership', 'Salesman', 'Test Drive', 'Car Models', 'Trade-in', 'Auto Financing', 'Showroom', 'SUV', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Care': ['Health', 'Wellness', 'Nursing', 'Patient', 'Medical', 'Therapy', 'Support', 'Compassion', 'Treatment', 'Rehabilitation', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Dance': ['Dancer', 'Choreography', 'Performance', 'Ballet', 'Hip-hop', 'Salsa', 'Contemporary', 'Rhythm', 'Expression', 'Dance Studio', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Videos': ['Film', 'Editing', 'Cinematography', 'Production', 'Director', 'Casting', 'Storyboard', 'Visual Effects', 'Script', 'Screenplay', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Video Games': ['Gaming', 'Console', 'PC', 'Multiplayer', 'Game Development', 'Gamer', 'Virtual Reality', 'Strategy', 'Adventure', 'Simulation', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'eCommerce': ['Online Shopping', 'Retail', 'Marketplace', 'Payment', 'Shopping Cart', 'E-commerce Platform', 'Product Listing', 'Customer Reviews', 'Digital Marketing', 'E-commerce Website', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Accommodation': ['Hotel', 'Hospitality', 'Lodging', 'Booking', 'Vacation Rental', 'Guesthouse', 'Resort', 'Check-in', 'Amenities', 'Room Service', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Accounting': ['Financial', 'Audit', 'Taxation', 'Bookkeeping', 'Accountant', 'Balance Sheet', 'Income Statement', 'Tax Returns', 'Financial Reporting', 'Budgeting', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Administration': ['Management', 'Organization', 'Office', 'Efficiency', 'Leadership', 'Teamwork', 'Processes', 'Bureaucracy', 'Decision Making', 'Workflow', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Adventure': ['Exploration', 'Thrill', 'Excitement', 'Journey', 'Discovery', 'Challenge', 'Outdoors', 'Travel', 'Expedition', 'Risk-Taking', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Advertising': ['Marketing', 'Promotion', 'Campaign', 'Advertisement', 'Branding', 'Target Audience', 'Creative', 'Media Planning', 'Digital Advertising', 'Advertising Agency', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Analytics': ['Data Analysis', 'Statistics', 'Data Visualization', 'Metrics', 'Insights', 'Big Data', 'Business Intelligence', 'Predictive Analytics', 'Reporting', 'Dashboards', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'App': ['Mobile App', 'Application Development', 'User Interface', 'Features', 'App Store', 'App Design', 'App Launch', 'App Performance', 'User Experience', 'App Marketing', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Architecture': ['Design', 'Blueprints', 'Construction', 'Architectural Styles', 'Structural Engineering', 'Urban Planning', 'Sustainability', 'Building Materials', 'Interior Design', 'Landscaping', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Artificial Intelligence': ['Machine Learning', 'Deep Learning', 'Neural Networks', 'AI Algorithms', 'Natural Language Processing', 'AI Ethics', 'Automation', 'AI Applications', 'Robotics', 'AI Research', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Audio': ['Sound', 'Music', 'Recording', 'Audio Engineering', 'Podcasting', 'Audio Production', 'Sound Effects', 'Voiceover', 'Mixing', 'Audio Equipment', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Auto': ['Automobiles', 'Car Maintenance', 'Auto Parts', 'Car Repairs', 'Auto Dealership', 'Car Brands', 'Vehicle Safety', 'Driving', 'Fuel Efficiency', 'Hybrid Cars', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Bags': ['Fashion', 'Handbags', 'Backpacks', 'Luggage', 'Designer Bags', 'Tote Bags', 'Shoulder Bags', 'Travel Bags', 'Leather Bags', 'Accessories', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Barber': ['Haircut', 'Shaving', 'Barbershop', 'Hairstyling', 'Beard Grooming', 'Hair Care', 'Grooming Products', 'Razor', 'Mens Grooming', 'Barber Tools', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Beauty': ['Skincare', 'Makeup', 'Cosmetics', 'Beauty Products', 'Skin Health', 'Beauty Tips', 'Beauty Routine', 'Glowing Skin', 'Self-care', 'Beauty Industry', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Bitcoin': ['Cryptocurrency', 'Blockchain', 'Digital Currency', 'Bitcoin Mining', 'Crypto Wallet', 'Cryptocurrency Exchange', 'Decentralization', 'Bitcoin Price', 'Blockchain Technology', 'Crypto Investment', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Building': ['Construction', 'Architecture', 'Structural Engineering', 'Design', 'Construction Materials', 'Real Estate', 'Building Codes', 'Sustainable Building', 'Building Maintenance', 'Contractors', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Business': ['Entrepreneurship', 'Management', 'Leadership', 'Startups', 'Business Strategy', 'Marketing', 'Finance', 'Market Research', 'Business Planning', 'Corporate Culture', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Campaign': ['Marketing', 'Political Campaign', 'Advertisement', 'Election', 'Campaign Strategy', 'Campaign Management', 'Voters', 'Campaign Slogans', 'Campaign Events', 'Campaign Finance', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Decoration': ['Interior Design', 'Home Decor', 'Decorative Items', 'Decorating Styles', 'Furniture', 'Decorative Accessories', 'Color Schemes', 'Wall Art', 'DIY Decor', 'Design Inspiration', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Detailing': ['Car Detailing', 'Cleaning', 'Auto Detailing', 'Car Wash', 'Detailing Products', 'Paint Protection', 'Detailing Services', 'Detailing Tools', 'Exterior Detailing', 'Interior Detailing', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Distribution': ['Supply Chain', 'Logistics', 'Distribution Network', 'Inventory Management', 'Warehousing', 'Shipping', 'Distribution Channels', 'Supply Chain Management', 'Distribution Strategy', 'Distribution Centers', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Dog Training': ['Pet Training', 'Obedience Training', 'Dog Behavior', 'Dog Trainer', 'Puppy Training', 'Positive Reinforcement', 'Canine Agility', 'Clicker Training', 'Leash Training', 'Dog Commands', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Engineering': ['Civil Engineering', 'Mechanical Engineering', 'Electrical Engineering', 'Engineering Design', 'Engineering Projects', 'Engineering Principles', 'Innovation', 'Engineering Ethics', 'Engineering Education', 'Engineering Solutions', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Instagram': ['Social Media', 'Photo Sharing', 'Instagram Influencers', 'Instagram Stories', 'Instagram Marketing', 'Instagram Filters', 'Engagement', 'Hashtags', 'Visual Content', 'Instagram Growth', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Social': ['Society', 'Social Issues', 'Community', 'Social Impact', 'Social Change', 'Social Norms', 'Social Behavior', 'Human Interaction', 'Cultural Studies', 'Social Trends', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Social Media': ['Digital Marketing', 'Content Strategy', 'Social Media Management', 'Social Media Advertising', 'Audience Engagement', 'Social Media Platforms', 'Influencer Marketing', 'Social Media Analytics', 'Social Media Tools', 'Online Presence', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Event Management': ['Event Planning', 'Event Coordination', 'Event Marketing', 'Event Production', 'Event Logistics', 'Corporate Events', 'Wedding Planning', 'Event Technology', 'Event Budgeting', 'Event Execution', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Events': ['Conferences', 'Concerts', 'Expos', 'Festivals', 'Trade Shows', 'Weddings', 'Sporting Events', 'Private Events', 'Public Gatherings', 'Event Entertainment', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Outdoor': ['Nature', 'Adventure', 'Hiking', 'Camping', 'Outdoor Activities', 'Exploration', 'Outdoor Gear', 'Wildlife', 'Outdoor Adventure', 'Outdoor Recreation', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Healthcare': ['Medical Care', 'Health Services', 'Healthcare Professionals', 'Patient Care', 'Healthcare Technology', 'Healthcare System', 'Medical Research', 'Health Insurance', 'Wellness', 'Medical Diagnostics', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Gadgets': ['Electronics', 'Tech Gadgets', 'Consumer Electronics', 'Gadget Reviews', 'Smart Devices', 'Tech Accessories', 'Innovative Gadgets', 'Gadget Trends', 'Gadget Innovation', 'Gadget News', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Philosophy': ['Ethics', 'Metaphysics', 'Epistemology', 'Philosophical Theories', 'Philosophical Debates', 'Philosopher', 'Existentialism', 'Philosophical Questions', 'Philosophical Inquiry', 'Philosophy of Mind', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Videography': ['Video Production', 'Cinematography', 'Videographer', 'Video Editing', 'Filmography', 'Camera Techniques', 'Video Shoot', 'Video Storytelling', 'Visual Storytelling', 'Video Equipment', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Logistics': ['Supply Chain', 'Transportation', 'Inventory Management', 'Warehouse', 'Shipping', 'Logistics Network', 'Logistics Solutions', 'Freight', 'Logistics Management', 'Logistics Technology', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Taxi': ['Transportation', 'Ride-Hailing', 'Taxi Service', 'Taxi Driver', 'Taxi Booking', 'Taxi App', 'Taxi Fare', 'City Transportation', 'Airport Taxi', 'Taxi Company', 'Creativity', 'Excellence', 'Innovation', 'Abstract'],
    'Other': ['Abstract', 'Creativity', 'Excellence', 'Innovation', 'Other', 'Election', 'Challenge', 'Books', 'Hair', 'Flower', 'Face', 'Accessories', 'Butterfly', 'Shipping', 'Tech', 'Interior'],
}

export const LOGO_STYLES = [
    { id: 1, name: 'minimalistic', img: Template1, layout: 'top', font_group: 'Display' },
    { id: 2, name: 'playful', img: Template2, layout: 'right', font_group: 'Sans-serif' },
    { id: 3, name: 'sleek', img: Template3, layout: 'top', font_group: 'Serif' },
    { id: 4, name: 'vintage', img: Template4, layout: 'left', font_group: 'Sans-serif' },
    { id: 5, name: 'organic', img: Template5, layout: 'top', font_group: 'Display' },
    { id: 6, name: 'sophisticated', img: Template6, layout: 'top', font_group: 'Script' },
    { id: 7, name: 'vibrant', img: Template7, layout: 'top', font_group: 'Serif' },
    { id: 8, name: 'clean', img: Template8, layout: 'left', font_group: 'Serif' },
    { id: 9, name: 'bold', img: Template9, layout: 'top', font_group: 'Display' },
]

export const COLOR_SCHEMAS = [
    { id: 1, name: 'Red', color: "#EB5757", description: 'Passion, love, energy, warmth, strength, excitement, and determination.' },
    { id: 2, name: 'Orange', color: "#F2994A", description: 'Creativity, enthusiasm, happiness, optimism, energy, and vibrancy.' },
    { id: 3, name: 'Yellow', color: "#F2C94C", description: 'Joy, sunshine, happiness, positivity, intellect, and cheerfulness.' },
    { id: 4, name: 'Blue', color: "#2D9CDB", description: 'Serenity, calmness, trust, depth, stability, and wisdom.' },
    { id: 5, name: 'Teal', color: "#2DDBD1", description: 'Balance, tranquility, calmness, clarity, and sophistication.' },
    { id: 6, name: 'Green', color: "#27AE60", description: 'Nature, growth, harmony, health, renewal, and fertility.' },
    { id: 7, name: 'Purple', color: "#9B51E0", description: 'Royalty, power, nobility, luxury, wealth, extravagance, and wisdom.' },
    { id: 8, name: 'Pink', color: "#F485E9", description: 'Romance, affection, tenderness, love, sweetness, and compassion.' },
    { id: 9, name: 'Greyscale', color: "#94A3B8", description: 'Neutrality, simplicity, elegance, balance, and timelessness.' },
];

export const SYMBOL_CATEGORIES = [
    { id: 1, name: 'Graphic Design' },
    { id: 2, name: 'Marketing' },
    { id: 3, name: 'Art' },
    { id: 4, name: 'Design' },
    { id: 5, name: 'Freelance' },
    { id: 6, name: 'Internet' },
    { id: 7, name: 'Web Design' },
    { id: 8, name: 'Computer' },
    { id: 9, name: 'Designer' },
    { id: 10, name: 'Logo' },
    { id: 11, name: 'Creative' },
    { id: 12, name: 'Developer' },
    { id: 13, name: 'Idea' },
    { id: 14, name: 'Innovation' },
    { id: 15, name: 'Agency' },
    { id: 16, name: 'Communication' },
    { id: 17, name: 'Creator' },
    { id: 18, name: 'Interior Design' },
    { id: 19, name: 'Digital Marketing' },
];

export const SEARCH_SYMBOL_SUGGESTIONS = [
    { id: 1, name: 'Book' },
    { id: 2, name: 'Face' },
    { id: 3, name: 'Phone' },
    { id: 4, name: 'Hand' },
    { id: 5, name: 'Flat' },
    { id: 6, name: 'Service' },
    { id: 7, name: 'Doctor' },
    { id: 8, name: 'Person' },
    { id: 9, name: 'Action' },
    { id: 10, name: 'Sick' },
    { id: 11, name: 'Patch' },
    { id: 12, name: 'Emergency' },
    { id: 13, name: 'Figure' },
    { id: 14, name: 'Arrow' },
    { id: 15, name: 'Message' },
    { id: 16, name: 'Sound' },
    { id: 17, name: 'Video' },
    { id: 18, name: 'Hurt' },
    { id: 19, name: 'Goal' },
    { id: 20, name: 'Notification' },
    { id: 21, name: 'Form' },
    { id: 22, name: 'Device' },
    { id: 23, name: 'Sleep' },
    { id: 24, name: 'Square' },
];

export const SUGGESTED_TEMPLATES_TYPES = [
    { id: 1, name: 'Modern' },
    { id: 2, name: 'Bold' },
    { id: 3, name: 'Classic' },
    { id: 4, name: 'Playful' },
    { id: 5, name: 'Friendly' },
    { id: 6, name: 'Sophisticated' },
]

export const BACKGROUND_COLOR_SCHEMAS = {
    black: ["#000000", "#1a1a1a", "#333333", "#4d4d4d", "#666666", "#808080", "#999999", "#b3b3b3", "#cccccc", "#e6e6e6", "#f2f2f2", "#f7f7f7", "#fbfbfb", "#fdfdfd", "#fefefe"],
    red: ["#FF0000", "#FF1919", "#FF3232", "#FF4C4C", "#FF6565", "#FF7E7E", "#FF9898", "#FFB1B1", "#FFCACA", "#FFE4E4", "#FF0000", "#FF1919", "#FF3232", "#FF4C4C", "#FF6565"],
    orange: ["#FFA500", "#FFB233", "#FFC966", "#FFDC99", "#FFF0CC", "#FFA500", "#FFB233", "#FFC966", "#FFDC99", "#FFF0CC", "#FFA500", "#FFB233", "#FFC966", "#FFDC99", "#FFF0CC"],
    yellow: ["#FFFF00", "#FFFF33", "#FFFF66", "#FFFF99", "#FFFFCC", "#FFFF00", "#FFFF33", "#FFFF66", "#FFFF99", "#FFFFCC", "#FFFF00", "#FFFF33", "#FFFF66", "#FFFF99", "#FFFFCC"],
    green: ["#008000", "#00A300", "#00C600", "#00E900", "#00FF00", "#00B700", "#00D100", "#00EB00", "#00F500", "#00FF15", "#00BF00", "#00D700", "#00ED00", "#00F200", "#00FF07"],
    ocean: ["#006699", "#0088B3", "#0099CC", "#00A6D6", "#00B3E0", "#0091A3", "#00A4B3", "#00B6C3", "#00C4D3", "#00D2E3", "#00AFC6", "#00BCD5", "#00C9E4", "#00D4F0", "#00E1FF"],
    blue: ["#0000FF", "#3333FF", "#6666FF", "#9999FF", "#CCCCFF", "#0000E5", "#3333E5", "#6666E5", "#9999E5", "#CCCCDF", "#0000D2", "#3333D2", "#6666D2", "#9999D2", "#CCCCD2"],
    purple: ["#800080", "#993399", "#AA55AA", "#BB66BB", "#CC77CC", "#8A008A", "#9B339B", "#AE55AE", "#C066C0", "#D177D1", "#950E95", "#A11FA1", "#B631B6", "#C743C7", "#D955D9"],
    pink: ["#FF69B4", "#FF82A8", "#FF9B9C", "#FFB490", "#FFCD84", "#FF77A7", "#FF8F9C", "#FFA794", "#FFC18A", "#FFDA80", "#FF6ABF", "#FF81B3", "#FF99A8", "#FFB09D", "#FFC792"],
}

export const FONT_FAMILY_TYPES = [
    'Inter',
    'ADLaM Display',
    'Montserrat',
    'Rubik Iso',
    'Black Ops One',
    'Playfair Display',
    'Tulpen One',
    'Qwitcher Grypen',
    'Sedgwick Ave Display',
    'Hind Siliguri',
    'Dancing Script',
    'Pacifico',
    'Lobster',
    'Gluten',
    'Tilt Prism',
    'Monoton',
    'Amarante',
    'Anton',
    'Archivo',
    'Crimson Text',
    'Fraunces',
    'Oi',
    'Syne',
    'WindSong',
    'Zen Dots',
    'Young Serif',
    'Caveat',
    'Patua One',
    'El Messiri',
]

export const DOWNLOAD_OPTIONS = [
    'High-resolution export',
    'Exclusive icon library',
    'Transparent backgrounds',
    'Vector file support',
    'Commercial usage rights',
]