import React from 'react'

import { FAQ_QUESTIONS } from '../../utils/constants'
import { FAQBlockWrapper } from '../../styles/styles'

import FAQItem from './FAQItem'

import FaqOutline from '../../assets/icons/faq-ouliner.svg'

const FAQ = () => {
    return (
        <FAQBlockWrapper className='p-[120px] m-auto lg:px-0 lg:py-[40px]'>
            <h5 className='faq-heading'>
                FAQs
                <img className='faq-heading-icon' src={FaqOutline} alt="" />
            </h5>
            <p className='faq-description'>
                Popular questions about our service and plans.
            </p>
            <div className='faq-questions-list'>
                {FAQ_QUESTIONS.map((faq) => (
                    <FAQItem key={faq.id} faq={faq} />
                ))}
            </div>
        </FAQBlockWrapper>
    )
}

export default FAQ