import React from 'react'

import { DashboardBlock } from '../styles/styles'

import DashboardNavigation from '../components/DashboardNavigation/DashboardNavigation'
import DashboardContent from '../components/DashboardContent/DashboardContent'
import Header from '../components/Header/Header'

const Dashboard = () => {
    return (
        <DashboardBlock>
            <Header withBorder={true} />
            <div className='dashboard-wrapper'>
                <DashboardNavigation />
                <DashboardContent />
            </div>
        </DashboardBlock>
    )
}

export default Dashboard