import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './sendGridConfig';

export const sendGridApi = createApi({
    reducerPath: 'sendGridApi',
    baseQuery: baseQuery,
    endpoints: builder => ({
        sendEmail: builder.mutation({
            query: ({ email, domain }) => {
                console.log(email, domain);
                return {
                    url: '',
                    method: 'POST',
                    body: JSON.stringify({
                        personalizations: [
                          {
                            to: [
                              {
                                email: email
                              }
                            ]
                          }
                        ],
                        subject: "SwiftLogo User Signup",
                        template_id: "d-5560d7399be14abaaab0530ecf99aefe",
                        from: {
                          email: "dmytro_palonka@sp-lutsk.com",
                          name: "Dmytro Palonka"
                        }
                    }),
                }
            },
        }),
    }),
});

export const { useSendEmailMutation } = sendGridApi;