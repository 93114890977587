import React, { useEffect, useMemo, useState } from 'react'
import { useTheme } from 'styled-components'
import { useNavigate } from 'react-router-dom';
import RangeSlider from 'react-range-slider-input';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { convertHtmlToCanvas, downloadLogoPreviewFrames } from '../utils/functions';
import { DownloadModalBlock, DownloadModalBody } from '../styles/styles'
import { useSendEmailOnDownloadMutation } from '../api/plunkApi';
import { useEditorContext } from '../context/EditorContext';
import { useViewport } from '../hooks/useViewport';

import MobileModalCloseWhite from '../assets/icons/modal-mobile-close-white.svg'
import MobileModalCloseBlack from '../assets/icons/modal-mobile-close-black.svg'
import DropdownFormatArrow from '../assets/icons/dropdown-format-arrow.svg'
import PreviewFrameThree from '../assets/preview/preview-frame-three.png'
import PreviewFrameFour from '../assets/preview/preview-frame-four.png'
import PreviewFrameOne from '../assets/preview/preview-frame-one.png'
import PreviewFrameTwo from '../assets/preview/preview-frame-two.png'
import DownloadCloseDark from '../assets/icons/download-close-dark.svg'
import CloseWhite from '../assets/icons/close-white.svg'
import { useDownloadDoneMutation } from '../api/api';

const DownloadModal = ({ show, handleClose }) => {
    const [selectedFormat, setSelectedFormat] = useState(null)
    const [showFormatDropdown, setShowFormatDropdown] = useState(false)
    const [previewMode, setPreviewMode] = useState(false)
    const [selectedFrames, setSelectedFrames] = useState([])
    const [logoSize, setLogoSize] = useState(500)
    const [logoElement, setLogoElement] = useState('')
    const [showMobileUpgrade, setShowMobileUpgrade] = useState(false)
    const [userPlan, setUserPlan] = useState('Free')
    const profile = useSelector((state) => state.app.profile)

    const navigate = useNavigate()

    const { isDark } = useTheme()
    const { isMobile } = useViewport()
    const {
        previewBlockRef, logo, companyName, slogan,
        imageColor, editableElement, sloganColor,
        companyNameColor, horizontalFlip, verticalFlip, sloganFontFamily,
        companyNameFontFamily, companyNameHorizontalPosition,
        companyNameVerticalPosition, sloganHorizontalPosition,
        sloganVerticalPosition, layout, currentTemplateData,
        sloganTransform, companyNameTransform
    } = useEditorContext()

    const [sendEmailOnDownload] = useSendEmailOnDownloadMutation()
    const [downloadedLogo] = useDownloadDoneMutation()

    const handleSelectFormat = (format) => {
        setSelectedFormat(format)
        setShowFormatDropdown(false)
    }

    const handleSizeChange = (values) => {
        setLogoSize(values[1])
    }

    const handleDownloadFrames = () => {
        downloadLogoPreviewFrames(selectedFrames)
    }

    const handleDownloadLogo = () => {
        if (profile?.plan === 'Free' && currentTemplateData.is_bought === false) {
            navigate('/plans?download=true')
        } else {
            let format = ''

            switch (selectedFormat) {
                case 'JPG (best for sharing)': {
                    format = 'jpg'
                    break;
                }
                case 'PNG (best for transparency)': {
                    format = 'png'
                    break;
                }
                case 'SVG (best for web)': {
                    format = 'svg'
                    break;
                }
                default: {
                    format = 'png'
                    break;
                }
            }

            if (previewBlockRef.current) {
                convertHtmlToCanvas(previewBlockRef.current, format, true)
            }

            if (profile?.downloads_done === 0) {
                sendEmailOnDownload({ email: profile?.email, domain: window.location.host })
            }

            if (!currentTemplateData?.is_bought) {
                downloadedLogo({ logo_id: currentTemplateData?.id })
            }
        }
    }

    const handleSelectFrame = (frame) => {
        if (selectedFrames.includes(frame)) {
            const filteredFrames = selectedFrames.filter((el) => el !== frame)

            setSelectedFrames(filteredFrames)
        } else {
            setSelectedFrames([...selectedFrames, frame])
        }
    }

    const previewImage = useMemo(() => {
        return (
            <div
                style={{
                    width: `100px`,
                    height: `100px`,
                    backgroundColor: `transparent`,
                    flexDirection: `${layout === 'top' ? 'column' : layout === 'bottom' ? 'column-reverse' : layout === 'left' ? 'row' : 'row-reverse'}`,
                    gap: '2px',
                }}
                className='preview-image-block'
            >
                {logo && (
                    <div
                        className={`generated-logo-icon ${editableElement === 'image' ? 'editable' : ''}`}
                    >
                        <img
                            draggable={false}
                            style={{
                                transform: `rotateX(${horizontalFlip ? '180deg' : '0deg'}) rotateY(${verticalFlip ? '180deg' : '0deg'})`,
                                width: `12px`,
                                minHeight: '10px',
                            }}
                            src={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(logoElement)}`}
                            alt=""
                        />
                    </div>
                )}
                <div className='generated-logo-text'>
                    <span
                        style={{
                            color: `${companyNameColor}`,
                            fontFamily: `${companyNameFontFamily}`,
                            fontSize: `5px`,
                            transform: `translateX(${companyNameHorizontalPosition}px) translateY(${companyNameVerticalPosition}px)`,
                            textTransform: `${companyNameTransform}`,
                            textAlign: `${layout === 'top' ? 'center' : layout === 'bottom' ? 'center' : layout === 'left' ? 'start' : 'end'}`,
                        }}
                        className={`generated-logo-company-name ${editableElement === 'company' ? 'editable' : ''}`}
                    >
                        {companyName}
                    </span>
                    {slogan && (
                        <span
                            style={{
                                color: `${sloganColor}`,
                                fontFamily: `${sloganFontFamily}`,
                                fontSize: `3px`,
                                transform: `translateX(${sloganHorizontalPosition}px) translateY(${sloganVerticalPosition}px)`,
                                textTransform: `${sloganTransform}`,
                                textAlign: `${layout === 'top' ? 'center' : layout === 'bottom' ? 'center' : layout === 'left' ? 'start' : 'end'}`,
                            }}
                            className={`generated-logo-slogan ${editableElement === 'slogan' ? 'editable' : ''}`}
                        >
                            {slogan}
                        </span>
                    )}
                </div>
            </div>
        )
    }, [logoElement])

    useEffect(() => {
        if (profile && profile.plan) {
            if (profile.plan.includes('Basic')) {
                setUserPlan('Basic')
            } else if (profile.plan.includes('Premium')) {
                setUserPlan('Premium')
            } else {
                setUserPlan('Free')
            }
        }
    }, [profile])

    useEffect(() => {
        if (logo) {
            let newStr = ''
            let splittedLogo = logo.split('<path')

            if (splittedLogo.length > 0) {
                splittedLogo.forEach((string, index) => {
                    if (index === 0) {
                        newStr += string
                    } else {
                        newStr += `<path fill='${imageColor}' ${string}`
                    }
                })
            }

            setLogoElement(`${newStr}`.replaceAll('  ', ' '))
        }
    }, [logo, imageColor])

    return (
        <DownloadModalBlock centered show={show} onHide={handleClose} plan={profile?.plan}>
            <DownloadModalBody plan={profile?.plan}>
                {((isMobile && !showMobileUpgrade) || !isMobile) && (
                    <div className='left-side'>
                        <h5 className='left-side-heading'>{previewMode ? 'Preview your logo' : 'Download your logo'}</h5>
                        {!previewMode && (
                            <p className='left-side-label'>Nailed the logo – a masterpiece in design! 👌</p>
                        )}
                        <div className='left-side-format-dropdown-wrapper'>
                            <div
                                onClick={() => setShowFormatDropdown(!showFormatDropdown)}
                                className='format-dropdown-button'>
                                {selectedFormat ? selectedFormat : <span className='format-dropdown-placeholder'>Select a file type</span>}
                                <img style={{ transform: `rotate(${showFormatDropdown ? '180deg' : '0deg'})` }} src={DropdownFormatArrow} alt="" />
                            </div>
                            {showFormatDropdown && (
                                <div className='format-dropdown-list'>
                                    {userPlan === 'Free' || userPlan === 'Premium' ? (
                                        <>
                                            <div onClick={() => handleSelectFormat('JPG (best for sharing)')} className='format-dropdown-item'>
                                                JPG (best for sharing)
                                            </div>
                                            <div onClick={() => handleSelectFormat('PNG (best for transparency)')} className='format-dropdown-item'>
                                                PNG (best for transparency)
                                            </div>
                                            <div onClick={() => handleSelectFormat('SVG (best for web)')} className='format-dropdown-item'>
                                                SVG (best for web)
                                            </div>
                                        </>
                                    ) : (
                                        <div onClick={() => handleSelectFormat('JPG (best for sharing)')} className='format-dropdown-item'>
                                            JPG (best for sharing)
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {previewMode ? (
                            <div className='preview-block-wrapper'>
                                <span className='left-side-preview-popular-label'>Popular</span>
                                <div className='preview-images-block'>
                                    <div id="preview-image-inserted-logo1" onClick={() => handleSelectFrame(1)} className={`preview-image-item ${selectedFrames.includes(1) ? 'selected' : ''}`}>
                                        <img src={PreviewFrameOne} alt="" />
                                        <div className='preview-image-inserted-logo-one'>
                                            {previewImage}
                                        </div>
                                    </div>
                                    <div id="preview-image-inserted-logo2" onClick={() => handleSelectFrame(2)} className={`preview-image-item ${selectedFrames.includes(2) ? 'selected' : ''}`}>
                                        <img src={PreviewFrameTwo} alt="" />
                                        <div className='preview-image-inserted-logo-two'>
                                            {previewImage}
                                        </div>
                                    </div>
                                    <div id="preview-image-inserted-logo3" onClick={() => handleSelectFrame(3)} className={`preview-image-item ${selectedFrames.includes(3) ? 'selected' : ''}`}>
                                        <img src={PreviewFrameThree} alt="" />
                                        <div className='preview-image-inserted-logo-three'>
                                            {previewImage}
                                        </div>
                                    </div>
                                    <div id="preview-image-inserted-logo4" onClick={() => handleSelectFrame(4)} className={`preview-image-item ${selectedFrames.includes(4) ? 'selected' : ''}`}>
                                        <img src={PreviewFrameFour} alt="" />
                                        <div className='preview-image-inserted-logo-four'>
                                            {previewImage}
                                        </div>
                                    </div>
                                </div>
                                <button disabled={selectedFrames.length > 0 ? false : true} onClick={handleDownloadFrames} className='left-side-download-button'>Download Image</button>
                                <span onClick={() => setPreviewMode(false)} className='back-to-download'>
                                    <MdKeyboardArrowLeft size={22} fill={isDark ? '#FFCB03' : '#027FE3'} />
                                    Back to download
                                </span>
                            </div>
                        ) : (
                            <>
                                <div className='left-side-size-multiplier'>
                                    <span className='size-multiplier-title'>Size x</span>
                                    <div className='size-multiplier-value'>1</div>
                                </div>
                                <div className='left-size-size-range'>
                                    <RangeSlider
                                        className="single-thumb navigation-logo-scale-input"
                                        defaultValue={[100, 500]}
                                        min={100}
                                        max={1000}
                                        step={1}
                                        value={[100, logoSize]}
                                        thumbsDisabled={[true, false]}
                                        rangeSlideDisabled={true}
                                        onInput={handleSizeChange}
                                    />
                                    <span className='size-in-pixels-value'>{logoSize} x {logoSize}</span>
                                </div>
                                <button disabled={selectedFormat ? false : true} onClick={handleDownloadLogo} className='left-side-download-button'>Download</button>
                            </>
                        )}
                    </div>
                )}
                {isMobile ? (
                    <>
                        {isDark ? (
                            <img onClick={handleClose} className='close-icon' src={MobileModalCloseWhite} alt="" />
                        ) : (
                            <img onClick={handleClose} className='close-icon' src={MobileModalCloseBlack} alt="" />
                        )}
                    </>
                ) : (
                    <>
                        {isDark ? (
                            <img onClick={handleClose} className='close-icon' src={CloseWhite} alt="" />
                        ) : (
                            <>
                                <img onClick={handleClose} className='close-icon' src={DownloadCloseDark} alt="" />
                            </>
                        )}
                    </>
                )}
            </DownloadModalBody>
        </DownloadModalBlock>
    )
}

export default DownloadModal