import React, { useEffect, useRef, useState } from 'react'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { IoIosArrowDown } from 'react-icons/io'
import { LuAlignLeft, LuImage, LuPaintBucket } from 'react-icons/lu'
import { TbSquareLetterT } from 'react-icons/tb'

import { useEditorContext } from '../../context/EditorContext'
import { EditorNavbarBlock } from '../../styles/styles'
import { useViewport } from '../../hooks/useViewport'
import { useTheme } from 'styled-components'

import EditorNavbarSuggested from './EditorNavbarSuggested'
import EditorNavbarBackground from './EditorNavbarBackground'
import EditorNavbarSlogan from './EditorNavbarSlogan'
import EditorNavbarName from './EditorNavbarName'
import EditorNavbarSymbol from './EditorNavbarSymbol'

import NavbarLineWhite from '../../assets/icons/navbar-line-white.svg'
import NavbarLineBlack from '../../assets/icons/navbar-line-black.svg'

const EditorNavbar = ({ defaultCompanyName, defaultSlogan }) => {
    const [showSuggestedTypes, setShowSuggestedTypes] = useState(false)

    const suggestedDropdownRef = useRef(null)

    const { isDark } = useTheme()
    const { isMobile } = useViewport()

    const {
        navbarTab, setNavbarTab, suggestedType, setSuggestedType,
        setEditableElement, expandMobileNavbar, setExpandMobileNavbar,
    } = useEditorContext()

    const handleSelectSuggestionType = (name) => {
        setSuggestedType(name)
        setShowSuggestedTypes(false)
    }

    const handleSelectNavbarTab = (tab) => {
        if (navbarTab === tab) {
            setNavbarTab('')
        } else {
            setNavbarTab(tab)

            if (tab === 'Slogan') {
                setEditableElement('slogan')
            } else if (tab === 'Symbol') {
                setEditableElement('image')
            } else if (tab === 'Name') {
                setEditableElement('company')
            } else {
                setEditableElement('')
            }
        }
    }

    useEffect(() => {
        const handleClickOutsideToolbar = (event) => {
            if (suggestedDropdownRef.current && !suggestedDropdownRef.current.contains(event.target)) {
                setShowSuggestedTypes(false)
            }
        };
        document.addEventListener('mousedown', handleClickOutsideToolbar);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideToolbar);
        };
    }, [suggestedDropdownRef]);

    return (
        <EditorNavbarBlock>
            <div onClick={() => setExpandMobileNavbar(!expandMobileNavbar)} className='editor-navbar-expand-line'>
                {isDark ? (
                    <img src={NavbarLineWhite} alt="" />
                ) : (
                    <img src={NavbarLineBlack} alt="" />
                )}
            </div>
            {(!isMobile || expandMobileNavbar) && (
                <div className='editor-navabar-tabs-list'>
                    <div className={`editor-navbar-tab-item ${navbarTab === 'Suggested' ? 'active' : ''}`}>
                        <div onClick={() => handleSelectNavbarTab('Suggested')} className={`editor-navbar-tab-header`}>
                            <div className='editor-navbar-tab-name-block'>
                                <HiOutlineLightBulb size={26} />
                                Suggested
                            </div>
                            <IoIosArrowDown size={26} style={{ transform: `rotate(${navbarTab === 'Suggested' ? '180deg' : '0deg'})` }} />
                        </div>
                        {navbarTab === 'Suggested' && (
                            <EditorNavbarSuggested
                                suggestedDropdownRef={suggestedDropdownRef}
                                suggestedType={suggestedType}
                                showSuggestedTypes={showSuggestedTypes}
                                setShowSuggestedTypes={setShowSuggestedTypes}
                                handleSelectSuggestionType={handleSelectSuggestionType}
                                defaultCompanyName={defaultCompanyName}
                                defaultSlogan={defaultSlogan}
                            />
                        )}
                    </div>
                    {/* <div className={`editor-navbar-tab-item ${navbarTab === 'Layout' ? 'active' : ''}`}>
                    <div onClick={() => handleSelectNavbarTab('Layout')} className={`editor-navbar-tab-header`}>
                        <div className='editor-navbar-tab-name-block'>
                            <BiLayer size={26} />
                            Layout
                        </div>
                        <IoIosArrowDown size={26} style={{ transform: `rotate(${navbarTab === 'Layout' ? '180deg' : '0deg'})` }} />
                    </div>
                </div>
                <div className={`editor-navbar-tab-item ${navbarTab === 'Palette' ? 'active' : ''}`}>
                    <div onClick={() => handleSelectNavbarTab('Palette')} className={`editor-navbar-tab-header`}>
                        <div className='editor-navbar-tab-name-block'>
                            <MdOutlinePalette size={26} />
                            Palette
                        </div>
                        <IoIosArrowDown size={26} style={{ transform: `rotate(${navbarTab === 'Palette' ? '180deg' : '0deg'})` }} />
                    </div>
                </div>
                <div className={`editor-navbar-tab-item ${navbarTab === 'More Ideas' ? 'active' : ''}`}>
                    <div onClick={() => handleSelectNavbarTab('More Ideas')} className={`editor-navbar-tab-header`}>
                        <div className='editor-navbar-tab-name-block'>
                            <SlMagicWand size={22} />
                            More Ideas
                        </div>
                        <IoIosArrowDown size={26} style={{ transform: `rotate(${navbarTab === 'More Ideas' ? '180deg' : '0deg'})` }} />
                    </div>
                </div> */}
                    <div className={`editor-navbar-tab-item ${navbarTab === 'Background' ? 'active' : ''}`}>
                        <div onClick={() => handleSelectNavbarTab('Background')} className={`editor-navbar-tab-header`}>
                            <div className='editor-navbar-tab-name-block'>
                                <LuPaintBucket size={26} />
                                Background
                            </div>
                            <IoIosArrowDown size={26} style={{ transform: `rotate(${navbarTab === 'Background' ? '180deg' : '0deg'})` }} />
                        </div>
                        {navbarTab === 'Background' && (
                            <EditorNavbarBackground />
                        )}
                    </div>
                    <div className={`editor-navbar-tab-item ${navbarTab === 'Name' ? 'active' : ''}`}>
                        <div onClick={() => handleSelectNavbarTab('Name')} className={`editor-navbar-tab-header`}>
                            <div className='editor-navbar-tab-name-block'>
                                <TbSquareLetterT size={26} />
                                Name
                            </div>
                            <IoIosArrowDown size={26} style={{ transform: `rotate(${navbarTab === 'Name' ? '180deg' : '0deg'})` }} />
                        </div>
                        {navbarTab === 'Name' && (
                            <EditorNavbarName />
                        )}
                    </div>
                    <div className={`editor-navbar-tab-item ${navbarTab === 'Slogan' ? 'active' : ''}`}>
                        <div onClick={() => handleSelectNavbarTab('Slogan')} className={`editor-navbar-tab-header`}>
                            <div className='editor-navbar-tab-name-block'>
                                <LuAlignLeft size={26} />
                                Slogan
                            </div>
                            <IoIosArrowDown size={26} style={{ transform: `rotate(${navbarTab === 'Slogan' ? '180deg' : '0deg'})` }} />
                        </div>
                        {navbarTab === 'Slogan' && (
                            <EditorNavbarSlogan />
                        )}
                    </div>
                    <div className={`editor-navbar-tab-item ${navbarTab === 'Symbol' ? 'active' : ''}`}>
                        <div onClick={() => handleSelectNavbarTab('Symbol')} className={`editor-navbar-tab-header`}>
                            <div className='editor-navbar-tab-name-block'>
                                <LuImage size={26} />
                                Symbol
                            </div>
                            <IoIosArrowDown size={26} style={{ transform: `rotate(${navbarTab === 'Symbol' ? '180deg' : '0deg'})` }} />
                        </div>
                        {navbarTab === 'Symbol' && (
                            <EditorNavbarSymbol />
                        )}
                    </div>
                    {/* <div className={`editor-navbar-tab-item ${navbarTab === 'Container' ? 'active' : ''}`}>
                    <div onClick={() => handleSelectNavbarTab('Container')} className={`editor-navbar-tab-header`}>
                        <div className='editor-navbar-tab-name-block'>
                            <TbContainer size={26} />
                            Container
                        </div>
                        <IoIosArrowDown size={26} style={{ transform: `rotate(${navbarTab === 'Container' ? '180deg' : '0deg'})` }} />
                    </div>
                    {navbarTab === 'Container' && (
                        <EditorNavbarBackground />
                    )}
                </div> */}
                </div>
            )}
        </EditorNavbarBlock>
    )
}

export default EditorNavbar