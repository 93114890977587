import React from 'react'
import { Modal } from 'react-bootstrap'

import { DeletePaymentMethodModalBlock } from '../styles/styles'
import { useDeletePaymentMethodMutation } from '../api/api'

import ModalCloseButton from '../components/ModalCloseButton/ModalCloseButton'

const DeletePaymentMethodModal = ({ show, handleClose, id, setEditCardElement }) => {
    const [deletePaymentMethod] = useDeletePaymentMethodMutation()

    const handleDeletePaymentMethod = () => {
        deletePaymentMethod(id).then((res) => {
            if ('error' in res) {

            } else {
                setEditCardElement('')
                handleClose()
            }
        })
    }

    return (
        <DeletePaymentMethodModalBlock centered show={show} onHide={handleClose}>
            <Modal.Body>
                <h5 className='send-invoice-title'>Delete payment method</h5>
                <span className='send-invoice-description'>
                    Are you sure you want to delete this payment method? This is a permanent action and cannot be undone.
                </span>
                <div className='send-invoice-actions'>
                    <button onClick={handleClose} className='send-invoice-cancel'>Cancel</button>
                    <button onClick={handleDeletePaymentMethod} className='send-invoice-delete'>Confirm</button>
                </div>
                <ModalCloseButton handleClose={handleClose} />
            </Modal.Body>
        </DeletePaymentMethodModalBlock>
    )
}

export default DeletePaymentMethodModal