import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { slide as Menu } from 'react-burger-menu';

export const darkTheme = {
    isDark: true
}

export const whiteTheme = {
    isDark: false,
}

export const PageWrapper = styled.div`
    width: 100%;
    padding-top: 84px;
    overflow: hidden;

    @media (max-width: 1200px) {
        padding-top: 75px;
    }
`

export const LogoMakerHeaderBlock = styled.header`
    background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};

    &.header-with-border {
        border-bottom: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
    }

    .menu-icon {
        font-size: 40px;
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
    }
`

export const LogoEditorHeaderBlock = styled.header`
    grid-area: header;
    background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
    border-bottom: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};

    .logo-editor-back {
        cursor: pointer;
    }

    .sign-up-button {
        display: flex;
        padding: 15px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #BEE0EA;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .download-button {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .download-button:hover {
        background: #027FE3;
        color: #FFF;
    }

    .share-button {
        display: flex;
        padding: 15px 16px;
        align-items: center;
        gap: 10px;
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .bm-menu-wrap {
        width: 280px !important;
    }

    @media (max-width: 1200px) {
        .share-button {
            display: flex;
            padding: 15px 8px;
            align-items: center;
            gap: 10px;
            color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
        }
    }
`

export const HeaderBlock = styled.header`
    background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#F0F0F0'};
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;

    &.header-with-border {
        border-bottom: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
    }

    .menu-icon {
        font-size: 40px;
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
    }
`

export const HeaderLogoBlock = styled(Link)`
    padding: 16px 8px;
    display: flex;
    gap: 4px;
    font-size: 18px;
    font-family: "Poppins";
    color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    cursor: pointer;
    margin-right: 16px;
    position: relative;

    img {
        position: absolute;
        left: 0px;
        top: calc(50% - 3px);
        transform: translateY(-50%);
    }

    span {
        margin-left: 15px;
    }
`

export const HeaderMenuBlock = styled(Menu)`
    background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};

    .bm-menu {
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        padding: 18px 26px;
    }

    .bm-item-list {
        padding: 34px 0;
        padding-top: 50px;
        justify-content: start;
        gap: 40px;
    }

    .menu-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .header-menu-link {
        display: flex;
        padding: 10px 8px;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        border-radius: 8px;
        cursor: pointer;
    }

    .header-menu-link:hover {
        background-color: #FFCB03;
        color: #1E1E1E;
    }

    .header-menu-link:hover img {
        filter: invert(207%) sepia(43%) saturate(0%) hue-rotate(175deg) brightness(6%) contrast(84%);
    }

    .menu-close {
        background-color: transparent;
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
    }

    .active-menu-link {
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-weight: 500;
    }

    .header-menu-user {
        position: absolute;
        left: 24px;
        top: 13px;
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
        display: flex !important;
        flex-direction: row !important;
        align-items: center;
        gap: 8px;
    }

    .avatar-navigation {
        width: 38px;
        height: 38px;
        border-radius: 38px;
    }
`

export const HeaderLink = styled(Link)`
    font-size: 18px;
    font-weight: 500;
    color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
    font-family: "Inter";
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.18px;
`

export const HeaderLoginButton = styled.button`
    display: flex;
    padding: 15px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.18px;
`

export const GetStartedTopBlock = styled.div`
    width: 100%;
    background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#F0F0F0'};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    height: calc(100vh - 84px);

    .text-wrapper {
        margin-right: 20%;
        position: relative;
        z-index: 2;
    }

    .corner-image {
        position: absolute;
        top: -25px;
        left: -55px;
        z-index: 0;
    }

    @media (max-width: 1300px) {
        .text-wrapper {
            margin-right: 11%;
        }
    }

    @media (max-width: 1200px) {
        flex-direction: column;
        height: auto;
        padding-top: 32px;

        .text-wrapper {
            margin: auto;
        }

        .corner-image {
            display: none;
        }
    }
`

export const GetStartedTopTextWrapper = styled.div`
    color: ${props => props.theme.isDark ? '#FFF' : '#141414'};
    font-family: "Almarai";
    font-size: 82px;
    font-style: normal;
    font-weight: 400;
    line-height: 104.2%;
    letter-spacing: -1.92px;
    max-width: 880px;
    width: 100%;

    * {
        color: ${props => props.theme.isDark ? '#FFF' : '#141414'};
        font-family: "Almarai";
        font-size: 82px;
        font-style: normal;
        font-weight: 400;
        line-height: 104.2%;
        letter-spacing: -1.92px;
    }

    .different-text {
        color: ${props => props.theme.isDark ? '#FFF' : '#141414'};
        font-family: "Gilda Display";
        font-size: 86px;
        font-style: normal;
        font-weight: 400;
        line-height: 44.2%;
        letter-spacing: -4px;
    }

    .mobile-text {
        display: none;
    }

    .mobile-normal-text-get-started {
        display: none;
    }

    @media (max-width: 1200px) {
        font-size: 42px;
        text-align: center;
        margin: auto;
        padding: 0 16px;
        width: fit-content;

        .mobile-normal-text-get-started {
            color: ${props => props.theme.isDark ? '#FFF' : '#141414'};
            font-family: "Poppins";
            font-size: 42px;
            font-style: normal;
            font-weight: 400;
            line-height: 94.2%;
            letter-spacing: -1.92px;
            display: flex;
            align-items: center;
            gap: 6px;
            justify-content: center;
        }

        * {
            color: ${props => props.theme.isDark ? '#FFF' : '#141414'};
            font-family: "Almarai";
            font-size: 42px;
            font-style: normal;
            font-weight: 400;
            line-height: 94.2%;
            letter-spacing: -1.92px;
        }

        .desktop-text {
            display: none;
        }

        .mobile-text {
            display: block;
        }

        .different-text {
            color: ${props => props.theme.isDark ? '#FFF' : '#141414'};
            font-family: "Neuton";
            font-size: 52px;
            font-style: normal;
            font-weight: 200;
            line-height: 100.2%;
            letter-spacing: -2.12px;
        }
    }
`

export const HomeGuideBlock = styled.div`
    background-color: ${props => props.theme.isDark && props.home ? '#2E2E2E' : props.theme.isDark && !props.home ? '#1E1E1E' : '#FFF'};
    width: 100%;

    .home-guide-row-reverse {
        flex-direction: row-reverse;
    }

    .theme-text-color {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
    }

    .get-started-button {
        display: flex;
        height: 54px;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .first-mobile {
        display: none;
    }

    .first-desktop {
        display: block;
    }

    @media (max-width: 1200px) {
        .home-guide-row-reverse {
            flex-direction: column;
        }

        .block-guide-title {
            flex-direction: column;
            align-items: flex-start;
        }

        .mobile-wrapper {
            padding-top: 80px;
        }

        .first-mobile {
            display: block;
        }

        .first-desktop {
            display: none;
        }
    }
`

export const FooterBlock = styled.footer`
    background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
    width: 100%;

    .theme-text-color {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
    }

    @media (max-width: 1200px) {
        padding-bottom: ${props => props.inPlans ? '70px' : '0px'};
    }
`

export const AuthModalBlock = styled(Modal)`
    .modal-dialog {
        min-width: unset;
        max-width: 800px;
        width: 100%;
    }

    @media (max-width: 1200px) {
        .modal-dialog {
            width: unset;
        }
    }
`

export const AuthModalBody = styled(Modal.Body)`
    background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#FFF'};
    color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
    display: grid;
    grid-template-columns: 1.9fr 1fr;
    padding: 0;

    .left-side {
        padding: 80px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .heading {
        font-family: "Poppins";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: 0.36px;
    }

    .description {
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        margin-bottom: 24px;
    }

    .inputs-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 8px;
        margin-bottom: 24px;
    }

    .input {
        display: flex;
        padding: 16px;
        align-items: flex-start;
        gap: 10px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 8px;
        border: 2px solid #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        width: 100%;
        background: ${props => props.theme.isDark ? '#2E2E2E' : 'transparent'};
    }

    .input:focus {
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
    }

    .input::placeholder {
        color:#A0A0A0;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .socials-auth-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 8px;
        margin-bottom: 24px;
    }

    .social-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 100%;
        border-radius: 8px;
        border: ${props => props.theme.isDark ? '1px solid #5E5E5E' : '1px solid #E5E5E5'};
        text-align: center;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
        position: relative;
    }

    .social-button-icon {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
    }

    .auth-terms-link {
        color: ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'};
        text-align: center;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }

    .auth-underline-with-or {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 26px;
        margin-bottom: 24px;
        border-top: 1px solid #A0A0A0;
        width: 100%;
        position: relative;
    }

    .underline-or-text {
        width: 40px;
        color: #A0A0A0;
        text-align: center;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
        position: absolute;
        z-index: 2;
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#FFF'};
    }

    .auth-continue-guest {
        display: flex;
        align-items: center;
        gap: 8px;
        color: ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'};
        text-align: center;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
    }

    .right-side {
        position: relative;
    }

    .auth-right-image {
        object-fit: cover;
        width: 100%;
        height: calc(100% + 1px);
        margin-top: -1px;
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }

    .submit-button {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        margin-bottom: 24px;
    }

    .auth-mobile-close {
        display: none;
    }

    .login-form-error {
        width: 100%;
        text-align: center;
        margin-bottom: 24px;
        color: #EF4444;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .input-error {
        border: 2px solid #EF4444;
    }

    .auth-switch-block {
        display: flex;
        align-items: center;
        gap: 4px;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
    }

    .password-show-hide-icon {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        cursor: pointer;
    }

    @media (max-width: 1200px) {
        display: grid;
        grid-template-columns: 1fr;
        
        .right-side {
            display: none;
        }

        .auth-mobile-close {
            display: flex;
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
    }
`

export const PlansBlockWrapper = styled.div`
    background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
    color: ${props => props.theme.isDark ? '#FFF' : '#0F172A'};

    .plans-heading {
        text-align: center;
        font-family: "​​Gilda Display";
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -0.64px;
        margin-bottom: 16px;
    }

    .heading-different-text {
        font-family: "Almarai";
        font-size: 56px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.28px;
        margin-left: 12px;
        position: relative;
    }

    .heading-circle-image {
        position: absolute;
        width: 141px;
        top: 8px;
        right: 0;
    }
    

    .plans-description {
        text-align: center;
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
        margin-bottom: 110px;
    }

    .plans-list {
        display: flex;
        justify-content: center;
        gap: 24px;
        max-width: 1200px;
        margin: 0 auto;
    }

    .upgrade-plan-new-mobile-block {
        display: none;
    }

    @media (max-width: 1200px) {
        .plans-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 16px;
        }

        .upgrade-plan-new-mobile-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            width: 100%;
            padding: 0 4px;
        }

        .upgrade-plan-new-plans-list {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 4px;
            border-radius: 16px;
            border: 1px solid ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
            background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        }

        .upgrade-plan-new-mobile-item {
            display: flex;
            padding: 16px;
            width: 100%;
            gap: 16px;
            justify-content: space-between;
            align-items: center;
            border: 2px solid transparent;
        }

        .upgrade-plan-new-mobile-item-active {
            display: flex;
            padding: 16px;
            width: 100%;
            gap: 16px;
            justify-content: space-between;
            align-items: center;
            border-radius: 8px;
            border: 2px solid #FFCB03;
            background: ${props => props.theme.isDark ? 'transparent' : '#FFFFFF'};
        }

        .upgrade-plan-new-small-padding {
            padding: 4px;
        }

        .upgrade-plan-new-mobile-item-price {
            color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
            font-family: "Inter";
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.26px;
        }

        .upgrade-plan-new-mobile-item-name {
            color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.16px;
        }

        .upgrade-plan-new-mobile-item-text-block {
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: calc(100% - 36px);
        }

        .upgrade-plan-new-mobile-item-price-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .upgrade-plan-new-mobile-item-best {
            display: flex;
            height: 24px;
            padding: 8px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 100px;
            border: 1px solid #FFCB03;
            color: #FFCB03;
            font-family: "Inter";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.12px;
        }

        .upgrade-plan-new-mobile-select-icon-inactive {
            display: flex;
            width: 20px;
            height: 20px;
            justify-content: center;
            align-items: center;
            border: 2px solid #BEC5CF;
            border-radius: 100px;
        }

        .upgrade-plan-new-mobile-item-save {
            display: flex;
            height: 24px;
            padding: 8px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 100px;
            border: 1px solid #27AE60;
            color: #27AE60;
            font-family: "Inter";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
        }

        .upgrade-plan-new-mobile-choose-button-block {
            position: sticky;
            bottom: 0px;
            left: -4px;
            width: calc(100% + 8px);
            padding: 16px;
            border-top: 1px solid #E2E8F0;
            background: #FFFFFF;
        }

        .upgrade-plan-new-mobile-choose-button {
            display: flex;
            padding: 15px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            border-radius: 8px;
            background: #FFCB03;
            color: #1E1E1E;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: calc(100% - 24px);
            margin: 0 auto;
        }

        .upgrade-plan-mobile-payment-type {
            color: #A0A0A0;
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.14px;
        }

        .upgrade-plan-options-list {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        .upgrade-plan-active-option {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .upgrade-plan-active-option-text {
            color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.14px;
        }

        .upgrade-plan-new-note {
            color: #A0A0A0;
            font-family: "Inter";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.12px;
            width: calc(100% - 24px);
            margin: 0 auto;
            margin-top: 16px;
        }

        .select-package-mobile-button-block {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 16px;
            background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#FFFFFF'};
            border-top: 1px solid ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
            z-index: 2;
        }
    }

    @media (max-width: 500px) {
        .plans-heading {
            text-align: center;
            font-family: "​​Gilda Display";
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: 94.2%;
            letter-spacing: -1.64px;
            max-width: 330px;
            margin: 0 auto;
            margin-bottom: 16px;
        }

        .heading-different-text {
            font-family: "Almarai";
            font-size: 32px;
            font-style: normal;
            font-weight: 300;
            line-height: 94.2%;
            letter-spacing: -1.28px;
            margin-left: 12px;
            position: relative;
        }

        .plans-description {
            text-align: center;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            letter-spacing: 0.22px;
            max-width: 300px;
            margin: 0 auto;
            margin-bottom: 32px;
        }

        .plans-list {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
            padding: 0 16px;
        }
    }
`

export const PlanCardBlock = styled.div`
    padding: 24px;
    border-radius: ${props => props.popular ? '0 0 8px 8px' : '8px'};
    border: ${props => props.theme.isDark && props.popular ? '1px solid #FFCB03' : props.theme.isDark && !props.popular ? '1px solid #1E1E1E' : !props.theme.isDark && props.popular ? '1px solid #FFCB03' : '1px solid #CDCDCD'};
    background: ${props => props.theme.isDark && props.popular ? '#FFCB03' : props.theme.isDark && !props.popular ? '#2E2E2E' : !props.theme.isDark && props.popular ? '#FFCB03' : '#FFFFFF'};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(50% - 12px);
    height: fit-content;
    position: relative;
    gap: 8px;

    .plan-card-image {
        height: 18px;
    }

    .plan-card-name {
        color: ${props => !props.theme.isDark || props.popular ? '#1E1E1E' : '#FFFFFF'};
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
    }

    .plan-card-description {
        height: 40px;
        color: ${props => props.theme.isDark ? '#5E5E5E' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
        display: flex;
        align-items: end;
    }

    .plan-card-price {
        color: ${props => !props.theme.isDark || props.popular ? '#1E1E1E' : '#FFFFFF'};
        font-family: 'Almarai';
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.48px;
        margin-bottom: 0;
    }

    .plan-card-price-per {
        color: ${props => !props.theme.isDark || props.popular ? '#1E1E1E' : '#FFFFFF'};
        font-family: 'Almarai';
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.48px;
    }

    .plan-card-payment-type {
        color: ${props => props.theme.isDark && props.popular ? '#5E5E5E' : props.theme.isDark && !props.popular ? '#A0A0A0' : !props.theme.isDark && props.popular ? '#1E1E1E' : '#A0A0A0'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        margin-bottom: 0;
    }

    .plan-card-select-button {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 8px;
        border: 1.5px solid ${props => props.theme.isDark && !props.popular ? '#FFFFFF' : '#1E1E1E'};
        background: ${props => props.popular ? '#1E1E1E' : 'transparent'};
        color: ${props => props.popular || (props.theme.isDark && !props.popular) ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        margin-top: 8px;
    }

    .plan-card-underline {
        height: 1px;
        width: 100%;
        background-color: ${props => props.theme.isDark && props.popular ? '#1E1E1E' : props.theme.isDark && !props.popular ? '#4E4E4E' : !props.theme.isDark && props.popular ? '#1E1E1E' : '#E5E5E5'};
        margin-top: 16px;
        margin-bottom: 16px;
        opacity: ${props => props.popular ? '0.2' : '1'};
    }

    .plan-card-options-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .plan-card-option {
        display: flex;
        align-items: center;
        gap: 8px;
        color: ${props => !props.theme.isDark ? '#1E1E1E' : props.theme.isDark && props.popular ? '#2E2E2E' : '#FFFFFF'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .plan-most-popular-hint {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #9B51E0;
        color: #FFF;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        position: absolute;
        top: -42px;
        left: -2px;
        width: calc(100% + 4px);
        border-radius: 16px 16px 0 0;
        padding: 10px;
    }

    .plan-card-discount {
        display: flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        align-self: stretch;
        border-radius: 30px;
        border: 1.5px solid #27AE60;
        background: rgba(111, 207, 151, 0.20);
        color: #27AE60;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        margin-top: 32px;
        white-space: nowrap;
    }

    .discount-stroke-text {
        text-decoration: line-through;
    }

    .plan-card-most-popular-block {
        position: absolute;
        top: -37px;
        left: -1px;
        width: calc(100% + 2px);
        border-radius: 8px 8px 0px 0px;
        border: 1px solid #FFCB03;
        background: #FFCB03;
        display: flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        color: #1E1E1E;
        text-align: center;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        border-bottom: 1px solid #1e1e1e20;
    }

    @media (max-width: 500px) {
        background: ${props => props.theme.isDark ? 'transparent' : '#FFF'};
        width: 100%;
        margin-top: ${props => props.popular ? '42px' : '0px'};
    }
`

export const FAQBlockWrapper = styled.div`
    background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
    color: ${props => props.theme.isDark ? '#FFF' : '#0F172A'};

    .faq-heading {
        text-align: center;
        font-family: "Almarai";
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.64px;
        position: relative;
        margin-bottom: 16px;
    }

    .faq-heading-icon {
        position: absolute;
        top: -25px;
        left: calc(50% - 90px);
        transform: translateX(-50%);
    }

    .faq-description {
        text-align: center;
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
        margin-bottom: 64px;
    }

    .faq-questions-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    @media (max-width: 1200px) {
        .faq-heading {
            text-align: center;
            font-family: "Poppins";
            font-size: 48px;
            font-style: normal;
            font-weight: 400;
            line-height: 94.2%;
            letter-spacing: 0.64px;
            position: relative;
            margin-bottom: 16px;
        }

        .faq-heading-icon {
            position: absolute;
            top: -20px;
            left: calc(50% - 70px);
            transform: translateX(-50%);
        }

        .faq-description {
            text-align: center;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            letter-spacing: 0.22px;
            margin-bottom: 40px;
            padding: 0 16px;
        }

        .faq-questions-list {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 0 16px;
        }
    }
`

export const FAQItemBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    gap: 10px;
    border-radius: 8px;
    background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.18px;

    .faq-item-expand-icon {
        cursor: pointer;
    }

    .support-window-question-item {
        display: flex;
        padding: 16px;
        flex-direction: column;
        gap: 8px;
        border-radius: 8px;
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        cursor: pointer;
        width: 100%;
    }

    .support-window-question-item-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }

    .support-window-question-item-heading.active img {
        transform: rotate(180deg);
    }

    .support-window-question-answers {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
    }

    @media (max-width: 1200px) {
        font-size: 14px;
    }
`

export const LogoMakerWrapper = styled.div`
    width: 100%;
    min-height: 100vh;

    .mobile-continue-block {
        display: none;
        position: fixed;
        bottom: 16px;
        left: 16px;
        width: calc(100% - 32px);
    }

    @media (max-width: 1200px) {
        .mobile-continue-block {
            display: flex;
            align-items: center;
            gap: 16px;
            position: fixed;
            bottom: 16px;
            left: 16px;
            width: calc(100% - 32px);
        }

        .logo-maker-back-btn {
            display: flex;
            padding: 15px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-radius: 8px;
            background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
            box-shadow: 0px 9px 18px 0px rgba(0, 0, 0, 0.15);
            color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
        }

        .logo-maker-back-btn:disabled {
            opacity: 0.4;
        }
    
        .logo-maker-skip-btn {
            display: flex;
            padding: 15px 16px;
            align-items: center;
            justify-content: center;
            gap: 10px;
            border-radius: 8px;
            background: #BEE0EA;
            color: #1E1E1E;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: 100%;
        }
    
        .logo-maker-continue-btn {
            display: flex;
            padding: 15px 16px;
            align-items: center;
            justify-content: center;
            gap: 10px;
            border-radius: 8px;
            background: #FFCB03;
            color: #1E1E1E;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: 100%;
        }

        .logo-maker-continue-btn:disabled {
            opacity: 0.5;
        }
    }
`

export const LogoMakerContentBlock = styled.div`
    min-height: 100vh;
    padding: 144px 120px 60px 120px;
    background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
    color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};

    @media (max-width: 1200px) {
        padding: 104px 16px 32px 16px;
    }
`

export const LogoMakerStepsNavigationBlock = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
    box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.40);
    position: fixed;
    width: calc(100% - 220px);
    left: 110px;
    bottom: 60px;
    z-index: 2;

    .logo-maker-back-btn {
        display: flex;
        padding: 15px 24px;
        align-items: center;
        gap: 10px;
        color: ${props => props.theme.isDark ? '#A0A0A0' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .logo-maker-back-btn:disabled {
        opacity: 0.4;
    }

    .logo-maker-step-points-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 0 0px 0 80px;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .logo-maker-skip-btn {
        display: flex;
        padding: 15px 16px;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-radius: 8px;
        background: #BEE0EA;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        width: 130px;
    }

    .logo-maker-continue-btn {
        display: flex;
        padding: 15px 16px;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        width: 130px;
    }

    .logo-maker-continue-btn:disabled {
        opacity: 0.5;
    }

    .logo-make-step-point {
        position: relative;
        width: 100%;
        margin-top: 20px;
        border-bottom: 1px solid transparent;
    }

    .logo-make-step-point.passed {
        border-bottom: 1px solid ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'};
    }

    .logo-make-step-point.active .logo-maker-step-point-circle,
    .logo-make-step-point.passed .logo-maker-step-point-circle {
        position: absolute;
        left: -8px;
        top: -8px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'};
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
    }

    .logo-make-step-point.passed .logo-maker-step-point-circle {
        background-color: ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'};
    }

    .logo-maker-step-point-name {
        position: absolute;
        top: -35px;
        left: 0;
        transform: translateX(-50%);
        cursor: pointer;
    }

    .logo-make-step-point.active .logo-maker-step-point-name,
    .logo-make-step-point.passed .logo-maker-step-point-name {
        color: ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'};
    }

    .logo-make-step-point.active,
    .logo-make-step-point.passed {
        cursor: pointer;
    }

    @media (max-width: 1200px) {
        display: flex;
        justify-content: space-between;
        padding: 16px;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.40);
        position: fixed;
        width: calc(100% - 32px);
        left: 16px;
        bottom: 80px;
        overflow-y: auto;
        z-index: 2;

        .logo-maker-step-points-wrapper {
            display: flex;
            align-items: center;
            min-width: 100%;
            justify-content: space-between;
            padding: 0 0px 0 40px;
            color: #A0A0A0;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            overflow-y: scroll;
            height: 52px;
            white-space: nowrap;
        }

        .logo-make-step-point {
            position: relative;
            width: 100%;
            margin-top: 20px;
            border-bottom: 1px solid transparent;
            min-width: 120px;
        }

        .logo-maker-continue-btn {
            display: none;
        }

        .logo-maker-skip-btn {
            display: none;
        }
    }
`

export const LogoMakerStepOneBlock = styled.div`
    width: 100%;

    .logo-maker-step-one-heading {
        font-family: "Poppins";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.38px;
        margin-bottom: 8px;
    }

    .logo-maker-step-one-description {
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .logo-maker-step-one-input-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        align-items: center;
        margin-top: 32px;
    }

    .logo-maker-step-one-input {
        width: 100%;
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        display: flex;
        padding: 16px;
        gap: 10px;
        border-radius: 8px;
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .logo-maker-step-one-input:focus {
        background-color: ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
    }

    .logo-maker-step-one-input::placeholder {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .logo-maker-step-one-suggestions-block {
        background-color: ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
        width: 100%;
        max-height: 280px;
        overflow-y: auto;
        padding: 8px;
        border-radius: 8px;
    }

    .logo-maker-step-one-suggestions-block::-webkit-scrollbar {
        background-color: transparent;
        width: 6px;
    }

    .logo-maker-step-one-suggestions-block::-webkit-scrollbar-thumb {
        background-color: black;
        border-radius: 4px;
    }

    .logo-maker-step-one-suggestions-block::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .logo-maker-step-one-suggestions-item {
        display: flex;
        padding: 16px 8px;
        gap: 10px;
        border-radius: 8px;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        cursor: pointer;
    }

    .logo-maker-step-one-suggestions-item:hover {
        background: ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        color: ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'}
    }

    .close-icon-for-reset {
        position: absolute;
        top: 27px;
        transform: translateY(-50%);
        right: 12px;
        cursor: pointer;
    }

    @media (max-width: 1200px) {
        .logo-maker-step-one-heading {
            font-family: "Poppins";
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: 94.2%;
            letter-spacing: -1.38px;
            margin-bottom: 8px;
        }

        .logo-maker-step-one-description {
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
        }

        .logo-maker-step-one-input-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;
            justify-content: center;
            align-items: center;
            margin-top: 4px;
        }

        .logo-maker-step-one-suggestions-block {
            background-color: ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
            width: 100%;
            max-height: 180px;
            overflow-y: auto;
            padding: 8px;
            border-radius: 8px;
        }

        .logo-maker-step-one-input {
            width: 100%;
            background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
            display: flex;
            padding: 16px;
            gap: 10px;
            border-radius: 8px;
            color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
        }

        .logo-maker-step-one-input::placeholder {
            color: #A0A0A0;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
        }
    }
`

export const LogoMakerStepTwoBlock = styled.div`
    width: 100%;

    .logo-maker-step-two-heading {
        font-family: "Poppins";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.38px;
        margin-bottom: 8px;
    }

    .logo-maker-step-two-description {
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .logo-maker-step-two-styles-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
        margin-top: 32px;
        padding-bottom: 100px;
    }

    .logo-maker-step-two-styles-item-block {
        width: 100%;
        border-radius: 16px;
        cursor: pointer;
    }

    .logo-maker-step-two-styles-item-block img {
        border: 5px solid transparent;
        border-radius: 16px;
    }

    .logo-maker-step-two-styles-item-block.selected img {
        border: 5px solid #FFCB03;
        border-radius: 16px;
    }

    .logo-maker-step-two-styles-icon {
        width: 100%;
    }

    @media (max-width: 1200px) {
        .logo-maker-step-two-heading {
            font-family: "Poppins";
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: 94.2%;
            letter-spacing: -1.38px;
            margin-bottom: 8px;
        }

        .logo-maker-step-two-description {
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
        }

        .logo-maker-step-two-styles-item-block {
            width: 100%;
            border-radius: 16px;
            cursor: pointer;
        }

        .logo-maker-step-two-styles-item-block img {
            border: 5px solid transparent;
            border-radius: 16px;
        }

        .logo-maker-step-two-styles-item-block.selected img {
            border: 5px solid #FFCB03;
            border-radius: 16px;
        }

        .logo-maker-step-two-styles-list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 8px;
            margin-top: 4px;
            padding-bottom: 150px;
        }
    }
`

export const LogoMakerStepThreeBlock = styled.div`
    width: 100%;

    .logo-maker-step-two-heading {
        font-family: "Poppins";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.38px;
        margin-bottom: 8px;
    }

    .logo-maker-step-two-description {
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .logo-maker-step-two-colors-list {
        margin-top: 32px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
        padding-bottom: 100px;
    }

    .logo-maker-step-two-color-item {
        width: 100%;
        height: 150px;
        padding: 16px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        cursor: pointer;
        transition: 0.3s;
        overflow: hidden;
    }

    @media (max-width: 1200px) {
        .logo-maker-step-two-heading {
            font-family: "Poppins";
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: 94.2%;
            letter-spacing: -1.38px;
            margin-bottom: 8px;
        }

        .logo-maker-step-two-description {
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.16px;
        }

        .logo-maker-step-two-colors-list {
            margin-top: 4px;
            display: grid;
            grid-template-columns: 1fr;
            gap: 16px;
            padding-bottom: 150px;
        }
    }
`

export const LogoMakerStepFourBlock = styled.div`
    width: 100%;

    .logo-maker-step-two-heading {
        font-family: "Poppins";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.38px;
        margin-bottom: 8px;
    }

    .logo-maker-step-two-description {
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .logo-maker-step-one-input {
        width: 100%;
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        display: flex;
        padding: 16px;
        gap: 10px;
        border-radius: 8px;
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .logo-maker-step-one-input:focus {
        background-color: ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
    }

    .logo-maker-step-four-block {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 32px;
    }

    @media (max-width: 1200px) {
        .logo-maker-step-two-heading {
            font-family: "Poppins";
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: 94.2%;
            letter-spacing: -1.38px;
            margin-bottom: 8px;
        }

        .logo-maker-step-two-description {
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.16px;
        }

        .logo-maker-step-four-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            margin-top: 4px;
        }
    }
`

export const LogoMakerStepFiveBlock = styled.div`
    width: 100%;

    .logo-maker-step-five-heading {
        font-family: "Poppins";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.38px;
        margin-bottom: 8px;
    }

    .logo-maker-step-five-description {
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .logo-maker-step-five-pick-symbol {
        color: #027FE3;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        cursor: pointer;
    }

    .logo-maker-step-five-icons-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        padding-bottom: 100px;
    }

    .symbols-categories-list {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
    }

    .symbol-categories-item {
        display: flex;
        padding: 16px 24px;
        align-items: center;
        gap: 8px;
        border-radius: 30px;
        border: 2px solid #A0A0A0;
        color: #A0A0A0;
        text-align: center;
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
        cursor: pointer;
    }

    .symbol-categories-item:hover {
        border: 2px solid #FFCB03;
    }

    .symbol-categories-item.selected {
        border: 2px solid #FFCB03;
        background-color: #FFCB03;
        color: #1E1E1E;
    }

    .logo-maker-step-five-want-to-pick {
        color: #027FE3;
        text-align: center;
        font-family: "Inter";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-top: 48px;
        cursor: pointer;
    }

    .step-five-symbols-search-block-wrapper {
        display: flex;
        flex-direction: column;
    }

    .search-block-input-wrapper {
        display: flex;
    }

    .search-block-input-container {
        display: flex;
        height: 61px;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        padding-left: 24px;
        gap: 10px;
        border-radius: 8px;
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        border: 2px solid transparent;
        width: 100%;
    }

    .search-block-input {
        font-family: "Inter";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
        background-color: transparent;
        width: 100%;
    }

    .search-block-input-container:focus-within {
        border: 2px solid ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
    }

    .search-block-input::placeholder {
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
    }

    .symbols-pick-search-button {
        display: flex;
        padding: 10px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .logo-maker-step-five-icons-list {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        margin-top: 48px;
        justify-content: center;
    }

    .logo-maker-step-five-icon {
        display: flex;
        padding: 11px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        border: 2px solid transparent;
        cursor: pointer;
    }

    .logo-maker-step-five-icon.selected {
        border: 2px solid #FFCB03;
    }

    .search-active-categories-list {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        overflow-y: auto;
        gap: 16px;
        padding-top: 24px;
        padding-bottom: 16px;
        border-bottom: 1px solid ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
    }

    .search-active-categories-list::-webkit-scrollbar {
        background-color: transparent;
        width: 6px;
        height: 6px;
    }

    .search-active-categories-list::-webkit-scrollbar-thumb {
        background-color: black;
        border-radius: 4px;
    }

    .search-active-categories-list::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .search-active-categories-list-active-item {
        background-color: #FFCB03;
        border: 2px solid #FFCB03;
        color: #1E1E1E;
    }

    .step-five-select-one-of-this-text {
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
        margin-top: 48px;
    }

    .symbols-pick-selected-list {
        display: flex;
        gap: 8px;
        margin-left: 16px;
    }

    .symbols-pick-selected-icon {
        display: flex;
        width: 60px;
        height: 60px;
        padding: 8px;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-radius: 8px;
        border: 2px solid ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
    }

    .symbols-pick-selected-icon-item {
        cursor: pointer;
        position: relative;
    }

    .symbols-pick-selected-icon-item:hover .symbols-pick-selected-icon-item-delete {
        display: flex;
    }

    .symbols-pick-selected-icon-item-delete {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #000000b7;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
    }

    @media (max-width: 1200px) {
        .logo-maker-step-five-heading {
            font-family: "Poppins";
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 94.2%;
            letter-spacing: -1.38px;
            margin-bottom: 8px;
        }

        .logo-maker-step-five-description {
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.16px;
        }

        .logo-maker-step-five-icons-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 4px;
            padding-bottom: 160px;
        }

        .symbol-categories-item {
            display: flex;
            padding: 16px;
            align-items: center;
            gap: 8px;
            border-radius: 30px;
            border: 2px solid #A0A0A0;
            color: #A0A0A0;
            text-align: center;
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.14px;
            cursor: pointer;
        }

        .symbols-categories-list {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }

        .logo-maker-step-five-want-to-pick {
            color: #027FE3;
            text-align: start;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.16px;
            margin-top: 24px;
            cursor: pointer;
        }

        .logo-maker-step-five-pick-symbol {
            color: #027FE3;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.16px;
            cursor: pointer;
        }

        .search-block-input-wrapper {
           display: flex;
           flex-direction: column;
           gap: 8px;
           margin-top: 20px;
        }

        .step-five-select-one-of-this-text {
            display: none;
            font-family: "Inter";
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            letter-spacing: 0.22px;
            margin-top: 48px;
        }

        .symbols-pick-selected-list {
            display: flex;
            gap: 8px;
            margin-left: 0px;
        }

        .search-active-categories-list-active-item {
            background-color: #FFCB03;
            border: 2px solid #FFCB03;
            color: #1E1E1E;
        }

        .search-block-input-container {
            display: flex;
            height: 61px;
            align-items: center;
            justify-content: space-between;
            padding: 8px;
            padding-left: 16px;
            gap: 10px;
            border-radius: 8px;
            background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
            border: 2px solid transparent;
            width: 100%;
        }

        .logo-maker-step-five-icons-list {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
            margin-top: 20px;
            justify-content: center;
        }

        .logo-maker-step-five-icon {
            display: flex;
            padding: 8px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 16px;
            background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
            border: 2px solid transparent;
            cursor: pointer;
        }

        .search-active-categories-list .symbol-categories-item {
            padding: 8px 16px;
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.14px;
        }

        .search-active-categories-list {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            width: 100%;
            overflow-y: auto;
            gap: 16px;
            padding-top: 20px;
            padding-bottom: 16px;
            border-bottom: 1px solid ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
        }

    }
`

export const LogoMakerStepSixBlock = styled.div`
    width: 100%;

    .heading-text {
        font-family: "Poppins";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.38px;
    }

    .description-text {
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .generated-logos-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
        margin-top: 32px;
        padding-bottom: 100px;
    }

    .generated-logo-wrapper {
        width: 100%;
        min-height: 350px;
        background-color: ${props => props.theme.isDark ? 'white' : 'black'};
        border-radius: 16px;
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.11);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .generated-logo-customize-button {
        opacity: 0;
        transition: opacity 0.5s;
        position: absolute;
        left: 16px;
        bottom: 16px;
        width: calc(100% - 32px);
        display: flex;
        padding: 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        cursor: pointer;
    }

    .generated-logo-wrapper:hover .generated-logo-customize-button {
        opacity: 1;
        transition: opacity 0.5s;
    }

    .generated-logo-company-name {
        font-family: "Inter";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
    }

    .generated-logo-slogan {
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
    }

    .generated-logo-text {
        display: flex;
        flex-direction: column;
        max-width: 80%;
    }

    .shuffle-logos-button {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    @media (max-width: 1200px) {
        .heading-text {
            font-family: "Poppins";
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: 94.2%;
            letter-spacing: -1.38px;
        }

        .description-text {
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.16px;
            margin-bottom: 0;
        }

        .generated-logos-list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 8px;
            margin-top: 4px;
            padding-bottom: 160px;
        }

        .generated-logo-wrapper {
            width: 100%;
            min-height: 250px;
            background-color: ${props => props.theme.isDark ? 'white' : 'blakc'};
            border-radius: 16px;
            box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.11);
            position: relative;
        }

        .generated-logo-customize-button {
            opacity: 0;
            transition: opacity 0.5s;
            position: absolute;
            left: 8px;
            bottom: 8px;
            width: calc(100% - 16px);
            display: flex;
            padding: 12px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
            color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
            font-family: "Inter";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.12px;
            cursor: pointer;
        }

        .step-six-header-block {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 20px;
            margin-bottom: 20px;
        }

        @media (max-width: 800px) {
            .generated-logos-list {
                display: grid;
                grid-template-columns: 1fr;
                gap: 8px;
                margin-top: 4px;
                padding-bottom: 160px;
            }
        }
    }
`

export const LogoEditorWrapperBlock = styled.div`
    width: 100%;
    display: grid;
    grid-template-areas: ${props => props.preview ?
        `'header'
         'content'
         'navigation'` :
        `'header header header'
         'content content navbar'
         'navigation navigation navbar'`
    };
    grid-template-columns: ${props => props.preview ? '1fr' : '2fr 2fr 1fr'};

    @media (max-width: 1200px) {
        width: 100%;
        display: grid;
        grid-template-areas: ${props => props.preview ?
        `'header'
            'content'
            'navigation'` :
        `'header header'
            'content content'
            'navigation navigation'
            'navbar navbar'`
    };
        grid-template-columns: ${props => props.preview ? '1fr' : '2fr 2fr'};
    }
`

export const LogoEditorContentWrapper = styled.div`
    grid-area: content;
    background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
    height: calc(100vh - 72px);
    width: 100%;
    padding: 20px;
    padding-top: 104px;
    display: flex;
    flex-direction: column;
    overflow: auto;

    @media (max-width: 1200px) {
        height: calc(100vh - 102px);
        padding-bottom: 120px;
    }
`

export const LogoEditorNavigationBlock = styled.div`
    grid-area: navigation;
    background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
    border-top: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
    width: 100%;
    height: 72px;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-editor-navigation-left {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .logo-editor-navigation-rigth {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .navigation-preview-button {
        display: flex;
        height: 40px;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .navigation-undo-button {
        display: flex;
        height: 40px;
        padding: 8px;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .navigation-undo-button:disabled {
        opacity: 0.5;
    }

    .navigation-logo-scale-input {
        width: 220px;
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        border-radius: 4px;
        height: 5px;
    }

    .navigation-logo-scale-input .range-slider__thumb[data-upper] {
        background: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        width: 20px;
        height: 20px;
        outline: 2px solid ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
    }

    .navigation-logo-scale-input .range-slider__range {
        background-color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
    }

    .logo-scale-value {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        text-align: center;
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
        width: 70px;
    }

    .expand-icon-button {
        cursor: pointer;
    }

    @media (max-width: 1200px) {
        padding-bottom: 16px;
        position: fixed;
        bottom: 32px;
        left: 0;

        .navigation-preview-button {
            display: none;
            height: 40px;
            padding: 6px 8px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: #FFCB03;
            color: #1E1E1E;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
        }

        .navigation-logo-scale-input {
            width: 90px;
            background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
            border-radius: 4px;
            height: 3px;
        }

        .navigation-logo-scale-input .range-slider__thumb[data-upper] {
            background: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
            width: 10px;
            height: 10px;
            outline: 2px solid ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
        }

        .navigation-logo-scale-input .range-slider__range {
            background-color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        }

        .logo-scale-value {
            color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
            text-align: center;
            font-family: "Inter";
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            letter-spacing: 0.20px;
            width: 65px;
        }
    }
`

export const LogoPreviewBlock = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .preview-image-block {
        width: 100%;
        border-radius: 16px;
        border: 1px solid #5E5E5E;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        user-select: none;
        touch-action: none;
    }

    .generated-logo-company-name {
        text-align: center;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
        cursor: pointer;
        user-select: none;
        touch-action: none;
    }

    .generated-logo-company-name:hover {
        outline: 2px solid #027FE3 !important;
        outline-offset: 3px;
        border-radius: 2px;
    }

    .generated-logo-slogan {
        text-align: center;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
        cursor: pointer;
        user-select: none;
        touch-action: none;
    }

    .generated-logo-slogan:hover {
        outline: 2px solid #027FE3 !important;
        outline-offset: 3px;
        border-radius: 2px;
    }

    .generated-logo-text {
        display: flex;
        flex-direction: column;
        max-width: 80%;
    }

    .generated-logo-icon {
        cursor: pointer;
        user-select: none;
        touch-action: none;
    }

    .generated-logo-icon:hover {
        outline: 2px solid #027FE3 !important;
        outline-offset: 3px;
        border-radius: 2px;
    }

    .editable {
        outline: 2px solid #027FE3 !important;
        outline-offset: 3px;
        border-radius: 2px;
    }

    @media (max-width: 1200px) {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: end;
        justify-content: center;

        .preview-image-block {
            width: 100% !important;
            height: 95% !important;
            border-radius: 16px;
            border: 1px solid #5E5E5E;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            user-select: none;
            touch-action: none;
        }
    }
`

export const EditorBlockWrapper = styled.div`
    width: 100%;
    height: 100%;

    .editor-left-side {
        
    }

    .editor-right-side {
        
    }
`

export const EditorToolbarBlock = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 24px;
    height: 50px;

    .color-picker-wrapper {
        position: relative;
    }

    .color-picker-block {
        width: 44px;
        height: 44px;
        border-radius: 8px;
        cursor: pointer;
    }

    .dropdown-color-picker {
        position: absolute;
        left: 0;
        top: 55px;
        width: 200px;
        z-index: 3;
    }

    .chrome-picker {
        background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'} !important;
        padding: 10px;
        width: 100% !important;
    }

    .chrome-picker input {
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        color: ${props => props.theme.isDark ? 'white' : '#1E1E1E'} !important;
        box-shadow: none !important;
    }

    .rcp-saturation,
    .rcp-hue {
        cursor: pointer;
    }

    .toolbar-size-wrapper {
        position: relative;
        display: flex;
        align-items: center;
    }

    .toolbar-size-button {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        cursor: pointer;
        user-select: none;
    }

    .toolbar-size-button.active {
        color: ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'}
    }

    .toolbar-position-wrapper {
        position: relative;
        display: flex;
        align-items: center;
    }

    .toolbar-position-button {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        cursor: pointer;
        user-select: none;
    }

    .toolbar-position-button.active {
        color: ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'}
    }

    .toolbar-remove-wrapper {
        cursor: pointer;
    }

    .remove-disabled {
        opacity: 0.5;
        cursor: default;
    }

    .toolbar-flip-block {
        display: flex;
        width: 88px;
        height: 44px;
        align-items: center;
        border-radius: 8px;
        border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        overflow: hidden;
    }

    .toolbar-flip-option {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 10px;
        width: 44px;
        height: 100%;
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        cursor: pointer;
    }

    .flip-option-active {
        background-color: ${props => props.theme.isDark ? '#4E4E4E' : '#CDCDCD'};
    }

    .toolbar-size-range-block {
        position: absolute;
        height: 59px;
        top: 40px;
        left: 0;
        padding: 8px 16px;
        border-radius: 8px;
        background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'} ;
        box-shadow: 0px 13px 37px 0px rgba(0, 0, 0, 0.21);
        display: flex;
        align-items: center;
        gap: 16px;
        z-index: 2;
    }

    .navigation-logo-scale-input {
        width: 220px;
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        border-radius: 4px;
        height: 5px;
    }

    .navigation-logo-scale-input .range-slider__thumb[data-upper] {
        background: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        width: 20px;
        height: 20px;
        outline: 2px solid ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
    }

    .navigation-logo-scale-input .range-slider__range {
        background-color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
    }

    .toolbar-size-range-text-value {
        color: ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'};
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
        width: 60px;
        text-align: center;
    }

    .toolbar-revert-size-range-value {
        cursor: pointer;
    }

    .toolbar-position-block {
        position: absolute;
        height: 175px;
        top: 40px;
        left: 0;
        padding: 16px;
        border-radius: 8px;
        background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'} ;
        box-shadow: 0px 13px 37px 0px rgba(0, 0, 0, 0.21);
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 16px;
        z-index: 2;
    }

    .toolbar-position-option-block {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .toolbar-position-option-title {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .toolbar-position-option-range-block {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .toolbar-layout-block {
        display: flex;
        width: 176px;
        height: 44px;
        align-items: center;
        border-radius: 8px;
        border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        overflow: hidden;
    }

    .toolbar-layout-option {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 10px;
        width: 44px;
        height: 100%;
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        cursor: pointer;
    }

    .layout-option-active {
        background-color: ${props => props.theme.isDark ? '#4E4E4E' : '#CDCDCD'};
    }

    .toolbar-company-name-block {
        display: flex;
        width: 259px;
        height: 43px;
        padding: 4px;
        gap: 10px;
        border-radius: 8px;
        border: 2px solid ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
        background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
    }

    .toolbar-company-name-input {
        background-color: transparent;
        color: #A0A0A0;
        text-align: start;
        font-family: "Poppins";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
        padding: 0 16px;
        width: 100%;
    }

    .toolbar-letter-spacing-block {
        height: 43px;
        width: 43px;
        border-radius: 8px;
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
    }

    .toolbar-letter-spacing-block.active {
        background-color: ${props => props.theme.isDark ? '#4E4E4E' : '#CDCDCD'};
    }

    .toolbar-spacing-range-block {
        position: absolute;
        height: 59px;
        top: 55px;
        left: 0;
        padding: 8px 16px;
        border-radius: 8px;
        background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'} ;
        box-shadow: 0px 13px 37px 0px rgba(0, 0, 0, 0.21);
        display: flex;
        align-items: center;
        gap: 16px;
        z-index: 2;
    }

    .toolbar-text-transform-block {
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background-color: transparent;
        width: 113px;
        height: 43px;
        overflow: hidden;
    }

    .toolbar-text-transform-option {
        width: 50%;
        height: 44px;
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .toolbar-text-transform-option.active {
        background-color: ${props => props.theme.isDark ? '#4E4E4E' : '#CDCDCD'};
    }

    @media (max-width: 1200px) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 24px;
        height: 50px;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;

        .toolbar-flip-block {
            display: flex;
            width: 88px;
            min-width: 88px;
            height: 44px;
            align-items: center;
            border-radius: 8px;
            border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
            overflow: hidden;
        }

        .toolbar-layout-block {
            display: flex;
            width: 176px;
            min-width: 176px;
            height: 44px;
            align-items: center;
            border-radius: 8px;
            border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
            overflow: hidden;
        }

        .toolbar-remove-wrapper {
            cursor: pointer;
            width: 24px;
            min-width: 24px;
        }

        .toolbar-letter-spacing-block {
            height: 43px;
            width: 43px;
            min-width: 43px;
            border-radius: 8px;
            background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
            border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: unset;
        }

        .toolbar-company-name-block {
            display: flex;
            width: 259px;
            min-width: 259px;
            height: 43px;
            padding: 4px;
            gap: 10px;
            border-radius: 8px;
            border: 2px solid ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
            background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
        }

        .toolbar-text-transform-block {
            display: flex;
            align-items: center;
            border-radius: 8px;
            border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
            background-color: transparent;
            width: 113px;
            min-width: 113px;
            height: 43px;
            overflow: hidden;
        }

        .toolbar-size-wrapper {
            position: unset;
            display: flex;
            align-items: center;
        }

        .toolbar-size-range-block {
            position: absolute;
            height: 59px;
            top: 155px;
            left: 16px;
            padding: 8px 16px;
            border-radius: 8px;
            background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'} ;
            box-shadow: 0px 13px 37px 0px rgba(0, 0, 0, 0.21);
            display: flex;
            align-items: center;
            gap: 8px;
            z-index: 2;
        }

        .navigation-logo-scale-input {
            width: 120px;
            background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
            border-radius: 4px;
            height: 4px;
        }

        .navigation-logo-scale-input .range-slider__thumb[data-upper] {
            background: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
            width: 15px;
            height: 15px;
            outline: 2px solid ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
        }

        .navigation-logo-scale-input .range-slider__range {
            background-color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        }

        .toolbar-position-wrapper {
            position: unset;
            display: flex;
            align-items: center;
        }

        .toolbar-position-block {
            position: absolute;
            height: 175px;
            top: 155px;
            left: 16px;
            padding: 16px;
            border-radius: 8px;
            background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'} ;
            box-shadow: 0px 13px 37px 0px rgba(0, 0, 0, 0.21);
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 8px;
            z-index: 2;
        }

        .color-picker-wrapper {
            position: unset;
        }

        .dropdown-color-picker {
            position: absolute;
            left: 16px;
            top: 155px;
            width: 200px;
            z-index: 3;
        }

        .toolbar-spacing-range-block {
            position: absolute;
            height: 59px;
            top: 155px;
            left: 16px;
            padding: 8px 16px;
            border-radius: 8px;
            background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'} ;
            box-shadow: 0px 13px 37px 0px rgba(0, 0, 0, 0.21);
            display: flex;
            align-items: center;
            gap: 8px;
            z-index: 2;
        }
    }
`

export const EditorNavbarBlock = styled.div`
    grid-area: navbar;
    width: 100%;
    background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
    border-left: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
    padding: 16px;
    padding-top: 100px;
    height: 100vh;
    overflow-y: auto;
    min-width: 360px;

    &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: black;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
        background: transparent; 
    }

    .editor-navabar-tabs-list {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    .editor-navbar-tab-item {
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: 10px 8px;
        width: 100%;
        max-height: calc(500px);
    }
    
    .editor-navbar-tab-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        fill: #A0A0A0;
        color: #A0A0A0;
        cursor: pointer;
        padding: 2px 0;
    }

    .editor-navbar-tab-item.active {
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
    }
    
    .editor-navbar-tab-item.active .editor-navbar-tab-header {
        fill: ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'};
        color: ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'};
    }

    .editor-navbar-tab-name-block {
        display: flex;
        align-items: center;
        gap: 8px;
        user-select: none;
        font-weight: 500;
    }

    .editor-navbar-item-content {
        padding-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        height: auto;
        overflow-y: auto;
    }

    .editor-suggested-type-dropdown-wrapper {
        position: relative;
    }

    .editor-suggested-type-dropdown-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 16px;
        border-radius: 8px;
        background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
    }

    .editor-suggested-type-dropdown-value {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .editor-suggested-type-dropdown-change {
        color: ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        cursor: pointer;
        user-select: none;
    }

    .editor-suggested-types-dropdown-menu {
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 8px;
        background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
        z-index: 2;
        box-shadow: 0px 13px 37px 0px rgba(0, 0, 0, 0.21);
        overflow: hidden;
    }

    .editor-suggested-type-item {
        display: flex;
        width: 100%;
        height: 46px;
        padding: 10px 16px;
        align-items: center;
        color:  ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        cursor: pointer;
        transition: 0.3s;
    }

    .editor-suggested-type-item:hover {
        background: ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
        color: ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'};
        transition: 0.3s;
    }

    .editor-suggested-templates-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        height: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .editor-suggested-templates-list::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
    }

    .editor-suggested-templates-list::-webkit-scrollbar-thumb {
        background: black;
        border-radius: 4px;
    }

    .editor-suggested-templates-list::-webkit-scrollbar-track {
        background: transparent; 
    }

    .preview-image-block {
        width: 100%;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        user-select: none;
        touch-action: none;
        cursor: pointer;
    }

    .generated-logo-company-name {
        text-align: center;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
        user-select: none;
        touch-action: none;
    }

    .generated-logo-slogan {
        text-align: center;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
        user-select: none;
        touch-action: none;
    }

    .generated-logo-text {
        display: flex;
        flex-direction: column;
        max-width: 80%;
    }

    .generated-logo-icon {
        user-select: none;
        touch-action: none;
    }

    .editor-navbar-background-colors-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        overflow-x: auto;
        min-height: 42px;
    }

    .editor-background-color-item {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        cursor: pointer;
        outline: none;
        outline-color: white;
        outline-offset: -4px;
        transition: outline 0.6s;
    }

    .editor-background-color-item:hover {
        outline: 1px solid white;
        outline-offset: -4px;
        transition: outline 0.6s;
    }

    .editor-navbar-expand-line {
        display: none;
    }

    @media (max-width: 1200px) {
        grid-area: navbar;
        width: 100%;
        background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
        border-left: none;
        padding: 16px;
        padding-top: 16px;
        max-height: calc(100vh - 160px);
        height: fit-content;
        overflow-y: auto;
        min-width: 360px;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 4;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.29);

        .editor-navbar-expand-line {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 0px 8px;
            padding-top: 0px;
        }

        .editor-navabar-tabs-list {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 16px;
        }

        .preview-image-block {
            width: 100%;
            border-radius: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            user-select: unset;
            touch-action: unset;
            cursor: pointer;
        }
    }
`

export const DownloadModalBlock = styled(Modal)`
    .modal-dialog {
        min-width: unset;
        max-width: ${props => props.plan === 'Free' ? '540px' : '540px'};
        width: 100%;
    }

    @media (max-width: 1200px) {
        .modal-dialog {
            width: unset;
        }
    }
`

export const DownloadModalBody = styled(Modal.Body)`
    background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
    color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
    display: grid;
    grid-template-columns: ${props => props.plan === 'Free' ? '1fr' : '1fr'};
    padding: 0;

    .left-side {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        padding: 40px;
        position: relative;
    }

    .right-side {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        background:  ${props => props.theme.isDark ? 'rgba(155, 81, 224, 0.70)' : 'rgba(155, 81, 224, 0.20)'};
        position: relative;
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }

    .right-side-title {
        color: ${props => props.theme.isDark ? '#FFF' : '#9B51E0'};
        font-family: "Poppins";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: 0.36px;
        margin: 8px 0;
    }

    .right-side-label {
        color: ${props => props.theme.isDark ? '#FFF' : '#9B51E0'};
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        margin: 0;
    }

    .download-options-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
        margin: 32px 0;
    }

    .download-option-item {
        color: ${props => props.theme.isDark ? '#FFF' : '#9B51E0'};
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
    }

    .select-plan-button {
        display: flex;
        height: 56px;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        border: 1.5px solid ${props => props.theme.isDark ? '#FFF' : '#9B51E0'};
        color: ${props => props.theme.isDark ? '#FFF' : '#9B51E0'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .left-side-heading {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Poppins";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: 0.36px;
    }

    .left-side-label {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        margin-top: 8px;
        margin-bottom: 0;
    }

    .left-side-format-dropdown-wrapper {
        position: relative;
        width: 100%;
        margin-top: 24px;
    }

    .format-dropdown-button {
        display: flex;
        padding: 16px;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 8px;
        border: 1.5px solid #A0A0A0;
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        cursor: pointer;
        user-select: none;
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .format-dropdown-list {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        z-index: 4;
        border-radius: 0 0 8px 8px;
        border: 1.5px solid #A0A0A0;
        border-top: none;
        padding: 8px;
    }

    .format-dropdown-placeholder {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }
    
    .format-dropdown-item {
        cursor: pointer;
        padding: 10px 16px;
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .format-dropdown-item:hover {
        border-radius: 8px;
        background: ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
        color: ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'}
    }

    .left-side-size-multiplier {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 16px;
    }

    .size-multiplier-title {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .size-multiplier-value {
        display: flex;
        padding: 2px 8px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 4px;
        border: 1.5px solid #A0A0A0;
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        user-select: none;
        color: #A0A0A0;
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .left-size-size-range {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
        width: 100%;
        gap: 8px;
    }

    .navigation-logo-scale-input {
        width: calc(100% - 120px);
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        border-radius: 4px;
        height: 5px;
    }

    .navigation-logo-scale-input .range-slider__thumb[data-upper] {
        background: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        width: 20px;
        height: 20px;
        outline: 2px solid ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
    }

    .navigation-logo-scale-input .range-slider__range {
        background-color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
    }

    .size-in-pixels-value {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .left-side-additional-options {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    .additional-option-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .additional-option-info {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .additional-option-info-white {
        display: flex;
        align-items: center;
        gap: 8px;
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .left-side-preview-logo-product {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        cursor: pointer;
    }

    .left-side-download-button {
        display: flex;
        height: 64px;
        padding: 15px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background:#FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        width: 100%;
        margin-top: 24px;
    }

    .left-side-download-button:disabled {
        opacity: 0.5;
    }

    .back-to-download {
        display: flex;
        align-items: center;
        gap: 4px;
        color: ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        position: absolute;
        left: 15px;
        top: 15px;
        cursor: pointer;
    }

    .left-side-preview-popular-label {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .preview-block-wrapper {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
    }

    .preview-images-block {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 8px;
        padding: 8px;
        border-radius: 10px;
        border: 1px solid #5E5E5E;
        background: #2E2E2E;
        width: 100%;
        margin-top: 24px;
    }

    .preview-image-item {
        width: 100%;
        height: 100%;
        position: relative;
        cursor: pointer;
    }

    .preview-image-item.selected {
        outline: 3px solid #FFCB03;
        border-radius: 8px;
    }

    .preview-image-inserted-logo-one {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: rotateZ(0deg) rotateY(0deg) translate(-52%, -55%);
        color: red;
        width: 100px;
        height: 100px;
    }

    .preview-image-inserted-logo-two {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: rotateZ(2deg) rotateY(0deg) translate(-50%, -65%);
        color: red;
        width: 100px;
        height: 100px;;
    }

    .preview-image-inserted-logo-three {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: rotateZ(5deg) rotateY(15deg) translate(-84%, -40%);
        color: red;
        width: 100px;
        height: 100px;
    }

    .preview-image-inserted-logo-four {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: rotateZ(1deg) rotateY(0deg) translate(-85%, -35%);
        color: red;
        width: 100px;
        height: 100px;
    }

    .preview-image-block {
        width: 100%;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        user-select: none;
        touch-action: none;
    }

    .generated-logo-text {
        display: flex;
        flex-direction: column;
        max-width: 80%;
    }

    @media (max-width: 1200px) {
        background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        display: grid;
        grid-template-columns: 1fr;
        padding: 0;

        .left-side-heading {
            color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
            font-family: "Poppins";
            font-size: 27px;
            font-style: normal;
            font-weight: 400;
            line-height: 94.2%;
            letter-spacing: -0.2px;
        }

        .preview-images-block {
            display: grid;
            grid-template-columns: 1fr;
            gap: 8px;
            padding: 8px;
            border-radius: 10px;
            border: 1px solid #5E5E5E;
            background: #2E2E2E;
            width: 100%;
            margin-top: 24px;
        }

        .right-side {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 80px 45px;
            background:  ${props => props.theme.isDark ? 'rgba(155, 81, 224, 0.70)' : 'rgba(155, 81, 224, 0.20)'};
            position: relative;
        }

        .back-to-download-mobile-upgrade {
            display: flex;
            align-items: center;
            gap: 4px;
            position: absolute;
            bottom: 40px;
            left: 50%;
            transform: translateX(-55%);
            color: ${props => props.theme.isDark ? '#FFCB03' : '#027FE3'};
            text-align: center;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            white-space: nowrap;
        }

        .navigation-logo-scale-input {
            width: calc(100% - 110px);
            background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
            border-radius: 4px;
            height: 4px;
        }

        .navigation-logo-scale-input .range-slider__thumb[data-upper] {
            background: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
            width: 15px;
            height: 15px;
            outline: 2px solid ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};
        }

        .navigation-logo-scale-input .range-slider__range {
            background-color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        }

        .size-in-pixels-value {
            color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.16px;
        }
    }
`

export const LoaderBlock = styled.div`
    width: 56px;
    height: 56px;
    display: grid;
    border: 4.5px solid #0000;
    border-radius: 50%;
    border-color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'} #0000;
    animation: spinner-e04l1k 1s infinite linear;

    &::before,
    &::after {
        content: "";
        grid-area: 1/1;
        margin: 2.2px;
        border: inherit;
        border-radius: 50%;
    }

    &::before {
        border-color: ${props => props.theme.isDark ? '#FFCB03' : '#474bff'} #0000;
        animation: inherit;
        animation-duration: 0.5s;
        animation-direction: reverse;
    }

    &::after {
        margin: 8.9px;
    }

    @keyframes spinner-e04l1k {
        100% {
            transform: rotate(1turn);
        }
    }
`

export const DashboardBlock = styled.div`
    .dashboard-wrapper {
        width: 100%;
        min-height: 100vh;
        padding-top: 92px;
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        display: grid;
        grid-template-columns: 250px 1fr;
    }

    @media (max-width: 1200px) {
        .dashboard-wrapper {
            width: 100%;
            min-height: 100vh;
            padding-top: 92px;
            background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
            display: grid;
            grid-template-columns: 1fr;
        }
    }
`

export const DashboardNavigationBlock = styled.div`
    height: calc(100vh - 92px);
    width: 100%;
    background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFFFFF'};
    border-right: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;

    .user-avatar-block {
        display: flex;
        align-items: center;
        gap: 8px;
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .navigation-group-block {
        display: flex;
        flex-direction: column;
        gap: 1px;
    }

    .navigation-link {
        display: flex;
        padding: 10px 8px;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        border-radius: 8px;
        cursor: pointer;
    }

    .navigation-link:hover {
        background-color: #FFCB03;
        color: #1E1E1E;
    }

    .navigation-link.active {
        background-color: #FFCB03;
        color: #1E1E1E;
    }

    .navigation-link:hover img,
    .navigation-link.active img {
        filter: invert(207%) sepia(43%) saturate(0%) hue-rotate(175deg) brightness(6%) contrast(84%);
    }

    .navigation-terms-navigation {
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: absolute;
        left: 16px;
        bottom: 16px;
    }

    .navigation-term-item {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
        cursor: pointer;
    }

    .avatar-navigation {
        width: 38px;
        height: 38px;
        border-radius: 38px;
    }

    .navigation-plan-block {
        display: flex;
        flex-direction: column;
    }

    .plan-block-name {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .plan-block-plan-name {
        color: #9B51E0;
        font-family: "Poppins";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
        display: flex;
        align-items: center;
        gap: 4px;
        fill: #9B51E0;
        stroke-width: 1px;
        stroke: #FFF;
    }

    @media (max-width: 1600px) {
        gap: 18px;
    }

    @media (max-width: 1500px) {
        gap: 10px;
    }

    @media (max-width: 1300px) {
        gap: 1px;

        .user-avatar-block {
            margin-bottom: 16px;
        }
    }

    @media (max-width: 1200px) {
        height: 78px;
        width: 100%;
        background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFFFFF'};
        border-right: none;
        border-bottom: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        position: relative;
        overflow-x: auto;

        .navigation-terms-navigation {
            display: none;
            flex-direction: column;
            gap: 8px;
            position: absolute;
            left: 16px;
            bottom: 16px;
        }

        .navigation-group-block {
            display: flex;
            flex-direction: row;
            width: fit-content;
            min-width: fit-content;
            gap: 8px;
        }

        .navigation-link {
            display: flex;
            padding: 10px 8px;
            align-items: center;
            gap: 4px;
            align-self: stretch;
            color: #A0A0A0;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            border-radius: 8px;
            cursor: pointer;
            white-space: nowrap;
            min-width: fit-content;
            width: 100%;
        }
    }
`

export const DashboardContentBlock = styled.div`
    width: 100%;
    height: calc(100vh - 92px);
    display: flex;
    flex-direction: column;
    padding: 40px;
    overflow-y: auto;

    @media (max-width: 1200px) {
        width: 100%;
        height: calc(100vh - 170px);
        display: flex;
        flex-direction: column;
        padding: 16px;
        overflow-y: auto;
    }
`

export const DashboardMainBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    .load-more-block {
        display: flex;
        height: 50px;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
        color: #027FE3;
        text-align: center;
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
        cursor: pointer;
    }
`

export const WhatWillDesignTodayBlock = styled.div`
    display: flex;
    padding: 80px 0px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
    background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

    .what-will-design-heading {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Poppins";
        font-size: 46px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.08px;
    }

    .create-logo-button {
        display: flex;
        height: 64px;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
    }

    @media (max-width: 1200px) {
        display: flex;
        padding: 32px 16px;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

        .what-will-design-heading {
            color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
            font-family: "Poppins";
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 94.2%;
            letter-spacing: -1.08px;
        }

        .create-logo-button {
            display: flex;
            height: 64px;
            padding: 15px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: #FFCB03;
            color: #1E1E1E;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
        }
    }
`

export const RecentDesignsBlock = styled.div`
    display: flex;
    padding: 80px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
    background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

    .recent-header-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .recent-header-title {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Poppins";
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.36px;
        margin-bottom: 0;
    }

    .recent-header-filters {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .recent-filter-text {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
    }

    .recent-support-text {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        cursor: pointer;
    }

    .dashboard-logos-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
        width: 100%;
    }

    .dashboard-logo-item {
        width: 100%;
        height: 300px;
        padding: 16px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.11);
    }

    .dashboard-logo-item:hover .dashboard-logo-item-actions-block,
    .dashboard-logo-item:hover .dashboard-logo-item-saved-block {
        display: flex;
    }

    .generated-logo-text {
        display: flex;
        flex-direction: column;
        max-width: 70%;
    }

    .dashboard-logo-item-actions-block {
        display: none;
        align-items: center;
        width: calc(100% - 32px);
        justify-content: space-between;
        gap: 8px;
        position: absolute;
        left: 16px;
        bottom: 16px;
    }

    .dashboard-logo-item-action-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        padding: 8px 16px;
        border-radius: 8px;
        background: #FFFFFF;
        color: #1E1E1E;
        text-align: center;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .dashboard-logo-item-saved-block {
        display: none;
        position: absolute;
        top: 16px;
        right: 16px;
    }

    @media (max-width: 1400px) {
        .dashboard-logos-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
            width: 100%;
        }
    }

    @media (max-width: 1200px) {
        display: flex;
        padding: 32px 16px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

        .recent-header-block {
            display: flex;
            flex-direction: column;
            gap: 24px;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }

    @media (max-width: 800px) {
        .dashboard-logos-list {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 16px;
            width: 100%;
        }
    }
`

export const NoLogosBlockWrapper = styled.div`
    display: flex;
    padding: 80px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    background-color: transparent;

    .no-logos-heading-block {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .heading-text {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Poppins";
        text-align: center;
        font-size: 46px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.48px;
    }

    .heading-text-label {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .create-logo-button {
        display: flex;
        height: 64px;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
    }

    @media (max-width: 1200px) {
        display: flex;
        padding: 32px 16px;
        padding-bottom: 0;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        align-self: stretch;
        background-color: transparent;

        .heading-text {
            color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
            font-family: "Poppins";
            text-align: center;
            font-size: 34px;
            font-style: normal;
            font-weight: 400;
            line-height: 94.2%;
            letter-spacing: -0.88px;
        }

        .create-logo-button {
            display: flex;
            height: 64px;
            padding: 15px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: #FFCB03;
            color: #1E1E1E;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
        }
    }
`

export const LogosAvailableForPurchaseBlock = styled.div`
    display: flex;
    padding: 80px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
    background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

    .recent-header-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .recent-header-title {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        text-align: center;
        font-family: "Poppins";
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.36px;
        margin-bottom: 0;
    }

    .recent-header-filters {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .recent-filter-text {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
    }

    .recent-support-text {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        cursor: pointer;
    }

    @media (max-width: 1200px) {
        display: flex;
        padding: 32px 16px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

        .recent-header-block {
            display: flex;
            flex-direction: column;
            gap: 24px;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }
`

export const SavedLogosBlock = styled.div`
    display: flex;
    padding: 80px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
    background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

    .recent-header-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .recent-header-title {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        text-align: center;
        font-family: "Poppins";
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.36px;
        margin-bottom: 0;
    }

    .recent-header-filters {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .recent-filter-text {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
    }

    .recent-support-text {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        cursor: pointer;
    }

    .dashboard-logos-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
        width: 100%;
    }

    .dashboard-logo-item {
        width: 100%;
        height: 300px;
        padding: 16px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.11);
    }

    .dashboard-logo-item:hover .dashboard-logo-item-actions-block,
    .dashboard-logo-item:hover .dashboard-logo-item-saved-block {
        display: flex;
    }

    .generated-logo-text {
        display: flex;
        flex-direction: column;
        max-width: 70%;
    }

    .dashboard-logo-item-actions-block {
        display: none;
        align-items: center;
        width: calc(100% - 32px);
        justify-content: space-between;
        gap: 8px;
        position: absolute;
        left: 16px;
        bottom: 16px;
    }

    .dashboard-logo-item-action-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        padding: 8px 16px;
        border-radius: 8px;
        background: #FFFFFF;
        color: #1E1E1E;
        text-align: center;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .dashboard-logo-item-saved-block {
        display: none;
        position: absolute;
        top: 16px;
        right: 16px;
    }

    @media (max-width: 1400px) {
        .dashboard-logos-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
            width: 100%;
        }
    }
    
    @media (max-width: 1200px) {
        display: flex;
        padding: 32px 16px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

        .recent-header-block {
            display: flex;
            flex-direction: column;
            gap: 24px;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }

    @media (max-width: 800px) {
        .dashboard-logos-list {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 16px;
            width: 100%;
        }
    }
`

export const RecentBlock = styled.div`
    display: flex;
    padding: 80px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
    background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

     .recent-header-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .recent-header-title {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        text-align: center;
        font-family: "Poppins";
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.36px;
        margin-bottom: 0;
    }

    .recent-header-filters {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .recent-filter-text {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
    }

    .recent-support-text {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        cursor: pointer;
    }

    .dashboard-logos-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
        width: 100%;
    }

    .dashboard-logo-item {
        width: 100%;
        height: 300px;
        padding: 16px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.11);
    }

    .dashboard-logo-item:hover .dashboard-logo-item-actions-block,
    .dashboard-logo-item:hover .dashboard-logo-item-saved-block {
        display: flex;
    }

    .generated-logo-text {
        display: flex;
        flex-direction: column;
        max-width: 70%;
    }

    .dashboard-logo-item-actions-block {
        display: none;
        align-items: center;
        width: calc(100% - 32px);
        justify-content: space-between;
        gap: 8px;
        position: absolute;
        left: 16px;
        bottom: 16px;
    }

    .dashboard-logo-item-action-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        padding: 8px 16px;
        border-radius: 8px;
        background: #FFFFFF;
        color: #1E1E1E;
        text-align: center;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .dashboard-logo-item-saved-block {
        display: none;
        position: absolute;
        top: 16px;
        right: 16px;
    }

    .load-more-block {
        display: flex;
        height: 50px;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
        color: #027FE3;
        text-align: center;
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
        cursor: pointer;
    }

    @media (max-width: 1400px) {
        .dashboard-logos-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
            width: 100%;
        }
    }
    
    @media (max-width: 1200px) {
        display: flex;
        padding: 32px 16px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

        .recent-header-block {
            display: flex;
            flex-direction: column;
            gap: 24px;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }

    @media (max-width: 800px) {
        .dashboard-logos-list {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 16px;
            width: 100%;
        }
    }
`

export const PurchaseHistoryBlock = styled.div`
    display: flex;
    padding: 80px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
    background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

     .recent-header-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .recent-header-title {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        text-align: center;
        font-family: "Poppins";
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.36px;
        margin-bottom: 0;
    }

    .recent-header-filters {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .recent-filter-text {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
    }

    .recent-support-text {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        cursor: pointer;
    }

    @media (max-width: 1200px) {
        display: flex;
        padding: 32px 16px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

        .recent-header-block {
            display: flex;
            flex-direction: column;
            gap: 24px;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }
`

export const DeleteLogoModalBlock = styled(Modal)`
    .modal-dialog {
        min-width: unset;
        max-width: 520px;
        width: 100%;
    }

    .modal-content {
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#FFF'};
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        display: flex;
        flex-direction: column;
        padding: 0px;
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }

    .modal-body {
        padding: 64px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        align-items: center;
    }

    .delete-logo-title {
        color: ${props => props.theme.isDark ? "#FFF" : "#1E1E1E"};
        font-family: "Poppins";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: 0.36px;
    }

    .delete-logo-description {
        color: ${props => props.theme.isDark ? "#FFF" : "#1E1E1E"};
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .delete-logo-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 24px;
        margin-top: 24px;
    }

    .delete-logo-cancel {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 1.5px solid ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        color: ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .delete-logo-delete {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    @media (max-width: 1200px) {
        .modal-dialog {
            width: unset;
        }

        .modal-body {
            padding: 64px 32px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            justify-content: center;
            align-items: center;
        }

        .delete-logo-actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 8px;
            margin-top: 24px;
        }

        .delete-logo-cancel {
            display: flex;
            padding: 15px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            border: 1.5px solid ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
            color: ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: 100%;
        }

        .delete-logo-delete {
            display: flex;
            padding: 15px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            background: #FFCB03;
            color: #1E1E1E;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: 100%;
        }
    }
`

export const CheckoutBlock = styled.div`
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    min-height: calc(100vh);
    padding-top: 162px;
    padding-bottom: 60px;
    background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

    .checkout-form-wrapper {
        display: flex;
        width: 100%;
        max-width: 1420px;
        margin: 0 auto;
    }

    .checkout-body {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 16px;
        margin-top: 40px;
    }

    .checkout-body-header {
        display: flex;
        align-items: center;
        gap: 8px;
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        text-align: center;
        font-family: "Almarai";
        font-size: 42px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: 0.48px;
        cursor: pointer;
    }

    .checkout-address-block {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .checkout-billing-header {
        color:  ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.26px;
        margin-bottom: 0;
    }

    .checkout-billing-label {
        color:  ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .checkout-card-title {
        color:  ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;
        margin-bottom: 16px;
        margin-top: 24px;
    }
    
    .checkout-plan-info {
        border-radius: 8px;
        border: 1px solid  ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .checkout-plan-info-header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    .checkout-plan-info-header-subscription {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .checkout-plan-info-header-prices {
        color: ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .checkout-plan-info-plan-name {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        margin-bottom: 0;
    }

    .checkout-line {
        height: 1px;
        width: 100%;
        background: ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
    }

    .checkout-button {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        width: 100%;
    }

    .checkout-bottom-text {
        color: #A0A0A0;
        text-align: center;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
    }

    .checkout-bottom-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
    }

    .checkout-page-loader-wrapper {
        width: 100%;
        min-height: calc(100vh - 76px);
        display: flex;
        justify-content: center;
    }

    .checkout-page-loader {
        background: transparent;
        width: 100%;
        height: fit-content;
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 24px;
        overflow: auto;
        max-width: 1420px;
    }

    .checkout-header {
        width: 100%;
        border-radius: 14px;
        background: #FFF;
        padding: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .checkout-header span {
        color: #334155;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
    }

    .checkout-header svg {
        font-size: 27px;
    }

    .checkout-body {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        width: 100%;
        gap: 24px;
        padding: 0 16px;
    }

    .checkout-new-title-powered {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
        padding-bottom: 40px;
    }

    .checkout-field-block {
        width: 66.6666%;
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#FFFFFF'};
        border-radius: 16px;
        height: fit-content;
        border: 1px solid ${props => props.theme.isDark ? 'transparent' : '#CDCDCD'};
        padding: 40px;
    }

    .checkout-body-header {
        width: 100%;
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Almarai";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.36px;
    }

    .checkout-card-field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 0;
    }

    .checkout-address-block {
        padding: 24px;
        padding-bottom: 0;
    }

    .checkout-address-block h2 {
        color: var(--black, #334155);
        font-family: Inter;
        font-size: 23px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        margin-bottom: 8px;
    }

    .checkout-address-block p {
        color: var(--black, #334155);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        margin-bottom: 16px;
    }

    .checkout-input-block input {
        display: flex;
        width: 100%;
        height: 44px;
        padding: 8px 16px;
        align-items: center;
        gap: 16px;
        border-radius: 8px;
        background: var(--grey-1, #F1F5F9);
        border: none;
    }

    .checkout-input-block span {
        color: var(--black, #334155);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 12px;
        display: block;
        margin-bottom: 4px;
    }

    .checkout-address-fields {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        justify-content:center;
        align-items: center;
    }

    .checkout-input-block-width {
        flex: 0 0 calc(50% - 8px);
    }

    .payment-element-wrapper {
        padding-bottom: 24px;
    }

    .checkout-body-right {
        width: calc(100% / 3);
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#FFFFFF'};
        border-radius: 16px;
        padding: 24px;
        gap: 16px;
        display: flex;
        flex-direction: column;
        border: 1px solid ${props => props.theme.isDark ? 'transparent' : '#CDCDCD'};
    }

    .checkout-plan-info {
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-radius: 8px 8px 0px 0px;
        border: 1px solid #E2E8F0;
        border-bottom: none;
        background: #FFF;
    }

    .checkout-plan-info h4 {
        color: var(--black, #334155);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
    }

    .checkout-plan-price p {
        color: var(--black, #334155);
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px; 
    }

    .checkout-plan-total-price p {
        color: var(--black, #334155);
        text-align: center;
        font-family: Inter;
        font-size: 19px;
        font-style: normal;
        font-weight: 700;
        line-height: 23px;
    }

    .checkout-line {
        background: #F1F5F9;
        width: 100%;
        height: 1px;
    }

    .checkout-plan-text {
        color: var(--grey-4, #9A9EA3);
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        margin: 0;
    }

    .checkout-satisfaction {
        display: flex;
        height: 60px;
        padding: 16px;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        border-radius: 0px 0px 8px 8px;
        background: var(--light-blue, #F2F2F2);
        color: var(--black, #334155);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        border: 1px solid #E2E8F0;
        border-top: none;
    }

    .checkout-satisfaction svg {
        color: #19F;
        font-size: 24px;
    }

    .checkout-button {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .checkout-button:disabled {
        background: var(--purple, #9747ff21);
    }

    .checkout-bottom-text {
        color: var(--black, #334155);
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        width: 100%;
        margin-bottom: 0;
        margin-top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .checkout-bottom-text svg {
        font-size: 18px;
        color: #7D8898;
    }

    .checkout-bottom-label {
        color: var(--off-black, #7D8898);
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    .checkout-bottom-label-wrapper {
        padding: 24px;
    }

    .change-selected-plan-block-mobile {
        display: none;
    }

    .checkout-new-purchase-block {
        display: none;
    }

    .checkout-new-payment-method-title {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.26px;
    }

    .select-payments-block-wrapper {
        display: flex;
        padding: 4px;
        align-items: center;
        border-radius: 100px;
        background: ${props => props.theme.isDark ? '#4E4E4E' : '#EDF3F9'};
    }

    .selecet-payment-method-element {
        display: flex;
        align-items: center;
        gap: 8px;
        display: flex;
        height: 36px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;
    }

    .select-payment-method-name {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#7D8898'};
        text-align: center;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .selecet-payment-method-element.active {
        display: flex;
        height: 36px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 100px;
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#FFFFFF'};
    } 

    .selecet-payment-method-element.active .select-payment-method-name {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#7D8898'};
        text-align: center;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .checkout-guarantee-block {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .checkout-guarantee-icon-wrapper {
        display: flex;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: ${props => props.theme.isDark ? '#4E4E4E' : '#F0F0F0'};
    }

    .checkout-guarantee-block-title {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        margin-top: 12px;
    }

    .checkout-guarantee-block-text {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
        margin-top: 4px;
    }

    .disable-on-desktop {
        display: none;
    }

    .checkout-new-selected-plan-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 24px;
    }

    .change-selected-plan-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#E2E8F0'};
    }

    .change-selected-plan-block-info {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .change-selected-plan-block-title {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    .change-selected-plan-block-name {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#334155'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .change-selected-plan-block-change-btn {
        color: ${props => props.theme.isDark ? '#BEE0EA' : '#027FE3'};
        text-align: center;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        cursor: pointer;
    }

    .checkout-new-due-total-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .checkout-new-due-total-title {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        text-align: center;
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
    }

    .checkout-new-due-total-price {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
    }

    .new-flow-checkout-text {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }

    .stripe-purchase-button {
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: end;
        padding: 0;
    }

    .payment-paypal-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        padding: 16px 0;
    }

    .payment-paypal-block-text {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#334155'};
        text-align: center;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
    }

    .secure-checkout-block-mobile {
        display: none;
    }

    .payment-checkout-underline {
        display: none;
    }

    @media (max-width: 1200px) {
        padding: 0px;
        padding-top: 124px;
        padding-bottom: 32px;

        .disable-on-desktop {
            display: flex;
        }

        .payment-element-wrapper {
            padding-bottom: 0px;
        }

        .checkout-page-loader {
            background: transparent;
            width: 100%;
            height: fit-content;
            padding: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 24px;
            overflow: auto;
            max-width: 1420px;
        }

        .new-flow-checkout-text {
            color: #A0A0A0;
            font-family: "Inter";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.12px;
            width: calc(100% + 32px);
            margin-left: -16px;
            border-top: 4px solid ${props => props.theme.isDark ? '#2E2E2E' : '#E2E8F0'};
            padding: 0 16px;
            padding-top: 16px;
        }

        .stripe-purchase-button {
            display: none;
            flex-direction: column;
            gap: 24px;
            justify-content: end;
            padding: 0;
        }

        .secure-checkout-text-mobile {
            color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
            text-align: center;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
        }

        .secure-checkout-block-mobile {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: fixed;
            top: 92px;
            left: 0;
            width: 100%;
            padding: 16px;
            border-bottom: 1px solid ${props => props.theme.isDark ? '#A0A0A0' : '#F0F0F0'};
            background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFFFFF'};
        }

        .checkout-new-selected-plan-block {
            width: 100%;
            display: none;
            flex-direction: column;
            gap: 24px;
            margin-bottom: 24px;
        }

        .selecet-payment-method-element {
            display: flex;
            align-items: center;
            gap: 8px;
            display: flex;
            height: 36px;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            width: 50%;
        }

        .checkout-new-title-powered {
            display: none;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            border-bottom: 1px solid ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
            padding-bottom: 40px;
        }

        .select-payments-block-wrapper {
            display: flex;
            padding: 4px;
            align-items: center;
            border-radius: 100px;
            background: ${props => props.theme.isDark ? '#4E4E4E' : '#EDF3F9'};
            width: 100%;
        }

        .payment-checkout-underline {
            display: flex;
            width: 100%;
            height: 1px;
            background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#E2E8F0'};
            margin-bottom: 16px;
        }

        .checkout-card-field {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: start;
            padding: 16px 0;
            gap: 16px;
        }

        .checkout-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 16px;
            margin-top: 100px;
            border-top: 3px solid ${props => props.theme.isDark ? '#2E2E2E' : '#E2E8F0'};
            padding: 0 16px;
        }

        .checkout-field-block {
            display: flex;
            flex-direction: column;
            padding: 0px;
            border-radius: 8px;
            border: none;
            width: 100%;
            background-color: transparent;
        }

        .checkout-body-right {
            width: 100%;
            background: ${props => props.theme.isDark ? 'transparent' : '#FFFFFF'};
            border-radius: 0px;
            padding: 0px;
            gap: 16px;
            display: flex;
            flex-direction: column;
            border: none;
        }

        .checkout-body-header {
            display: flex;
            align-items: center;
            gap: 8px;
            color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
            text-align: center;
            font-family: "Poppins";
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 94.2%;
            letter-spacing: 0.48px;
            cursor: pointer;
        }

        .checkout-new-purchase-block {
            display: flex;
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            border-top: 1px solid ${props => props.theme.isDark ? '#2E2E2E' : '#E2E8F0'};
            background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFFFFF'};
            width: 100%;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 2;
        }

        .change-selected-plan-block-mobile {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            width: 100%;
            padding: 16px;
            border-top: 1px solid ${props => props.theme.isDark ? '#2E2E2E' : '#E2E8F0'};
            border-bottom: 1px solid ${props => props.theme.isDark ? '#2E2E2E' : '#E2E8F0'};
            position: fixed;
            top: 145px;
            left: 0;
            background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFFFFF'};
            z-index: 2;
        }

        .checkout-new-purchase-button {
            display: flex;
            padding: 15px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: #FFCB03;
            color: #1E1E1E;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: 100%;
        }

        .checkout-guarantee-underline {
            width: calc(100% + 32px);
            margin-left: -16px;
            height: 4px;
            background: ${props => props.theme.isDark ? '#2E2E2E' : '#E2E8F0'};
        }
    }
`

export const UpgradeSuccessBLock = styled.div`
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    min-height: calc(100vh);
    padding-top: 162px;
    padding-bottom: 60px;
    background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

    .checkout-page {
        display: flex;
        flex-direction: column;
        max-width: 1200px;
        width: 100%;
    }

    .checkout-body {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 16px;
        margin-top: 40px;
    }

    .checkout-body-header {
        display: flex;
        align-items: center;
        gap: 8px;
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        text-align: center;
        font-family: "Poppins";
        font-size: 42px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: 0.48px;
        cursor: pointer;
    }

    .checkout-field-block {
        display: flex;
        flex-direction: column;
        padding: 32px;
        border-radius: 8px;
        border: 1px solid  ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        width: 100%;
    }

    .checkout-address-block {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .checkout-billing-header {
        color:  ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.26px;
        margin-bottom: 0;
    }

    .checkout-billing-label {
        color:  ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .checkout-card-title {
        color:  ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Poppins";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;
        margin-bottom: 16px;
        margin-top: 24px;
    }

    .checkout-body-right {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        max-width: 300px;
    }

    .checkout-plan-info {
        border-radius: 8px;
        border: 1px solid  ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
    }

    .checkout-plan-info-header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    .checkout-plan-info-header-subscription {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .checkout-plan-info-header-prices {
        color: ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .checkout-plan-info-plan-name {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        margin-bottom: 0;
    }

    .checkout-line {
        height: 1px;
        width: 100%;
        background: ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
    }

    .checkout-button {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        width: 100%;
    }

    .checkout-bottom-text {
        color: #A0A0A0;
        text-align: center;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
    }

    .checkout-bottom-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
    }

    .success-upgrade-title {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.26px;
        margin-bottom: 8px;
    }

    .success-upgrade-description {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        margin: 0;
    }

    .success-upgrade-download-actions {
        display: flex;
        gap: 8px;
        margin-top: 40px;
    }

    .success-upgrade-action-button {
        display: flex;
        padding: 15px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1.5px solid #A0A0A0;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .success-upgrade-info-block {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 40px;
    }

    .success-upgrade-infor-title {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.26px;
    }

    .success-upgrade-infor-text {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        margin-bottom: 0;
    }

    @media (max-width: 1200px) {
        padding: 0 16px;
        padding-top: 124px;
        padding-bottom: 32px;

        .checkout-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            gap: 16px;
            margin-top: 24px;
        }

        .checkout-field-block {
            display: flex;
            flex-direction: column;
            padding: 16px;
            border-radius: 8px;
            border: 1px solid  ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
            width: 100%;
        }

        .checkout-body-right {
            display: flex;
            flex-direction: column;
            gap: 24px;
            width: 100%;
            max-width: unset;
        }

        .checkout-body-header {
            display: flex;
            align-items: center;
            gap: 8px;
            color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
            text-align: center;
            font-family: "Poppins";
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 94.2%;
            letter-spacing: -0.68px;
            cursor: pointer;
        }

        .success-upgrade-download-actions {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 40px;
        }
    }
`

export const AccountBlock = styled.div`
    display: flex;
    padding: 80px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    align-self: stretch;
    border-radius: 8px;
    background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

    .recent-header-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .recent-header-title {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Poppins";
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.36px;
        margin-bottom: 0;
    }

    .recent-header-filters {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .recent-filter-text {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
    }

    .recent-support-text {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        cursor: pointer;
    }

    .account-info-block {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
    }

    .account-info-block-title {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.26px;
    }

    .account-info-block-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .account-info-input-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 4px;
    }

    .account-info-input-label {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
    }

    .account-info-input-wrapper {
        position: relative;
        width: 100%;
    }

    .account-info-input {
        display: flex;
        padding: 16px;
        padding-right: 40px;
        align-items: flex-start;
        border-radius: 8px;
        border: 1.5px solid #A0A0A0;
        background-color: transparent;
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        width: 100%;
    }

    .account-info-input::placeholder {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .account-info-edit-icon {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .account-info-edit-save-btn {
        display: flex;
        padding: 10px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        margin-right: -8px;
    }

    .account-info-add-payment-method {
        display: flex;
        height: 88px;
        padding: 24px;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        width: 100%;
        cursor: pointer;
    }

    .account-info-current-plan-block {
        display: flex;
        flex-direction: column;
        padding: 24px;
        border-radius: 8px;
        border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        width: fit-content;
        min-width: 350px;
    }

    .current-plan-name {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.26px;
        margin: 4px 0;
    }

    .current-plan-payment-type {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .current-plan-underline {
        height: 1px;
        width: 100%;
        background: ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        margin: 24px 0;
    }

    .current-plan-info-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
    }

    .current-plan-info-side-block {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        display: flex;
        flex-direction: column;
    }

    .current-plan-exclude-tax {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .delete-account-btn {
        color: #027FE3;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .account-payment-methods-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
    }

    .account-info-existing-payment-method {
        display: flex;
        align-items: center;
        min-height: 88px;
        gap: 8px;
        padding: 0 24px;
        border-radius: 8px;
        border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        position: relative;
        height: fit-content;
    }

    .existing-card-info-block {
        display: flex;
        flex-direction: column;
    }

    .existing-card-info-ending {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .exisiting-card-info-expires {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .existing-card-edit {
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .add-payment-method-wrapper {
        width: 100%;
        position: relative;
    }

    .add-payment-method-block {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        padding: 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
    }

    .add-payment-method-block .StripeElement {
        width: 100%;
    }

    .add-payment-form-element {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .add-credit-card-error {
        color: #EF4444;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
        text-align: center;
    }

    .add-payment-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 4px;
    }

    .add-payment-input-label {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
    }

    .add-payment-input-wrapper {
        display: flex;
        padding: 16px;
        align-items: flex-start;
        border-radius: 8px;
        border: 1.5px solid ${props => props.theme.isDark ? '#A0A0A0' : '#F0F0F0'};
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#E5E5E5'};
    }

    .add-payment-cvc-expiry-block {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .add-payment-save-btn {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .add-payment-cancel-btn {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        border-radius: 8px;
        border: 1.5px solid ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
    }

    .account-payment-method-edit-block {
        position: absolute;
        top: 0;
        left: 0;
    }

    .edit-payment-method-block {
        position: absolute;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        border-radius: 8px;
        padding: 24px;
        z-index: 2;
    }

    .edit-payment-element-input {
        background-color: transparent;
        width: 100%;
    }

    .edit-payment-delete-payment-method {
        display: flex;
        height: 40px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #EF4444;
        text-align: center;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
        width: 100%;
    }

    .account-info-change-theme-block {
        position: relative;
    }

    .account-info-change-theme-button {
        display: flex;
        width: 254px;
        align-items: center;
        justify-content: space-between;
        background: transparent;
        padding: 16px;
        gap: 10px;
        border-radius: 8px;
        border: 1.5px solid #A0A0A0;
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#A0A0A0'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        cursor: pointer;
    }

    .account-info-change-theme-button.active {
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        border: 1.5px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
    }

    .account-info-change-theme-dropdown {
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        padding: 8px;
        position: absolute;
        top: 58px;
        left: 0;
        width: 254px;
        border-radius: 8px;
        z-index: 2;
    }

    .account-info-change-theme-dropdown-item {
        display: flex;
        padding: 16px 8px;
        align-items: flex-start;
        gap: 10px;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        border-radius: 8px;
        cursor: pointer;
    }

    .account-info-change-theme-dropdown-item:hover {
        background: ${props => props.theme.isDark ? '#1E1E1E' : '#E5E5E5'};
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#027FE3'}
    }

    .expanded-payment-items-list {
        min-height: 420px;
    }

    @media (max-width: 1450px) {
        .account-payment-methods-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
        }
    }

    @media (max-width: 1200px) {
        display: flex;
        padding: 32px 16px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        border-radius: 8px;
        background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

        .recent-header-block {
            display: flex;
            flex-direction: column;
            gap: 24px;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .account-info-add-payment-method {
            display: flex;
            height: 88px;
            padding: 24px;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
            color: #A0A0A0;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: 100%;
            min-width: unset;
        }

        .account-info-current-plan-block {
            display: flex;
            flex-direction: column;
            padding: 24px;
            border-radius: 8px;
            border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
            background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
            width: 100%;
            min-width: unset;
        }

        .current-plan-info-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
        }
    }

    @media (max-width: 740px) {
        .account-payment-methods-list {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 16px;
        }
    }
`

export const DeleteAccountModalBlock = styled(Modal)`
    .modal-dialog {
        min-width: unset;
        max-width: 520px;
        width: 100%;
    }

    .modal-content {
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#FFF'};
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        display: flex;
        flex-direction: column;
        padding: 0px;
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }

    .modal-body {
        padding: 64px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        align-items: center;
    }

    .delete-account-title {
        color: ${props => props.theme.isDark ? "#FFF" : "#1E1E1E"};
        font-family: "Poppins";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: 0.36px;
    }

    .delete-account-description {
        color: ${props => props.theme.isDark ? "#FFF" : "#1E1E1E"};
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .delete-account-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 24px;
        margin-top: 24px;
    }

    .delete-account-cancel {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 1.5px solid ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        color: ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .delete-account-delete {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    @media (max-width: 1200px) {
        .modal-dialog {
            min-width: unset;
            max-width: 520px;
            width: unset;
        }

        .modal-body {
            padding: 64px 32px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            justify-content: center;
            align-items: center;
        }

        .delete-account-actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 8px;
            margin-top: 24px;
        }

        .delete-account-cancel {
            display: flex;
            padding: 15px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            border: 1.5px solid ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
            color: ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: 100%;
        }

        .delete-account-delete {
            display: flex;
            padding: 15px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            background: #FFCB03;
            color: #1E1E1E;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: 100%;
        }
    }
`

export const BillingPlansBlock = styled.div`
    display: flex;
    padding: 80px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    align-self: stretch;
    border-radius: 8px;
    background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

    .recent-header-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .recent-header-title {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Poppins";
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -1.36px;
        margin-bottom: 0;
    }

    .recent-header-filters {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .recent-filter-text {
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
    }

    .recent-support-text {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        cursor: pointer;
    }

    .billings-info-block {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
    }

    .billings-info-block-title {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.26px;
    }

    .billings-info-current-plan-block {
        display: flex;
        align-items: start;
        width: 100%;
        border-radius: 8px;
        border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        padding: 24px;
        gap: 24px;
    }

    .billings-info-current-plan {
        display: flex;
        flex-direction: column;
        min-width: 320px;
    }

    .current-plan-name {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.26px;
        margin: 4px 0;
    }

    .current-plan-payment-type {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        margin-bottom: 24px;
    }

    .current-plan-underline {
        height: 1px;
        width: 100%;
        background: ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        margin: 24px 0;
    }

    .current-plan-info-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
    }

    .current-plan-info-side-block {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        display: flex;
        flex-direction: column;
    }

    .current-plan-exclude-tax {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .billings-vertical-line {
        width: 1px;
        height: 100%;
        background-color: ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
    }

    .billings-current-plan-options {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
    }

    .billing-current-plan-options-title {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        margin: 0;
    }

    .billing-current-plan-options-block {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        width: fit-content;
    }

    .billing-current-plan-option-item {
        display: flex;
        align-items: center;
        gap: 8px;
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        margin: 0;
    }

    .billings-manage-content-block {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
        width: 100%;
    }

    .manage-block-wrapper {
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        border-radius: 8px;
        border: 2px solid  ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        gap: 8px;
    }

    .manage-block-title {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        margin: 0;
    }

    .manage-block-description {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .manage-block-button {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        border-radius: 8px;
        background: #FFCB03;
        margin-top: 24px;
    }

    .billings-info-add-payment-method {
        display: flex;
        height: 88px;
        padding: 24px;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        width: 100%;
        cursor: pointer;
    }

    .billings-history-wrapper {
        display: flex;
        padding: 24px;
        flex-direction: column;
        border-radius: 8px;
        border: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        overflow-x: auto;
    }

    .table-head-cell {
        padding: 8px;
        padding-bottom: 24px;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .table-head-row {
        border-bottom: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
    }

    .table-body-row {
        border-bottom: 1px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
    }

    .table-body-row:last-child {
        border: none;
    }

    .table-body-cell {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        padding: 24px 8px;
        white-space: nowrap;
    }

    .billing-table-actions {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .billing-table-action-block {
        display: flex;
        height: 40px;
        width: 40px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background: ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
        cursor: pointer;
    }

    .billing-table-action-block img {
        filter: ${props => props.theme.isDark ? 'invert(100%) sepia(97%) saturate(11%) hue-rotate(180deg) brightness(104%) contrast(103%)' : ''};
    }

    .billing-table-action-block:hover {
        border: 2px solid #FFCB03;
        background: #FFCB03;
    }

    .billing-table-action-block:hover img {
        filter: unset;
    }

    .billing-history-empty {
        padding: 32px 16px;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        text-align: center;
    }

    .account-payment-methods-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
    }

    .account-info-existing-payment-method {
        display: flex;
        align-items: center;
        height: 88px;
        gap: 8px;
        padding: 24px;
        border-radius: 8px;
        border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        position: relative;
        height: fit-content;
    }

    .existing-card-info-block {
        display: flex;
        flex-direction: column;
    }

    .existing-card-info-ending {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .exisiting-card-info-expires {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .existing-card-edit {
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .account-info-add-payment-method {
        display: flex;
        height: 88px;
        padding: 24px;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        width: 100%;
        cursor: pointer;
    }

    .account-info-current-plan-block {
        display: flex;
        flex-direction: column;
        padding: 24px;
        border-radius: 8px;
        border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        width: fit-content;
        min-width: 350px;
    }

    .current-plan-name {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.26px;
        margin: 4px 0;
    }

    .current-plan-payment-type {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .current-plan-underline {
        height: 1px;
        width: 100%;
        background: ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        margin: 24px 0;
    }

    .current-plan-info-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
    }

    .current-plan-info-side-block {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        display: flex;
        flex-direction: column;
    }

    .current-plan-exclude-tax {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .delete-account-btn {
        color: #027FE3;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .account-payment-methods-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
    }

    .account-info-existing-payment-method {
        display: flex;
        align-items: center;
        min-height: 88px;
        gap: 8px;
        padding: 24px;
        border-radius: 8px;
        border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        position: relative;
        height: fit-content;
    }

    .existing-card-info-block {
        display: flex;
        flex-direction: column;
    }

    .existing-card-info-ending {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .exisiting-card-info-expires {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .existing-card-edit {
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .add-payment-method-wrapper {
        width: 100%;
        position: relative;
    }

    .add-payment-method-block {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        padding: 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
    }

    .add-payment-method-block .StripeElement {
        width: 100%;
    }

    .add-payment-form-element {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .add-credit-card-error {
        color: #EF4444;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
        text-align: center;
    }

    .add-payment-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 4px;
    }

    .add-payment-input-label {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
    }

    .add-payment-input-wrapper {
        display: flex;
        padding: 16px;
        align-items: flex-start;
        border-radius: 8px;
        border: 1.5px solid ${props => props.theme.isDark ? '#A0A0A0' : '#F0F0F0'};
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#E5E5E5'};
    }

    .add-payment-cvc-expiry-block {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .add-payment-save-btn {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .add-payment-cancel-btn {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        border-radius: 8px;
        border: 1.5px solid ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
    }

    .account-payment-method-edit-block {
        position: absolute;
        top: 0;
        left: 0;
    }

    .edit-payment-method-block {
        position: absolute;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
        border-radius: 8px;
        padding: 24px;
        z-index: 2;
    }

    .edit-payment-element-input {
        background-color: transparent;
        width: 100%;
    }

    .edit-payment-delete-payment-method {
        display: flex;
        height: 40px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #EF4444;
        text-align: center;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
        width: 100%;
    }

    .expanded-payment-items-list {
        min-height: 420px;
    }

    @media (max-width: 1450px) {
        .account-payment-methods-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
        }
    }

    @media (max-width: 1200px) {
        display: flex;
        padding: 32px 16px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        border-radius: 8px;
        background: ${props => props.theme.isDark ? '#1E1E1E' : '#FFF'};

        .recent-header-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 24px;
        }

        .billings-info-current-plan-block {
            display: flex;
            flex-direction: column;
            align-items: start;
            width: 100%;
            border-radius: 8px;
            border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
            background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
            padding: 24px;
            gap: 24px;
        }

        .billings-info-current-plan {
            display: flex;
            flex-direction: column;
            min-width: unset;
            width: 100%;
        }

        .current-plan-info-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 12px;
        }

        .billing-current-plan-options-block {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 16px;
            width: fit-content;
        }

        .billings-manage-content-block {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 24px;
            width: 100%;
        }

        .billings-info-add-payment-method {
            display: flex;
            height: 88px;
            padding: 24px;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            border: 2px solid ${props => props.theme.isDark ? '#5E5E5E' : '#CDCDCD'};
            color: #A0A0A0;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: 100%;
            min-width: unset;
        }
    }

    @media (max-width: 740px) {
        .account-payment-methods-list {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 16px;
        }
    }
`

export const SendInvoiceModalBlock = styled(Modal)`
    .modal-dialog {
        min-width: unset;
        max-width: 520px;
        width: 100%;
    }

    .modal-content {
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#FFF'};
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        display: flex;
        flex-direction: column;
        padding: 0px;
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }

    .modal-body {
        padding: 64px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        align-items: center;
    }

    .send-invoice-title {
        color: ${props => props.theme.isDark ? "#FFF" : "#1E1E1E"};
        font-family: "Poppins";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: 0.36px;
        text-align: center;
    }

    .send-invoice-description {
        color: ${props => props.theme.isDark ? "#FFF" : "#1E1E1E"};
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .send-invoice-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 24px;
        margin-top: 24px;
    }

    .send-invoice-cancel {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 1.5px solid ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        color: ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .send-invoice-delete {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }
`

export const VerificationEmailSentModalBlock = styled(Modal)`
    .modal-dialog {
        min-width: unset;
        max-width: 520px;
        width: 100%;
    }

    .modal-content {
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#FFF'};
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        display: flex;
        flex-direction: column;
        padding: 0px;
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }

    .modal-body {
        padding: 64px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        align-items: center;
    }

    .delete-account-title {
        color: ${props => props.theme.isDark ? "#FFF" : "#1E1E1E"};
        font-family: "Poppins";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: 0.36px;
        text-align: center;
    }

    .delete-account-description {
        color: ${props => props.theme.isDark ? "#FFF" : "#1E1E1E"};
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .delete-account-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 24px;
        margin-top: 24px;
    }

    .delete-account-cancel {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 1.5px solid ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        color: ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .delete-account-delete {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    @media (max-width: 1200px) {
        .modal-dialog {
            min-width: unset;
            max-width: 520px;
            width: unset;
        }

        .modal-body {
            padding: 64px 32px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            justify-content: center;
            align-items: center;
        }

        .delete-account-actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 8px;
            margin-top: 24px;
        }

        .delete-account-cancel {
            display: flex;
            padding: 15px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            border: 1.5px solid ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
            color: ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: 100%;
        }

        .delete-account-delete {
            display: flex;
            padding: 15px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            background: #FFCB03;
            color: #1E1E1E;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: 100%;
        }
    }
`

export const EnterPasswordModalBlock = styled(Modal)`
    .modal-dialog {
        min-width: unset;
        max-width: 520px;
        width: 100%;
    }

    .modal-content {
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#FFF'};
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        display: flex;
        flex-direction: column;
        padding: 0px;
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }

    .modal-body {
        padding: 64px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        align-items: center;
    }

    .send-invoice-title {
        color: ${props => props.theme.isDark ? "#FFF" : "#1E1E1E"};
        font-family: "Poppins";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: 0.36px;
        text-align: center;
    }

    .send-invoice-description {
        color: ${props => props.theme.isDark ? "#FFF" : "#1E1E1E"};
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .send-invoice-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 24px;
        margin-top: 24px;
    }

    .send-invoice-cancel {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 1.5px solid ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        color: ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .send-invoice-delete {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .reenter-password-input {
        display: flex;
        padding: 16px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid #A0A0A0;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        background: ${props => props.theme.isDark ? 'transparent' : '#F0F0F0'};
        margin-top: 24px;
    }

    @media (max-width: 1200px) {
        .modal-body {
            padding: 64px 32px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            justify-content: center;
            align-items: center;
        }
    }
`

export const DeletePaymentMethodModalBlock = styled(Modal)`
    .modal-dialog {
        min-width: unset;
        max-width: 520px;
        width: 100%;
    }

    .modal-content {
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#FFF'};
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        display: flex;
        flex-direction: column;
        padding: 0px;
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }

    .modal-body {
        padding: 64px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        align-items: center;
    }

    .send-invoice-title {
        color: ${props => props.theme.isDark ? "#FFF" : "#1E1E1E"};
        font-family: "Poppins";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: 0.36px;
        text-align: center;
    }

    .send-invoice-description {
        color: ${props => props.theme.isDark ? "#FFF" : "#1E1E1E"};
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .send-invoice-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 24px;
        margin-top: 24px;
    }

    .send-invoice-cancel {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 1.5px solid ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        color: ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .send-invoice-delete {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    @media (max-width: 1200px) {
        .modal-dialog {
            min-width: unset;
            max-width: 520px;
            width: unset;
        }

        .modal-body {
            padding: 64px 32px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            justify-content: center;
            align-items: center;
        }

        .send-invoice-title {
            color: ${props => props.theme.isDark ? "#FFF" : "#1E1E1E"};
            font-family: "Poppins";
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 94.2%;
            letter-spacing: 0.36px;
            text-align: center;
        }

        .send-invoice-actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 24px;
            margin-top: 24px;
        }

        .send-invoice-cancel {
            display: flex;
            padding: 15px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            border: 1.5px solid ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
            color: ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: 100%;
        }

        .send-invoice-delete {
            display: flex;
            padding: 15px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            background: #FFCB03;
            color: #1E1E1E;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: 100%;
        }
    }
`

export const CancelSubscriptionModalBlock = styled(Modal)`
    .modal-dialog {
        min-width: unset;
        max-width: 720px;
        width: 100%;
    }

    .modal-content {
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#FFF'};
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        display: flex;
        flex-direction: column;
        padding: 0px;
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }

    .modal-body {
        padding: 64px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        align-items: center;
    }

    .send-invoice-title {
        color: ${props => props.theme.isDark ? "#FFF" : "#1E1E1E"};
        font-family: "Poppins";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: 0.36px;
        text-align: center;
    }

    .send-invoice-description {
        color: ${props => props.theme.isDark ? "#FFF" : "#1E1E1E"};
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .send-invoice-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 24px;
        margin-top: 24px;
    }

    .send-invoice-cancel {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 1.5px solid ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        color: ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .send-invoice-delete {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .billings-current-plan-options {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        margin: 24px 0;
    }

    .billing-current-plan-options-title {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        margin: 0;
    }

    .billing-current-plan-options-block {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        width: fit-content;
    }

    .billing-current-plan-option-item {
        display: flex;
        align-items: center;
        gap: 8px;
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        margin: 0;
    }

    .reenter-password-input {
        display: flex;
        padding: 16px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid #A0A0A0;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        background: ${props => props.theme.isDark ? 'transparent' : '#F0F0F0'};
        margin-top: 24px;
    }

    .cancel-subscription-error {
        color: #F87171;
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        margin-top: 16px;
    }

    @media (max-width: 1200px) {
        .modal-dialog {
            min-width: unset;
            max-width: 720px;
            width: unset;
        }

        .modal-body {
            padding: 64px 32px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            justify-content: center;
            align-items: center;
        }

        .send-invoice-actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 24px;
            margin-top: 24px;
        }

        .send-invoice-cancel {
            display: flex;
            padding: 15px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            border: 1.5px solid ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
            color: ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: 100%;
        }

        .send-invoice-delete {
            display: flex;
            padding: 15px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            background: #FFCB03;
            color: #1E1E1E;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: 100%;
        }
    }
`

export const ChangePasswordModalBlock = styled(Modal)`
    .modal-dialog {
        min-width: unset;
        max-width: 560px;
        width: 100%;
    }

    .modal-content {
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#FFF'};
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        display: flex;
        flex-direction: column;
        padding: 0px;
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }

    .modal-body {
        padding: 64px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        align-items: center;
    }

    .send-invoice-title {
        color: ${props => props.theme.isDark ? "#FFF" : "#1E1E1E"};
        font-family: "Poppins";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: 0.36px;
        text-align: center;
    }

    .send-invoice-description {
        color: ${props => props.theme.isDark ? "#FFF" : "#1E1E1E"};
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .send-invoice-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 24px;
        margin-top: 24px;
    }

    .send-invoice-cancel {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 1.5px solid ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        color: ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .send-invoice-delete {
        display: flex;
        padding: 15px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        background: #FFCB03;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .billings-current-plan-options {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        margin: 24px 0;
    }

    .billing-current-plan-options-title {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        margin: 0;
    }

    .billing-current-plan-options-block {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        width: fit-content;
    }

    .billing-current-plan-option-item {
        display: flex;
        align-items: center;
        gap: 8px;
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        margin: 0;
    }

    .reenter-password-input {
        display: flex;
        padding: 16px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid #A0A0A0;
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        background: ${props => props.theme.isDark ? 'transparent' : '#F0F0F0'};
        margin-top: 24px;
    }

    .cancel-subscription-error {
        color: #F87171;
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        margin-top: 16px;
    }

    .cancel-subscription-error {
        color: #F87171;
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        margin-top: 16px;
    }

    @media (max-width: 1200px) {
        .modal-dialog {
            min-width: unset;
            max-width: 520px;
            width: unset;
        }

        .modal-body {
            padding: 64px 32px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            justify-content: center;
            align-items: center;
        }

        .send-invoice-actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 24px;
            margin-top: 24px;
        }

        .send-invoice-cancel {
            display: flex;
            padding: 15px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            border: 1.5px solid ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
            color: ${props => props.theme.isDark ? '#FFCB03' : '#A0A0A0'};
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: 100%;
        }

        .send-invoice-delete {
            display: flex;
            padding: 15px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            background: #FFCB03;
            color: #1E1E1E;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            width: 100%;
        }
    }
`

export const SupportWindowBlock = styled.div`
    width: 340px;
    padding: 10px 16px;
    background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFFFFF'};
    position: absolute;
    right: ${props => props.theme.position === 'top' ? '0' : '24px'};
    bottom: ${props => props.theme.position === 'top' ? '55px' : '24px'};
    border-radius: 8px;
    box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.50);
    z-index: 5;

    .support-window-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .support-window-header-title {
        display: flex;
        align-items: center;
        gap: 10px;
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .support-window-search-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        gap: 10px;
        border-radius: 8px;
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        margin-top: 16px;
    }

    .support-search-input {
        background-color: transparent;
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        width: calc(100% - 50px);
    }

    .support-search-input::placeholder {
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .support-window-underline {
        position: relative;
        width: calc(100% + 32px);
        left: -16px;
        margin: 16px 0;
        height: 1px;
        background: ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
    }

    .support-block-questions-title-block {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .support-block-question-title {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .support-block-question-description {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
    }

    .support-window-questions-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 16px;
        max-height: 300px;
        overflow-y: auto;
    }

    .support-window-questions-list::-webkit-scrollbar {
        background-color: transparent;
        width: 6px;
    }

    .support-window-questions-list::-webkit-scrollbar-thumb {
        background-color: black;
        border-radius: 4px;
    }

    .support-window-questions-list::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .support-window-question-item {
        display: flex;
        padding: 16px;
        flex-direction: column;
        gap: 8px;
        border-radius: 8px;
        background: ${props => props.theme.isDark ? '#2E2E2E' : '#F0F0F0'};
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;
        cursor: pointer;
    }

    .support-window-question-item-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }

    .support-window-question-item-heading.active img {
        transform: rotate(90deg);
    }

    .support-window-question-answers {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
    }

    @media (max-width: 1200px) {
        width: 340px;
        padding: 10px 16px;
        background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#FFFFFF'};
        position: absolute;
        right: ${props => props.theme.position === 'top' ? '0' : '16px'};
        bottom: ${props => props.theme.position === 'top' ? '55px' : '16px'};
        border-radius: 8px;
        box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.50);
    }
`

export const ShareModalBlock = styled(Modal)`
    .modal-dialog {
        min-width: unset;
        max-width: 560px;
        width: 100%;
    }

    .modal-content {
        background-color: ${props => props.theme.isDark ? '#2E2E2E' : '#FFF'};
        color: ${props => props.theme.isDark ? '#FFF' : '#1E1E1E'};
        display: flex;
        flex-direction: column;
        padding: 0px;
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }

    .modal-body {
        padding: 64px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        align-items: center;
    }

    .share-modal-title {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        text-align: center;
        font-family: "Almarai";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -0.72px;
    }

    .share-modal-description {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .share-modal-input-block {
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        border: 1.5px solid #A0A0A0;
        width: 100%;
        justify-content: space-between;
        margin-top: 40px;
    }

    .share-modal-input {
        background-color: transparent;
        width: calc(100% - 50px);
        color: #A0A0A0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .share-modal-input.copied {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    @media (max-width: 1200px) {
        .modal-dialog {
            min-width: unset;
            max-width: 520px;
            width: unset;
        }

        .modal-body {
            padding: 64px 32px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            justify-content: center;
            align-items: center;
        }
    }
`

export const SupportWindowWrapper = styled.div`
    @media (max-width: 1200px) {
        position: fixed;
        width: 100%;
        height: 100vh;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
    }
`

export const TermsAndConditionsBlock = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    background-color: ${props => props.theme.isDark ? '#1E1E1E' : '#F0F0F0'};

    .terms-and-conditions-wrapper {
        padding: 80px 16px;
        max-width: 1232px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 380px 1fr;
        gap: 24px;
        padding-top: 172px;
        width: 100%;
    }

    .terms-and-conditions-sticky-title {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Gilda Display";
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -2.56px;
        height: fit-content;
        position: sticky;
        top: 172px;
    }

    .terms-and-conditions-content {
        display: flex;
        flex-direction: column;
    }

    .terms-and-conditions-content-title {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Almarai";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 94.2%;
        letter-spacing: -0.72px;
        margin-bottom: 24px;
    }

    .terms-and-conditions-content-text {
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    .terms-and-conditions-content-border {
        height: 1px;
        width: 100%;
        margin: 40px 0;
        background-color: ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
    }

    ul {
        padding-left: 12px;
    }

    ul li::before {
        content: "•";
        padding-right: 10px;
        color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'}
    }

    .privacy-policy-blue-title {
        color: ${props => props.theme.isDark ? '#BEE0EA' : '#027FE3'};
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    @media (max-width: 1200px) {
        .terms-and-conditions-wrapper {
            padding: 16px;
            max-width: 1232px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 1fr;
            gap: 24px;
            padding-top: 108px;
            width: 100%;
        }

        .terms-and-conditions-sticky-title {
            color: ${props => props.theme.isDark ? '#FFFFFF' : '#1E1E1E'};
            font-family: "Gilda Display";
            font-size:48px;
            font-style: normal;
            font-weight: 400;
            line-height: 94.2%;
            letter-spacing: -1.92px;
            height: fit-content;
            position: initial;
            top: unset;
            padding-bottom: 24px;
            border-bottom: 1px solid ${props => props.theme.isDark ? '#4E4E4E' : '#CDCDCD'};
        }

        .terms-and-conditions-content-border {
            height: 1px;
            width: 100%;
            margin: 24px 0;
            background-color: ${props => props.theme.isDark ? '#4E4E4E' : '#E5E5E5'};
        }
    }
`