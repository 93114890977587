import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const BASE_URL = `https://api.sendgrid.com/v3/mail/send`;

export const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
        headers.set('Authorization', `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY}`);
        headers.set('Content-Type', 'application/json');

        return headers;
    },
});