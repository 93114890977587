import React, { useState } from "react";
import { useEffect } from "react";

const ThemeContext = React.createContext();

export const useThemeContext = () => React.useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
    const [isDarkTheme, setIsDarkTheme] = useState(false)
    const [selectedMode, setSelectedMode] = useState('Use system settings')

    useEffect(() => {
        const theme = localStorage.getItem('theme')

        if (theme) {
            if (theme === 'system') {
                const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches

                if (systemTheme) {
                    setIsDarkTheme(true)
                    setSelectedMode('Use system settings')
                } else {
                    setIsDarkTheme(false)
                    setSelectedMode('Use system settings')
                }
            } else if (theme === 'white') {
                setIsDarkTheme(false)
                setSelectedMode('Light mode')
            } else {
                setIsDarkTheme(true)
                setSelectedMode('Dark mode')
            }
        } else {
            localStorage.setItem('theme', 'system')
        }
    }, [])

    const value = {
        isDarkTheme,
        selectedMode,
        setIsDarkTheme,
        setSelectedMode,
    }

    return (
        <ThemeContext.Provider value={value}>
            {children}
        </ThemeContext.Provider>
    );
};
