import { useState } from "react";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import GetStartedTop from "../components/GetStartedTop/GetStartedTop";
import HomeGuide from "../components/HomeGuide/HomeGuide";
import GetStartedBottom from "../components/GetStartedBottom/GetStartedBottom";

import { PageWrapper } from "../styles/styles";

function MainPage() {
    const [companyName, setCompanyName] = useState('');

    const handleCompanyName = (e) => {
        setCompanyName(e.target.value);
    }

    return (
        <PageWrapper>
            <Header />
            <GetStartedTop companyName={companyName} handleCompanyName={handleCompanyName} />
            <HomeGuide />
            <GetStartedBottom companyName={companyName} handleCompanyName={handleCompanyName} />
            <Footer />
        </PageWrapper>
    );
}

export default MainPage;
