import React, { useEffect, useState } from 'react'
import { IoCloseOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { IoIosMenu } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';

import { HeaderMenuBlock, LogoEditorHeaderBlock } from '../../styles/styles'
import { userSignOut } from '../../firebase/auth/googleAuth';
import { useViewport } from '../../hooks/useViewport'
import { logoutUser } from '../../redux/appSlice';
import { api } from '../../api/api';

import DownloadModal from '../../modals/DownloadModal';
import ShareModal from '../../modals/ShareModal';
import AuthModal from '../../modals/AuthModal';

import EditorMenuSaved from '../../assets/icons/editor-menu-saved.svg'
import EditorMenuGenerated from '../../assets/icons/editor-menu-generated.svg'
import EditorMenuSettings from '../../assets/icons/editor-menu-settings.svg'
import EditorMenuBilling from '../../assets/icons/editor-menu-billing.svg'
import EditorMenuLogout from '../../assets/icons/editor-menu-logout.svg'
import EditorMenuSignUp from '../../assets/icons/editor-menu-signup.svg'
import ArrowBackBlackLeft from '../../assets/icons/arrow-left-back-black.svg'
import ArrowBackLeft from '../../assets/icons/arrow-left-back.svg'
import AuthModalImage from '../../assets/auth-modal-image.svg'
import ShareWhite from '../../assets/icons/share-white.svg'
import ShareBlack from '../../assets/icons/share-black.svg'
import DefaultAvatar from '../../assets/default-avatar.jpg'

const LogoEditorHeader = () => {
    const [isSignUp, setIsSignUp] = useState(true)
    const [showAuthModal, setShowAuthModal] = useState(false)
    const [showDownloadModal, setShowDownloadModal] = useState(false)
    const [showShareModal, setShowShareModal] = useState(false)
    const user = useSelector((state) => state.app.user)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { isDark } = useTheme()
    const { isMobile } = useViewport()

    const handleSignUp = () => {
        setIsSignUp(true)
        setShowAuthModal(true)
    }

    const handleLogout = async () => {
        await userSignOut()
        dispatch(api.util.resetApiState());
        dispatch(logoutUser())
    }

    const handleOpenDownload = () => {
        setShowDownloadModal(true)
    }

    const handleBackToExplorer = () => {
        navigate('/logo-maker?step=exploring')
    }

    const handleNavigateTo = (link) => {
        navigate(link)
    }

    const handleShareLink = () => {
        setShowShareModal(true)
        navigator.clipboard.writeText(`${window.location.href}`)
    }

    useEffect(() => {
        const newImage = new Image();
        newImage.src = AuthModalImage;
        window[AuthModalImage] = newImage;
    }, [])

    return (
        <LogoEditorHeaderBlock>
            <div className="flex gap-[8px]">
                {isDark ? (
                    <img className='logo-editor-back' onClick={handleBackToExplorer} src={ArrowBackLeft} alt="" />
                ) : (
                    <img className='logo-editor-back' onClick={handleBackToExplorer} src={ArrowBackBlackLeft} alt="" />
                )}
            </div>

            <div className="flex justify-end items-center flex-auto">
                <div className="flex gap-[8px] items-center">
                    <button onClick={handleShareLink} className="share-button">
                        {isMobile ? (
                            <>
                                {isDark ? (
                                    <img src={ShareWhite} alt="" />
                                ) : (
                                    <img src={ShareBlack} alt="" />
                                )}
                            </>
                        ) : (
                            'Share'
                        )}
                    </button>
                    {!user && (
                        <button onClick={handleSignUp} className="sign-up-button">Sign up</button>
                    )}
                    <button onClick={handleOpenDownload} className='download-button'>Download</button>
                    <div className="relative justify-center items-center h-[28px] w-[28px] block">
                        <HeaderMenuBlock right customBurgerIcon={<IoIosMenu className="menu-icon" />} customCrossIcon={<IoCloseOutline className='menu-close' />} >
                            <div className='header-menu-user'>
                                {user ? (
                                    <>
                                        <img className='avatar-navigation' src={DefaultAvatar} alt="" />
                                        {user.displayName ? user.displayName : 'User'}
                                    </>
                                ) : (
                                    <>
                                        Guest
                                    </>
                                )}
                            </div>
                            <div className='flex flex-col'>
                                <div className='header-menu-link' onClick={() => handleNavigateTo('/dashboard/saved-logos')}>
                                    <img width={26} src={EditorMenuSaved} alt="" />
                                    Saved logos
                                </div>
                                <div className='header-menu-link' onClick={() => handleNavigateTo('/logo-maker')}>
                                    <img width={26} src={EditorMenuGenerated} alt="" />
                                    Logo Generator
                                </div>
                            </div>
                            {user && (
                                <div className='flex flex-col'>
                                    <div className='header-menu-link' onClick={() => handleNavigateTo('/dashboard/account')}>
                                        <img width={26} src={EditorMenuSettings} alt="" />
                                        Your Account
                                    </div>
                                    <div className='header-menu-link' onClick={() => handleNavigateTo('/dashboard/billing-plans')}>
                                        <img width={26} src={EditorMenuBilling} alt="" />
                                        Billing & Plans
                                    </div>
                                    {/* <div className='header-menu-link' onClick={() => handleNavigateTo('/dashboard/purchase-history')}>
                                        <img width={26} src={EditorMenuHistory} alt="" />
                                        Purchase History
                                    </div> */}
                                </div>
                            )}
                            <div className="flex flex-col">
                                {user ? (
                                    <div className='header-menu-link' onClick={handleLogout}>
                                        <img width={26} src={EditorMenuLogout} alt="" />
                                        Logout
                                    </div>
                                ) : (
                                    <div className='header-menu-link' onClick={handleSignUp}>
                                        <img width={26} src={EditorMenuSignUp} alt="" />
                                        Sign up
                                    </div>
                                )}
                            </div>
                        </HeaderMenuBlock>
                    </div >
                </div>
            </div >
            {showAuthModal && (
                <AuthModal
                    show={showAuthModal}
                    handleClose={() => setShowAuthModal(false)}
                    signUp={isSignUp}
                    image={AuthModalImage}
                />
            )}
            {
                showDownloadModal && (
                    <DownloadModal
                        show={showDownloadModal}
                        handleClose={() => setShowDownloadModal(false)}
                    />
                )
            }
            {
                showShareModal && (
                    <ShareModal
                        show={showShareModal}
                        handleClose={() => setShowShareModal(false)}
                    />
                )
            }
        </LogoEditorHeaderBlock >
    )
}

export default LogoEditorHeader